import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import Label from "@components/form/Label";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { useAllLevel0Resources } from "@hooks/query/useAllLevel0Resources";
import { useAllLevel1Resources } from "@hooks/query/useAllLevel1Resources";
import { getAccountingPeriodOptions } from "@utils/getAccountingPeriodOptions";

type CreateExchangeRateStep1Props = {
  accountingPeriod?: { periodStart: Date; periodEnd: Date };
  level1Resource: any;
  level0Resources: any;
  onSave: (data: {
    accountingPeriod: { periodStart: Date; periodEnd: Date };
    level1Resource: any;
    level0Resources: any;
  }) => void;
  onWaterClassesChange: (level0Resources: any[]) => void;
};

const CreateExchangeRateStep1: React.FunctionComponent<
  CreateExchangeRateStep1Props
> = ({
  accountingPeriod,
  level1Resource,
  level0Resources,
  onSave,
  onWaterClassesChange,
}) => {
  const [selectedAccountingPeriod, setSelectedAccountingPeriod] =
    useState(accountingPeriod);
  const { t } = useTranslation();
  const [selectedScheme, setSelectedScheme] = useState<any>(level1Resource);
  const [selectedZones, setSelectedZones] = useState<any[]>(level0Resources);

  const { data: accountingPeriods, refetch: refetchAccountingPeriod } =
    useAllAccountingPeriods({
      params: { level1ResourceId: selectedScheme?.id },
      options: {
        enabled: Boolean(selectedScheme?.id),
      },
    });
  const { data: level1Resources } = useAllLevel1Resources();
  const { data: level0ResourceOptions = [] } = useAllLevel0Resources({
    params: {
      parentId: selectedScheme?.id,
      periodStart:
        selectedAccountingPeriod?.periodStart &&
        new Date(selectedAccountingPeriod.periodStart),
      periodEnd:
        selectedAccountingPeriod?.periodEnd &&
        new Date(selectedAccountingPeriod.periodEnd),
    },
    enabled: Boolean(selectedScheme?.id) && Boolean(selectedAccountingPeriod),
    select: (data: any[]) => {
      return data.map((level0) => ({
        label: level0.identifier,
        value: level0.id,
      }));
    },
  });

  const getWaterClassOptions = (waterClasses: any) => {
    return waterClasses
      ?.filter(
        (waterClass: any) =>
          !waterClass.name?.toLowerCase().includes("forward draw") &&
          !waterClass.name?.toLowerCase().includes("stock & domestic")
      )
      ?.map((waterClass: any) => ({
        label: waterClass.name,
        value: waterClass.id,
      }));
  };

  const handleSave = (e: any) => {
    if (!selectedAccountingPeriod) return;
    e.preventDefault();

    onSave({
      accountingPeriod: selectedAccountingPeriod,
      level1Resource: selectedScheme,
      level0Resources: selectedZones,
    });
  };

  const handleSelectAccountingPeriod = (value: any) => {
    setSelectedAccountingPeriod(value.value);
  };

  const handleSelectZones = (values: any) => {
    if (selectedScheme) {
      const selectedZones = values.map((value: any) =>
        selectedScheme?.level0WRSMembers?.find(
          (zone: any) => zone.id === value.value
        )
      );
      setSelectedZones(selectedZones);
      onWaterClassesChange(
        selectedZones.filter((z: any) => z.selectedWaterClasses?.length > 0)
      );
    }
  };

  const handleSelectWaterClasses = (zone: any, values: any) => {
    const updatedSelectedZones = [...selectedZones];

    updatedSelectedZones.forEach((targetZone) => {
      if (targetZone.id === zone.id) {
        targetZone.selectedWaterClasses = values.map((value: any) =>
          zone.waterClasses.find(
            (waterClass: any) => waterClass.id === value.value
          )
        );
      }
    });

    setSelectedZones(updatedSelectedZones);
    onWaterClassesChange(
      updatedSelectedZones.filter(
        (z: any) => z.selectedWaterClasses?.length > 0
      )
    );
  };

  return (
    <form className="space-y-8" onSubmit={handleSave}>
      <div className="p-6">
        <div>
          <Heading className="text-xl leading-6" light={true}>
            {t("exchange_rate.select_hierarchy")}
          </Heading>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <Label htmlFor="level1WRS">
              {t("exchange_rate.select_level1wrs")}
            </Label>
            <SelectLevel1Resource
              inputId="level1WRS"
              className="w-full"
              onChange={(e) => {
                setSelectedScheme(
                  level1Resources?.find(
                    (scheme: any) => scheme.id === e?.value
                  ) || undefined
                );
                refetchAccountingPeriod();
                setSelectedAccountingPeriod(undefined);
              }}
              value={selectedScheme?.id}
              isClearable
            />
          </div>
          {selectedScheme && (
            <div className="sm:col-span-4">
              <Label htmlFor="accountingPeriod">
                {t("exchange_rate.select_accounting_period")}
              </Label>
              <Select
                inputId="accountingPeriod"
                className="w-full"
                options={getAccountingPeriodOptions(accountingPeriods)}
                onChange={handleSelectAccountingPeriod}
                value={
                  getAccountingPeriodOptions(accountingPeriods)?.find(
                    (y) => y.value === selectedAccountingPeriod
                  ) || null
                }
                openMenuOnFocus
              />
            </div>
          )}
          {selectedAccountingPeriod && (
            <div className="sm:col-span-4">
              <Label htmlFor="level0WRS">
                {t("exchange_rate.select_level0wrs")}
              </Label>
              <Select
                inputId="level0WRS"
                className="w-full"
                isMulti={true}
                options={level0ResourceOptions}
                onChange={handleSelectZones}
                value={level0ResourceOptions.filter(
                  (option: any) =>
                    selectedZones.findIndex(
                      (zone) => zone.id === option.value
                    ) !== -1
                )}
                openMenuOnFocus
              />
            </div>
          )}
          {selectedZones?.map((zone: any) => (
            <div key={zone.identifier} className="sm:col-span-4">
              <Label htmlFor={`waterClass--${zone.identifier}`}>
                {t("exchange_rate.select_water_class", {
                  level0wrsIdentifier: zone?.identifier,
                })}
              </Label>
              <Select
                inputId={`waterClass--${zone.identifier}`}
                className="w-full"
                isMulti={true}
                options={getWaterClassOptions(zone?.waterClasses)}
                onChange={(values: any) =>
                  handleSelectWaterClasses(zone, values)
                }
                value={getWaterClassOptions(zone?.waterClasses)?.filter(
                  (option: any) => {
                    const selectedWaterClasses = selectedZones?.find(
                      (z) => z.id === zone.id
                    )?.selectedWaterClasses;

                    return (
                      selectedWaterClasses &&
                      selectedWaterClasses?.findIndex(
                        (waterClass: any) => waterClass.id === option.value
                      ) !== -1
                    );
                  }
                )}
                openMenuOnFocus
              />
            </div>
          ))}
        </div>
      </div>
      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <Link to={"/polestar"} className="btn-outline-primary">
          {t("common.cancel")}
        </Link>
        <button
          type="submit"
          className="btn-primary"
          disabled={
            !selectedScheme ||
            !selectedAccountingPeriod ||
            !selectedZones.length ||
            selectedZones.some((z) => !z?.selectedWaterClasses?.length)
          }
        >
          {t("common.next_step")}
        </button>
      </footer>
    </form>
  );
};

export default CreateExchangeRateStep1;
