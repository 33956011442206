import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loading from "@components/shared/Loading";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { type ConfirmData } from "@components/shared/ConfirmationDetail";
import { type Address } from "@services/subscriber";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";

const initialPrimaryUser = {
  id: "",
  name: "",
};

type User = typeof initialPrimaryUser;

type Subscriber = {
  id: string;
  walletId: string;
  level1Resource: {
    id: string;
    name: string;
    identifier: string;
  };
  accountNumber: string;
  name: string;
  abn?: string;
  isActive: boolean;
  address: Address;
  originalAccountNumber: string;
};

type GetFullAddressType = (address: Address) => string;

type ParseAddressType = (address: Address) => Partial<Address> | undefined;

type ContextValue = {
  subscriber: Subscriber;
  setSubscriber: React.Dispatch<React.SetStateAction<Subscriber>>;
  user: any;
  setUser: React.Dispatch<any>;
  cancel: () => void;
  getFullAddress: GetFullAddressType;
  parseAddress: ParseAddressType;
  getSubscriberInfo: () => ConfirmData;
  getUserInfo: () => ConfirmData;
  getPrimaryUserInfo: () => ConfirmData;

  isSubscriberComplete: boolean;
  setIsSubcriberComplete: React.Dispatch<React.SetStateAction<boolean>>;
  isUserComplete: boolean;
  setIsUserComplete: React.Dispatch<React.SetStateAction<boolean>>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  allUsers: any[];
  primaryUser: User;
  setPrimaryUser: React.Dispatch<React.SetStateAction<User>>;
};

const Context = React.createContext<ContextValue | undefined>(undefined);

const initialSubscriber: Subscriber = {
  id: "",
  walletId: "",
  accountNumber: "",
  name: "",
  level1Resource: {
    id: "",
    name: "",
    identifier: "",
  },
  isActive: true,
  address: {
    id: "",
    street: "",
    town: "",
    region: "",
    postcode: "",
    country: "",
    location: "",
  },
  originalAccountNumber: "",
};

function SubscriberCreateOrEditProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") ?? "";
  const level1ResourceId = searchParams.get("level1ResourceId") ?? "";
  const [subscriber, setSubscriber] = React.useState({ ...initialSubscriber });
  const [user, setUser] = React.useState<any>();
  const [isSubscriberComplete, setIsSubcriberComplete] = React.useState(false);
  const [isUserComplete, setIsUserComplete] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const [allUsers, setAllUsers] = React.useState<any[]>([]);
  const [primaryUser, setPrimaryUser] = React.useState({
    ...initialPrimaryUser,
  });

  const cancel = () => {
    Boolean(searchParams.get("newWindow")) ? window.close() : navigate(-1);
  };

  const { isLoading } = useSubscriber(subscriberId, {
    onSuccess: (data: any) => {
      const { address, users = [] } = data;
      setAllUsers(
        // true values first
        users.sort((a: any, b: any) => {
          return a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1;
        })
      );

      if (primaryUser.id === "") {
        const primaryUser = users.find((i: any) => i.isPrimary);
        setPrimaryUser({
          id: primaryUser?.id ?? "",
          name: primaryUser?.name ?? "",
        });
      }

      if (subscriber.id === "") {
        setSubscriber({
          id: data.id,
          walletId: data.walletId,
          level1Resource: {
            id: data.level1WRS?.id ?? "",
            name: data.level1WRS?.name ?? "",
            identifier: data.level1WRS?.identifier ?? "",
          },
          accountNumber: data.accountNumber ?? "",
          name: data.name ?? "",
          abn: data.abn ?? "",
          isActive: data.isActive,
          address: {
            id: address?.id ?? "",
            street: address?.street ?? "",
            town: address?.town ?? "",
            region: address?.region ?? "",
            postcode: address?.postcode ?? "",
            country: address?.country ?? "",
            location: address?.location ?? "",
          },
          originalAccountNumber: data.accountNumber,
        });
      }
    },
    keepPreviousData: false,
  });

  const { isLoading: isLevel1ResourceLoading } = useGetLevel1Resource(
    level1ResourceId,
    {
      onSuccess: (data: any) => {
        if (subscriber.level1Resource.id === "") {
          setSubscriber((prev) => ({
            ...prev,
            level1Resource: {
              id: data.id,
              name: data.name,
              identifier: data.identifier,
            },
          }));
        }
      },
    }
  );

  const getFullAddress: GetFullAddressType = (address) => {
    return [
      address.street,
      address.town,
      address.region,
      address.postcode,
      address.country,
    ]
      .filter(Boolean)
      .join(", ");
  };

  const parseAddress: ParseAddressType = (address) => {
    const result: Address = { ...address };
    let key: keyof Address;
    for (key in result) {
      if (!result[key]) {
        delete result[key];
      }
    }
    return Object.entries(result).length > 0 ? result : undefined;
  };

  const getSubscriberInfo = () => {
    return {
      title: t("subscriber.create.subscriber_details"),
      body: [
        {
          key: t("common.level1wrs"),
          value: subscriber.level1Resource.name,
        },
        {
          key: t("common.name"),
          value: subscriber.name,
        },
        {
          key: t("subscriber.abn"),
          value: subscriber.abn || "-",
        },
        {
          key: t("subscriber.create.subscriber_address"),
          value: subscriber.address ? getFullAddress(subscriber.address) : "",
        },
        {
          key: t("common.status"),
          value: subscriber.isActive
            ? t("common.active")
            : t("common.inactive"),
        },
      ],
    };
  };

  const getUserInfo = () => {
    return {
      title: t("user.details"),
      body: [
        {
          key: t("subscriber.name"),
          value: subscriber?.name,
        },
        {
          key: t("user.username"),
          value: user?.name,
        },
        {
          key: t("user.email"),
          value: user?.email,
        },
        {
          key: t("user.tel"),
          value: user?.tel,
        },
      ],
    };
  };

  const getPrimaryUserInfo = () => {
    return {
      title: t("user.create_primary_user.details"),
      body: [
        {
          key: t("user.username"),
          value: primaryUser.name,
        },
      ],
    };
  };

  if (
    (subscriberId && isLoading) ||
    (level1ResourceId && isLevel1ResourceLoading)
  ) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  return (
    <Context.Provider
      value={{
        subscriber,
        setSubscriber,
        user,
        setUser,
        cancel,
        getFullAddress,
        parseAddress,
        getSubscriberInfo,
        getUserInfo,
        getPrimaryUserInfo,
        isSubscriberComplete,
        setIsSubcriberComplete,
        isUserComplete,
        setIsUserComplete,
        workflowInstance,
        setWorkflowInstance,
        allUsers,
        primaryUser,
        setPrimaryUser,
      }}
    >
      {children}
    </Context.Provider>
  );
}

function useSubscriberCreateOrEditContext() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      "useSubscriberCreateOrEditContext must be used within a SubscriberCreateOrEditProvider"
    );
  }
  return context;
}

export { SubscriberCreateOrEditProvider, useSubscriberCreateOrEditContext };
