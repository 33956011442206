import { formatDate } from "@utils/formatDate";

export function formatAccountingPeriod(accountingPeriod: {
  periodStart: string;
  periodEnd: string;
}) {
  if (
    !accountingPeriod ||
    !accountingPeriod.periodStart ||
    !accountingPeriod.periodEnd
  )
    return "-";
  return `${formatDate(new Date(accountingPeriod.periodStart))} → ${formatDate(
    new Date(accountingPeriod.periodEnd)
  )}`;
}
