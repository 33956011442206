import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { t } from "i18next";
import Table from "@components/layout/Table";
import { useAppContext } from "@context/AppContext";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { formatVolume } from "@utils/formatVolume";

type Level1WRSAccountingPeriodListProps = {
  level1Resource: any;
};

const Level1WRSAccountingPeriodList: React.FunctionComponent<
  Level1WRSAccountingPeriodListProps
> = ({ level1Resource }) => {
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const { data: accountingPeriods, isLoading } = useAllAccountingPeriods({
    params: { level1ResourceId: level1Resource?.id, querySummary: true },
  });

  const tableData = accountingPeriods
    ?.map((accountingPeriod: any) => {
      const options = [
        {
          label: t("common.view"),
          value: `/polestar/accounting_period_checks/create?accountingPeriodId=${accountingPeriod.id}`,
          disabled:
            !checkPermissions(["ViewAccountingPeriod"]) ||
            accountingPeriod.isActive,
        },
        {
          label: t("common.reset"),
          value: `/polestar/accounting_period_checks/create?accountingPeriodId=${accountingPeriod.id}`,
          disabled:
            !checkPermissions(["ResetAccountingPeriod"]) ||
            !accountingPeriod.isActive,
        },
      ].filter((item) => (item.disabled ? false : item));

      return {
        ...accountingPeriod,
        accountingPeriod: formatAccountingPeriod(accountingPeriod),
        ptCountAndVolume: `${
          accountingPeriod.summary?.ptAmountAndCount?.count
        } (${formatVolume(
          accountingPeriod.summary?.ptAmountAndCount?.totalAmount
        )})`,
        stCountAndVolume: `${
          accountingPeriod.summary?.stAmountAndCount?.count
        } (${formatVolume(
          accountingPeriod.summary?.stAmountAndCount?.totalAmount
        )})`,
        fdCountAndVolume: `${
          accountingPeriod.summary?.fdAmountAndCount?.count
        } (${formatVolume(
          accountingPeriod.summary?.fdAmountAndCount?.totalAmount
        )})`,
        declarationCountAndUsage: `${
          accountingPeriod.summary?.declarationUsageAndCount?.count
        } (${formatVolume(
          accountingPeriod.summary?.declarationUsageAndCount?.totalUsage
        )})`,
        distributionLossUsage: `${formatVolume(
          accountingPeriod.summary?.distributionLossUsageAndCount?.totalUsage
        )}`,
        actions: (
          <Select
            placeholder={t("common.actions")}
            options={options}
            onChange={(e) => navigate(e?.value ?? "")}
            menuPortalTarget={document.body}
            closeMenuOnScroll={() => true}
            isSearchable={false}
            className="w-32"
          />
        ),
      };
    })
    .sort((a: any, b: any) => +a.accountNumber - +b.accountNumber);

  return (
    <>
      <Table
        containerClassName="rounded-none md:rounded-none text-sm"
        tableHeaderClassName="relative z-10"
        fields={[
          {
            title: t("common.accounting_period"),
            name: "accountingPeriod",
          },
          {
            title: t("level1wrs.permanent_trades"),
            name: "ptCountAndVolume",
          },
          {
            title: t("level1wrs.seasonal_trades"),
            name: "stCountAndVolume",
          },
          {
            title: t("accounting_period_checks.meter_reads"),
            name: "declarationCountAndUsage",
          },
          {
            title: t("accounting_period_checks.distribution_losses"),
            name: "distributionLossUsage",
          },
          {
            title: t("accounting_period_checks.forward_draws"),
            name: "fdCountAndVolume",
          },
          {
            title: t("common.action"),
            name: "actions",
          },
        ]}
        data={tableData}
        stickyHeader
        loading={isLoading}
      />
    </>
  );
};

export default Level1WRSAccountingPeriodList;
