import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import { useStepContext } from "@context/shared/StepContext";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { useStockAndDomesticApprovalContext } from "@context/StockAndDomesticApprovalContext";
import { isValidationError } from "@utils/formError";
import { toastSuccess, toastError } from "@utils/toast";

type ConfirmPermanentTransferProps = {
  onSubmit: () => Promise<void>;
};

const ConfirmCreateStockAndDomestic: React.FunctionComponent<
  ConfirmPermanentTransferProps
> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { stepHelpers } = useStepContext();
  const { info, isComplete, setIsComplete, workflowInstance, handleCancel } =
    useStockAndDomesticApprovalContext();
  const { getEvidencesInfo } = useEvidenceContext();

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await onSubmit();
      setIsComplete(true);
      toastSuccess(t("approval.stock_and_domestic.toast.create_success"));
    } catch (error: any) {
      const { data } = error?.response;
      const errorMessages = isValidationError(error)
        ? data?.errors?.map((i: any) => i.message)
        : [data?.message];

      toastError(
        <>
          <p>{t("approval.stock_and_domestic.toast.error")}</p>
          {errorMessages?.length ? (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => (
                <li key={text}>{text}</li>
              ))}
            </ul>
          ) : null}
        </>
      );
    }

    setIsLoading(false);
    setShowConfirmModal(false);
  };

  if (isComplete && workflowInstance) {
    return <WorkflowComplete references={[workflowInstance?.id]} />;
  }

  return (
    <>
      <div className="p-6 flex flex-col gap-4 grow">
        <Heading light>
          {t("approval.stock_and_domestic.confirm_transfer")}
        </Heading>

        <ConfirmationDetail
          data={[
            info.scheme,
            info.subscriber,
            info.extractionRight,
            info.details,
            getEvidencesInfo(),
          ]}
          onEdit={stepHelpers.setStep}
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
        >
          {t("common.ledger")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={isLoading}
      >
        {t("approval.stock_and_domestic.modal.confirm.approve")}
      </ConfirmModal>
    </>
  );
};

export default ConfirmCreateStockAndDomestic;
