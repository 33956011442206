import { useState } from "react";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import BalanceAdjustmentTable from "@components/table/BalanceAdjustmentTable";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";

const WaterHarvestingsChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { t } = useTranslation();
  const { currentAccountingPeriodCheck } = useAccountingPeriodChecksContext();
  const [data, setData] = useState<any[]>([]);

  return (
    <>
      <div className="flex flex-col p-4 grow gap-4">
        <Heading1 light>
          {t("accounting_period_checks.water_harvestings")}
          <strong className="ml-6">{data?.length}</strong>
        </Heading1>
        <BalanceAdjustmentTable
          level1ResourceId={currentAccountingPeriodCheck?.level1ResourceId}
          periodStart={
            currentAccountingPeriodCheck?.accountingPeriod?.periodStart &&
            new Date(currentAccountingPeriodCheck.accountingPeriod.periodStart)
          }
          periodEnd={
            currentAccountingPeriodCheck?.accountingPeriod?.periodEnd &&
            new Date(currentAccountingPeriodCheck.accountingPeriod.periodEnd)
          }
          openNewWindow={true}
          onLoaded={(data) => setData(data)}
        />
      </div>
      <AccountingPeriodChecklistFooter
        stepData={{
          waterHarvestingsCheckedAt: new Date(),
          waterHarvestingsCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />
    </>
  );
};

export default WaterHarvestingsChecklist;
