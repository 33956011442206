import api from "./api";

export const getExtractionPointById = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/extractionPoints/${id}`);
  return data.extractionPoint;
};

export type GetAllExtractionPointsParams = {
  level0ResourceId: string;
  level1ResourceId: string;
  definedByWalletId: string;
  name: string;
  isActive: boolean;
  source: string;
  showDeclarations: boolean;
  group: string;
  sequence: string;
};

export const getAllExtractionPoints = async ({
  params,
}: { params?: Partial<GetAllExtractionPointsParams> } = {}): Promise<any> => {
  const { data } = await api.get("/admin/extractionPoints", { params });

  return data.extractionPoints;
};

export const getExtractionPointsByLevel1WRS = async (
  level1ResourceId: string
): Promise<any> => {
  const { data } = await api.get("/admin/extractionPoints", {
    params: {
      level1ResourceId,
    },
  });

  return data.extractionPoints;
};

type Address = {
  region: string;
  street: string;
  location: string;
  postcode: string;
  town: string;
  country: string;
};

export type CreateExtractionPointPayload = {
  subscriberId: string;
  name: string;
  description?: string;
  comment?: string;
  maxFlow: number;
  volLimit: number;
  source: string;
  isActive: boolean;
  level0ResourceId?: string;
  meterId?: string;
  address?: Partial<Address>;
  group: string | null;
  sequence: number | null;
};

export const createExtractionPoint = async (
  payload: CreateExtractionPointPayload
): Promise<any> => {
  const { data } = await api.post("/admin/extractionPoints", payload);

  return [data.extractionPoint, data.workflowInstance];
};

export const linkToExtractionRights = async (payload: {
  extractionRightIds: string[];
  extractionPointId: string;
}) => {
  const { data } = await api.post("/admin/linkExtractionRights", payload);

  return data.extractionRight;
};

export type UpdateExtractionPointPayload = {
  extractionPointId: string;
  name?: string;
  description?: string;
  maxFlow?: number;
  volLimit?: number;
  source?: string;
  address?: {
    location?: string;
  };
  meterId?: string;
  group: string | null;
  sequence: number | null;
};

export const updateExtractionPoint = async (
  payload: UpdateExtractionPointPayload
) => {
  const { extractionPointId, ...extractionPoint } = payload;
  const { data } = await api.patch(
    `/admin/extractionPoints/${extractionPointId}`,
    {
      ...extractionPoint,
    }
  );

  return [data.extractionPoint, data.workflowInstance];
};

export const unlink = async ({
  extractionPointId,
  ...payload
}: {
  extractionPointId: string;
  extractionRightIds: string[];
  effectiveAt: Date;
}) => {
  const { data } = await api.put(
    `/admin/extractionPoints/${extractionPointId}/unlink`,
    payload
  );

  return [data.extractionPoint, data.workflowInstance];
};

export const generateExtractionPointName = async (params: {
  level1ResourceId: string;
}): Promise<number> => {
  const { data } = await api.get("/admin/generateExtractionPointName", {
    params,
  });

  return data;
};

export const generateExtractionPointSequence = async (params: {
  group: string;
}): Promise<number> => {
  const { data } = await api.get("/admin/generateExtractionPointSequence", {
    params,
  });

  return data;
};
