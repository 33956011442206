import { useTranslation } from "react-i18next";

import Card from "@components/layout/Card";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import UserTableActions from "@components/table/UserTableActions";
import NotificationIcon from "@components/icons/NotificationIcon";
import { useSubscriberIndexContext } from "@context/SubscriberIndexContext";
import { Link } from "react-router-dom";

const SubscriberAccountHoldersWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { subscriber = { users: [] }, refetchSubscriber } =
    useSubscriberIndexContext();

  const primaryUser = subscriber.users.filter((user: any) => user.isPrimary);
  const generalUser = subscriber.users.filter((user: any) => !user.isPrimary);
  const tableData = [...primaryUser, ...generalUser].map(
    (user: Record<string, any>) => {
      return {
        ...user,
        type: (
          <Tag status={user.isPrimary ? "info" : "warning"}>
            {user.isPrimary ? t("user.primary") : t("user.general")}
          </Tag>
        ),
        actions: <UserTableActions user={user} refetch={refetchSubscriber} />,
      };
    },
  );

  return (
    <Card
      className="h-full"
      header={
        <div className="flex items-center justify-between gap-3">
          <p className="inline-flex gap-3">
            <NotificationIcon /> {t("subscriber.accounts_widget_title")}
          </p>
          <Link
            to={`/polestar/subscribers/${subscriber.id}/users/create?subscriberId=${subscriber.id}`}
            className="btn-secondary text-sm rounded font-normal"
          >
            {t("common.add_new")}
          </Link>
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        <Table
          containerClassName="rounded-none md:rounded-none scrollbar-hide text-sm"
          tableHeaderClassName="relative z-10"
          fields={[
            {
              title: t("user.username"),
              name: "name",
            },
            {
              title: t("user.email"),
              name: "email",
            },
            {
              title: t("user.tel"),
              name: "tel",
            },
            {
              title: t("common.type"),
              name: "type",
            },
            {
              title: t("common.actions"),
              name: "actions",
            },
          ]}
          data={tableData}
        />
      </div>
    </Card>
  );
};

export default SubscriberAccountHoldersWidget;
