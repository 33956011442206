import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createStockAndDomestic } from "@services/administrativeApprovals";
import { isFunction } from "lodash";

export const useCreateStockAndDomestic = (
  options: Record<string, any> = {},
) => {
  const queryClient = useQueryClient();

  return useMutation(createStockAndDomestic, {
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["administrativeApprovals"],
      });

      if (isFunction(options?.onSuccess)) {
        options.onSuccess(res);
      }
    },
    ...options,
  });
};
