import React from "react";

const TransactionIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#clip0_878_36643)">
        <path
          fill="#004A88"
          fillRule="evenodd"
          d="M1.5 17.75a.75.75 0 00.75.75h17.69l-4.721 4.719a.75.75 0 001.062 1.062l6-6a.75.75 0 000-1.062l-6-6a.75.75 0 10-1.062 1.062L19.939 17H2.25a.75.75 0 00-.75.75zm21-10.5a.75.75 0 01-.75.75H4.06l4.72 4.719a.75.75 0 11-1.061 1.062l-6-6a.75.75 0 010-1.062l6-6A.75.75 0 118.78 1.781L4.06 6.5H21.75a.75.75 0 01.75.75z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_878_36643">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="rotate(90 11.75 12.25)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default TransactionIcon;
