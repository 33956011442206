import { useTranslation } from "react-i18next";
import { MapPinIcon } from "@heroicons/react/24/outline";

import Card from "@components/layout/Card";
import DamLevelIcon from "@components/icons/DamLevelIcon";
import { useGetWaterLevels } from "@hooks/query/zenith/useGetWaterLevels";
import { useEffect, useState } from "react";
import Loading from "@components/shared/Loading";
import MapView from "@components/shared/MapView";
import { formatDateTime } from "@utils/formatDateTime";

const DamLevelWidget = () => {
  const { t } = useTranslation();
  const [location, setLocation] = useState<{ lat: number; lng: number }>();
  const [error, setError] = useState<string>();
  const [waterLevel, setWaterLevel] = useState<any>();
  const { data: waterLevels = [] } = useGetWaterLevels({
    lat: location?.lat || 0,
    lng: location?.lng || 0,
    n: 1,
    options: {
      enabled: !!location?.lat && !!location?.lng,
      onSuccess: (data: any) => {
        setWaterLevel(data[0]);
      },
    },
  });

  useEffect(() => {
    if (!navigator.geolocation) {
      setError(t("error.geolocation") as string);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ lat: latitude, lng: longitude });
      },
      () => {
        setError(t("widgets.water_level.error_geolocation") as string);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      header={
        <p className="flex gap-3">
          <DamLevelIcon className="w-6 h-6 text-primary-2" />{" "}
          {t("zenith.dashboard.water_level")}
        </p>
      }
    >
      {error ? (
        <div className="py-20 text-center">{error}</div>
      ) : waterLevels.length > 0 ? (
        <div className="flex flex-col gap-2">
          <div className="w-full h-64">
            <MapView
              center={{ lat: waterLevels[0].lat, lng: waterLevels[0].lng }}
              zoomLevel={15}
              markers={waterLevels.map((waterLevel: any) => ({
                lat: waterLevel.lat,
                lng: waterLevel.lng,
              }))}
              onMarkerClick={(e) => {
                const selectedWaterLevel = waterLevels.find(
                  (waterLevel: any) =>
                    waterLevel.lat === e.lat && waterLevel.lng === e.lng
                );

                if (selectedWaterLevel) {
                  setWaterLevel(selectedWaterLevel);
                }
              }}
            />
          </div>
          <p className="flex gap-2 mt-2">
            <MapPinIcon className="w-5 h-5" />
            {waterLevel?.name}
          </p>
          <dl className="w-full pl-8">
            <div className="flex gap-4">
              <dt className="w-1/4 text-sm font-bold">
                {t("widgets.water_level.value")}
              </dt>
              <dd className="text-sm">
                {waterLevel?.value} {waterLevel?.unit}
              </dd>
            </div>
            <div className="flex gap-4">
              <dt className="w-1/4 text-sm font-bold">
                {t("widgets.water_level.date")}
              </dt>
              <dd className="text-sm">
                {formatDateTime(new Date(waterLevel?.date))}
              </dd>
            </div>
          </dl>
        </div>
      ) : (
        <div className="py-20">
          <Loading loadingText={t("widgets.water_level.loading") as string} />
        </div>
      )}
    </Card>
  );
};

export default DamLevelWidget;
