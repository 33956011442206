import { useTranslation } from "react-i18next";

import NotificationIcon from "@components/icons/NotificationIcon";
import Card from "@components/layout/Card";
import StackScale from "@components/layout/StackScale";
import { useSubscriberIndexContext } from "@context/SubscriberIndexContext";

const defaultDataset = [
  { title: "01", values: [{ legend: "", value: 0.0 }] },
  { title: "02", values: [{ legend: "", value: 0.0 }] },
  { title: "03", values: [{ legend: "", value: 0.0 }] },
  { title: "04", values: [{ legend: "", value: 0.0 }] },
  { title: "05", values: [{ legend: "", value: 0.0 }] },
  { title: "06", values: [{ legend: "", value: 0.0 }] },
  { title: "06", values: [{ legend: "", value: 0.0 }] },
  { title: "07", values: [{ legend: "", value: 0.0 }] },
  { title: "08", values: [{ legend: "", value: 0.0 }] },
  { title: "09", values: [{ legend: "", value: 0.0 }] },
  { title: "10", values: [{ legend: "", value: 0.0 }] },
  { title: "11", values: [{ legend: "", value: 0.0 }] },
  { title: "11", values: [{ legend: "", value: 0.0 }] },
  { title: "12", values: [{ legend: "", value: 0.0 }] },
];

const SubscriberDeclarationChartWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { subscriber = { declarations: [] } } = useSubscriberIndexContext();

  const subscriberDeclarationsDataset = subscriber?.declarations
    .map((d: any) => d.classProportions)
    .filter((d: any) => d.month !== "0")
    .map((d: any) => {
      const values = [];
      for (const value of d.proportions) {
        values.push({
          legend: Object.keys(value)[0],
          value: +(Number(Object.values(value)[0]) / 1000000).toFixed(3),
        });
      }
      return { title: d.month, values };
    })
    .reduce((accumulator: any, object: any) => {
      const indexOfMonth = accumulator.findIndex(
        (e: any) => e.title === object.title
      );
      if (indexOfMonth > -1) {
        accumulator[indexOfMonth].values.push(...object.values);
      } else {
        accumulator.push(object);
      }

      return accumulator;
    }, [])
    .map((d: any) => {
      const cumulativeValue: Array<{ legend: string; value: number }> = [];
      for (const value of d.values) {
        const cumulativeValueIndex = cumulativeValue.findIndex(
          (cv: any) => cv.legend === value.legend
        );
        if (cumulativeValueIndex > -1) {
          cumulativeValue[cumulativeValueIndex].value += value.value;
        } else {
          cumulativeValue.push(value);
        }
      }
      d.values = cumulativeValue;
      return d;
    });

  const filterOuts = subscriberDeclarationsDataset?.map((sd: any) => sd.title);

  const dataset = [
    ...subscriberDeclarationsDataset,
    ...defaultDataset.filter((dd) => !filterOuts.includes(dd.title)),
  ].sort((first, second) => +first.title - +second.title);

  const defaultColors = ["#FFB200", "#34B53A", "#4339F2"];

  const zones: any[] = [];
  subscriberDeclarationsDataset?.forEach((sdd: any) => {
    sdd.values.forEach((v: any) => {
      const indexOfZone = zones.indexOf(v.legend);
      if (!(indexOfZone > -1)) {
        zones.push(v.legend);
      }
    });
  });

  const legends: any[] = [];
  for (const zone of zones) {
    let color = "";
    if (defaultColors.length > 0) {
      color = defaultColors.shift()!;
    } else {
      color =
        "#" +
        (
          "00000" + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)
        ).slice(-6);
    }
    legends.push({ title: zone, color });
  }

  return (
    <Card
      className="h-full"
      header={
        <p className="flex gap-3">
          <NotificationIcon />
          {t("subscriber.volume_used_widget_title")}
        </p>
      }
    >
      {subscriberDeclarationsDataset.length > 0 ? (
        <StackScale
          className="w-full h-96"
          legends={legends}
          dataset={dataset}
        />
      ) : (
        <div className="font-light text-primary-2 text-center text-xl">
          {t("declaration.no_data")}
        </div>
      )}
    </Card>
  );
};

export default SubscriberDeclarationChartWidget;
