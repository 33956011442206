import SubscriberAccountHoldersWidget from "./SubscriberAccountHoldersWidget";
import SubscriberDeclarationChartWidget from "./SubscriberDeclarationChartWidget";
import SubscriberLevel0WRSBalanceWidget from "./Level0WRSBalanceWidget";
import SubscriberNotificationWidget from "./SubscriberNotificationWidget";

const SubscriberDashboard: React.FunctionComponent = () => {
  return (
    <div className="grid grid-cols-6 gap-6 mb-10">
      <div className="col-span-2">
        <SubscriberNotificationWidget />
      </div>
      <div className="col-span-4">
        <SubscriberLevel0WRSBalanceWidget />
      </div>
      <div className="col-span-3">
        <SubscriberDeclarationChartWidget />
      </div>
      <div className="col-span-3">
        <SubscriberAccountHoldersWidget />
      </div>
    </div>
  );
};

export default SubscriberDashboard;
