import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import SelectSingleSubscriberTable from "@components/table/SelectSingleSubscriberTable";
import { useStepContext } from "@context/shared/StepContext";
import { useStockAndDomesticApprovalContext } from "@context/StockAndDomesticApprovalContext";

const SelectSubscriber = () => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();

  const { details, setDetails, handleCancel } =
    useStockAndDomesticApprovalContext();

  return (
    <>
      <div className="flex flex-col gap-4 p-6 grow">
        <Heading light>
          {t("approval.stock_and_domestic.select_subscriber")}
        </Heading>
        <SelectSingleSubscriberTable
          onSelect={(value: any) => {
            setDetails((prev) => ({
              ...prev,
              subscriber: {
                id: value.id,
                name: value.name,
                accountNumber: value.accountNumber,
                walletId: value.walletId,
              },
              extractionRight: {
                id: "",
                name: "",
              },
            }));
          }}
          selected={details.subscriber}
          params={{
            level1ResourceId: details.level1Resource.id,
          }}
        />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
          disabled={!details.subscriber.id}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SelectSubscriber;
