import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { noop } from "lodash";

type Heading1Props = {
  className?: string;
  light?: boolean;
  children: ReactNode;
  onClick?: () => void;
};

const Heading1: React.FunctionComponent<Heading1Props> = ({
  className,
  children,
  light,
  onClick = noop,
}) => {
  return (
    <h1
      className={twMerge(
        "text-xl md:text-2xl text-primary-3 font-roboto",
        light ? "font-light" : "font-bold",
        className
      )}
      onClick={onClick}
    >
      {children}
    </h1>
  );
};

export default Heading1;
