import { formatDate } from "../utils/formatDate";
import api from "./api";

export const getAllExchangeRates = async (params?: any) => {
  const { data } = await api.get("/admin/exchangeRates", { params });
  return data.exchangeRates ?? [];
};

export const saveExchangeRates = async (exchangeRates: {
  waterClassIds: string[];
  rate: number;
  startAt: Date;
}): Promise<any> => {
  const { data } = await api.post("/admin/exchangeRates", {
    ...exchangeRates,
    startAt: formatDate(exchangeRates.startAt, "yyyy-MM-dd"),
  });

  return [data.exchangeRates, data.workflowInstance];
};
