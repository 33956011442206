import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { sumBy } from "lodash";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import TextInput from "@components/form/TextInput";
import AdjustLevel0ResourceWaterClassesTable from "@components/table/AdjustLevel0ResourceWaterClassesTable";
import { useAppContext } from "@context/AppContext";
import { useWaterClassContext } from "@context/WaterClassContext";
import { convertLiterToML, convertMLToLiter } from "@utils/convertUnits";

const schema = z.object({
  volume: z.number().positive(),
  allocation: z.number().gte(0).lte(100),
  acctPriority: z.number().positive(),
  startAt: z.string(),
});

const WaterClassVolume = () => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const {
    stepHelpers,
    details,
    setDetails,
    navigateForCancel,
    isExceedTotalWaterInZone,
    updatingWaterClass,
    waterClasses,
    isVolumeExceed,
    setIsVolumeExceed,
    setVolumeToValidate,
    doAdjustWaterClassVolumeDetails,
    setDoAdjustWaterClassVolumeDetails,
    adjustedWaterClassVolumeDetails,
    setAdjustedWaterClassVolumeDetails,
    level0Resource,
    validateCurrentClassVolume,
    validateAdjustedClassVolume,
    volumeToValidate,
    totalWaterClassesVolume,
    getInfoMessages,
  } = useWaterClassContext();

  const totalCurrentAPClassVolume = +convertLiterToML(totalWaterClassesVolume);

  const totalClassVolumeOfOtherClasses =
    totalCurrentAPClassVolume - +convertLiterToML(updatingWaterClass?.volume);

  const isAdjustingClassBalanceMandatory =
    volumeToValidate !== undefined
      ? volumeToValidate + totalClassVolumeOfOtherClasses >
        totalCurrentAPClassVolume
      : false;

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      volume: details?.volume ?? 0,
      allocation: details?.allocation ?? 0,
      startAt: details?.startAt ?? "",
      acctPriority: details?.acctPriority ?? 0,
    },
  });

  const isUpdating = Boolean(updatingWaterClass);

  const availableToDistribute =
    +convertLiterToML(level0Resource.yield) -
    sumBy(
      adjustedWaterClassVolumeDetails.map((i: any) => {
        if (i.classId === updatingWaterClass?.id) {
          return volumeToValidate
            ? +volumeToValidate
            : +convertLiterToML(updatingWaterClass.volume);
        }
        return +i.adjustedVolume;
      })
    );

  const submit = async (data: any) => {
    const isExceed = isUpdating
      ? false
      : isExceedTotalWaterInZone(convertMLToLiter(data.volume));
    setIsVolumeExceed(isExceed);
    if (isExceed) {
      window.scrollTo(0, 0);
    } else {
      setDetails((prevState: any) => ({ ...prevState, ...data }));
      stepHelpers.goToNextStep();
    }
  };

  return (
    <div className="flex flex-col grow p-6">
      <Heading light className="mb-8">
        {t("water_class.class_volume")}
      </Heading>

      <form
        className="flex flex-col grow space-y-8"
        onSubmit={handleSubmit(submit)}
      >
        <fieldset className="space-y-4">
          <Legend>{t("water_class.enter_class_volume")}</Legend>
          <div className="max-w-xs">
            <Label htmlFor="volume">
              {t("water_class.water_class_volume")}
            </Label>
            <TextInput
              {...register("volume", {
                valueAsNumber: true,
                onChange: (e) => {
                  setDoAdjustWaterClassVolumeDetails(false);
                  setVolumeToValidate(+e.target.value);

                  setAdjustedWaterClassVolumeDetails((prev) => {
                    return prev.map((i) => {
                      if (updatingWaterClass?.id === i.classId) {
                        return {
                          ...i,
                          adjustedVolume: e.target.value,
                        };
                      }
                      return i;
                    });
                  });
                },
                onBlur: () => {
                  validateCurrentClassVolume(isAdjustingClassBalanceMandatory);
                },
              })}
              id="volume"
              errorMessage={
                isVolumeExceed
                  ? (t("water_class.volume_exceed") as string)
                  : (errors?.volume?.message as string)
              }
              suffix={t("common.volume_unit")}
            />
          </div>

          {checkPermissions(["CreateWaterClassesWithAllocation"]) && (
            <>
              <div className="max-w-xs">
                <Label htmlFor="allocation">
                  {t("water_class.current_allocation")}
                </Label>
                <TextInput
                  {...register("allocation", { valueAsNumber: true })}
                  id="allocation"
                  errorMessage={errors?.allocation?.message as string}
                  disabled={isUpdating}
                  suffix="%"
                />
              </div>
              <div className="max-w-xs">
                <Label htmlFor="startAt">
                  {t("exchange_rate.enter_start_at")}
                </Label>
                <TextInput
                  {...register("startAt")}
                  type="date"
                  id="startAt"
                  errorMessage={errors?.startAt?.message as string}
                  disabled={isUpdating}
                />
              </div>
            </>
          )}
        </fieldset>

        <fieldset className="space-y-4">
          <Legend>{t("water_class.enter_priority")}</Legend>
          <div className="max-w-xs">
            <Label htmlFor="acctPriority">{t("water_class.priority")}</Label>
            <TextInput
              {...register("acctPriority", { valueAsNumber: true })}
              id="acctPriority"
              errorMessage={errors?.acctPriority?.message as string}
            />
          </div>
        </fieldset>

        {isUpdating && (
          <AdjustLevel0ResourceWaterClassesTable
            waterClasses={waterClasses.map((wc: any) => {
              if (wc.id === updatingWaterClass?.id) {
                return {
                  ...wc,
                  volumeNextYear: convertMLToLiter(volumeToValidate ?? 0),
                };
              }
              return { ...wc };
            })}
            nextYearLevel0wrsVolume={
              +level0Resource.yieldNextAccountingPeriod === 0
                ? +convertLiterToML(level0Resource.yield)
                : +convertLiterToML(level0Resource.yieldNextAccountingPeriod)
            }
            level0wrsIdentifier={level0Resource.identifier}
            hasValidationError={getInfoMessages("error", 2).length > 0}
            currentYearLevel0wrsVolume={+level0Resource.yield}
            doAdjustWaterClassVolumeDetails={doAdjustWaterClassVolumeDetails}
            setDoAdjustWaterClassVolumeDetails={
              setDoAdjustWaterClassVolumeDetails
            }
            adjustedWaterClassVolumeDetails={adjustedWaterClassVolumeDetails}
            setAdjustedWaterClassVolumeDetails={
              setAdjustedWaterClassVolumeDetails
            }
            validateAdjustedClassVolume={validateAdjustedClassVolume}
            isAdjustingClassBalanceMandatory={isAdjustingClassBalanceMandatory}
            keepFixedNextYearVolume={{
              classId: updatingWaterClass?.id,
              nextYearVolume:
                volumeToValidate ??
                +convertLiterToML(updatingWaterClass?.volume),
            }}
          />
        )}

        <div className="grow" />

        <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={() => {
              setDetails((prev: any) => ({
                ...prev,
                ...watch(),
              }));
              stepHelpers.goToPrevStep();
              setIsVolumeExceed(false);
            }}
          >
            {t("common.prev_step")}
          </button>
          <button
            type="submit"
            className="btn-primary"
            disabled={
              availableToDistribute < 0 ||
              (isAdjustingClassBalanceMandatory &&
                !doAdjustWaterClassVolumeDetails)
            }
          >
            {t("common.next_step")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </form>
    </div>
  );
};

export default WaterClassVolume;
