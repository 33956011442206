import { formatDate } from "@utils/formatDate";

export function getAccountingPeriodOptions(
  accountingPeriods: Record<string, any>[]
) {
  return accountingPeriods?.map((y) => ({
    label: `${formatDate(new Date(y.periodStart))} → ${formatDate(
      new Date(y.periodEnd)
    )}`,
    value: y,
  }));
}
