import { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import PermanentTransferModal from "@components/modal/PermanentTransferModal";
import { AdministrativeApprovalType } from "@services/administrativeApprovals";
import { useAppContext } from "@context/AppContext";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import { useGetAdministrativeApproval } from "@hooks/query/useGetAdministrativeApproval";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { getAccountingPeriodOptions } from "@utils/getAccountingPeriodOptions";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";

type Level1WRSPermanentTradeProps = {
  level1Resource: any;
};

const statusString: Record<number, string> = {
  1: t("common.pending") as string,
  2: t("common.success") as string,
  3: t("common.rejected") as string,
  4: t("common.require_info") as string,
};

const Level1WRSPermanentTrade: React.FunctionComponent<
  Level1WRSPermanentTradeProps
> = ({ level1Resource }) => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState("");
  const [filter, setFilter] = useState<{
    accountingPeriod?: {
      id: string;
      periodStart: Date;
      periodEnd: Date;
    };
    customerName?: string;
    status?: string;
  }>({ accountingPeriod: level1Resource?.activeAccountingPeriod });

  const { data: accountingPeriods } = useAllAccountingPeriods({
    params: { level1ResourceId: level1Resource?.id },
  });

  const { data: permanentTrades = [], isLoading } =
    useAllAdministrativeApprovals({
      params: {
        type: AdministrativeApprovalType.PT,
      },
    });

  const { data: approval } = useGetAdministrativeApproval(selectedId);

  const getStatusOptions = () => {
    const statuses: any[] = permanentTrades
      ? permanentTrades.map((row: any) => row.status)
      : [];

    return statuses
      ?.map((status) => ({
        label: statusString[status as number],
        value: status,
      }))
      .filter(
        (option, i, self) =>
          self.findIndex((status) => status.value === option.value) === i
      );
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex justify-between items-end gap-3">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 2xl:w-2/1 relative z-20 justify-between items-end">
          <div>
            <Label>{t("common.accounting_period")}</Label>
            <Select
              className="w-full"
              isClearable
              options={getAccountingPeriodOptions(accountingPeriods)}
              onChange={(e: any) =>
                handleFilterChange("accountingPeriod", e?.value)
              }
              value={
                getAccountingPeriodOptions(accountingPeriods)?.find(
                  (y) => y.value.id === filter.accountingPeriod?.id
                ) || null
              }
            />
          </div>
          <div>
            <Label htmlFor="customer-name">{t("subscriber.filter.name")}</Label>
            <SearchInput
              id="customer-name"
              onChange={(e) =>
                handleFilterChange("customerName", e.target.value)
              }
            />
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <Select
              options={getStatusOptions()}
              value={getStatusOptions().find((e) => e.value === filter?.status)}
              onChange={(e: any) => {
                handleFilterChange("status", e?.value);
              }}
              isClearable
            />
          </div>
        </form>
        {checkPermissions(["CreateApprovals"]) && (
          <Link
            to={`/polestar/administrative_approvals/permanent_trade?level1ResourceId=${level1Resource.id}`}
            className="btn-secondary text-sm whitespace-nowrap"
          >
            {t("accounting_period_checks.add_permanent_trade")}
          </Link>
        )}
      </div>
      <Table
        fields={[
          {
            title: t("common.level1wrs"),
            name: "level1Resource",
          },
          {
            title: t("common.accounting_period"),
            name: "accountingPeriod",
          },
          {
            title: t("approval.permanent_trades.from_subscriber"),
            name: "sellerName",
          },
          {
            title: t("approval.permanent_trades.to_subscriber"),
            name: "buyerName",
          },
          {
            title: t("approval.permanent_trades.price"),
            name: "price",
          },
          {
            title: t("extraction_right.volume"),
            name: "volume",
          },
          {
            title: t("common.status"),
            name: "status",
          },
          {
            title: t("common.timestamp"),
            name: "createdAt",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={permanentTrades
          ?.filter(
            (row: { level1Resource: any }) =>
              row.level1Resource.id === level1Resource.id
          )
          ?.filter(
            (row: { seller: any; buyer: any }) =>
              !filter?.customerName?.length ||
              row.seller?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase()) ||
              row.buyer?.name
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase())
          )
          ?.filter(
            (row: { accountingPeriod: any }) =>
              filter.accountingPeriod === undefined ||
              row.accountingPeriod?.id === filter.accountingPeriod?.id
          )
          ?.filter(
            (row: { status: any }) =>
              filter.status === undefined || row.status === filter.status
          )
          ?.map((app: any) => ({
            ...app,
            level1Resource: app.level1Resource.identifier,
            createdAt: formatDate(new Date(app.lodgedAt)),
            accountingPeriod: formatAccountingPeriod(app.accountingPeriod),
            sellerName: app.seller.name,
            buyerName: app.buyer.name,
            volume: formatVolume(
              sumBy(app.extractionRights, (i: any) => +i.volume)
            ),
            price: `$${app.price}`,
            status: <Tag status="success">{t("common.success")}</Tag>,
            action: (
              <div className="flex gap-1 items-center w-full">
                <button
                  type="button"
                  onClick={() => setSelectedId(app.id)}
                  className="btn-default text-sm"
                >
                  {t("common.view")}
                </button>
              </div>
            ),
          }))}
        stickyHeader
        loading={isLoading}
      />

      {selectedId && approval ? (
        <PermanentTransferModal
          data={approval}
          onClose={() => setSelectedId("")}
        />
      ) : null}
    </>
  );
};

export default Level1WRSPermanentTrade;
