import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import TextArea from "@components/form/TextArea";
import { useStepContext } from "@context/shared/StepContext";
import { useStockAndDomesticApprovalContext } from "@context/StockAndDomesticApprovalContext";

const EnterDetails = () => {
  const { t } = useTranslation();

  const { stepHelpers } = useStepContext();
  const { details, setDetails, handleCancel } =
    useStockAndDomesticApprovalContext();

  return (
    <form
      className="p-6 pb-0 flex flex-col gap-4 grow"
      onSubmit={(e) => {
        e.preventDefault();
        stepHelpers.goToNextStep();
      }}
    >
      <Heading light>{t("approval.stock_and_domestic.enter_details")}</Heading>
      <div className="max-w-xs">
        <Label htmlFor="volume">{t("common.volume")}:</Label>
        <TextInput
          type="number"
          min={0}
          step="0.001"
          id="volume"
          value={details.volume}
          onChange={(e) =>
            setDetails((prev) => ({
              ...prev,
              volume: e.target.value,
            }))
          }
          suffix={t("common.volume_unit")}
          placeholder="0.000"
          required
        />
      </div>

      <div className="max-w-xs">
        <Label htmlFor="lodgedAt">
          {t("approval.stock_and_domestic.lodged_at")}:
        </Label>
        <TextInput
          type="date"
          id="lodgedAt"
          value={details.lodgedAt}
          onChange={(e) =>
            setDetails((prev) => ({
              ...prev,
              lodgedAt: e.target.value,
            }))
          }
          required
        />
      </div>

      <div className="max-w-lg">
        <Label htmlFor="reason" optional>
          {t("approval.stock_and_domestic.enter_reason")}:
        </Label>
        <TextArea
          id="reason"
          value={details.reason}
          onChange={(e) =>
            setDetails((prev) => ({
              ...prev,
              reason: e.target.value,
            }))
          }
          rows={4}
        />
      </div>
      <div className="grow" />

      <footer className="flex gap-4 -mx-6 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default EnterDetails;
