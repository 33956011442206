import { useTranslation } from "react-i18next";

import DropWaterIcon from "@components/icons/DropWaterIcon";
import Card from "@components/layout/Card";

const WaterUsageWidget = () => {
  const { t } = useTranslation();

  return (
    <Card
      className="p-6"
      header={
        <h2 className="flex gap-3 item-centers text-inherit">
          <DropWaterIcon className="w-5 h-5" />
          {t("zenith.dashboard.water_usage")}
        </h2>
      }
    >
      <div className="space-y-6">
        <div className="text-sm">
          <p>View by:</p>
          <div className="flex items-end gap-2 mt-1">
            <button className="btn-secondary text-xs rounded py-1.5 px-3">
              {t("common.extraction_right")}
            </button>
            <button className="btn-default text-xs rounded py-1.5 px-3">
              {t("common.water_class")}
            </button>
            <button className="btn-default text-xs rounded py-1.5 px-3">
              {t("zenith.dashboard.management_area")}
            </button>
          </div>
        </div>

        <ul className="space-y-4">
          {[1, 2, 3, 4].map((value) => {
            return (
              <li key={`my-usage-${value}`}>
                <div className="text-sm flex justify-between items-center mb-1">
                  <span>190826</span>
                  <span>84%</span>
                </div>
                <div className="relative">
                  <div className="h-2 w-full rounded-lg bg-blue-100" />
                  <div className="absolute top-0 left-0 h-2 w-3/4 rounded-lg bg-blue-500" />
                  <div className="absolute top-0 left-0 h-2 w-1/4 rounded-lg bg-green-500" />
                </div>
              </li>
            );
          })}
        </ul>

        <div className="flex justify-center">
          <button className="btn-secondary text-sm rounded">
            {t("common.view_all")}
          </button>
        </div>
      </div>
    </Card>
  );
};

export default WaterUsageWidget;
