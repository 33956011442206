import React, { useState } from "react";
import classNames from "classnames";
import Select from "react-select";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { startCase, sumBy } from "lodash";
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import Card from "@components/layout/Card";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Tag from "@components/shared/Tag";
import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmUpdateExtractionRightStatusModal from "@components/modal/ConfirmUpdateExtractionRightStatusModal";
import { useSubscriberIndexContext } from "@context/SubscriberIndexContext";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { useAppContext } from "@context/AppContext";
import {
  AdministrativeApprovalType,
  ExtractionRightApprovalType,
} from "@services/administrativeApprovals";
import { isValidationError } from "@utils/formError";
import { useRequestAmalgamationOrSubdivision } from "@hooks/mutation/useRequestAmalgamation";
import { toastError } from "@utils/toast";
import { CanAmalgamate } from "@utils/getExtractionRightsCanAmalgamate";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";

const ExtractionRightTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { checkPermissions } = useAppContext();
  const [selectedExtractionRightIds, setSelectedExtractionRightIds] = useState<
    string[]
  >([]);
  const [subdivisionRightId, setSubdivisionRightId] = useState<string>();
  const [confirmModalString, setConfirmModalString] = useState<
    AdministrativeApprovalType.AME | AdministrativeApprovalType.SDE | ""
  >("");
  const [showInactiveExtractionRights, setShowInactiveExtractionRights] =
    useState(searchParams.get("isActive") === "false");
  const {
    subscriber,
    filter,
    handleFilterChange,
    refetchSubscriber,
    zonePriorityBalance,
  } = useSubscriberIndexContext();
  const { mutateAsync: requestAmalgamationMutation, isLoading } =
    useRequestAmalgamationOrSubdivision();
  const [updateExtractionRightStatus, setUpdateExtractionRightStatus] =
    useState({
      id: "",
      name: "",
      isActive: false,
    });

  const { data: extractionRights = [] } = useAllExtractionRights({
    params: {
      assertedByWalletId: subscriber?.walletId,
      level0ResourceId: zonePriorityBalance?.zoneId,
      isActive: !showInactiveExtractionRights,
    },
    options: {
      enabled:
        Boolean(subscriber?.walletId) && Boolean(zonePriorityBalance?.zoneId),
    },
  });

  const filteredExtractionRights = extractionRights
    ?.filter(
      (right: any) =>
        !/WH$/.test(right.name) &&
        !/SA$/.test(right.name) &&
        !/^PT-Q/.test(right.name) &&
        !right.name.includes(`Quasi-Q/${subscriber.id}`)
    )
    ?.filter(
      (right: any) =>
        filter.extractionRightName === undefined ||
        right.name
          .toLowerCase()
          .includes(filter.extractionRightName.toLowerCase())
    );

  const handleOnConfirm = async ({
    type,
    rightId,
  }: {
    type: AdministrativeApprovalType.AME | AdministrativeApprovalType.SDE;
    rightId?: string;
  }) => {
    const level1ResourceId = subscriber?.level1ResourceId;
    const selectedRights = filteredExtractionRights?.filter(
      (i: any) =>
        selectedExtractionRightIds.some((id: any) => i.id === id) ||
        i.id === rightId
    );

    try {
      const administrativeApproval = await requestAmalgamationMutation({
        level1ResourceId,
        extractionRightIds: [
          ...(rightId ? [rightId] : selectedExtractionRightIds),
        ],
        level0ResourceId: selectedRights[0]?.level0ResourceId,
        waterClassId: selectedRights[0]?.waterClassId,
        buyerId: subscriber.id,
        volume: sumBy(selectedRights, "volume"),
        type,
      });
      navigate(
        `/polestar/administrative_approvals/amalgamate_or_subdivide?administrativeApprovalId=${administrativeApproval.id}&from=subscriber`
      );
    } catch (error: any) {
      const toastErrorMessage = t(
        "level1wrs.tab_info_panel.extraction_right.toast_error"
      );
      if (isValidationError(error)) {
        const { errors = [] } = error?.response?.data;
        const messages = errors.map((i: any) => i.message);

        toastError(
          <>
            <p>{toastErrorMessage}</p>
            {messages.length ? (
              <ul className="list-disc pl-4">
                {messages.map((text: any) => {
                  return <li key={text}>{text}</li>;
                })}
              </ul>
            ) : null}
          </>
        );
      } else {
        toastError(
          <>
            <p>{toastErrorMessage}</p>
            <p>{error?.message}</p>
          </>
        );
      }
    }
  };

  const getCanAmalgamate = (): CanAmalgamate => {
    const selected = filteredExtractionRights?.filter((i: any) =>
      selectedExtractionRightIds.some((id: any) => i.id === id)
    );
    if (selected.length === 0) {
      return "";
    } else if (selected.length < 2) {
      return "require_multiple_rights";
    } else {
      const firstItem = selected[0];
      const sameClass = selected.every(
        (item: any) => item.waterClassId === firstItem.waterClassId
      );

      if (!sameClass) {
        return "require_different_class";
      } else {
        return "yes";
      }
    }
  };

  return (
    <div>
      <header>
        <div className="flex flex-row justify-end gap-2">
          {checkPermissions(["CreateAmalgamation"]) && (
            <button
              className="btn-secondary text-sm shrink-0 rounded"
              disabled={getCanAmalgamate() !== "yes"}
              onClick={() => {
                setConfirmModalString(AdministrativeApprovalType.AME);
              }}
            >
              {t("approval.subdivide_and_amalgamate.create.new_amalgamate")}
            </button>
          )}
          <Link
            className="btn-secondary text-sm rounded"
            to={`/polestar/level1wrs/${subscriber?.level1ResourceId}/extraction_rights/create?subscriberId=${subscriber?.id}&level0ResourceId=${zonePriorityBalance?.zoneId}`}
          >
            {t("extraction_right.create.title")}
          </Link>
          {checkPermissions(["CreateBalanceAdjustments"]) && (
            <Link
              className="btn-secondary text-sm rounded"
              to={`/polestar/balance_adjustments/create?subscriberId=${subscriber?.id}`}
            >
              {t("balance_adjustment.create.title")}
            </Link>
          )}
        </div>

        <div className="flex flex-row justify-between items-end">
          <div className="max-w-sm flex-1">
            <Label htmlFor="extraction_right_name">
              {t("extraction_right.filter_extraction_right_number")}
            </Label>
            <SearchInput
              id="extraction_right_name"
              onChange={(e) =>
                handleFilterChange("extractionRightName", e.target.value)
              }
              placeholder={t("common.search") as string}
              value={filter.extractionRightName || ""}
            />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <Switch
              checked={showInactiveExtractionRights}
              onChange={setShowInactiveExtractionRights}
              className={classNames(
                "relative inline-flex h-6 w-11 items-center rounded-full",
                showInactiveExtractionRights ? "bg-primary-1" : "bg-gray-200"
              )}
            >
              <span
                className={`${
                  showInactiveExtractionRights
                    ? "translate-x-6"
                    : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
            <span className="text-sm">
              {t("extraction_right.show_inactivated_extraction_rights")}
            </span>
          </div>
        </div>
      </header>
      <div className="px-2 py-8 gap-4 space-y-2">
        {filteredExtractionRights?.length ? (
          filteredExtractionRights?.map((right: any) => (
            <div
              key={right.id}
              className={classNames(
                "px-2 py-6 flex gap-3 border",
                selectedExtractionRightIds.includes(right.id)
                  ? "border-blue-500 shadow-md"
                  : ""
              )}
            >
              <div className="w-3/5">
                <Card className="p-0">
                  <div className="flex flex-col">
                    <header
                      className="w-full h-auto px-4 py-2 rounded-t-lg"
                      style={{
                        backgroundColor: "#00385A",
                      }}
                    >
                      <div className="w-full flex justify-between">
                        <div className="flex justify-between items-center w-full font-light text-white text-lg">
                          <h1 className="flex items-center gap-2">
                            <span>
                              {t("extraction_right.name")} {right.name}
                            </span>
                            {right.isActive ? null : (
                              <Tag status="error">{t("common.inactive")}</Tag>
                            )}
                          </h1>
                          <span className="text-base">
                            {t("extraction_right.purpose")}: {right.purpose}
                          </span>
                        </div>
                        <Select
                          placeholder={t("common.actions")}
                          options={[
                            {
                              label: t("common.update"),
                              value: `/polestar/level1wrs/${subscriber?.level1ResourceId}/extraction_rights/${right.id}/edit?subscriberId=${subscriber?.id}&level0ResourceId=${right?.level0ResourceId}`,
                              disabled: !right.isActive,
                            },
                            {
                              label: t(
                                "approval.subdivide_and_amalgamate.create.short_title_subdivide"
                              ),
                              callback: () => {
                                setSubdivisionRightId(right.id);
                                setConfirmModalString(
                                  AdministrativeApprovalType.SDE
                                );
                              },
                              value: t(
                                "approval.subdivide_and_amalgamate.create.short_title_subdivide"
                              ),
                              disabled:
                                !checkPermissions(["CreateSubdivision"]) ||
                                !right.isActive,
                            },
                            ...(checkPermissions(["CreateAmalgamation"])
                              ? [
                                  {
                                    label: t("common.select"),
                                    callback: () => {
                                      setSelectedExtractionRightIds(
                                        (currentState: string[]) => {
                                          return [...currentState, right.id];
                                        }
                                      );
                                    },
                                    value: right.id,
                                    disabled: !right.isActive,
                                  },
                                  ...(selectedExtractionRightIds.includes(
                                    right.id
                                  )
                                    ? [
                                        {
                                          label: t("common.unselect"),
                                          callback: () => {
                                            setSelectedExtractionRightIds(
                                              (currentState: string[]) => {
                                                return currentState.filter(
                                                  (i) => i !== right.id
                                                );
                                              }
                                            );
                                          },
                                        },
                                      ]
                                    : []),
                                ]
                              : []),
                            {
                              label: right.isActive
                                ? t("common.deactivate")
                                : t("common.activate"),
                              callback: () => {
                                setUpdateExtractionRightStatus({
                                  id: right.id,
                                  name: right.name,
                                  isActive: right.isActive,
                                });
                              },
                              disabled:
                                !checkPermissions([
                                  "UpdateExtractionRightStatus",
                                ]) || right.deletedAt,
                            },
                            {
                              label: t("common.delete"),
                              value: `/polestar/level1wrs/${subscriber?.level1ResourceId}/extraction_rights/${right.id}/delete?subscriberId=${subscriber?.id}&level0ResourceId=${right?.level0ResourceId}`,
                              disabled: !checkPermissions([
                                "DeleteExtractionRight",
                              ]),
                            },
                          ].filter((i) => !i.disabled)}
                          onChange={(e) => {
                            if (e?.callback) {
                              e.callback();
                            } else if (e?.value) {
                              navigate(e.value);
                            }
                          }}
                          isOptionSelected={(option) => {
                            return selectedExtractionRightIds.includes(
                              option.value
                            );
                          }}
                          hideSelectedOptions={true}
                          menuPortalTarget={document.body}
                          isSearchable={false}
                          className="w-32 mx-2"
                          controlShouldRenderValue={false}
                        />
                      </div>
                      <div className="w-full flex"></div>
                    </header>
                    <div className="grid grid-cols-10">
                      <div className="border-r flex flex-col relative bg-gray-200 col-span-6">
                        <div className="absolute -bottom-4 rounded-b-lg px-4 h-full w-full grid bg-[#E2ECF2]">
                          <div>
                            <div className="flex justify-between">
                              <div>
                                <h3 className="text-sm">
                                  {t("extraction_right.start_at")}
                                </h3>
                                <div className="text-lg text-primary-2 font-bold">
                                  {right.startAt
                                    ? formatDate(new Date(right.startAt))
                                    : "-"}
                                </div>
                              </div>
                              <div>
                                <h3 className="text-sm">
                                  {t("extraction_right.end_at")}
                                </h3>
                                <div className="text-lg text-primary-2 font-bold">
                                  {right.endAt
                                    ? formatDate(new Date(right.endAt))
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <h3 className="text-sm">
                              {t("extraction_right.volume")}
                            </h3>
                            <div className="text-4xl text-primary-2 font-bold mt-2">
                              {formatVolume(right.volume)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-4 flex flex-col gap-2 p-4 text-sm text-gray-500">
                        <h3 className="flex justify-between">
                          {t("extraction_right.allocation_type")}
                          <span className="text-black">
                            {right.type?.identifier}
                          </span>
                        </h3>
                        <h3 className="flex justify-between">
                          {t("extraction_right.is_active")}
                          <span className="text-black">
                            {subscriber?.isActive ? "A" : "I"}
                          </span>
                        </h3>
                        <h3 className="flex justify-between">
                          {t("extraction_right.source")}
                          <span className="text-black">
                            {startCase(right.level0Resource?.source)}
                          </span>
                        </h3>
                        <h3 className="flex justify-between">
                          {t("common.water_class")}
                          <span className="text-black">
                            {right.waterClass.name}
                          </span>
                        </h3>
                        {right.note && (
                          <h3 className="flex flex-row flex-wrap justify-between">
                            {t("common.note")}
                            <span className="text-black">
                              {right.note.note}
                            </span>
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="w-2/5">
                <Card className="p-0 w-auto">
                  <div className="flex flex-col">
                    <header className="w-auto h-auto p-4 rounded-t-lg bg-[#7ECCC2]">
                      <h1 className="font-thin text-gray-700 text-xl">
                        {t("extraction_right.billing_info")}
                      </h1>
                    </header>
                    <div className="grid grid-cols-1">
                      <div className="p-4 flex flex-col">
                        <div>
                          <h3 className="text-sm text-gray-500 grid grid-cols-2">
                            {t("extraction_right.billing_group")}
                            <span className="text-sm text-black">
                              {right.billingGroup?.name}
                            </span>
                          </h3>
                          <h3 className="mt-2 text-sm text-gray-500 grid grid-cols-2">
                            {t("extraction_right.freq")}
                            <span className="text-sm text-black">
                              {subscriber?.billingFrequency}
                            </span>
                          </h3>
                          <h3 className="mt-2 text-sm text-gray-500 grid grid-cols-2">
                            {t("extraction_right.item_no")}
                            <span className="text-sm text-black">
                              {right.itemNo}
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          ))
        ) : (
          <div className="py-6 whitespace-nowrap text-gray-400 text-center">
            {t("extraction_right.no_data")}
          </div>
        )}
      </div>
      <ConfirmModal
        open={
          confirmModalString === AdministrativeApprovalType.AME ||
          confirmModalString === AdministrativeApprovalType.SDE
        }
        onClose={() => {
          setConfirmModalString("");
        }}
        onConfirm={() => {
          if (confirmModalString === "") return;
          handleOnConfirm({
            type: confirmModalString,
            rightId:
              AdministrativeApprovalType.SDE === confirmModalString
                ? subdivisionRightId
                : undefined,
          });
        }}
        isSubmitting={isLoading}
      >
        {t(
          "approval.subdivide_and_amalgamate.create.step_3.confirm_modal.title",
          {
            context:
              confirmModalString === AdministrativeApprovalType.AME
                ? ExtractionRightApprovalType.Amalgamate
                : ExtractionRightApprovalType.Subdivide,
          }
        )}
      </ConfirmModal>

      <ConfirmUpdateExtractionRightStatusModal
        open={Boolean(updateExtractionRightStatus.id)}
        extractionRight={updateExtractionRightStatus}
        onSuccess={refetchSubscriber}
        onClose={() => {
          setUpdateExtractionRightStatus({
            id: "",
            name: "",
            isActive: false,
          });
        }}
      />
    </div>
  );
};

export default ExtractionRightTab;
