import React, { FunctionComponent } from "react";
import classNames from "classnames";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import type { ColumnDef } from "@tanstack/react-table";

import ActionsButton from "@components/shared/DataTable/ActionsButton";
import IndeterminateCheckbox from "@components/form/IndeterminateCheckbox";
import AuditTrailDetailModal from "@components/modal/AuditTrailDetailModal";
import { formatDateTime } from "@utils/formatDateTime";
import DataTableRenderer from "@components/shared/DataTable/DataTableRenderer";

import { usePaginationState } from "@components/shared/DataTable/Pagination";
import ExpandableCell from "@components/shared/DataTable/ExpandableCell";
import { getEventTransactions } from "@services/eventTransactions";

type EventTransaction = {
  id: string;
  eventGroup: string;
  eventType: string;
  description: string;
  status: string;
  ip: string;
  parentId?: string;
  timestamp: string;
};

type EventTransactionPaginatedTableProps = {
  stickyHeader?: boolean;
  className?: string;
  walletId?: string;
  references?: string[];
  eventGroups?: string[];
  eventTypes?: string[];
  level0ResourceId?: string;
  level1ResourceId?: string;
  limit?: number;
};

const transformEventTransactions = (
  data: Record<string, any>,
): EventTransaction => {
  return {
    id: data.id,
    eventGroup: data.eventType?.eventGroup?.name,
    eventType: data.eventType?.name,
    description: data.description,
    status: Boolean(data.hcsId) ? "success" : "failed",
    ip: data.ip,
    parentId: data.parentId,
    timestamp: formatDateTime(new Date(data.createdAt)),
  };
};

const useEventTransactions = ({
  params,
  ...options
}: Record<string, any> = {}) => {
  return useQuery({
    queryKey: ["eventTransactions", "list", params],
    queryFn: () => getEventTransactions(params),
    select: (res) => {
      return {
        data: res.eventTransactions.map((item: any) => ({
          ...transformEventTransactions(item),
          childEventTransactions: item.childEventTransactions?.map(
            (child: any) => transformEventTransactions(child),
          ),
        })),
        meta: res.meta,
      };
    },
    ...options,
  });
};

const EventTransactionPaginatedTable: FunctionComponent<
  EventTransactionPaginatedTableProps
> = ({
  stickyHeader = true,
  walletId,
  eventGroups,
  eventTypes,
  level0ResourceId,
  level1ResourceId,
  references,
  className,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [pagination, setPagination] = usePaginationState();
  const [viewDetailId, setViewDetailId] = React.useState<string | null>(
    searchParams.get("id"),
  );

  const { data, isLoading, isFetching } = useEventTransactions({
    params: {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      references,
      walletId,
      eventGroups,
      eventTypes,
      level0ResourceId,
      level1ResourceId,
    },
  });

  const { refetch: fetchAllEventTractions, isFetching: isFetchingAll } =
    useEventTransactions({
      params: {
        references,
        walletId,
        eventGroups,
        eventTypes,
        limit: -1,
      },
      enabled: false,
    });

  const columns = React.useMemo<ColumnDef<EventTransaction>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ table, row }) => {
          const selectedIds = table
            .getSelectedRowModel()
            .rows.map((row) => row.id);

          return (
            <IndeterminateCheckbox
              {...{
                checked: selectedIds.includes(row.id),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          );
        },
      },
      {
        accessorKey: "eventGroup",
        header: t("audit_trail.group") as string,
        cell: (context) => <ExpandableCell {...context} />,
      },
      {
        accessorKey: "eventType",
        header: t("audit_trail.type") as string,
      },
      {
        accessorKey: "description",
        header: t("audit_trail.description") as string,
        cell: (info: any) => (
          <div className="max-w-sm truncate">{info.getValue()}</div>
        ),
      },
      {
        accessorKey: "status",
        header: t("common.status") as string,
        cell: (info) => {
          const status = info.getValue() as string;
          const isSuccess = status === "success";
          const statuses = {
            success: "text-green-400 bg-green-400/10",
            offChain: "text-gray-400 bg-gray-400/10",
          };

          return (
            <div className="flex items-center justify-end gap-x-2 sm:justify-start">
              <div
                className={classNames(
                  statuses[isSuccess ? "success" : "offChain"],
                  "flex-none rounded-full p-1",
                )}
              >
                <div className="h-1.5 w-1.5 rounded-full bg-current" />
              </div>
              <div>
                {isSuccess ? t("common.success") : t("common.off_chain")}
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "timestamp",
        header: t("audit_trail.timestamp") as string,
      },
      {
        id: "actions",
        header: t("common.actions") as string,
        cell: ({ row }) => {
          return (
            <ActionsButton
              menus={[
                {
                  label: t("common.view"),
                  onClick: () => setViewDetailId(row.id),
                },
              ]}
            />
          );
        },
      },
    ],
    [t],
  );

  return (
    <>
      <section
        className={classNames(className, "flex flex-col flex-1 space-y-4")}
      >
        <DataTableRenderer
          name="autdit-trail"
          data={data?.data ?? []}
          columns={columns}
          pagination={pagination}
          pageCount={data?.meta?.lastPage ?? -1}
          onPaginationChange={setPagination}
          isLoading={isLoading}
          isFetching={isFetching || isFetchingAll}
          fetchAll={fetchAllEventTractions}
          columnsVisibility
          subRowsKey="childEventTransactions"
          stickyHeader={stickyHeader}
          transformExportData={({
            childEventTransactions: directChilds,
            ...parentRest
          }: any) => {
            if (directChilds?.length > 0) {
              const childEvents = directChilds.map(
                ({ childEventTransactions, ...childRest }: any) => {
                  return {
                    ...childRest,
                    parentId: parentRest.id,
                  };
                },
              );
              return [parentRest, ...childEvents];
            }
            return [parentRest];
          }}
        />
      </section>

      {viewDetailId ? (
        <AuditTrailDetailModal
          open={Boolean(viewDetailId)}
          id={viewDetailId}
          onClose={() => {
            setViewDetailId(null);
          }}
        />
      ) : null}
    </>
  );
};

export default EventTransactionPaginatedTable;
