import { useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Layout from "@components/layout/Layout";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import ExchangeRateHistory from "@components/form/exchange_rate/ExchangeRateHistory";
import CreateExchangeRateStep1 from "@components/form/exchange_rate/CreateExchangeRateStep1";
import CreateExchangeRateStep2 from "@components/form/exchange_rate/CreateExchangeRateStep2";
import CreateExchangeRateStep3 from "@components/form/exchange_rate/CreateExchangeRateStep3";
import ConfirmModal from "@components/shared/ConfirmModal";
import StepButton from "@components/shared/StepButton";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import { formatPercentage } from "@utils/formatPercentage";
import { formatDate } from "@utils/formatDate";
import { saveExchangeRates } from "@services/exchangeRate";

const CreateExchangeRate: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLevel0Resources, setSelectedLevel0Resources] = useState([]);
  const [workflowInstance, setWorkflowInstance] = useState<any>({} as any);
  const [exchangeRateData, setExchangeRateData] = useState<Record<string, any>>(
    {
      accountingPeriod: undefined,
      level1Resource: null,
      level0Resources: [],
      rate: null,
      startAt: null,
    }
  );
  const { mutateAsync: saveExchangeRatesData, isLoading } = useMutation<
    any,
    any,
    {
      waterClassIds: string[];
      rate: number;
      startAt: Date;
    }
  >((exchangeRateData) => saveExchangeRates(exchangeRateData));

  const handleStep1Save = (step1Data: {
    accountingPeriod: { periodStart: Date; periodEnd: Date };
    level1Resource: any;
    level0Resources: any;
  }) => {
    setExchangeRateData({ ...exchangeRateData, ...step1Data });

    setCurrentStep(1);
  };

  const handleStep1WaterClassesChange = (level0Resources: any) => {
    setSelectedLevel0Resources(level0Resources);
  };

  const handleStep2Save = (step2Data: { rate: number; startAt: Date }) => {
    setExchangeRateData({ ...exchangeRateData, ...step2Data });

    setCurrentStep(2);
  };

  const handleStep3Save = () => {
    setIsModalOpen(true);
  };

  const handleSave = async () => {
    try {
      let waterClassIds: string[] = [];

      exchangeRateData.level0Resources.forEach(
        (zone: any) =>
          (waterClassIds = [
            ...waterClassIds,
            ...zone.selectedWaterClasses.map((c: any) => c.id),
          ])
      );

      const [, workflowInstance] = await saveExchangeRatesData({
        waterClassIds: waterClassIds,
        rate: exchangeRateData.rate,
        startAt: exchangeRateData.startAt,
      });

      setWorkflowInstance(workflowInstance);
      setIsModalOpen(false);

      toast.success(t("exchange_rate.save_succeed"), {
        className: "toast toast-success",
      });

      setCurrentStep(3);
    } catch (e: any) {
      toast.error(
        <>
          <p>{t("exchange_rate.save_failed")}</p>
          <p>{e.message}</p>
        </>,
        {
          className: "toast toast-error",
        }
      );
      setIsModalOpen(false);
    }
  };

  return (
    <Layout
      permissions={["CreateAllocationAnnouncement"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("exchange_rate.new_exchange_rate"),
        },
      ]}
      title={t("exchange_rate.new_exchange_rate")}
    >
      <div className="flex gap-4">
        <WorkflowPanels
          itemClassName="border-t"
          selectedStep={currentStep}
          steps={[
            {
              label: (
                <StepButton
                  index={0}
                  currentStep={currentStep}
                  info={
                    <>
                      <dl className="grid grid-cols-1 gap-x-4">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-bold text-gray-500">
                            {t("common.level1wrs")}:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-500">
                            {exchangeRateData.level1Resource?.name}
                          </dd>
                        </div>
                      </dl>
                      <dl className="grid grid-cols-1 gap-x-4">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-bold text-gray-500">
                            {t("common.level0wrs")} {t("common.and")}{" "}
                            {t("common.water_classes")}:
                          </dt>
                          {exchangeRateData.level0Resources?.map(
                            (zone: any) => (
                              <dd className="mt-1 text-sm text-gray-500">
                                {zone.identifier} -{" "}
                                {zone.selectedWaterClasses
                                  .map((c: any) => c.name)
                                  .join(" / ")}
                              </dd>
                            )
                          )}
                        </div>
                      </dl>
                    </>
                  }
                >
                  {t("exchange_rate.select_hierarchy")}
                </StepButton>
              ),
              panel: (
                <CreateExchangeRateStep1
                  accountingPeriod={exchangeRateData.accountingPeriod}
                  level1Resource={exchangeRateData.level1Resource}
                  level0Resources={exchangeRateData.level0Resources}
                  onSave={handleStep1Save}
                  onWaterClassesChange={handleStep1WaterClassesChange}
                />
              ),
              infoPanel: (
                <InfoPanel>
                  <div className="space-y-4">
                    <p>{t("exchange_rate.history_title")}</p>
                  </div>
                </InfoPanel>
              ),
            },
            {
              label: (
                <StepButton
                  index={1}
                  currentStep={currentStep}
                  info={
                    <>
                      <dl className="grid grid-cols-1 gap-x-4">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-bold text-gray-500">
                            {t("exchange_rate.rate")}:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-500">
                            {formatPercentage(exchangeRateData?.rate)}
                          </dd>
                        </div>
                      </dl>
                      <dl className="grid grid-cols-1 gap-x-4">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-bold text-gray-500">
                            {t("exchange_rate.start_at")}:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-500">
                            {formatDate(exchangeRateData?.startAt)}
                          </dd>
                        </div>
                      </dl>
                    </>
                  }
                >
                  {t("exchange_rate.enter_values")}
                </StepButton>
              ),
              panel: (
                <CreateExchangeRateStep2
                  rate={exchangeRateData.rate}
                  startAt={exchangeRateData.startAt}
                  level0Resources={exchangeRateData.level0Resources}
                  onSave={handleStep2Save}
                  onCancel={() => {
                    setExchangeRateData({
                      ...exchangeRateData,
                      rate: null,
                      startAt: null,
                    });
                    setCurrentStep(0);
                  }}
                  selectedYear={exchangeRateData.accountingPeriod}
                />
              ),
              infoPanel: (
                <InfoPanel heading={t("exchange_rate.history") as string}>
                  <div className="space-y-4">
                    <p>
                      {selectedLevel0Resources?.map((zone: any) => (
                        <ExchangeRateHistory
                          key={zone.id}
                          level0Resource={zone}
                        />
                      ))}
                    </p>
                  </div>
                </InfoPanel>
              ),
            },
            {
              label: (
                <StepButton index={2} currentStep={currentStep}>
                  {t("exchange_rate.confirm_values")}
                </StepButton>
              ),
              panel: (
                <CreateExchangeRateStep3
                  level1Resource={exchangeRateData.level1Resource}
                  level0Resources={exchangeRateData.level0Resources}
                  rate={exchangeRateData?.rate}
                  startAt={exchangeRateData?.startAt}
                  onSave={handleStep3Save}
                  onEditZones={() => setCurrentStep(0)}
                  onEditRate={() => setCurrentStep(1)}
                  disabled={isLoading}
                />
              ),
              infoPanel: (
                <InfoPanel heading={t("exchange_rate.history") as string}>
                  <div className="space-y-4">
                    <p>
                      {selectedLevel0Resources?.map((zone: any) => (
                        <ExchangeRateHistory
                          key={zone.id}
                          level0Resource={zone}
                        />
                      ))}
                    </p>
                  </div>
                </InfoPanel>
              ),
            },
            {
              label: (
                <StepButton
                  index={3}
                  currentStep={currentStep}
                  isOnChain={currentStep === 3}
                >
                  {t("exchange_rate.workflow_completed")}
                </StepButton>
              ),
              panel: (
                <div className="flex flex-col grow h-[800px] max-h-screen">
                  <WorkflowComplete references={[workflowInstance?.id]} />
                </div>
              ),
              infoPanel: (
                <InfoPanel successes={[t("exchange_rate.info.complete")]}>
                  <div className="space-y-4"></div>
                </InfoPanel>
              ),
            },
          ]}
        />
      </div>
      <ConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleSave}
        isSubmitting={isLoading}
      >
        {t("exchange_rate.modal_confirmation")}
      </ConfirmModal>
    </Layout>
  );
};

export default CreateExchangeRate;
