import React from "react";

import { useStep } from "@hooks/useStep";

type Props = {
  children: React.ReactNode;
};

type ContextValue = Record<string, any>;

const ForwardDrawContext = React.createContext<ContextValue | undefined>(
  undefined
);

const initialDetails = {
  id: "",
  subscribers: [],
  extractionRights: [],
};

const ForwardDrawProvider = ({ children }: Props) => {
  const [currentStep, stepHelpers] = useStep(6);
  const [details, setDetails] = React.useState(initialDetails);
  const [supportingEvidence, setSupportingEvidence] = React.useState<any>();
  const [workflowInstance, setWorkflowInstance] = React.useState<any>(null);
  const [message, setMessage] = React.useState<any>({
    messageTemplate: null,
    subject: "",
    body: "",
  });
  const [workflowCompleted, setWorkflowCompleted] =
    React.useState<boolean>(false);

  const handleChangeDetails = (key: string, value: any, subKey?: string) => {
    setDetails((prevState: any) => {
      const updatedDetails: any = { ...prevState };
      if (subKey) {
        updatedDetails[key] = { ...prevState[key], [subKey]: value };
      } else {
        updatedDetails[key] = value;
      }
      return updatedDetails;
    });
  };

  const value = {
    currentStep,
    stepHelpers,
    details,
    setDetails,
    message,
    setMessage,
    workflowInstance,
    setWorkflowInstance,
    handleChangeDetails,
    supportingEvidence,
    setSupportingEvidence,
    workflowCompleted,
    setWorkflowCompleted,
  };

  return (
    <ForwardDrawContext.Provider value={value}>
      {children}
    </ForwardDrawContext.Provider>
  );
};

const useForwardDrawContext = () => {
  const context = React.useContext(ForwardDrawContext);
  if (context === undefined) {
    throw new Error(
      "useForwardDrawContext must be used within a ForwardDrawProvider"
    );
  }
  return context;
};

export { ForwardDrawProvider, useForwardDrawContext };
