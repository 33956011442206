import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import { formatDateInput } from "@utils/formatDate";
import { useStepContext } from "@context/shared/StepContext";

type ForwardDrawEffectiveDateProps = {
  onCancel: () => void;
  effectiveDate: Date;
  setEffectiveDate: Dispatch<SetStateAction<Date>>;
  accountingPeriod?: {
    periodStart: Date;
    periodEnd: Date;
  };
};

const ForwardDrawEffectiveDate: React.FunctionComponent<
  ForwardDrawEffectiveDateProps
> = ({ onCancel, effectiveDate, setEffectiveDate, accountingPeriod }) => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();

  return (
    <>
      <form
        id="form"
        className="flex flex-col gap-4 grow"
        onSubmit={(e) => {
          e.preventDefault();
          stepHelpers.goToNextStep();
        }}
      >
        <div className="p-6 space-y-4">
          <Heading className="text-xl leading-6" light>
            {t("approval.forward_draws.approve.set_effective_date")}
          </Heading>

          <div className="max-w-xs">
            <Label htmlFor="effectiveDate">
              {t("approval.forward_draws.approve.effective_date")}
            </Label>
            <TextInput
              type="date"
              id="effectiveDate"
              min={
                accountingPeriod?.periodStart &&
                formatDateInput(new Date(accountingPeriod.periodStart))
              }
              max={
                accountingPeriod?.periodEnd &&
                formatDateInput(new Date(accountingPeriod.periodEnd))
              }
              value={formatDateInput(effectiveDate)}
              onChange={(e) => {
                setEffectiveDate(new Date(e.target.value));
              }}
              required
            />
          </div>
        </div>
      </form>
      <div className="grow" />

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary" form="form">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default ForwardDrawEffectiveDate;
