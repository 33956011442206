import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";

import Loading from "@components/shared/Loading";
import { useSearchParams } from "react-router-dom";
import { useAccountingPeriodCheck } from "@hooks/query/useAccountingPeriodCheck";

type AccountingPeriodCheckData = {
  level1ResourceId: string;
  currentStep: number;
  status: number;
  [key: string]: any;
};

type ContextValue = {
  currentAccountingPeriodCheck: any;
  saveData: (data: any) => void;
  selectedLevel1ResourceId: string;
  setSelectedLevel1ResourceId: React.Dispatch<React.SetStateAction<string>>;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<React.SetStateAction<any>>;
  selectedCarriedOverAdministrativeApprovalIds: string[];
  setSelectedCarriedOverAdministrativeApprovalIds: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  selectedCanceledAdministrativeApprovalIds: string[];
  setSelectedCanceledAdministrativeApprovalIds: React.Dispatch<
    React.SetStateAction<string[]>
  >;
};

const AccountingPeriodChecksContext = createContext<ContextValue>(
  {} as ContextValue
);

const AccountingPeriodChecksProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const accountingPeriodId = searchParams.get("accountingPeriodId") || "";

  const [currentAccountingPeriodCheck, setCurrentAccountingPeriodCheck] =
    useState<AccountingPeriodCheckData>({
      level1ResourceId: "",
    } as AccountingPeriodCheckData);
  const [selectedLevel1ResourceId, setSelectedLevel1ResourceId] =
    useState<string>(currentAccountingPeriodCheck?.level1ResourceId || "");
  const [
    selectedCarriedOverAdministrativeApprovalIds,
    setSelectedCarriedOverAdministrativeApprovalIds,
  ] = useState<string[]>([]);
  const [
    selectedCanceledAdministrativeApprovalIds,
    setSelectedCanceledAdministrativeApprovalIds,
  ] = useState<string[]>([]);

  const [workflowInstance, setWorkflowInstance] = useState<any>();
  const [workflowCompleted, setWorkflowCompleted] = useState<boolean>(false);

  const { data: accountingPeriodChecks, isLoading } = useAccountingPeriodCheck({
    params: { accountingPeriodId },
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  });

  useEffect(() => {
    if (!isLoading) {
      setCurrentAccountingPeriodCheck(accountingPeriodChecks);
      setSelectedCanceledAdministrativeApprovalIds(
        accountingPeriodChecks?.canceledAdministrativeApprovals?.map(
          (i: any) => i.id
        ) ?? []
      );
      setSelectedCarriedOverAdministrativeApprovalIds(
        accountingPeriodChecks?.carriedOverAdministrativeApprovals?.map(
          (i: any) => i.id
        ) ?? []
      );
    }
  }, [isLoading, accountingPeriodChecks]);

  useEffect(() => {
    setSelectedLevel1ResourceId(currentAccountingPeriodCheck?.level1ResourceId);
  }, [currentAccountingPeriodCheck?.level1ResourceId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentAccountingPeriodCheck?.currentStep]);

  const saveData = (data: any) => {
    setCurrentAccountingPeriodCheck({
      ...currentAccountingPeriodCheck,
      ...data,
    });
  };

  if (isLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  return (
    <AccountingPeriodChecksContext.Provider
      value={{
        currentAccountingPeriodCheck,
        saveData,
        selectedLevel1ResourceId,
        setSelectedLevel1ResourceId,
        workflowCompleted,
        setWorkflowCompleted,
        workflowInstance,
        setWorkflowInstance,
        selectedCanceledAdministrativeApprovalIds,
        selectedCarriedOverAdministrativeApprovalIds,
        setSelectedCanceledAdministrativeApprovalIds,
        setSelectedCarriedOverAdministrativeApprovalIds,
      }}
    >
      {children}
    </AccountingPeriodChecksContext.Provider>
  );
};

function useAccountingPeriodChecksContext() {
  const context = useContext(AccountingPeriodChecksContext);
  if (context === undefined) {
    throw new Error(
      "useAccountingPeriodChecksContext must be used within a AccountingPeriodChecksProvider"
    );
  }
  return context;
}

export { AccountingPeriodChecksProvider, useAccountingPeriodChecksContext };
