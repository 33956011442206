import { useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import TabPanels from "@components/form/TabPanels";
import InfoPanel from "@components/form/InfoPanel";
import Level1WRSPermanentTrade from "@components/form/admin_approvals/Level1WRSPermanentTrade";
import ForwardDrawsTable from "@components/table/administrative_approvals_table/ForwardDrawsTable";
import SeasonalTransferTable from "@components/table/administrative_approvals_table/SeasonalTransferTable";
import AmalgamateAndSubdivisionTable from "@components/table/administrative_approvals_table/AmalgamateAndSubdivisionTable";
import StockAndDomesticTable from "@components/table/StockAndDomesticTable";
import BalanceAdjustmentTable from "@components/table/BalanceAdjustmentTable";
import { StatusInfo } from "@components/pages/polestar/administrative_approvals/ApprovalIndex";

type Level1WRSTradesAndApprovalsListProps = {
  level1Resource: any;
};

const Level1WRSTradesAndApprovalsList: React.FunctionComponent<
  Level1WRSTradesAndApprovalsListProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || 0;

  return (
    <div className="flex grow gap-4">
      <div className="grow">
        <TabPanels
          className="bg-white border"
          selectedTab={+tab || 0}
          tabs={[
            {
              title: t("approval.seasonal_water_assignments.short_title"),
              panel: <SeasonalTransferTable level1Resource={level1Resource} />,
              tabInfoPanel: (
                <InfoPanel className="h-full">
                  <div className="space-y-2">
                    <ul className="flex flex-col gap-5 list-disc list-outside">
                      <li>
                        <Trans
                          i18nKey="approval.seasonal_water_assignments.tab_info_panel.level1wrs_intro"
                          values={{
                            level1wrsName: level1Resource?.name,
                          }}
                        />
                      </li>
                      <li>
                        {t(
                          "approval.seasonal_water_assignments.tab_info_panel.conclusion"
                        )}
                      </li>
                    </ul>
                    <StatusInfo />
                  </div>
                </InfoPanel>
              ),
            },
            {
              title: t("approval.permanent_trades.short_title"),
              panel: (
                <Level1WRSPermanentTrade level1Resource={level1Resource} />
              ),
              tabInfoPanel: (
                <InfoPanel className="h-full">
                  <div className="space-y-2">
                    <ul className="flex flex-col gap-5 list-disc list-outside">
                      <li>
                        <Trans
                          i18nKey="approval.permanent_trades.tab_info_panel.level1wrs_intro"
                          values={{
                            level1wrsName: level1Resource?.name,
                          }}
                        />
                      </li>
                      <li>
                        {t(
                          "approval.permanent_trades.tab_info_panel.conclusion"
                        )}
                      </li>
                    </ul>
                    <StatusInfo />
                  </div>
                </InfoPanel>
              ),
            },
            {
              title: t("approval.forward_draws.title"),
              panel: <ForwardDrawsTable level1Resource={level1Resource} />,
              tabInfoPanel: (
                <InfoPanel className="h-full">
                  <div className="space-y-2">
                    <ul className="flex flex-col gap-5 list-disc list-outside">
                      <li>
                        <Trans
                          i18nKey="approval.forward_draws.tab_info_panel.level1wrs_intro"
                          values={{
                            level1wrsName: level1Resource?.name,
                          }}
                        />
                      </li>
                      <li>
                        {t("approval.forward_draws.tab_info_panel.conclusion")}
                      </li>
                    </ul>
                    <StatusInfo />
                  </div>
                </InfoPanel>
              ),
            },
            {
              title: t("approval.stock_and_domestic.title"),
              panel: <StockAndDomesticTable />,
              tabInfoPanel: (
                <InfoPanel>
                  <div className="space-y-2">
                    <ul className="flex flex-col gap-5 list-disc list-outside">
                      <li>{t("approval.stock_and_domestic.tab_info.intro")}</li>
                      <li>
                        {t("approval.stock_and_domestic.tab_info.conclusion")}
                      </li>
                    </ul>
                    <StatusInfo />
                  </div>
                </InfoPanel>
              ),
            },
            {
              title: t("approval.subdivide_and_amalgamate.title"),
              panel: (
                <AmalgamateAndSubdivisionTable
                  level1Resource={level1Resource}
                />
              ),
              tabInfoPanel: (
                <InfoPanel className="h-full">
                  <div className="space-y-2">
                    <ul className="flex flex-col gap-5 list-disc list-outside">
                      <li>
                        <Trans
                          i18nKey="approval.subdivide_and_amalgamate.tab_info_panel.level1wrs_intro"
                          values={{
                            level1wrsName: level1Resource?.name,
                          }}
                        />
                      </li>
                      <li>
                        {t(
                          "approval.subdivide_and_amalgamate.tab_info_panel.conclusion"
                        )}
                      </li>
                    </ul>
                    <StatusInfo />
                  </div>
                </InfoPanel>
              ),
            },
            {
              title: t("balance_adjustment.water_harvesting.title"),
              panel: (
                <BalanceAdjustmentTable
                  level1ResourceId={level1Resource?.id}
                  accountingPeriod={level1Resource?.activeAccountingPeriod}
                />
              ),
              tabInfoPanel: (
                <InfoPanel className="h-full">
                  <div className="space-y-2">
                    <ul className="flex flex-col gap-5 list-disc list-outside">
                      <li>
                        {t(
                          "balance_adjustment.water_harvesting.list.tab_info_panel_1"
                        )}
                      </li>
                      <li>
                        {t(
                          "balance_adjustment.water_harvesting.list.tab_info_panel_2"
                        )}
                      </li>
                      <li>
                        {t(
                          "balance_adjustment.water_harvesting.list.tab_info_panel_3"
                        )}
                      </li>
                    </ul>
                  </div>
                </InfoPanel>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Level1WRSTradesAndApprovalsList;
