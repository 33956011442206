import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import AuditTrail from "@components/pages/polestar/AuditTrail";
import Dashboard from "@components/pages/polestar/dashboard/Dashboard";
import CreateExchangeRate from "@components/pages/polestar/exchange_rates/CreateExchangeRate";
import Level1WRSIndex from "@components/pages/polestar/level1wrs/Level1WRSIndex";
import BulkMeterUpload from "@components/pages/polestar/level1wrs/BulkMeterUpload";
import CreateExtractionPoint from "@components/pages/polestar/extraction_point/CreateExtractionPoint";
import CreateMeter from "@components/pages/polestar/meters/CreateMeter";
import SingleSignOn from "@components/pages/polestar/SingleSignOn";
import PolestarLogin from "@components/pages/polestar/PolestarLogin";
import Logout from "@components/pages/Logout";
import ForgotPassword from "@components/pages/ForgotPassword";
import SubscriberList from "@components/pages/polestar/subscribers/SubscriberList";
import SubscriberIndex from "@components/pages/polestar/subscribers/SubscriberIndex";
import SubscriberCreate from "@components/pages/polestar/subscribers/SubscriberCreate";
import SubscriberUpdate from "@components/pages/polestar/subscribers/SubscriberUpdate";
import Notifications from "@components/pages/polestar/notifications/Notifications";
import UpdateThreshold from "@components/pages/polestar/meters/UpdateThreshold";
import ApprovalIndex from "@components/pages/polestar/administrative_approvals/ApprovalIndex";
import ZenithApp from "@components/pages/zenith/ZenithApp";
import CreateSeasonalTransfer from "@components/pages/polestar/administrative_approvals/seasonal_transfer/CreateSeasonalTransfer";
import UserCreate from "@components/pages/polestar/users/UserCreate";
import UserUpdate from "@components/pages/polestar/users/UserUpdate";
import RegulatoryReports from "@components/pages/polestar/reports/RegulatoryReports";
import BillingReports from "@components/pages/polestar/reports/BillingReports";
import LinkToExtractionRights from "@components/pages/polestar/subscribers/extraction_points/link_to_rights/LinkToExtractionRights";
import LinkMeter from "@components/pages/polestar/extraction_point/LinkMeter";
import CreateForwardDraw from "@components/pages/polestar/administrative_approvals/forward_draw/CreateForwardDraw";
import CreateAdminTransfer from "@components/pages/polestar/administrative_approvals/admin_transfer/CreateAdminTransfer";
import ReplaceMeter from "@components/pages/polestar/meters/ReplaceMeter";
import DecommissionMeter from "@components/pages/polestar/meters/DecommissionMeter";
import GeneralReports from "@components/pages/polestar/reports/GeneralReports";
import CreateAccountingPeriodCheck from "@components/pages/polestar/accounting_period_checks/CreateAccountingPeriodCheck";
import CreateDistributionLoss from "@components/pages/polestar/distribution_losses/CreateDistributionLoss";
import WaterAccountsReport from "@components/pages/polestar/reports/WaterAccountsReport";
import CreateMeterEOY from "@components/pages/polestar/meters/eoy/CreateMeterEOY";
import PermanentTransfer from "@components/pages/polestar/administrative_approvals/permanent_trade/PermanentTransfer";
import CreateBillingGroup from "@components/pages/polestar/billing_groups/CreateBillingGroup";
import EditBillingGroup from "@components/pages/polestar/billing_groups/EditBillingGroup";
import CreateWaterCharge from "@components/pages/polestar/water_charges/CreateWaterCharge";
import EditWaterCharge from "@components/pages/polestar/water_charges/EditWaterCharge";
import CreateLevel1WRS from "@components/pages/polestar/level1wrs/CreateLevel1WRS";
import CreateWaterClass from "@components/pages/polestar/water_classes/CreateWaterClass";
import CreateLevel0WRS from "@components/pages/polestar/level0wrs/CreateLevel0WRS";
import CreateExtractionRight from "@components/pages/polestar/extraction_right/CreateExtractionRight";
import NotFound from "@components/error/NotFound";
import Level1WRSCreateSubscriber from "@components/pages/polestar/level1wrs/Level1WRSCreateSubscriber";
import Level1WRSCreateUser from "@components/pages/polestar/level1wrs/Level1WRSCreateUser";
import ErrorBoundary from "@components/error/ErrorBoundary";
import UpdateExtractionRight from "@components/pages/polestar/extraction_right/UpdateExtractionRight";
import UpdateExtractionPoint from "@components/pages/polestar/extraction_point/UpdateExtractionPoint";
import EditMeter from "@components/pages/polestar/meters/EditMeter";
import UpdateLevel0WRS from "@components/pages/polestar/level0wrs/UpdateLevel0WRS";
import UpdateWaterClass from "@components/pages/polestar/water_classes/UpdateWaterClass";
import CreateDeclaration from "@components/pages/polestar/declarations/CreateDeclaration";
import SubscriberCreateDeclaration from "@components/pages/polestar/subscribers/SubscriberCreateDeclaration";
import SubscriberReplaceMeter from "@components/pages/polestar/subscribers/SubscriberReplaceMeter";
import SubscriberDecommissionMeter from "@components/pages/polestar/subscribers/SubscriberDecommissionMeter";
import SubscriberLinkMeter from "@components/pages/polestar/subscribers/SubscriberLinkMeter";
import MeterReadSequenceReport from "@components/pages/polestar/reports/MeterReadSequenceReport";
import CreateStockAndDomestic from "@components/pages/polestar/administrative_approvals/CreateStockAndDomestic";
import CreateAmalgamationOrSubdivision from "@components/pages/polestar/administrative_approvals/amalgamation_or_subdivision/CreateAmalgamationOrSubdivision";
import CreateBalanceAdjustment from "@components/pages/polestar/balance_adjustment/CreateBalanceAdjustment";
import AdjustDeclaration from "@components/pages/polestar/balance_adjustment/AdjustDeclaration";
import CreateNotification from "@components/pages/polestar/notifications/CreateNotification";
import ProfileIndex from "@components/pages/polestar/profile/ProfileIndex";
import UnlinkExtractionPoint from "@components/pages/polestar/extraction_point/UnlinkExtractionPoint";
import ReplaceMeterCapsule from "@components/pages/polestar/meters/ReplaceMeterCapsule";
import DeleteExtractionRight from "@components/pages/polestar/extraction_right/DeleteExtractionRight";
import Level1WRSCreateExtractionPoint from "@components/pages/polestar/level1wrs/Level1WRSCreateExtractionPoint";
import Level1WRSUpdateExtractionPoint from "@components/pages/polestar/level1wrs/Level1WRSUpdateExtractionPoint";
import Level1WRSUpdateSubscriber from "@components/pages/polestar/level1wrs/Level1WRSUpdateSubscriber";
import FileVerification from "@components/pages/FileVerification";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorBoundary />}>
      <Route path="fileVerification" element={<FileVerification />} />
      <Route index element={<Navigate to="polestar" />} />
      <Route path="polestar">
        <Route index element={<Dashboard />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="sso" element={<SingleSignOn />} />
        <Route path="login" element={<PolestarLogin />} />
        <Route path="logout" element={<Logout />} />
        <Route path="profile">
          <Route index element={<ProfileIndex />} />
        </Route>
        <Route path="exchange_rates">
          <Route path="create" element={<CreateExchangeRate />} />
        </Route>
        <Route path="distribution_losses">
          <Route path="create" element={<CreateDistributionLoss />} />
        </Route>
        <Route path="notifications">
          <Route path="create" element={<CreateNotification />} />
          <Route index element={<Notifications />} />
        </Route>
        <Route path="meters">
          <Route path="create" element={<CreateMeterEOY />} />
          <Route path="update" element={<UpdateThreshold />} />
          <Route path=":meterId/edit" element={<EditMeter />} />
        </Route>
        <Route path="subscribers">
          <Route index element={<SubscriberList />} />
          <Route path="create" element={<SubscriberCreate />} />

          <Route path=":id">
            <Route index element={<SubscriberIndex />} />
            <Route path="edit" element={<SubscriberUpdate />} />
            <Route path="level0_resources/:level0ResourceId">
              <Route path="extraction_points">
                <Route path="link" element={<LinkToExtractionRights />} />
                <Route path="link_meter" element={<SubscriberLinkMeter />} />
                <Route path="create" element={<CreateExtractionPoint />} />
                <Route
                  path=":extractionPointId/edit"
                  element={<UpdateExtractionPoint />}
                />
              </Route>
              <Route path="meters">
                <Route path="create" element={<CreateMeter />} />
                <Route path="replace" element={<SubscriberReplaceMeter />} />
                <Route
                  path="Decommission"
                  element={<SubscriberDecommissionMeter />}
                />
              </Route>
              <Route path="declarations">
                <Route
                  path="create"
                  element={<SubscriberCreateDeclaration />}
                />
              </Route>
            </Route>
            <Route path="users">
              <Route path="create" element={<UserCreate />} />
              <Route path=":userId/edit" element={<UserUpdate />} />
            </Route>
          </Route>
        </Route>
        <Route path="level1wrs">
          <Route path="create" element={<CreateLevel1WRS />} />
          <Route path=":id">
            <Route index element={<Level1WRSIndex />} />
            <Route path="declarations">
              <Route path="create" element={<CreateDeclaration />} />
            </Route>
            <Route path="extraction_rights">
              <Route path="create" element={<CreateExtractionRight />} />
              <Route path=":extractionRightId">
                <Route path="edit" element={<UpdateExtractionRight />} />
                <Route path="delete" element={<DeleteExtractionRight />} />
                <Route
                  path="updateStatus"
                  element={<DeleteExtractionRight />}
                />
              </Route>
            </Route>
            <Route path="extraction_points">
              <Route
                path="create"
                element={<Level1WRSCreateExtractionPoint />}
              />
              <Route path="link_meter" element={<LinkMeter />} />
              <Route path="unlink" element={<UnlinkExtractionPoint />} />
              <Route
                path=":extractionPointId/edit"
                element={<Level1WRSUpdateExtractionPoint />}
              />
            </Route>
            <Route path="subscribers">
              <Route path="create" element={<Level1WRSCreateSubscriber />} />
              <Route
                path=":subscriberId/edit"
                element={<Level1WRSUpdateSubscriber />}
              />
            </Route>
            <Route path="users/create" element={<Level1WRSCreateUser />} />
            <Route path="bulk_meter_upload" element={<BulkMeterUpload />} />
            <Route path="water_classes">
              <Route path="create" element={<CreateWaterClass />} />
              <Route path=":waterClassId/edit" element={<UpdateWaterClass />} />
            </Route>
            <Route path="level0wrs">
              <Route path="create" element={<CreateLevel0WRS />} />
              <Route path=":level0wrsId/edit" element={<UpdateLevel0WRS />} />
            </Route>
            <Route path="meters">
              <Route path="replace" element={<ReplaceMeter />} />
              <Route path="decommission" element={<DecommissionMeter />} />
              <Route
                path=":meterId/replace_capsule"
                element={<ReplaceMeterCapsule />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="audit_trail" element={<AuditTrail />} />
        <Route path="administrative_approvals">
          <Route index element={<ApprovalIndex />} />
          <Route path="permanent_trade" element={<PermanentTransfer />} />
          <Route path="approve_forward_draw" element={<CreateForwardDraw />} />
          <Route
            path="stock_and_domestic"
            element={<CreateStockAndDomestic />}
          />
          <Route
            path="create_admin_transfer"
            element={<CreateAdminTransfer />}
          />
          <Route
            path="seasonal_transfer"
            element={<CreateSeasonalTransfer />}
          />
          <Route
            path="amalgamate_or_subdivide"
            element={<CreateAmalgamationOrSubdivision />}
          />
        </Route>
        <Route path="balance_adjustments">
          <Route path="create" element={<CreateBalanceAdjustment />} />
          <Route path="declaration" element={<AdjustDeclaration />} />
        </Route>
        <Route path="reports">
          <Route path="regulatory">
            <Route index element={<RegulatoryReports />} />
            <Route path=":id" element={<RegulatoryReports />} />
          </Route>
          <Route path="billing">
            <Route index element={<BillingReports />} />
            <Route path=":id" element={<BillingReports />} />
          </Route>
          <Route path="meter_read_sequence">
            <Route index element={<MeterReadSequenceReport />} />
            <Route path=":id" element={<MeterReadSequenceReport />} />
          </Route>
          <Route path="general" element={<GeneralReports />} />
          <Route path="water_accounts" element={<WaterAccountsReport />} />
        </Route>
        <Route path="accounting_period_checks">
          <Route path="create" element={<CreateAccountingPeriodCheck />} />
        </Route>
        <Route path="billing_groups">
          <Route path="create" element={<CreateBillingGroup />} />
          <Route path=":id/edit" element={<EditBillingGroup />} />
        </Route>
        <Route path="water_charges">
          <Route path="create" element={<CreateWaterCharge />} />
          <Route path=":id/edit" element={<EditWaterCharge />} />
        </Route>
        <Route path="declarations">
          <Route path="create" element={<CreateDeclaration />} />
        </Route>
      </Route>
      <Route path="zenith/*" element={<ZenithApp />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export default router;
