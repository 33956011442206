import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import CreateExtractionRightWorkflow from "@components/form/extraction_right/CreateExtractionRightWorkflow";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { useGetAdministrativeApproval } from "@hooks/query/useGetAdministrativeApproval";
import {
  CreateOrUpdateExtractionRightProvider,
  useCreateOrUpdateExtractionRightContext,
} from "@context/CreateOrUpdateExtractionRightContext";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import { convertLiterToML } from "@utils/convertUnits";
import { AdministrativeApprovalNumericType } from "@services/administrativeApprovals";
import Loading from "@components/shared/Loading";

const CreateExtractionRight = () => {
  return (
    <EvidenceProvider>
      <CreateOrUpdateExtractionRightProvider>
        <Consumer />
      </CreateOrUpdateExtractionRightProvider>
    </EvidenceProvider>
  );
};

const Consumer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const {
    handleChangeDetails,
    setFromSubscriber,
    setFromLevel0wrs,
    details,
    setAmalgamateOrSubdivideDetails,
  } = useCreateOrUpdateExtractionRightContext();

  const subscriberId = searchParams.get("subscriberId") || "";
  const level0ResourceId = searchParams.get("level0ResourceId") || "";
  const administrativeApprovalId =
    searchParams.get("administrativeApprovalId") || "";

  const { data: level1Resource } = useGetLevel1Resource(level1ResourceId, {
    onSuccess: (res: any) => {
      handleChangeDetails("level1wrs", res);
    },
  });
  const { data: subscriber } = useSubscriber(subscriberId, {
    onSuccess: (data) => {
      setFromSubscriber(data);
      if (details.subscriber.id === "") {
        handleChangeDetails("subscriber", data);
      }
    },
  });
  const { data: level0Resource } = useLevel0Resource(level0ResourceId, {
    onSuccess: (data) => {
      setFromLevel0wrs(data);
      if (details.level0wrs.id === "") {
        handleChangeDetails("level0wrs", data);
      }
    },
  });

  useGetAdministrativeApproval(administrativeApprovalId, {
    enabled: Boolean(administrativeApprovalId),
    onSuccess: (data: any) => {
      setAmalgamateOrSubdivideDetails(data);
      if (details?.waterClass?.id === "") {
        handleChangeDetails("waterClass", data.waterClass);
        if (data.type === AdministrativeApprovalNumericType.AME) {
          handleChangeDetails("volume", convertLiterToML(data.volume));
        }
        handleChangeDetails("startAt", new Date());
        handleChangeDetails("linkToExtractionPoints", []);
      }
    },
  });

  if (
    (level1ResourceId && !details?.level1wrs?.id) ||
    (level0ResourceId && !details?.level0wrs?.id) ||
    (subscriberId && !details?.subscriber?.id)
  ) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  return (
    <Layout
      permissions={["CreateExtractionRight"]}
      breadcrumb={[
        { label: t("dashboard.dashboard"), href: "/polestar" },
        ...(subscriber
          ? [
              {
                label: t("common.subscribers"),
                href: "/polestar/subscribers",
              },
              {
                label: subscriber?.name,
                href: `/polestar/subscribers/${subscriber?.id}`,
              },
              {
                label: level0Resource?.identifier,
                href: `/polestar/subscribers/${subscriber?.id}?level0ResourceId=${level0Resource?.id}`,
              },
              {
                label: t("common.extraction_rights"),
                href: `/polestar/subscribers/${subscriber?.id}?level0ResourceId=${level0Resource?.id}&extractionRightId=random`,
              },
            ]
          : [
              {
                label: level1Resource?.name,
                href: `/polestar/level1wrs/${level1Resource?.id}`,
              },
              {
                label: t("common.extraction_rights"),
                href: `/polestar/level1wrs/${level1Resource?.id}#4`,
              },
            ]),
        {
          label: t("extraction_right.create.title"),
        },
      ]}
      title={t("extraction_right.create.title")}
      accountingPeriodLevel1ResourceId={level1Resource?.id}
    >
      <CreateExtractionRightWorkflow />
    </Layout>
  );
};

export default CreateExtractionRight;
