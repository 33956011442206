import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Table from "@components/layout/Table";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";

type Declaration = Record<string, any>;

type SelectDeclarationTableProps = {
  level0ResourceId?: string;
  definedByWalletId?: string;
  onSelect: (items: Declaration[]) => void;
  selected?: Declaration[];
  multipleSelection?: boolean;
};

const SelectDeclarationTable: React.FunctionComponent<
  SelectDeclarationTableProps
> = ({
  level0ResourceId,
  definedByWalletId,
  onSelect,
  selected,
  multipleSelection = false,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const { data: extractionPoints, isLoading } = useAllExtractionPoints({
    params: {
      level0ResourceId,
      definedByWalletId,
      isActive: true,
      showDeclarations: true,
    },
    options: {
      select: (extractionPionts: any) =>
        extractionPionts.filter(
          (ep: any) => ep.meter?.declarations?.length > 0
        ),
    },
  });

  const tableFields = [
    {
      title: t("extraction_point.name"),
      name: "extractionPointName",
    },
    {
      title: t("meter.serial_no"),
      name: "serialNo",
    },
    {
      title: t("common.level0wrs"),
      name: "level0Resource",
    },
    {
      title: t("declaration.reading"),
      name: "reading",
    },
    {
      title: t("declaration.volume"),
      name: "volume",
    },
    {
      title: t("declaration.read_at"),
      name: "readAt",
    },
    {
      title: t("extraction_point.source"),
      name: "source",
    },
  ];

  const tableData = extractionPoints
    ?.filter((ep: any) => {
      const matcher = filter.toLowerCase();

      return ep?.meter?.serialNo?.toLowerCase()?.includes(matcher);
    })
    .map((ep: any) => {
      return {
        id: ep.meter.id,
        extractionPointName: ep.name,
        serialNo: ep.meter?.serialNo,
        level0Resource: ep?.level0WRS?.identifier,
        reading: formatVolume(ep?.meter?.declarations?.[0]?.reading, ""),
        volume: formatVolume(ep?.meter?.declarations?.[0]?.volume, ""),
        readAt: formatDate(new Date(ep?.meter?.declarations?.[0]?.readAt)),
        source: ep?.meter?.extractionPoint?.source,
      };
    });

  return (
    <>
      <div className="pb-0 flex flex-col gap-4 grow">
        <fieldset className="max-w-xs">
          <Label>{t("meter.filter_serial_no")}</Label>
          <SearchInput onChange={(e) => setFilter(e.target.value)} />
        </fieldset>
        <Table
          fields={tableFields}
          data={tableData}
          stickyHeader
          loading={isLoading}
          noRowsText={t("common.no_data")}
          selectionKey={"id"}
          multiple={!!multipleSelection}
          selectedKeys={selected?.map((i) => i?.meter?.id)}
          onSelectionChange={(meterIds) => {
            onSelect(
              multipleSelection
                ? extractionPoints
                    .filter((ep: any) => meterIds.includes(ep.meter.id))
                    .map((ep: any) => ({
                      ...ep.meter?.declarations?.[0],
                      meter: ep.meter,
                      isInitial: +ep.meter?.declarations?.[0]?.volume === 0,
                    }))
                : extractionPoints
                    .filter(
                      (ep: any) => meterIds[meterIds.length - 1] === ep.meter.id
                    )
                    .map((ep: any) => ({
                      ...ep.meter?.declarations?.[0],
                      meter: ep.meter,
                      isInitial: +ep.meter?.declarations?.[0]?.volume === 0,
                    }))
            );
          }}
        />
      </div>
    </>
  );
};

export default SelectDeclarationTable;
