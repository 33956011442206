import React from "react";
import TextInput from "@components/form/TextInput";
import ErrorMessage from "./ErrorMessage";

type Props = {
  name?: string;
  id?: string;
  defaultValue?: string;
  value?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
};

const DecimalInput: React.FunctionComponent<Props> = ({
  id,
  name,
  defaultValue,
  value,
  onChange,
  errorMessage,
}) => {
  const initialValue = defaultValue
    ? defaultValue.split(".")
    : value
    ? value.split(".")
    : [];

  const [wholeNumber, setWholeNumber] = React.useState(initialValue[0] ?? "0");
  const [decimal, setDecimal] = React.useState(initialValue[1] ?? "000");

  const changeHandler = React.useRef(onChange);

  React.useEffect(() => {
    const value = `${wholeNumber}.${decimal === "" ? "000" : decimal}`;
    changeHandler.current(value);
  }, [wholeNumber, decimal]);

  return (
    <div>
      <div className="flex flex-row gap-2">
        <TextInput
          type="number"
          id={id}
          className="w-24"
          inputClassName="font-bold sm:text-xl text-right"
          name={name}
          min={0}
          value={wholeNumber}
          onChange={(e) => {
            setWholeNumber(e.target.value);
          }}
          inline
        />
        <span className="mt-2 font-bold text-3xl">.</span>
        <TextInput
          id={`${id}-decimal`}
          className="w-24"
          inputClassName="font-bold sm:text-xl"
          name="reading_fraction"
          value={decimal}
          onChange={(e) => {
            setDecimal(e.target.value);
          }}
          min={0}
          pattern="\d*"
          maxLength={3}
          inline
        />
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default DecimalInput;
