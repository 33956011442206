import WaterEntitlementItem from "./WaterEntitlementItem";
import Loading from "@components/shared/Loading";
import { useAllExtractionRights } from "@hooks/query/zenith/useAllExtractionRights";

const WaterEntitlements: React.FunctionComponent = () => {
  const { data: extractionRights, isLoading } = useAllExtractionRights();

  if (isLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      {extractionRights?.map((r: any) => {
        return <WaterEntitlementItem key={r.id} data={r} isActive />;
      })}
    </div>
  );
};

export default WaterEntitlements;
