import React from "react";
import { useTranslation } from "react-i18next";

import Modal from "@components/layout/Modal";
import Heading from "@components/layout/Heading";
import ModalInformationIcon from "@components/icons/ModalInformationIcon";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";

import ENV from "@config/env";
import { useUpdateMeter } from "@hooks/mutation/useUpdateMeter";
import { convertLiterToML, convertMLToLiter } from "@utils/convertUnits";
import { toastError, toastSuccess } from "@utils/toast";
import type { Declaration } from "@context/DeclarationContext";

type UpdateMeterClickOverModalProps = {
  open: boolean;
  data: Declaration;
  onSubmit: (clickOver: number) => void;
  onCancel: () => void;
};

const UpdateMeterClickOverModal: React.FunctionComponent<
  UpdateMeterClickOverModalProps
> = ({ open, data, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [clickOver, setClickOver] = React.useState("");

  const { mutateAsync: updateMeterMutation } = useUpdateMeter();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const newClickOver = convertMLToLiter(+clickOver);
      await updateMeterMutation({
        id: data.meter.id,
        clickOver: newClickOver,
      });
      onSubmit(newClickOver);
      toastSuccess(t("declaration.toast.click_over.success"));
      setClickOver("");
      onCancel();
    } catch (e) {
      toastError(t("declaration.toast.click_over.failure"));
    }
  };

  return (
    <Modal open={open}>
      <form onSubmit={handleSubmit}>
        <div className="py-8 px-4 flex gap-4">
          <ModalInformationIcon className="shrink-0 h-12 w-12" />

          <div className="text-gray-500 space-y-4">
            <Heading className="text-inherit">
              {t("declaration.modal.update_click_over.title")}
            </Heading>

            <div className="max-w-lg">
              <Label htmlFor="current">
                {t("declaration.modal.update_click_over.current_value")}
              </Label>
              <TextInput
                id="current"
                value={convertLiterToML(data.meter.clickOver)}
                suffix={t("common.volume_unit")}
                readOnly
              />
            </div>

            <div className="max-w-lg">
              <Label htmlFor="new">
                {t("declaration.modal.update_click_over.new_value")}
              </Label>
              <TextInput
                id="new"
                type="number"
                value={clickOver}
                onChange={(e) => {
                  setClickOver(e.target.value);
                }}
                placeholder={ENV.DEFAULT_METER_CLICK_OVER}
                suffix={t("common.volume_unit")}
                required
              />
            </div>
          </div>
        </div>

        <footer className="border-t p-4 flex justify-end gap-4">
          <button
            type="button"
            className="btn-primary text-sm rounded"
            onClick={onCancel}
          >
            {t("common.cancel")}
          </button>
          <button type="submit" className="btn-default text-sm rounded">
            {t("common.save")}
          </button>
        </footer>
      </form>
    </Modal>
  );
};

export default UpdateMeterClickOverModal;
