import api from "../api";

type GetAllAccountingPeriodsParams = {
  isActive: boolean;
};

export const getAllAccountingPeriods = async (
  params?: Partial<GetAllAccountingPeriodsParams>
) => {
  const { data } = await api.get("accountingPeriods", { params });

  return data.accountingPeriods;
};
