import { useTranslation } from "react-i18next";

import SlideOver from "@components/layout/SlideOver";
import Legend from "@components/form/Legend";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import Loading from "@components/shared/Loading";
import { toastError, toastSuccess } from "@utils/toast";
import TextArea from "@components/form/TextArea";
import SelectSourceOfWater from "@components/form/SelectSourceOfWater";
import { useEffect, useState } from "react";
import LocationInputs from "@components/shared/LocationInputs";
import { getLatitudeLongitude } from "@utils/getLatitudeLongitude";
import { useUpdateExtractionPoint } from "@hooks/mutation/useUpdateExtractionPoint";
import { convertLiterToML, convertMLToLiter } from "@utils/convertUnits";
import ENV from "@config/env";

type UpdateExtractionPointSlideOverProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  extractionPoint: any;
};

type Details = {
  name: string;
  description: string;
  maxFlow?: number;
  volLimit?: number;
  source: string;
  address: { location: string };
  group?: string;
  sequence?: number;
};

const initialDetails = {
  name: "",
  description: "",
  source: "Ground",
  address: {
    location: "",
  },
};

const UpdateExtractionPointSlideOver: React.FunctionComponent<
  UpdateExtractionPointSlideOverProps
> = ({ open, onClose, extractionPoint, onSuccess }) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState<Details>(initialDetails);
  const [locationCoordinates, setLocationCoordinates] = useState<{
    lat: string;
    lng: string;
  }>({ lat: "", lng: "" });

  useEffect(() => {
    if (!extractionPoint?.address?.location) return;
    const {
      address: { location },
    } = extractionPoint;
    if (location) {
      const latLong = getLatitudeLongitude(location);
      if (latLong.isValid)
        setLocationCoordinates({
          lat: latLong.lat!.toString() ?? "",
          lng: latLong.lng!.toString() ?? "",
        });
    }
  }, [extractionPoint]);

  useEffect(() => {
    if (!extractionPoint) return;
    const {
      name,
      description,
      maxFlow,
      volLimit,
      source,
      address,
      group,
      sequence,
    } = extractionPoint;
    setDetails({
      name,
      description,
      maxFlow: +convertLiterToML(maxFlow),
      volLimit: +convertLiterToML(volLimit),
      source,
      address: {
        location: address?.location,
        ...(locationCoordinates.lat !== "" && {
          location: `${locationCoordinates.lat},${locationCoordinates.lng})}`,
        }),
      },
      group,
      sequence,
    });
  }, [extractionPoint, locationCoordinates]);

  const { mutateAsync: updateExtractionPointMutation, isLoading } =
    useUpdateExtractionPoint();

  const latLong = getLatitudeLongitude(details?.address?.location);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const [res] = await updateExtractionPointMutation({
        ...details,
        extractionPointId: extractionPoint.id,
        maxFlow: details.maxFlow ? convertMLToLiter(+details.maxFlow) : 0,
        volLimit: details.volLimit ? convertMLToLiter(+details.volLimit) : 0,
        sequence: details.sequence || null,
        group: details.group || null,
      });

      setDetails({
        name: res.name,
        description: res.description,
        maxFlow: res.maxFlow,
        volLimit: res.volLimit,
        source: res.source,
        address: {
          location: res.address?.location,
        },
        sequence: details.sequence,
        group: details.group,
      });

      toastSuccess(
        t("extraction_point.update.toast.success", { name: res.name })
      );

      onSuccess();
      onClose();
    } catch (error) {
      toastError(
        t("extraction_point.update.toast.error", { name: details.name })
      );
    }
  };

  return (
    <SlideOver
      open={open}
      title={t("extraction_point.update.title")}
      onClose={onClose}
    >
      {isLoading ? (
        <div className="pt-20">
          <Loading />
        </div>
      ) : (
        <form className="space-y-6" onSubmit={handleSubmit}>
          <fieldset className="space-y-4">
            <Legend>{t("extraction_point.update.name_description")}</Legend>
            <div>
              <Label>{t("extraction_point.name")}</Label>
              <TextInput
                name="name"
                value={details.name}
                onChange={(e) =>
                  setDetails({ ...details, name: e.target.value })
                }
              />
            </div>
            <div>
              <Label htmlFor="description">
                {t("extraction_point.description")}
              </Label>
              <TextArea
                id="description"
                name="description"
                value={details.description}
                onChange={(e) =>
                  setDetails({ ...details, description: e.target.value })
                }
              />
            </div>

            {ENV.CLIENT_ID === "seqwater" && (
              <>
                <div>
                  <Label htmlFor="group" optional>
                    {t("extraction_point.group")}
                  </Label>
                  <TextInput
                    id="group"
                    className="max-w-md"
                    value={details.group || ""}
                    onChange={(e) => {
                      setDetails({ ...details, group: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <Label htmlFor="sequence" optional>
                    {t("extraction_point.sequence")}
                  </Label>
                  <TextInput
                    id="sequence"
                    type="number"
                    min={0}
                    className="max-w-md"
                    value={details.sequence || ""}
                    onChange={(e) => {
                      setDetails({ ...details, sequence: +e.target.value });
                    }}
                  />
                </div>
              </>
            )}
          </fieldset>

          <fieldset className="space-y-4">
            <Legend>{t("extraction_point.update.operational_limit")}</Legend>
            <div>
              <Label htmlFor="maximum_flow_rate" optional>
                {t("extraction_point.create.enter.maximum_flow_rate")}
              </Label>
              <TextInput
                type="number"
                id="maximum_flow_rate"
                className="max-w-md"
                min={0}
                value={details.maxFlow || ""}
                step="0.001"
                suffix={t("common.volume_unit")}
                placeholder="0.00"
                onChange={(e) =>
                  setDetails({ ...details, maxFlow: +e.target.value })
                }
              />
            </div>
            <div>
              <Label
                htmlFor="maximum_volume"
                className="block text-gray-600"
                optional
              >
                {t("extraction_point.create.enter.maximum_volume")}
              </Label>
              <TextInput
                type="number"
                id="maximum_volume"
                className="max-w-md"
                min={0}
                value={details.volLimit || ""}
                step="0.001"
                suffix={t("common.volume_unit")}
                placeholder="0.00"
                onChange={(e) =>
                  setDetails({ ...details, volLimit: +e.target.value })
                }
              />
            </div>
          </fieldset>

          <fieldset className="space-y-4">
            <Legend>{t("extraction_point.update.water_source")}</Legend>
            <div>
              <Label htmlFor="source_of_water" optional>
                {t("extraction_point.create.enter.source_of_water")}
              </Label>
              <SelectSourceOfWater
                className="max-w-md"
                value={details.source}
                onChange={({ value }) =>
                  setDetails({ ...details, source: value })
                }
              />
            </div>
          </fieldset>

          <fieldset className="space-y-4">
            <Legend>{t("extraction_point.update.geography")}</Legend>

            {latLong.isValid && (
              <>
                <Label className="mb-2">Current geography</Label>
                <div className="flex gap-4">
                  <div className="text-gray-600 text-xs place-self-center">
                    Lat:{" "}
                    {typeof latLong.lat !== "undefined"
                      ? latLong.lat.toFixed(5)
                      : ""}
                  </div>
                  <div className="text-gray-600 text-xs place-self-center">
                    Long:{" "}
                    {typeof latLong.lng !== "undefined"
                      ? latLong.lng.toFixed(5)
                      : ""}
                  </div>
                </div>
              </>
            )}

            <LocationInputs
              locationCoordinates={locationCoordinates}
              setLocationCoordinates={setLocationCoordinates}
            />
          </fieldset>

          <footer className="border-t border-gray-200 flex gap-4 p-6 pb-0 -mx-6">
            <button type="submit" className="btn-default" disabled={isLoading}>
              {t("common.confirm")}
            </button>
            <button type="button" className="btn-primary" onClick={onClose}>
              {t("common.cancel")}
            </button>
          </footer>
        </form>
      )}
    </SlideOver>
  );
};

export default UpdateExtractionPointSlideOver;
