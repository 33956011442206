import React from "react";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import { UseStepHelpers, useStep } from "@hooks/useStep";
import { useValidateAdminTransferRelationship } from "@hooks/mutation/useValidateAdminTransferRelationship";
import { useTranslation } from "react-i18next";
import { toastError } from "@utils/toast";
import {
  GetInfoMessagesFunction,
  ModifyStatusesFunction,
  useStepStatuses,
} from "@hooks/useStepStatuses";
import { useSearchParams } from "react-router-dom";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useExtractionRight } from "@hooks/query/useExtractionRight";
import { formatVolume } from "@utils/formatVolume";
import { type ConfirmData } from "@components/shared/ConfirmationDetail";

type Props = {
  children: React.ReactNode;
};

type Details = {
  id: string;
  level1wrs: {
    id: string;
    name: string;
    identifier: string;
  };
  subscriber: {
    id: string;
    name: string;
    accountNumber: string;
    walletId: string;
  };
  extractionRight: {
    id: string;
    name: string;
    volume: number;
    level0Resource: {
      identifier: string;
    };
    waterClass: {
      name: string;
    };
  };
  extractionPoint: {
    id: string;
    name: string;
    isActive: boolean;
    level0WRS: {
      id: string;
      identifier: string;
    };
  };
};

type HandleChangeDetails = <TValue, TKeys extends keyof Details>(
  key: TKeys,
  value: TValue,
  subKey?: string
) => void;

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<typeof initialDetails>>;
  message: any;
  setMessage: React.Dispatch<React.SetStateAction<any>>;
  handleChangeDetails: HandleChangeDetails;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<React.SetStateAction<any>>;
  getInfoMessages: GetInfoMessagesFunction;
  modifyStatuses: ModifyStatusesFunction;
  navigateForCancel: () => void;
  existsRelationship: boolean;
  setExistsRelationship: React.Dispatch<React.SetStateAction<boolean>>;
  supportingEvidence: any;
  setSupportingEvidence: React.Dispatch<React.SetStateAction<any>>;
  confirmData: Record<string, ConfirmData>;
};

const AdminTransferContext = React.createContext<ContextValue | undefined>(
  undefined
);

const initialDetails = {
  id: "",
  level1wrs: {
    id: "",
    name: "",
    identifier: "",
  },
  subscriber: {
    id: "",
    name: "",
    accountNumber: "",
    walletId: "",
  },
  extractionRight: {
    id: "",
    name: "",
    volume: 0,
    level0Resource: {
      identifier: "",
    },
    waterClass: {
      name: "",
    },
  },
  extractionPoint: {
    id: "",
    name: "",
    isActive: false,
    level0WRS: {
      id: "",
      identifier: "",
    },
  },
};

const AdminTransferProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const maxStep = 6;
  const [currentStep, stepHelpers] = useStep(maxStep);
  const handleGoBackOrClose = HandleGoBackOrClose();
  const { modifyStatuses, getInfoMessages } = useStepStatuses(maxStep);
  const [details, setDetails] = React.useState(initialDetails);
  const [existsRelationship, setExistsRelationship] =
    React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>({
    messageTemplate: null,
    subject: "",
    body: "",
  });
  const { mutateAsync: validateRelationshipMutation } =
    useValidateAdminTransferRelationship();
  const [workflowCompleted, setWorkflowCompleted] =
    React.useState<boolean>(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const [supportingEvidence, setSupportingEvidence] = React.useState<any>();

  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") || "";
  const extractionRightId = searchParams.get("extractionRightId") || "";
  const initialStep = searchParams.get("initialStep") || "";
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    if (initialStep && !isMounted.current) {
      isMounted.current = true;
      stepHelpers.setStep(Number(initialStep));
    }
  }, [initialStep, stepHelpers]);

  useSubscriber(subscriberId, {
    onSuccess: (data: any) => {
      handleChangeDetails("subscriber", {
        id: data.id,
        name: data.name,
        accountNumber: data.accountNumber,
        walletId: data.walletId,
      });
    },
    refetchOnWindowFocus: false,
  });

  useExtractionRight(extractionRightId, {
    onSuccess: (data: any) => {
      handleChangeDetails("extractionRight", {
        id: data.id,
        name: data.name,
        volume: Number(data.volume),
        level0Resource: {
          identifier: data.level0Resource.identifier,
        },
        waterClass: {
          name: data.waterClass.name,
        },
      });
    },
    refetchOnWindowFocus: false,
  });

  const handleChangeDetails = (key: string, value: any, subKey?: string) => {
    setDetails((prevState: any) => {
      const updatedDetails: any = { ...prevState };
      if (subKey) {
        updatedDetails[key] = { ...prevState[key], [subKey]: value };
      } else {
        updatedDetails[key] = value;
      }
      return updatedDetails;
    });
    if (key === "extractionPoint") {
      validateRelationship(details.extractionRight.id, value.id);
    }
  };

  const validateRelationship = async (
    extractionRightId: string,
    extractionPointId: string
  ) => {
    if (!extractionRightId || extractionPointId === "") {
      modifyStatuses({
        stepNumber: 3,
        fieldName: "extractionPoint",
      });
      modifyStatuses({
        stepNumber: 3,
        fieldName: "extractionPointWarning",
      });
      return;
    }
    try {
      const existRelation = await validateRelationshipMutation({
        extractionRightId,
        extractionPointId,
      });

      if (existRelation) {
        setExistsRelationship(true);
        modifyStatuses({
          stepNumber: 3,
          fieldName: "extractionPoint",
          message: t(
            "approval.admin_transfer.create.step_4.error_1_warn"
          ) as string,
          infoType: "warning",
        });
        modifyStatuses({
          stepNumber: 3,
          fieldName: "extractionPointWarning",
          message: t("approval.admin_transfer.create.step_4.error_1") as string,
          infoType: "error",
        });
      } else {
        setExistsRelationship(false);
        modifyStatuses({
          stepNumber: 3,
          fieldName: "extractionPoint",
        });
        modifyStatuses({
          stepNumber: 3,
          fieldName: "extractionPointWarning",
        });
      }
    } catch (error) {
      toastError(t("level1wrs.create.toast.check_identifier_failure"));
    }
  };

  const navigateForCancel = handleGoBackOrClose;

  const confirmData = {
    level1Resource: {
      title: t("level1wrs.create.details"),
      body: [
        {
          key: t("level1wrs.create.name"),
          value: details.level1wrs?.name,
        },
        {
          key: t("level1wrs.create.identifier"),
          value: details.level1wrs?.identifier,
        },
      ],
    },
    subscriber: {
      title: t("subscriber.create.subscriber_details"),
      body: [
        {
          key: t("subscriber.create.subscriber_name"),
          value: details.subscriber?.name,
        },
        {
          key: t("subscriber.identifier"),
          value: details.subscriber?.accountNumber,
        },
      ],
    },
    extractionRight: {
      title: t("extraction_right.details"),
      body: [
        {
          key: t("extraction_right.number"),
          value: details.extractionRight?.name,
        },
        {
          key: t("common.level0wrs"),
          value: details.extractionRight?.level0Resource?.identifier,
        },
        {
          key: t("common.single_water_class"),
          value: details.extractionRight?.waterClass?.name,
        },
        {
          key: t("extraction_right.volume"),
          value: formatVolume(details.extractionRight?.volume),
        },
      ],
    },
    extractionPoint: {
      title: t("extraction_point.details"),
      body: [
        {
          key: t("extraction_point.name"),
          value: details.extractionPoint?.name,
        },
        {
          key: t("common.level0wrs"),
          value: details.extractionPoint?.level0WRS?.identifier,
        },
        {
          key: t("common.status"),
          value: details.extractionPoint?.isActive ? "Active" : "Inactive",
        },
      ],
    },
  };

  const value: ContextValue = {
    currentStep,
    stepHelpers,
    details,
    setDetails,
    message,
    setMessage,
    handleChangeDetails,
    workflowCompleted,
    setWorkflowCompleted,
    workflowInstance,
    setWorkflowInstance,
    getInfoMessages,
    modifyStatuses,
    navigateForCancel,
    existsRelationship,
    setExistsRelationship,
    supportingEvidence,
    setSupportingEvidence,
    confirmData,
  };

  return (
    <AdminTransferContext.Provider value={value}>
      {children}
    </AdminTransferContext.Provider>
  );
};

const useAdminTransferContext = () => {
  const context = React.useContext(AdminTransferContext);
  if (context === undefined) {
    throw new Error(
      "useAdminTransferContext must be used within a AdminTransferProvider"
    );
  }
  return context;
};

export { AdminTransferProvider, useAdminTransferContext };
