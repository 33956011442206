import { useQuery } from "@tanstack/react-query";

import { getAllSubscriberAdministrativeApprovals } from "@services/zenith/administrativeApprovals";

type QueryParams = {
  types: number[];
  status: number;
  limit: number;
  page: number;
};

type Params = {
  params: Partial<QueryParams>;
  [key: string]: any;
};

export const useAllAdministrativeApprovals = ({
  params,
  ...options
}: Partial<Params> = {}) => {
  return useQuery<any>({
    queryKey: ["administrativeApprovals", params],
    queryFn: () => getAllSubscriberAdministrativeApprovals(params),
    ...options,
  });
};
