import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Breadcrumb from "@components/layout/Breadcrumb";
import Loading from "@components/shared/Loading";
import TabPanels from "@components/form/TabPanels";
import WaterEntitlements from "./WaterEntitlements";
import ZoneAccountSummary from "./ZoneAccountSummary";
import OfftakesAndMetersTab from "./OfftakesAndMetersTab";
import TransactionHistory from "./TransactionHistory";
import { useGetZoneAccountById } from "@hooks/query/useGetZoneAccountById";

export default function ZoneAccount() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, isLoading } = useGetZoneAccountById(id ?? "");

  const breadcrumb = [
    {
      label: "Dashboard",
      href: "/zenith",
    },
    {
      label: `${t("zenith.zone_accounts.title")} ${data?.identifier}`,
    },
  ];

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <header className="mt-3">
        <Heading>
          {t("zenith.zone_accounts.title")} {data?.identifier}
        </Heading>

        {isLoading ? (
          <div className="pt-8">
            <Loading />
          </div>
        ) : (
          <section className="grid lg:grid-cols-4 gap-6 mt-5">
            <aside>
              <ZoneAccountSummary
                zonePriorityBalance={data.zonePriorityBalances[0]}
                extractionRights={data.extractionRights}
              />
            </aside>

            <div className="bg-white border border-gray-300 lg:col-span-3">
              <TabPanels
                tabs={[
                  {
                    title: t("zenith.zone_accounts.tabs.water_entitlements"),
                    panel: <WaterEntitlements />,
                  },
                  {
                    title: t("zenith.zone_accounts.tabs.offtakes_and_meters"),
                    panel: <OfftakesAndMetersTab />,
                  },
                  {
                    title: t("zenith.zone_accounts.tabs.transaction_history"),
                    panel: (
                      <TransactionHistory
                        zonePriorityBalance={data.zonePriorityBalances[0]}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </section>
        )}
      </header>
    </>
  );
}
