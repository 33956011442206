import { MapEngines } from "@components/shared/MapView";

const ENV = {
  REACT_APP_ENV: process.env.REACT_APP_ENV ?? "development",
  APP_KEY: process.env.REACT_APP_KEY || "some-secret-key",
  API_HOST: process.env.REACT_APP_API_HOST,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID || "",
  SUPPORTED_LANGUAGES:
    process.env.REACT_APP_SUPPORTED_LANGUAGES || "en,de,fr,th",
  LEDGER_WORKS_URL:
    process.env.REACT_APP_LEDGER_WORKS_URL ||
    "https://explore.lworks.io/testnet",
  HELP_CENTER_URL:
    process.env.REACT_APP_HELP_CENTER_URL ||
    "https://civicledger.atlassian.net/servicedesk/customer/portal/1",
  MAP_ENGINE: process.env.REACT_APP_MAP_ENGINE || MapEngines.OPENSTREETMAP,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  OPEN_WEATHER_MAP_API_KEY: process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY,
  BYPASS_MFA: process.env.REACT_APP_BYPASS_MFA === "true",
  DEFAULT_METER_THRESHOLD: process.env.REACT_APP_DEFAULT_METER_THRESHOLD
    ? parseInt(process.env.REACT_APP_DEFAULT_METER_THRESHOLD, 10)
    : 2000000000,
  DEFAULT_METER_CLICK_OVER:
    process.env.REACT_APP_DEFAULT_METER_CLICK_OVER ?? "999.999",
  EXCLUDE_WATER_CLASS_NAMES: process.env.REACT_APP_EXCLUDE_WATER_CLASS_NAMES
    ? process.env.REACT_APP_EXCLUDE_WATER_CLASS_NAMES.split(",")
    : [],
  TEST_ACCOUNT: process.env.REACT_APP_TEST_ACCOUNT || "",
};

export default ENV;
