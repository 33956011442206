import waterledgerLogo from "@images/waterledger-dark.svg";
import packageJson from "../../../../package.json";
import { useTranslation } from "react-i18next";

const AppFooter = () => {
  const { t } = useTranslation();
  return (
    <footer className="flex flex-col gap-4 lg:flex-row items-center justify-between py-6 px-4 sm:px-6 lg:px-8 mt-2 lg:mt-4 text-gray-500 text-xs">
      <p>
        &copy; {new Date().getFullYear()} {t("common.all_rights_reserved")}
      </p>

      <p className="flex items-center gap-2">
        <span>{t("common.powered_by")}</span>
        <img src={waterledgerLogo} alt="Water Ledger logo" className="h-5" />
        <span className="inline-block mr-3">
          Version: {packageJson.version}
        </span>
      </p>
    </footer>
  );
};

export default AppFooter;
