import BillingReportsChecklist from "@components/form/accounting_period_checks/BillingReportsChecklist";
import DistributionLossesChecklist from "@components/form/accounting_period_checks/DistributionLossesChecklist";
import FinalizeChecklist from "@components/form/accounting_period_checks/FinalizeChecklist";
import ForwardDrawsChecklist from "@components/form/accounting_period_checks/ForwardDrawsChecklist";
import MeterAdjustmentsChecklist from "@components/form/accounting_period_checks/MeterAdjustmentsChecklist";
import MeterReadingsChecklist from "@components/form/accounting_period_checks/MeterReadingsChecklist";
import PermanentTradesChecklist from "@components/form/accounting_period_checks/PermanentTradesChecklist";
import SeasonalAssignmentsChecklist from "@components/form/accounting_period_checks/SeasonalAssignmentsChecklist";
import SelectLevel1WRSChecklist from "@components/form/accounting_period_checks/SelectLevel1WRSChecklist";
import WaterChargesChecklist from "@components/form/accounting_period_checks/WaterChargesChecklist";
import WaterHarvestingsChecklist from "@components/form/accounting_period_checks/WaterHarvestingsChecklist";
import ZoneVolumesChecklist from "@components/form/accounting_period_checks/ZoneVolumesChecklist";
import WorkflowPanels from "@components/form/WorkflowPanels";
import Layout from "@components/layout/Layout";
import StepButton from "@components/shared/StepButton";
import { useAppContext } from "@context/AppContext";
import {
  useAccountingPeriodChecksContext,
  AccountingPeriodChecksProvider,
} from "@context/AccountingPeriodChecksContext";
import { formatDate } from "@utils/formatDate";
import { useTranslation } from "react-i18next";
import PendingAdminApprovalConfirmationChecklist from "@components/form/accounting_period_checks/PendingAdminApprovalConfirmationChecklist";
import InfoPanel from "@components/form/InfoPanel";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { StatusInfo } from "../administrative_approvals/ApprovalIndex";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";

const CreateAccountingPeriodCheck: React.FunctionComponent = () => {
  return (
    <AccountingPeriodChecksProvider>
      <MainComponent />
    </AccountingPeriodChecksProvider>
  );
};

const MainComponent: React.FunctionComponent = () => {
  const { currentAccountingPeriodCheck, workflowCompleted, workflowInstance } =
    useAccountingPeriodChecksContext();
  const { loggedInInfo } = useAppContext();
  const { t } = useTranslation();

  const translationData = {
    accountingPeriod: formatAccountingPeriod(
      currentAccountingPeriodCheck?.accountingPeriod
    ),
    level1wrsName: currentAccountingPeriodCheck?.level1WRS?.name,
  };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          forceSuccess={currentAccountingPeriodCheck?.level1wrsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.permanentTradesCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.level1wrsCheckedAt)
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.select_scheme")}
        </StepButton>
      ),
      panel: <SelectLevel1WRSChecklist />,
      infoPanel: (
        <InfoPanel
          warnings={[t("accounting_period_checks.info_panel.step_1.warning_1")]}
        />
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          forceSuccess={currentAccountingPeriodCheck?.permanentTradesCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.permanentTradesCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.permanentTradesCheckedAt
                  )
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.permanent_trades")}
        </StepButton>
      ),
      panel: <PermanentTradesChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_2.info", {
                ...translationData,
              })}
            </p>
            <StatusInfo />
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          forceSuccess={
            currentAccountingPeriodCheck?.seasonalAssignmentsCheckedAt
          }
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.seasonalAssignmentsCheckedBy
                  ?.name || loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.seasonalAssignmentsCheckedAt
                  )
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.seasonal_assignments")}
        </StepButton>
      ),
      panel: <SeasonalAssignmentsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_3.info", {
                ...translationData,
              })}
            </p>
            <StatusInfo />
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          forceSuccess={currentAccountingPeriodCheck?.meterAdjustmentsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.meterAdjustmentsCheckedBy
                  ?.name || loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.meterAdjustmentsCheckedAt
                  )
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.meter_adjustment")}
        </StepButton>
      ),
      panel: <MeterAdjustmentsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_4.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          forceSuccess={currentAccountingPeriodCheck?.meterReadsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.meterReadsCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.meterReadsCheckedAt)
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.meter_read")}
        </StepButton>
      ),
      panel: <MeterReadingsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_5.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          forceSuccess={currentAccountingPeriodCheck?.waterHarvestingsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.waterHarvestingsCheckedBy
                  ?.name || loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.waterHarvestingsCheckedAt
                  )
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.water_harvesting")}
        </StepButton>
      ),
      panel: <WaterHarvestingsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_6.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={6}
          forceSuccess={
            currentAccountingPeriodCheck?.distributionLossesCheckedAt
          }
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.distributionLossesCheckedBy
                  ?.name || loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.distributionLossesCheckedAt
                  )
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.distribution_losses")}
        </StepButton>
      ),
      panel: <DistributionLossesChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_7.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={7}
          forceSuccess={currentAccountingPeriodCheck?.forwardDrawsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.forwardDrawsCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.forwardDrawsCheckedAt)
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.forward_draws")}
        </StepButton>
      ),
      panel: <ForwardDrawsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_8.info", {
                ...translationData,
              })}
            </p>
          </div>
          <StatusInfo />
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={8}
          forceSuccess={currentAccountingPeriodCheck?.billingReportsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.billingReportsCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.billingReportsCheckedAt
                  )
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.billing_reports")}
        </StepButton>
      ),
      panel: <BillingReportsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_9.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={9}
          forceSuccess={currentAccountingPeriodCheck?.zoneVolumesCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.zoneVolumesCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.zoneVolumesCheckedAt)
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.level0wrs_volumes")}
        </StepButton>
      ),
      panel: <ZoneVolumesChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_10.info", {
                ...translationData,
              })}
            </p>
            <div className="pt-2 flex gap-4">
              <span>
                <InformationCircleIcon className="text-blue-600 w-5 h-5"></InformationCircleIcon>
              </span>
              {t("accounting_period_checks.info_panel.step_10.info_1")}
            </div>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={10}
          forceSuccess={currentAccountingPeriodCheck?.waterChargesCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.waterChargesCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.waterChargesCheckedAt)
                )}
              </div>
            </>
          }
        >
          {t("accounting_period_checks.step_labels.water_charges")}
        </StepButton>
      ),
      panel: <WaterChargesChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_11.info", {
                ...translationData,
              })}
            </p>
            <div className="pt-2 flex gap-4">
              <span>
                <InformationCircleIcon className="text-blue-600 w-5 h-5"></InformationCircleIcon>
              </span>
              {t("accounting_period_checks.info_panel.step_11.info_1")}
            </div>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={11}
          forceSuccess={
            currentAccountingPeriodCheck?.pendingAdminApprovalsCheckedAt
          }
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck
                  ?.pendingAdminApprovalsCheckedByUserId?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period_checks.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.forwardDrawsCheckedAt)
                )}
              </div>
            </>
          }
        >
          {t(
            "accounting_period_checks.step_labels.pending_admin_approval_confirmation"
          )}
        </StepButton>
      ),
      panel: <PendingAdminApprovalConfirmationChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_12.info", {
                ...translationData,
              })}
            </p>
            <p>{t("accounting_period_checks.info_panel.step_12.body")}</p>
            <p>{t("accounting_period_checks.info_panel.step_12.conc")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={12}
          forceSuccess={
            !currentAccountingPeriodCheck?.accountingPeriod?.isActive
          }
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
        >
          {t("accounting_period_checks.step_labels.reset")}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <WorkflowComplete references={[workflowInstance?.id]} />
      ) : (
        <FinalizeChecklist />
      ),
      infoPanel: workflowCompleted ? (
        <InfoPanel
          successes={[
            t(
              "accounting_period_checks.info_panel.workflow_completed.success_1",
              {
                ...translationData,
              }
            ),
          ]}
        />
      ) : (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period_checks.info_panel.step_13.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
  ];

  return (
    <Layout
      breadcrumb={[
        {
          label: t("common.home"),
          href: "/polestar",
        },
        {
          label: t("accounting_period_checks.title"),
        },
      ]}
      title={t("accounting_period_checks.title")}
      permissions={["CreateAccountingPeriodChecks"]}
      accountingPeriodLevel1ResourceId={
        currentAccountingPeriodCheck?.level1ResourceId
      }
    >
      <WorkflowPanels
        selectedStep={currentAccountingPeriodCheck?.currentStep}
        steps={steps}
      />
    </Layout>
  );
};

export default CreateAccountingPeriodCheck;
