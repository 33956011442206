import Heading from "@components/layout/Heading";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Label from "../Label";
import {
  BalanceAdjustmentType,
  useBalanceAdjustmentContext,
} from "@context/BalanceAdjustmentContext";

type CreateBalanceAdjustmentSelectTypeProps = {
  onSave: () => void;
};

const CreateBalanceAdjustmentSelectType: FunctionComponent<
  CreateBalanceAdjustmentSelectTypeProps
> = ({ onSave }) => {
  const { t } = useTranslation();
  const { balanceAdjustmentData, setBalanceAdjustmentData, navigateForCancel } =
    useBalanceAdjustmentContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSave();
  };

  return (
    <form className="pb-4 flex flex-col h-full" onSubmit={handleSubmit}>
      <div className="p-6 grow">
        <Heading className="text-xl leading-6" light={true}>
          {t("balance_adjustment.select_type")}
        </Heading>
        <div className="mt-6 flex flex-col gap-4">
          <Label className="flex gap-2 items-center">
            <input
              type="radio"
              name="balance_adjustment_type"
              value={BalanceAdjustmentType.WATER_HARVESTING}
              checked={
                balanceAdjustmentData.type ===
                BalanceAdjustmentType.WATER_HARVESTING
              }
              onClick={() =>
                setBalanceAdjustmentData({
                  ...balanceAdjustmentData,
                  type: BalanceAdjustmentType.WATER_HARVESTING,
                })
              }
            />
            {t("balance_adjustment.water_harvesting.title")}
          </Label>
          <Label className="flex gap-2 items-center">
            <input
              type="radio"
              name="balance_adjustment_type"
              value={BalanceAdjustmentType.METER_READING}
              checked={
                balanceAdjustmentData.type ===
                BalanceAdjustmentType.METER_READING
              }
              onClick={() =>
                setBalanceAdjustmentData({
                  ...balanceAdjustmentData,
                  type: BalanceAdjustmentType.METER_READING,
                  declarations:
                    balanceAdjustmentData.declarations.length > 0
                      ? [balanceAdjustmentData.declarations[0]]
                      : balanceAdjustmentData.declarations,
                })
              }
            />
            {t("balance_adjustment.meter_reading.title")}
          </Label>
        </div>
      </div>
      <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
        <button type="submit" className="btn-primary">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default CreateBalanceAdjustmentSelectType;
