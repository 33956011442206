import { useMutation } from "@tanstack/react-query";

import {
  CreateSeasonalTransferPayload,
  manualCreateSeasonalTransfer,
} from "@services/administrativeApprovals";

export const useCreateSeasonalTransfer = (options: any = {}) => {
  return useMutation<any, any, CreateSeasonalTransferPayload>({
    mutationKey: ["approval.seasonal_water_assignments.create.manual_approve"],
    mutationFn: manualCreateSeasonalTransfer,
    ...options,
  });
};
