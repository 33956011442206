import React from "react";

import EventTransactionTable from "@components/table/EventTransactionTable";

type TransactionHistoryTabProps = {
  walletId: string;
};

const TransactionHistoryTab: React.FunctionComponent<
  TransactionHistoryTabProps
> = ({ walletId }: TransactionHistoryTabProps) => {
  return (
    <EventTransactionTable
      eventGroups={["User", "Accounting", "Exchange"]}
      walletId={walletId}
      limit={-1}
      stickyHeader
    />
  );
};

export default TransactionHistoryTab;
