import React from "react";
import { useTranslation } from "react-i18next";

import ExtractionRightTable from "@components/table/ExtractionRightTable";
import { useStepContext } from "@context/shared/StepContext";
import { useStockAndDomesticApprovalContext } from "@context/StockAndDomesticApprovalContext";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import ConfirmModal from "@components/shared/ConfirmModal";

const SelectExtractionRight: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const [isApprove, setIsApprove] = React.useState(false);
  const { details, setDetails, handleCancel } =
    useStockAndDomesticApprovalContext();

  const rightName = details.extractionRight.name;
  const { data = [] } = useAllExtractionRights({
    params: {
      name: `SD${rightName}`,
    },
    options: {
      enabled: Boolean(rightName),
    },
  });

  const handleSubmit = () => {
    if (data.length > 0) {
      setIsApprove(true);
      return;
    }
    stepHelpers.goToNextStep();
  };

  return (
    <>
      <ExtractionRightTable
        subscriber={details.subscriber}
        onSelect={(value: any) => {
          let extractionRight = {
            id: "",
            name: "",
          };
          let level0Resource = {
            id: "",
            identifier: "",
          };

          if (value.length === 1) {
            extractionRight = {
              id: value[0].id,
              name: value[0].name,
            };
            level0Resource = {
              id: value[0].level0ResourceId,
              identifier: value[0].level0ResourceIdentifier,
            };
          } else if (value.length > 1) {
            extractionRight = {
              id: value[1].id,
              name: value[1].name,
            };
            level0Resource = {
              id: value[1].level0ResourceId,
              identifier: value[1].level0ResourceIdentifier,
            };
          }

          setDetails((prev) => ({
            ...prev,
            extractionRight,
            level0Resource,
          }));
        }}
        selected={details.extractionRight.id ? [details.extractionRight] : []}
        multiple={false}
      />

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={handleSubmit}
          disabled={!details.extractionRight.id}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={isApprove}
        onConfirm={() => setIsApprove(false)}
        confirmText={t("common.close")}
      >
        {t("approval.stock_and_domestic.modal.approved", {
          extractionRightName: details.extractionRight.name,
        })}
      </ConfirmModal>
    </>
  );
};

export default SelectExtractionRight;
