import React, { FunctionComponent } from "react";

const WorkflowExtractionRightIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#0C3C9D"
        d="M10.5 17a7.5 7.5 0 110-15 7.5 7.5 0 110 15zm5.151-4.373v-5.94l-5.158-2.971-.007.003-5.14 2.97v5.94l5.144 2.963 5.16-2.965h.001zm-2.283-.95l-.88-.504-1.963 1.14a.037.037 0 01-.037 0l-2.253-1.306V8.736l-1.98-1.134 4.219-2.473a.01.01 0 01.011 0l3.917 2.24-.692.4-3.223-1.854-2.858 1.678.991.57 1.869-1.087v-.001l2.284 1.305-.001 2.161 1.945 1.114v.033l-4.226 2.468-3.918-2.237.686-.402 3.225 1.85 2.884-1.69zM11.112 9.1V9.073l-.001-.006-.001-.011v-.004l-.002-.013v-.003l-.001-.013V9.02l-.002-.013v-.002l-.003-.014V8.99l-.003-.014v-.001l-.004-.015a.17.17 0 00-.003-.015l-.004-.015-.004-.015a.215.215 0 00-.01-.028l-.005-.015a.236.236 0 00-.006-.014l-.006-.014-.006-.013-.007-.014-.006-.013-.008-.013a1.185 1.185 0 00-.015-.025v-.001a.195.195 0 00-.008-.012l-.008-.012h-.001l-.008-.012-.001-.001-.008-.011v-.001l-.01-.011-.009-.011v-.001l-.01-.01-.001-.001-.009-.01V8.67l-.01-.01-.01-.01-.002-.001-.01-.01-.01-.009-.002-.001-.01-.01a.186.186 0 00-.01-.009h-.002l-.01-.01h-.002l-.01-.008h-.002l-.01-.009h-.002l-.01-.008h-.002l-.012-.008v-.001a.279.279 0 00-.012-.007h-.001l-.012-.008h-.001l-.013-.007a.63.63 0 00-.536 1.137l-.272 1.243h1.028l-.274-1.242a.632.632 0 00.389-.583z"
      ></path>
    </svg>
  );
};

export default WorkflowExtractionRightIcon;
