import { useQuery } from "@tanstack/react-query";

import { getSubscriberLevel0ResourceBalances } from "@services/subscriber";

export const useSubscriberLevel0ResourceBalances = (
  id: string,
  options?: Record<string, any>,
) => {
  return useQuery<any>({
    queryKey: ["subscriberLevel0ResourceBalances", id],
    queryFn: () => getSubscriberLevel0ResourceBalances(id),
    enabled: Boolean(id),
    ...options,
  });
};
