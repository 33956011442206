import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import _sumBy from "lodash/sumBy";
import { useTranslation } from "react-i18next";

import Loading from "@components/shared/Loading";
import { useStep, UseStepHelpers } from "@hooks/useStep";
import { useAllWaterClasses } from "@hooks/query/useAllWaterClasses";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import {
  GetInfoMessagesFunction,
  ModifyStatusesFunction,
  useStepStatuses,
} from "@hooks/useStepStatuses";
import { formatVolume } from "@utils/formatVolume";
import { convertLiterToML, convertMLToLiter } from "@utils/convertUnits";
import { formatDateInput } from "@utils/formatDate";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";

export type AdjustedWaterClassVolumeDetail = {
  classId: string;
  adjustedVolume: string;
};

type WaterClassDetails = {
  id: string;
  name: string;
  level0ResourceId: string;
  classCategoryId: string;
  volume: number;
  acctPriority: number;
  allocation: number;
  startAt: string;
  yield: number;
  activatedEvidenceIds: any;
  originalName: string;
};

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  level1Resource: any;
  level0Resource: any;
  waterClasses: any;
  details: WaterClassDetails;
  setDetails: React.Dispatch<React.SetStateAction<WaterClassDetails>>;
  volumeToValidate?: number | undefined;
  setVolumeToValidate: React.Dispatch<number | undefined>;
  getInfoMessages: GetInfoMessagesFunction;
  modifyStatuses: ModifyStatusesFunction;
  updatingWaterClass: any;
  setUpdatingWaterClass: React.Dispatch<any>;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<boolean>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  navigateForCancel: () => void;
  classCategoryName: string;
  setClassCategoryName: React.Dispatch<string>;
  sumOfWaterClassVolumes: () => number;
  isExceedTotalWaterInZone: (volume: number) => boolean;
  isVolumeExceed: boolean;
  setIsVolumeExceed: React.Dispatch<boolean>;
  totalWaterClassesVolume: number;
  doAdjustWaterClassVolumeDetails: boolean;
  setDoAdjustWaterClassVolumeDetails: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  adjustedWaterClassVolumeDetails: AdjustedWaterClassVolumeDetail[];
  setAdjustedWaterClassVolumeDetails: React.Dispatch<
    React.SetStateAction<AdjustedWaterClassVolumeDetail[]>
  >;
  validateCurrentClassVolume: (
    isAdjustingClassBalanceMandatory: boolean
  ) => void;
  validateAdjustedClassVolume: ({
    availableToDistribute,
    currentYearLevel0wrsYield,
  }: {
    availableToDistribute?: number;
    currentYearLevel0wrsYield: number;
  }) => void;
};

const Context = React.createContext<ContextValue | undefined>(undefined);

const WaterClassProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: level1wrsId = "", level0wrsId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const maxStep = 7;
  const [currentStep, stepHelpers] = useStep(maxStep);
  const { getInfoMessages, modifyStatuses } = useStepStatuses(maxStep);
  const [details, setDetails] = React.useState({
    id: "",
    name: "",
    level0ResourceId: "",
    classCategoryId: "",
    volume: 0,
    acctPriority: 0,
    allocation: 0,
    startAt: "",
    activatedEvidenceIds: [],
    originalName: "",
    yield: 0,
  });
  const [classCategoryName, setClassCategoryName] = React.useState("");
  const [isVolumeExceed, setIsVolumeExceed] = React.useState(false);
  const [workflowCompleted, setWorkflowCompleted] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const [updatingWaterClass, setUpdatingWaterClass] = React.useState<any>();
  const [volumeToValidate, setVolumeToValidate] = React.useState<
    number | undefined
  >(undefined);
  const [doAdjustWaterClassVolumeDetails, setDoAdjustWaterClassVolumeDetails] =
    React.useState(false);
  const [adjustedWaterClassVolumeDetails, setAdjustedWaterClassVolumeDetails] =
    React.useState<AdjustedWaterClassVolumeDetail[]>([]);

  React.useEffect(() => {
    if (!level0wrsId) {
      return;
    }

    setDetails((prev: any) => ({
      ...prev,
      level0ResourceId: level0wrsId,
    }));
  }, [level0wrsId]);

  const { data: currentAccountingPeriod = {} } = useAllAccountingPeriods({
    params: {
      level1ResourceId: level1wrsId,
      isActive: true,
    },
    options: {
      enabled: Boolean(level1wrsId),
      select: (data: any) => data?.[0],
    },
  });
  React.useEffect(() => {
    if (details.startAt || !currentAccountingPeriod.periodStart) {
      return;
    }

    setDetails((prev: any) => ({
      ...prev,
      startAt: formatDateInput(new Date(currentAccountingPeriod.periodStart)),
    }));
  }, [details.startAt, currentAccountingPeriod.periodStart]);

  const { data: level1Resource, isLoading: isLevel1ResourceLoading } =
    useGetLevel1Resource(level1wrsId, {
      enabled: Boolean(level1wrsId),
    });

  const { data: level0Resource = {} } = useLevel0Resource(
    details.level0ResourceId,
    {
      enabled: Boolean(details.level0ResourceId),
    }
  );
  const { data: waterClasses = [], refetch: refetchAllWaterClasses } =
    useAllWaterClasses({
      params: {
        level0ResourceId: details.level0ResourceId,
      },
      enabled: Boolean(details.level0ResourceId),
      refetchOnWindowFocus: true,
      onSuccess: (data: any[]) => {
        setAdjustedWaterClassVolumeDetails((prev) => {
          const waterClassIds = prev.map((i) => i.classId);

          const updated = data
            .filter((i) => !waterClassIds.includes(i.id))
            .map((i) => ({
              classId: i.id,
              adjustedVolume: convertLiterToML(+i.volume),
            }));
          return [...prev, ...updated];
        });
      },
    });

  React.useEffect(() => {
    if (details.level0ResourceId) {
      refetchAllWaterClasses();
    }
  }, [details.level0ResourceId, refetchAllWaterClasses]);

  const navigateForCancel = () =>
    !!searchParams.get("newWindow")
      ? window.close()
      : navigate(`/polestar/level1wrs/${level1wrsId}#1`);

  const sumOfWaterClassVolumes = () => {
    return _sumBy(waterClasses, (i: any) => {
      if (i.id === updatingWaterClass?.id) {
        return 0;
      }
      return +i.volume;
    });
  };

  const isExceedTotalWaterInZone = (volume: number) => {
    const totalWater = level0Resource.yield ? level0Resource?.yield : 0;
    return sumOfWaterClassVolumes() + volume > totalWater;
  };

  if (isLevel1ResourceLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  const totalWaterClassesVolume = waterClasses.length
    ? _sumBy(waterClasses, (i: any) => +i.volume)
    : 0;

  const validateCurrentClassVolume = (
    isAdjustingClassBalanceMandatory: boolean
  ) => {
    modifyStatuses({
      stepNumber: 2,
      fieldName: "adjustedClassVolume",
    });
    if (volumeToValidate === 0) {
      modifyStatuses({
        stepNumber: 2,
        fieldName: "yield",
        infoType: "error",
        message: t("level0wrs.update.step_3.error_2") as string,
      });
      return;
    }
    if (isAdjustingClassBalanceMandatory) {
      modifyStatuses({
        stepNumber: 2,
        fieldName: "yield",
        infoType: "warning",
        message: t("level0wrs.update.step_3.warning_6", {
          volume: formatVolume(convertMLToLiter(details.yield)),
        }) as string,
      });
    } else {
      modifyStatuses({
        stepNumber: 2,
        fieldName: "yield",
      });
    }
  };

  const validateAdjustedClassVolume = async ({
    availableToDistribute,
    currentYearLevel0wrsYield = 0,
  }: {
    availableToDistribute?: number;
    currentYearLevel0wrsYield: number;
  }) => {
    if (!availableToDistribute && availableToDistribute !== 0) {
      modifyStatuses({
        stepNumber: 2,
        fieldName: "adjustedClassVolume",
      });
      return;
    }
    const sumOfDistributedClassVolume = _sumBy(
      adjustedWaterClassVolumeDetails.map((i: any) => +i.adjustedVolume)
    );
    const level0wrsYield = formatVolume(
      convertMLToLiter(currentYearLevel0wrsYield)
    );
    if (sumOfDistributedClassVolume > 0) {
      modifyStatuses({
        stepNumber: 2,
        fieldName: "yield",
      });
    }
    if (+availableToDistribute < 0) {
      modifyStatuses({
        stepNumber: 2,
        fieldName: "adjustedClassVolume",
        infoType: "error",
        message: t("level0wrs.update.step_3.error_1", {
          totalAdjustedVolume: formatVolume(
            convertMLToLiter(sumOfDistributedClassVolume)
          ),
          level0wrsYield,
        }) as string,
      });
      return;
    }
    if (+availableToDistribute === 0) {
      modifyStatuses({
        stepNumber: 2,
        fieldName: "adjustedClassVolume",
        infoType: "success",
        message: t("level0wrs.update.step_3.success_1", {
          level0wrsYield,
        }) as string,
      });
      return;
    }

    modifyStatuses({
      stepNumber: 2,
      fieldName: "adjustedClassVolume",
      infoType: "warning",
      message: t("level0wrs.update.step_3.warning_5", {
        volume: formatVolume(convertMLToLiter(+availableToDistribute)),
      }) as string,
    });
  };

  const value: ContextValue = {
    currentStep,
    stepHelpers,
    level1Resource,
    level0Resource,
    waterClasses,
    details,
    setDetails,
    updatingWaterClass,
    setUpdatingWaterClass,
    workflowCompleted,
    setWorkflowCompleted,
    workflowInstance,
    setWorkflowInstance,
    navigateForCancel,
    classCategoryName,
    setClassCategoryName,
    sumOfWaterClassVolumes,
    isExceedTotalWaterInZone,
    isVolumeExceed,
    setIsVolumeExceed,
    totalWaterClassesVolume,
    volumeToValidate,
    setVolumeToValidate,
    doAdjustWaterClassVolumeDetails,
    setDoAdjustWaterClassVolumeDetails,
    adjustedWaterClassVolumeDetails,
    setAdjustedWaterClassVolumeDetails,
    validateCurrentClassVolume,
    validateAdjustedClassVolume,
    getInfoMessages,
    modifyStatuses,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

function useWaterClassContext() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      "useWaterClassContext must be used within a WaterClassProvider"
    );
  }
  return context;
}

export { WaterClassProvider, useWaterClassContext };
