import { Trans, useTranslation } from "react-i18next";

import ExtractionPointTab from "./ExtractionPointTab";
import ExtractionRightTab from "./ExtractionRightTab";
import Level0WRSSummaryTab from "./Level0WRSSummaryTab";
import TransactionHistoryTab from "./TransactionHistoryTab";
import TabPanels from "@components/form/TabPanels";
import InfoPanel from "@components/form/InfoPanel";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import { useSubscriberIndexContext } from "@context/SubscriberIndexContext";

const Level0WRSDetails: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { currentSubMenuTab, subscriber, zonePriorityBalance } =
    useSubscriberIndexContext();

  return (
    <TabPanels
      className="bg-white"
      selectedTab={currentSubMenuTab}
      tabs={[
        {
          title: t("subscriber.level0wrs_summary"),
          panel: <Level0WRSSummaryTab />,
        },
        {
          title: t("subscriber.extraction_rights"),
          panel: <ExtractionRightTab />,
          tabInfoPanel: (
            <InfoPanel>
              <div className="space-y-2">
                <p>
                  <Trans
                    i18nKey="subscriber.level0wrs_account_tab_info_panel.extraction_right.intro"
                    values={{
                      subscriberName: subscriber?.name,
                      level0wrsIdentifier: zonePriorityBalance?.zoneIdentifier,
                    }}
                  />
                </p>
                <p>{t("level1wrs.tab_info_panel.extraction_right.body")}</p>
                <p>
                  {t("level1wrs.tab_info_panel.extraction_right.conclusion")}
                </p>
                <div className="flex items-start py-2 gap-2">
                  <InformationCircleIcon className="text-blue-600 w-5 h-5 shrink-0" />
                  <div className="text-sm bg-tray-200 rounded-md">
                    {t(
                      "subscriber.level0wrs_account_tab_info_panel.extraction_right.warning_1"
                    )}
                  </div>
                </div>
              </div>
            </InfoPanel>
          ),
        },
        {
          title: `${t("common.extraction_points")} ${t("common.and")} ${t(
            "common.meters"
          )}`,
          panel: <ExtractionPointTab />,
        },
        {
          title: t("subscriber.audit_trail"),
          panel: <TransactionHistoryTab />,
        },
      ]}
    />
  );
};

export default Level0WRSDetails;
