import { t } from "i18next";
import { Trans } from "react-i18next";

import TabPanels from "@components/form/TabPanels";
import InfoPanel from "@components/form/InfoPanel";
import Level1WRSAccountingPeriodList from "./Level1WRSAccountingPeriodList";

type Props = {
  level1Resource: any;
};

const Level1WRSResetAccountingPeriod: React.FunctionComponent<Props> = ({
  level1Resource,
}) => {
  return (
    <TabPanels
      className="bg-white border"
      tabs={[
        {
          title: t("common.accounting_periods"),
          panel: (
            <Level1WRSAccountingPeriodList
              level1Resource={level1Resource}
            ></Level1WRSAccountingPeriodList>
          ),
          tabInfoPanel: (
            <InfoPanel>
              <div className="space-y-2">
                <ul className="flex flex-col gap-5 list-disc list-outside">
                  <li>
                    <Trans
                      i18nKey="level1wrs.tab_info_panel.accounting_period.intro"
                      values={{ level1wrsName: level1Resource?.name }}
                    />
                  </li>
                  <li>
                    {t("level1wrs.tab_info_panel.accounting_period.body")}
                  </li>
                  <li>
                    {t("level1wrs.tab_info_panel.accounting_period.conclusion")}
                  </li>
                </ul>
              </div>
            </InfoPanel>
          ),
        },
      ]}
    />
  );
};

export default Level1WRSResetAccountingPeriod;
