import { Fragment, useState } from "react";
import classNames from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MenuItem from "./MenuItem";
import MenuItemMobile from "./MenuItemMobile";
import Tooltip from "@components/shared/Tooltip";
import LanguageSwitcher from "@components/shared/LanguageSwitcher";
import Bar3Icon from "@components/icons/Bar3Icon";
import HelpIcon from "@components/icons/HelpIcon";
import NotificationIcon from "@components/icons/NotificationIcon";
import { useAppContext } from "@context/AppContext";
import { useAllLevel1Resources } from "@hooks/query/useAllLevel1Resources";
import { getImgSrc } from "@utils/getImgSrc";
import ENV from "@config/env";

type TopNavProps = {
  onShowNotification: () => void;
};

const TopNav: React.FunctionComponent<TopNavProps> = ({
  onShowNotification,
}) => {
  const { t } = useTranslation();
  const { loggedInInfo, notifications } = useAppContext();
  const isAdmin = loggedInInfo?.userDetails?.isAdmin;
  const rootPath = isAdmin ? "/polestar" : "/zenith";
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const logoPath = getImgSrc(
    window.location.pathname.includes("polestar")
      ? "/polestar-logo.svg"
      : "/zenith-logo.svg"
  );
  const [showSubMenu, setShowSubMenu] = useState("");

  const { data: level1ResourceSubMenus = [] } = useAllLevel1Resources({
    enabled: !!isAdmin,
    select: (data: any) => {
      return data
        .map((item: any) => ({
          label: item.name,
          href: `${rootPath}/level1wrs/${item.id}`,
        }))
        ?.sort((a: any, b: any) => {
          return a.label.localeCompare(b.label);
        });
    },
  });

  const leftMenus = [
    {
      label: t("topnav.level1_wrs"),
      permissions: ["ViewLevel1Resource"],
      items: level1ResourceSubMenus.concat({
        label: t("topnav.create_scheme"),
        href: "/polestar/level1wrs/create",
        permissions: ["CreateLevel1Resource"],
      }),
    },
    {
      label: t("topnav.subscribers"),
      href: "/polestar/subscribers",
      permissions: ["ViewSubscribers"],
    },
    {
      label: t("topnav.reporting"),
      items: [
        {
          label: t("topnav.regulatory_report"),
          href: "/polestar/reports/regulatory",
          permissions: ["ViewReports"],
        },
        {
          label: t("topnav.billing_report"),
          href: "/polestar/reports/billing",
          permissions: ["ViewReports"],
        },
        {
          label: t("topnav.meter_read_sequence_report"),
          href: "/polestar/reports/meter_read_sequence",
          permissions: ["ViewReports"],
        },
        {
          label: t("topnav.generate_reports"),
          href: "/polestar/reports/general",
          permissions: ["ViewReports"],
        },
      ],
    },
    {
      label: t("topnav.audit_trail"),
      href: "/polestar/audit_trail",
      permissions: ["ViewEventTransactions"],
    },
    {
      label: t("topnav.administration"),
      items: [
        {
          label: t("topnav.approvals"),
          href: "/polestar/administrative_approvals",
          permissions: ["ViewApprovals", "CreateApprovals", "UpdateApprovals"],
        },
        {
          label: t("topnav.notification"),
          href: "/polestar/notifications",
          permissions: [
            "CreateNotifications",
            "CreateMessages",
            "ManageNotifications",
          ],
        },
        {
          label: t("topnav.exchange_rate"),
          href: "/polestar/exchange_rates/create",
          permissions: ["CreateAllocationAnnouncement"],
        },
        {
          label: t("topnav.distribution_losses"),
          href: "/polestar/distribution_losses/create",
          permissions: ["ManageDistributionLosses"],
        },
        {
          label: t("meter.update_threshold"),
          href: "/polestar/meters/update",
          permissions: ["UpdateThreshold"],
        },
      ],
    },
  ];
  const rightMenus: any[] = [
    {
      label: t("topnav.help_center"),
      to: ENV.HELP_CENTER_URL,
      icon: HelpIcon,
      external: true,
    },
  ];
  const userNavigation = [
    { name: t("topnav.profile"), href: "/polestar/profile" },
    { name: t("topnav.logout"), href: `${rootPath}/logout` },
  ];

  return (
    <>
      <nav data-test-id="main-top-nav" className="sticky bg-white shadow-md">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center justify-center">
              <Link to={rootPath} className="flex-shrink-0">
                <img className="h-8 w-auto" src={logoPath} alt="Water Ledger" />
              </Link>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  {leftMenus.map((menuItem) => {
                    return (
                      <MenuItem
                        key={menuItem.label}
                        isOpen={showSubMenu === menuItem.label}
                        item={menuItem}
                        onClick={() =>
                          setShowSubMenu((prevState) =>
                            prevState === menuItem.label ? "" : menuItem.label
                          )
                        }
                        onClose={() => setShowSubMenu("")}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex items-center gap-4">
                <LanguageSwitcher />
                <Tooltip message={t("notification.view_notifications")}>
                  <button
                    onClick={onShowNotification}
                    type="button"
                    className="relative rounded-full p-1 text-gray-400 hover:text-white focus:outline-none"
                  >
                    <span className="sr-only">
                      {t("notification.view_notifications")}
                    </span>
                    <NotificationIcon />
                    {notifications?.filter((n) => !n.viewedAt)?.length > 0 && (
                      <p className="absolute right-0 top-0 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex justify-center items-center">
                        {notifications?.filter((n) => !n.viewedAt)?.length >= 10
                          ? "9+"
                          : notifications?.filter((n) => !n.viewedAt).length}
                      </p>
                    )}
                  </button>
                </Tooltip>
                {rightMenus.map(({ label, to, icon: Icon, external }) => {
                  return (
                    <Tooltip key={label} message={label}>
                      {external ? (
                        <a
                          key={label}
                          href={to}
                          target="_blank"
                          rel="noreferrer"
                          className="rounded-full p-1 text-gray-400 hover:text-white focus:outline-none"
                        >
                          <span className="sr-only">{label}</span>
                          <Icon />
                        </a>
                      ) : (
                        <Link
                          key={label}
                          to={to}
                          className="rounded-full p-1 text-gray-400 hover:text-white focus:outline-none"
                        >
                          <span className="sr-only">{label}</span>
                          <Icon />
                        </Link>
                      )}
                    </Tooltip>
                  );
                })}
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="relative flex-shrink-0 rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-1 focus:ring-offset-2">
                      <div className="w-8 h-8 grid place-content-center bg-gray-800 text-white rounded-full">
                        {loggedInInfo?.userDetails?.name[0]}
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        <div className="p-3">
                          <h3 className="font-semibold leading-7 mb-1">
                            {loggedInInfo.userDetails?.name}
                          </h3>
                          <p className="text-gray-500 break-words text-sm">
                            {loggedInInfo.userDetails?.email}
                          </p>
                        </div>
                      </Menu.Item>
                      <div className="p-1">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "group flex w-full items-center rounded-md px-2 py-2 text-sm"
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="-mr-2 flex sm:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <span className="sr-only">{t("topnav.open_menu")}</span>
                <Bar3Icon className="block h-6 w-6" />
              </button>
            </div>
          </div>
        </div>

        {isMenuOpen && (
          <div className="sm:hidden bg-gray-50" id="mobile-menu">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {leftMenus.map((menuItem: any) => (
                <MenuItemMobile key={menuItem.label} item={menuItem} />
              ))}
            </div>
            <div className="border-t border-gray-700 pt-4 pb-3">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <div className="flex justify-center items-center rounded-full w-8 h-8 bg-gray-800 text-white text-base text-center uppercase font-extrabold focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    {loggedInInfo?.userDetails?.name[0]}
                  </div>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-400">
                    {loggedInInfo.userDetails?.name}
                  </div>
                  <div className="text-sm font-medium text-gray-400">
                    {loggedInInfo.userDetails?.email}
                  </div>
                </div>
                <Link
                  to={`${rootPath}/notifications`}
                  className="relative ml-auto flex-shrink-0 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">
                    {t("notification.view_notifications")}
                  </span>
                  <NotificationIcon />
                  {notifications?.length > 0 && (
                    <p className="absolute right-0 top-0 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex justify-center items-center">
                      {notifications?.length >= 10
                        ? "9+"
                        : notifications?.length}
                    </p>
                  )}
                </Link>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {rightMenus.map((menu) => {
                  return (
                    <Link
                      key={menu.label}
                      to={menu.to}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {menu.label}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default TopNav;
