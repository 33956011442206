import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import Layout from "@components/layout/Layout";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import Tag from "@components/shared/Tag";
import { useAllSubscribers } from "@hooks/query/useAllSubscribers";
import { useAppContext } from "@context/AppContext";

type Subscriber = {
  id: string;
  name: string;
  abn: string | null;
  walletId: string;
  addressId: string | null;
  level1ResourceId: string;
  isActive: boolean;
  isForDistributionLoss: boolean;
  billingFrequency: string;
  accountNumber: string;
  accountType: string;
  createdAt: string;
  updatedAt: string;
  level1WRS: Record<string, any>;
  extractionRights: Record<string, any>[];
};

const SubscriberList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const { data: subscribers = [], isLoading } = useAllSubscribers();
  const [searchedValue, setSearchedValue] = useState("");
  const [selectedScheme, setSelectedScheme] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<boolean>();
  const [selectedLevel0Resource, setSelectedLevel0Resource] = useState("");

  const activeOptions = [
    { label: t("common.active"), value: true },
    { label: t("common.inactive"), value: false },
  ];

  const tableData = subscribers
    ?.filter(
      (row: Subscriber) =>
        !searchedValue.length ||
        row.name
          .toString()
          .toLowerCase()
          .includes(searchedValue.toString().toLowerCase()) ||
        row.accountNumber.toString().includes(searchedValue.toString())
    )
    ?.filter(
      (row: Subscriber) =>
        selectedStatus === undefined || row.isActive === selectedStatus
    )
    ?.filter(
      (row: Subscriber) =>
        !selectedScheme.length || row.level1ResourceId === selectedScheme
    )
    ?.filter(
      (row: Subscriber) =>
        !selectedLevel0Resource.length ||
        row.extractionRights?.some(
          (i) => i.level0ResourceId === selectedLevel0Resource
        )
    )
    .map((subscriber: any) => ({
      ...subscriber,
      scheme: subscriber.level1WRS.name,
      isActive: (
        <Tag status={subscriber.isActive ? "success" : "error"}>
          {subscriber.isActive ? t("common.active") : t("common.inactive")}
        </Tag>
      ),
      actions: (
        <Select
          placeholder={t("common.actions")}
          options={[
            {
              label: t("common.view"),
              value: `/polestar/subscribers/${subscriber.id}`,
            },
            ...(checkPermissions(["UpdateSubscribers"])
              ? [
                  {
                    label: t("subscriber.edit.title"),
                    value: `/polestar/subscribers/${subscriber.id}/edit?subscriberId=${subscriber.id}`,
                  },
                ]
              : []),
            ...(checkPermissions(["CreateUsers"])
              ? [
                  {
                    label: t("user.create.title"),
                    value: `/polestar/subscribers/${subscriber.id}/users/create?subscriberId=${subscriber.id}`,
                  },
                ]
              : []),
          ]}
          onChange={(e) => {
            if (e?.value) {
              navigate(e.value);
            }
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={() => true}
          isSearchable={false}
        />
      ),
    }));

  return (
    <Layout
      permissions={["ViewSubscribers"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
      ]}
      title={t("common.subscribers")}
    >
      <div className="flex flex-col-reverse gap-4 items-start xl:flex-row xl:items-end xl:justify-between relative z-20 mb-4">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-4 lg:grid-cols-4 w-full">
          <div>
            <Label htmlFor="level1">{t("subscriber.filter.level1wrs")}</Label>
            <SelectLevel1Resource
              inputId="level1"
              onChange={(e) => {
                setSelectedScheme(e.value || "");
              }}
              isClearable
            />
          </div>
          <div>
            <Label htmlFor="level0">{t("subscriber.filter.level0wrs")}</Label>
            <SelectLevel0Resource
              inputId="level0"
              level1ResourceId={selectedScheme}
              value={selectedLevel0Resource}
              onChange={(e: any) => {
                setSelectedLevel0Resource(e?.value || "");
              }}
              isDisabled={!Boolean(selectedScheme)}
              isClearable
            />
          </div>
          <div>
            <Label htmlFor="subscriber">
              {t("subscriber.filter.account_number_or_name")}
            </Label>
            <SearchInput
              id="subscriber"
              onChange={(e) => setSearchedValue(e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor="status">{t("subscriber.filter.status")}</Label>
            <Select
              inputId="status"
              options={activeOptions}
              value={activeOptions.find(
                (activeOption) => activeOption.value === selectedStatus
              )}
              onChange={(e) => {
                setSelectedStatus(e?.value);
              }}
              isClearable
            />
          </div>
        </form>
        {checkPermissions(["CreateSubscribers"]) ? (
          <Link
            to={`/polestar/subscribers/create`}
            className="btn-secondary text-sm rounded whitespace-nowrap"
          >
            {t("user.create.new_customer")}
          </Link>
        ) : null}
      </div>
      <Table
        containerClassName="rounded-none md:rounded-none"
        tableHeaderClassName="relative z-10"
        fields={[
          {
            title: t("common.level1wrs"),
            name: "scheme",
          },
          {
            title: t("subscriber.account_number"),
            name: "accountNumber",
          },
          {
            title: t("subscriber.name"),
            name: "name",
          },
          {
            title: t("common.status"),
            name: "isActive",
          },
          {
            title: t("common.actions"),
            name: "actions",
          },
        ]}
        data={tableData}
        stickyHeader
        loading={isLoading}
      />
    </Layout>
  );
};

export default SubscriberList;
