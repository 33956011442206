import React from "react";
import classNames from "classnames";

import ErrorMessage from "@components/form/ErrorMessage";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
  suffix?: React.ReactNode;
  inline?: boolean;
  inputClassName?: string;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      className,
      errorMessage,
      suffix,
      inline = false,
      inputClassName,
      step,
      ...inputProps
    } = props;
    return (
      <div
        className={classNames(
          "sm:text-sm",
          {
            "w-full": !inline,
          },
          className
        )}
      >
        <div className="relative">
          <input
            ref={ref}
            className={classNames(
              "block w-full appearance-none rounded-sm border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-blue-500 focus:border-0",
              {
                "border-red-300 focus:outline-red-500": Boolean(errorMessage),
                "pr-10": suffix,
                "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200":
                  inputProps.disabled,
                "cursor-not-allowed": inputProps.readOnly,
              },
              inputClassName
            )}
            step={step}
            {...inputProps}
          />

          {suffix && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              {suffix}
            </div>
          )}
        </div>

        {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      </div>
    );
  }
);

export default TextInput;
