import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { formatDate } from "../../../utils/formatDate";
import { formatPercentage } from "../../../utils/formatPercentage";

type ExchangeRateHistoryProps = {
  level0Resource: any;
};

const ExchangeRateHistory: React.FunctionComponent<
  ExchangeRateHistoryProps
> = ({ level0Resource }) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-hidden bg-white border sm:rounded-lg mb-4">
      <div className="px-2 py-3 sm:px-4">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-3">
          <div className="sm:col-span-3">
            <dt className="text-sm font-medium text-gray-400">
              {t("common.level0wrs")}
            </dt>
            <dd className="mt-1 text-base font-semibold text-gray-900">
              {level0Resource.identifier}
            </dd>
          </div>
          {level0Resource?.selectedWaterClasses.map((waterClass: any) => {
            const currentWaterClass = level0Resource?.waterClasses.find(
              (c: any) => c.id === waterClass.id
            );

            const currentAllocation = currentWaterClass.exchangeRates?.sort(
              (a: any, b: any) =>
                new Date(b.createdAt).valueOf() -
                new Date(a.createdAt).valueOf()
            )?.[0];

            return (
              <Fragment key={waterClass.id}>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-400">
                    {t("common.water_class")}
                  </dt>
                  <dd className="mt-1 text-sm font-semibold text-gray-900">
                    {currentWaterClass.name}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-400 flex-nowrap">
                    {t("exchange_rate.rate")}
                  </dt>
                  <dd className="mt-1 text-sm font-semibold text-primary-2">
                    {formatPercentage(currentAllocation?.rate)}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-400">
                    {t("exchange_rate.start_at")}
                  </dt>
                  <dd className="mt-1 text-sm font-semibold text-primary-2">
                    {formatDate(new Date(currentAllocation?.startAt))}
                  </dd>
                </div>
              </Fragment>
            );
          })}
        </dl>
      </div>
    </div>
  );
};

export default ExchangeRateHistory;
