import api from "./api";

export const getAllLevel0Resources = async (params?: any) => {
  const { data } = await api.get("/admin/level0Resources", { params });
  return data.level0Resources ?? [];
};

export const getLevel0Resource = async (id: string) => {
  const { data } = await api.get(`/admin/level0Resources/${id}`);
  return data.level0Resource;
};

export type CreateLevel0ResourcePayload = {
  name: string;
  identifier: string;
  parentId: string;
  geography: string;
  periodStart: Date;
  periodEnd: Date;
  yield: number;
  internalTradeAllowed: boolean;
  internalLimit: number;
  inboundTradeAllowed: boolean;
  inboundLimit: number;
  outboundTradeAllowed: boolean;
  outboundLimit: number;
  sdApproval: boolean;
  fdApproval: boolean;
  stApproval: boolean;
  ptApproval: boolean;
  whApproval: boolean;
  saApproval: boolean;
  inboundZoneExchanges: string[];
  outboundZoneExchanges: string[];
  source: string;
};

export const createLevel0Resource = async (
  payload: CreateLevel0ResourcePayload
): Promise<any> => {
  const { data } = await api.post("/admin/level0Resources", payload);

  return [data.level0Resource, data.workflowInstance];
};

export const checkName = async (payload: {
  name: string;
  parentId: string;
}): Promise<boolean> => {
  const { data } = await api.post("/admin/level0Resources/checkName", payload);

  return data.exists;
};

export const checkIdentifier = async (payload: {
  identifier: string;
  parentId: string;
}): Promise<boolean> => {
  const { data } = await api.post(
    "/admin/level0Resources/checkIdentifier",
    payload
  );

  return data.exists;
};

export const checkPeriodStart = async (payload: {
  level1wrsId: string;
  periodStart?: Date;
}): Promise<any> => {
  const { data } = await api.post(
    "/admin/level0Resources/checkPeriodStart",
    payload
  );

  return data;
};

export const checkPeriodEnd = async (payload: {
  level1wrsId: string;
  periodEnd?: Date;
}): Promise<any> => {
  const { data } = await api.post(
    "/admin/level0Resources/checkPeriodEnd",
    payload
  );

  return data;
};

export const updateLevel0Resource = async ({
  level0ResourceId,
  ...payload
}: any) => {
  const { data } = await api.put(
    `/admin/level0Resources/${level0ResourceId}`,
    payload
  );

  return [data.level0Resource, data.workflowInstance];
};
