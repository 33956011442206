import { Trans, useTranslation } from "react-i18next";

import TabPanels from "@components/form/TabPanels";
import InfoPanel from "@components/form/InfoPanel";
import EvidenceTable from "@components/table/EvidenceTable";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import SubscriberNotesTable from "@components/table/SubscriberNotesTable";
import NotificationTable from "@components/table/NotificationTable";
import { useAppContext } from "@context/AppContext";

type Props = {
  level1Resource: any;
};

const Level1AuditTrailAndEvidence: React.FunctionComponent<Props> = ({
  level1Resource,
}) => {
  const { checkPermissions } = useAppContext();
  const { t } = useTranslation();

  return (
    <TabPanels
      className="bg-white border"
      tabs={[
        ...(checkPermissions(["ViewEventTransactions"])
          ? [
              {
                title: t("audit_trail.widget_title"),
                panel: (
                  <EventTransactionPaginatedTable
                    level1ResourceId={level1Resource?.id}
                    stickyHeader={true}
                  />
                ),
                tabInfoPanel: getInfoPanel(level1Resource.name, t),
              },
            ]
          : []),
        ...(checkPermissions(["ViewEvidences"])
          ? [
              {
                title: t("evidence.documents"),
                panel: <EvidenceTable level1Resource={level1Resource} />,
                tabInfoPanel: getInfoPanel(level1Resource.name, t),
              },
            ]
          : []),
        ...(checkPermissions(["ViewSubscribers"])
          ? [
              {
                title: t("subscriber.notes"),
                panel: <SubscriberNotesTable level1Resource={level1Resource} />,
                tabInfoPanel: getInfoPanel(level1Resource.name, t),
              },
            ]
          : []),
        ...(checkPermissions(["ViewNotifications"])
          ? [
              {
                title: t("notification.title"),
                panel: (
                  <NotificationTable level1ResourceId={level1Resource?.id} />
                ),
                tabInfoPanel: getInfoPanel(level1Resource.name, t),
              },
            ]
          : []),
      ]}
    />
  );
};

const getInfoPanel = (level1wrsName: any, t: any) => {
  return (
    <InfoPanel className="h-full">
      <div className="flex flex-col gap-5">
        <ul className="flex flex-col gap-5 list-disc list-outside">
          <li>
            <Trans
              i18nKey="evidence.info.dashboard"
              values={{
                level1wrsName,
              }}
            />
          </li>
          <li>{t("evidence.info.document_list")}</li>
        </ul>
      </div>
    </InfoPanel>
  );
};

export default Level1AuditTrailAndEvidence;
