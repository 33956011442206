import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Legend: React.FunctionComponent<Props> = ({ children, className }) => {
  return (
    <legend
      className={classNames(
        "block w-full pb-3 font-bold text-primary-1 text-xl border-b border-gray-200",
        className
      )}
    >
      {children}
    </legend>
  );
};

export default Legend;
