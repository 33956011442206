import { useState, useEffect } from "react";
import { Popover } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { sumBy } from "lodash";

import Layout from "@components/layout/Layout";
import Card from "@components/layout/Card";
import SubscriberDashboard from "@components/form/subscriber/dashboard/SubscriberDashboard";
import WorkflowPanels from "@components/form/WorkflowPanels";
import Level0WRSDetails from "@components/form/subscriber/dashboard/level0accounts/Level0WRSDetails";
import SubscriberAuditTrailAndEvidenceTabs from "@components/form/subscriber/dashboard/subscriber_history/SubscriberAuditTrailAndEvidenceTabs";
import Tag from "@components/shared/Tag";
import Loading from "@components/shared/Loading";
import Alert from "@components/shared/Alert";
import LockIcon from "@components/icons/LockIcon";
import DotIcon from "@components/icons/DotIcon";
import FourDotsIcon from "@components/icons/FourDotsIcon";
import ZoneAccountIcon from "@components/icons/ZoneAccountIcon";
import {
  SubscriberIndexProvider,
  useSubscriberIndexContext,
} from "@context/SubscriberIndexContext";
import ForwardIcon from "@components/icons/ForwardIcon";

const SubscriberIndex = () => {
  return (
    <SubscriberIndexProvider>
      <Consumer />
    </SubscriberIndexProvider>
  );
};

const Consumer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    currentMenu,
    setCurrentMenu,
    currentSubMenu,
    setCurrentSubMenu,
    setZonePriorityBalance,
    subscriber,
  } = useSubscriberIndexContext();

  const [subscriberBalanceAndAllocation, setSubscriberBalanceAndAllocation] =
    useState<{ balance: number; allocation: number }>({
      balance: 0,
      allocation: 0,
    });

  useEffect(() => {
    if (subscriber) {
      const balance = sumBy(
        subscriber?.zonePriorityBalances,
        (zonePriority: any) =>
          sumBy(
            zonePriority.zoneBalances,
            (zoneBalance: any) => +zoneBalance.balance,
          ),
      );
      const allocation = sumBy(
        subscriber?.zonePriorityBalances,
        (zonePriority: any) =>
          sumBy(
            zonePriority.zoneBalances,
            (zoneBalance: any) => +zoneBalance.rightAllocation,
          ),
      );

      setSubscriberBalanceAndAllocation({ balance, allocation });
    }
  }, [subscriber]);

  useEffect(() => {
    if (subscriber) {
      const subscriberExtractionRights = subscriber.extractionRights;

      const zonePriorityRightNames: any[] = [];

      subscriber.zonePriorityBalances.forEach((zoneBalance: any) => {
        zoneBalance.zoneBalances.forEach((zoneBalanceDetail: any) => {
          zonePriorityRightNames.push(...zoneBalanceDetail.rightNames);
        });
      });

      const missingRights = subscriberExtractionRights.filter(
        (r: any) => !zonePriorityRightNames.includes(r.name),
      );

      if (missingRights.length >= 0) {
        const zonePriorityBalances: any = {};
        missingRights.forEach((missingRight: any) => {
          const waterClassId = missingRight.waterClass.id;
          const rightName = missingRight.name;
          const zoneId = missingRight.level0Resource.id;
          const zoneIdentifier = missingRight.level0Resource.identifier;

          if (zonePriorityBalances.hasOwnProperty(zoneId)) {
            const existingZoneBalance = zonePriorityBalances[
              zoneId
            ].zoneBalances.find((balance: any) => balance.id === waterClassId);

            if (existingZoneBalance) {
              existingZoneBalance.rightNames.push(rightName);
            } else {
              zonePriorityBalances[zoneId].zoneBalances.push({
                id: waterClassId,
                priority: missingRight.waterClass.name,
                rightNames: [rightName],
                spUsage: 0,
                uaUsage: 0,
              });
            }
          } else {
            zonePriorityBalances[zoneId] = {
              zoneId: zoneId,
              zoneIdentifier: zoneIdentifier,
              zoneBalances: [
                {
                  id: waterClassId,
                  priority: missingRight.waterClass.name,
                  rightNames: [rightName],
                  spUsage: 0,
                  uaUsage: 0,
                },
              ],
              sellingVolume: 0,
              buyingVolume: 0,
            };
          }
        });

        const mockZonePriorityBalances = Object.values(zonePriorityBalances);

        Object.values(mockZonePriorityBalances).forEach(
          (mockZonePriorityBalance: any) => {
            const existingZoneIndex = subscriber.zonePriorityBalances.findIndex(
              (zonePriorityBalance: any) =>
                zonePriorityBalance.zoneId === mockZonePriorityBalance.zoneId,
            );
            if (existingZoneIndex !== -1) {
              const existingZone =
                subscriber.zonePriorityBalances[existingZoneIndex];
              existingZone.zoneBalances.push(
                ...mockZonePriorityBalance.zoneBalances,
              );
            } else {
              subscriber.zonePriorityBalances.push(mockZonePriorityBalance);
            }
          },
        );
      }
    }
  }, [subscriber]);

  if (!subscriber) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  return (
    <Layout
      permissions={["ViewSubscribers"]}
      accountingPeriodLevel1ResourceId={subscriber?.level1ResourceId}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
        {
          label: `${subscriber?.name}`,
        },
      ]}
      title={`${subscriber?.name} ${
        subscriber?.accountNumber ? `(${subscriber.accountNumber})` : ""
      }`}
      status={
        <Tag status={subscriber?.isActive ? "success" : "error"}>
          {subscriber?.isActive ? t("common.active") : t("common.inactive")}
        </Tag>
      }
      moreInfo={
        <>
          <Popover className="static">
            <Popover.Button className="text-sm text-primary-2 underline">
              {t("common.more_info")}
            </Popover.Button>

            <Popover.Overlay className="absolute">
              <div className="flex justify-center items-center h-min bg-gray-300">
                <div className="max-w-sm rounded shadow-lg bg-white">
                  <div className="relative flex justify-left">
                    <div className="bg-white absolute w-3 h-3 transform rotate-45 -mt-1"></div>
                  </div>
                  <div className="px-6 py-4 text-sm">
                    <p className="text-gray-700">
                      <span className="pr-1 font-bold">
                        {t("common.level1wrs")}:
                      </span>
                      {subscriber?.level1WRS.name}
                    </p>
                    <p className="text-gray-700">
                      <span className="pr-1 font-bold">
                        {t("common.address")}:
                      </span>
                      {subscriber?.address ? (
                        `${subscriber.address.street}, ${subscriber.address.town}`
                      ) : (
                        <span className="pl-10">-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Popover.Overlay>
          </Popover>
        </>
      }
    >
      {subscriber?.extractionRights.filter((i: any) => !i.inactivedAt).length >
        0 && subscriberBalanceAndAllocation.balance === 0 ? (
        <Alert type="error" className="mb-4">
          {subscriberBalanceAndAllocation.allocation === 0
            ? t("subscriber.allocation_not_done")
            : t("subscriber.balance_exhausted")}

          {/* TODO: NOT IMPLEMENTED */}
          {false && (
            <button
              type="button"
              className="btn-default border-red-700 bg-red-800 text-red-200 text-sm hover:text-red-500 flex items-center gap-1"
            >
              <LockIcon className="w-4" />
              {t("subscriber.lock_users")}
            </button>
          )}
        </Alert>
      ) : null}
      <WorkflowPanels
        menuClassName="w-1/6 text-sm"
        contentClassName="border-none bg-transparent"
        activeItemClassName="border-r-4 border-r-primary-2 bg-blue-100 text-primary-3 font-semibold"
        inActiveItemClassName="text-primary-3 font-extralight opacity-70"
        activeSubItemClassName="text-primary-3 font-semibold"
        inActiveSubItemClassName="text-primary-3 font-extralight"
        selectedStep={currentMenu}
        selectedSubStep={currentSubMenu}
        steps={[
          {
            label: (
              <button
                type="button"
                className="flex gap-2 items-center justify-start w-full"
                onClick={() => setCurrentMenu(0)}
              >
                <FourDotsIcon />
                {t("subscriber.dashboard")}
              </button>
            ),
            panel: subscriber ? <SubscriberDashboard /> : null,
          },
          {
            label: (
              <button
                type="button"
                className="flex gap-2 items-center justify-start w-full"
                onClick={() => {
                  setCurrentMenu(1);
                  setCurrentSubMenu(0);
                  setZonePriorityBalance(
                    subscriber?.zonePriorityBalances[currentSubMenu],
                  );
                }}
              >
                <ZoneAccountIcon />
                {t("subscriber.level0wrs")}
              </button>
            ),
            panel:
              subscriber?.zonePriorityBalances.length === 0 ? (
                <Card
                  header={
                    <p className="flex gap-3">{t("subscriber.level0wrs")}</p>
                  }
                >
                  <div className="justify-center flex">
                    <div className="font-light text-primary-2 text-center text-xl h-40">
                      {t("level0wrs.no_data")}
                      <div className="justify-center flex mt-4"></div>
                    </div>
                  </div>
                </Card>
              ) : null,
            subSteps: subscriber?.zonePriorityBalances?.map(
              (zpb: any, index: number) => {
                return {
                  label: (
                    <button
                      type="button"
                      key={zpb.zoneIdentifier}
                      className="flex gap-2 items-center justify-start w-full"
                      onClick={(e) => {
                        e.preventDefault();
                        setZonePriorityBalance(zpb);
                        setCurrentSubMenu(index);
                      }}
                    >
                      <DotIcon />
                      {zpb.zoneIdentifier}
                    </button>
                  ),
                  panel: <Level0WRSDetails />,
                };
              },
            ),
          },
          {
            label: (
              <button
                type="button"
                className="flex gap-2 items-center justify-start w-full"
                onClick={() => setCurrentMenu(2)}
              >
                <ForwardIcon />
                {t("common.audit_trail_and_evidence")}
              </button>
            ),
            panel: (
              <SubscriberAuditTrailAndEvidenceTabs subscriber={subscriber} />
            ),
          },
        ]}
      ></WorkflowPanels>
    </Layout>
  );
};

export default SubscriberIndex;
