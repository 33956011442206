import api from "./api";

export const getAllNotifications = async (params: Record<string, any> = {}) => {
  const { data } = await api.get("/notifications", { params });

  return { data: data.notifications, meta: data.meta };
};

export const getCustomerNotifications = async (
  recipientWalletId: string
): Promise<any[]> => {
  const { data } = await api.get("/notifications", {
    params: { recipientWalletId, limit: -1 },
  });

  return data.notifications;
};

export const getNotification = async (
  id: string,
  params: Record<string, any> = {}
) => {
  const { data } = await api.get(`/notifications/${id}`, {
    params,
  });

  return data.notification;
};

export const getNewNotifications = async () => {
  const { data } = await api.get("/notifications?onlyNew=1");

  return data.notifications;
};

export const markNotificationAsRead = async (notificationData: {
  notificationId: string;
}) => {
  const { data } = await api.put(
    `/notifications/${notificationData.notificationId}?read=1`,
    notificationData
  );

  return data.notification;
};

export const markNotificationAsUnread = async (notificationData: {
  notificationId: string;
}) => {
  const { data } = await api.put(
    `/notifications/${notificationData.notificationId}`,
    notificationData
  );

  return data.notification;
};

export const deleteNotification = async (notificationData: {
  notificationId: string;
}) => {
  const { data } = await api.delete(
    `/notifications/${notificationData.notificationId}`
  );

  return data.notification;
};

export const createNotificationForZenith = async (notificationData: {
  level1ResourceId: string;
  typeId: string;
  message: {
    subject: string;
    body: string;
  };
}): Promise<any> => {
  const { level1ResourceId, typeId, message } = notificationData;
  const { subject, body } = message;

  const { data } = await api.post("/notifications", {
    level1ResourceId,
    typeId,
    subject,
    body,
  });

  return data.notification;
};
