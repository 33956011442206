import React, { FunctionComponent } from "react";

const AdministrativeTransferRequestIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="#0C3C9D"
        d="M48.406 22.813h-3.625v19.843H5.22V10.25H25v-4H5.219a3.781 3.781 0 00-3.657 4v32.406a3.781 3.781 0 003.657 3.907H44.78a3.78 3.78 0 003.657-3.907l-.032-19.843z"
      ></path>
      <path
        fill="#0C3C9D"
        d="M33 8.969L19.437 22.563l-1.375 1.375-.25.25-4.75 9.875a3.376 3.376 0 004.532 4.53l9.812-4.78 1.657-1.657 1.343-1.343L43.97 17.188l1.406-1.375.781-.782a6.813 6.813 0 000-9.625 6.781 6.781 0 00-4.781-1.968 6.844 6.844 0 00-4.813 1.968l-2.187 2.157-1.406 1.375.031.03zM17.219 34.375l3.687-7.594 3.907 3.938-7.594 3.656zm6.25-10.5l12.187-12.219 4.063 4.094-12.063 12.375-4.187-4.25zm17.75-16.594a2.844 2.844 0 012.031.844 2.906 2.906 0 010 4.094l-.781.75-4.063-4.063.782-.781a2.751 2.751 0 012.156-.781l-.125-.063z"
      ></path>
    </svg>
  );
};

export default AdministrativeTransferRequestIcon;
