import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ConfirmData } from "@components/shared/ConfirmationDetail";

import { formatDate, formatDateInput } from "@utils/formatDate";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";

const initialData = {
  volume: "",
  readAt: formatDateInput(new Date()),
  level1Resource: {
    id: "",
    name: "",
  },
  extractionRight: {
    id: "",
    name: "",
  },
  extractionPointName: "",
  previousDeclarations: [],
  meterSerialNo: "",
  subscriberWalletId: "",
  subscriber: {
    id: "",
    name: "",
  },
};

type DistributionLossData = typeof initialData;

type ContextValue = {
  distributionLossData: DistributionLossData;
  setDistributionLossData: any;
  getDetails: () => ConfirmData;
  isValidData: boolean;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  currentAccountingPeriod: any;
  [key: string]: any;
};

const CreateDistributionLossContext = createContext<ContextValue>(
  {} as ContextValue
);

const CreateDistributionLossProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [distributionLossData, setDistributionLossData] = useState({
    ...initialData,
  });
  const [workflowInstance, setWorkflowInstance] = useState<any>();

  const [isComplete, setIsComplete] = useState(false);
  const { data: currentAccountingPeriod } = useAllAccountingPeriods({
    options: {
      enabled: Boolean(distributionLossData?.level1Resource?.id),
      select: (data: any) => data?.[0],
      onSuccess: (data) => {
        setDistributionLossData((prev: any) => {
          return { ...prev };
        });
      },
    },
    params: { level1ResourceId: distributionLossData?.level1Resource?.id },
  });

  const getDetails = () => {
    return {
      title: t("subscriber.create.subscriber_details"),
      body: [
        {
          key: t("common.level1wrs"),
          value: distributionLossData?.level1Resource?.name,
        },
        {
          key: t("distribution_losses.account"),
          value: distributionLossData?.level1Resource?.name,
        },
        {
          key: t("distribution_losses.loss_volume"),
          value: `${distributionLossData?.volume} ${t("common.volume_unit")}`,
        },
        {
          key: t("common.effective_date"),
          value: formatDate(new Date(distributionLossData?.readAt)),
        },
      ],
    };
  };

  const handleCancel = () =>
    !!searchParams.get("newWindow") ? window.close() : navigate("/polestar");

  const isValidData =
    +distributionLossData.volume > 0 &&
    distributionLossData.readAt !== "" &&
    distributionLossData.level1Resource.id !== "" &&
    distributionLossData.extractionRight.id !== "";

  return (
    <CreateDistributionLossContext.Provider
      value={{
        distributionLossData,
        setDistributionLossData,
        isComplete,
        setIsComplete,
        getDetails,
        handleCancel,
        isValidData,
        workflowInstance,
        setWorkflowInstance,
        currentAccountingPeriod,
      }}
    >
      {children}
    </CreateDistributionLossContext.Provider>
  );
};

const useCreateDistributionLossContext = () => {
  const context = useContext(CreateDistributionLossContext);
  if (context === undefined) {
    throw new Error(
      "useCreateDistributionLossContext must be used within a CreateDistributionLossProvider"
    );
  }
  return context;
};

export { CreateDistributionLossProvider, useCreateDistributionLossContext };
