import React from "react";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import TextArea from "@components/form/TextArea";
import ApplicationActionButtons from "@components/form/ApplicationActionButtons";
import Modal from "@components/layout/Modal";
import Tag from "@components/shared/Tag";
import ConfirmModal from "@components/shared/ConfirmModal";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { useUpdateAdministrativeApprovalStatus } from "@hooks/mutation/useUpdateAdministrativeApprovalStatus";
import { AdministrativeApprovalNumericStatus } from "@services/administrativeApprovals";
import { toastError, toastSuccess } from "@utils/toast";
import {
  getActionTimestamp,
  getStatusAndText,
  getActionByUser,
} from "@utils/administrativeApproval";
import EvidenceList from "@components/shared/EvidenceList";

type AdminTransferModalProps = {
  data?: any;
  onClose: () => void;
  actionButtons?: React.ReactNode;
};

const AdminTransferModal: React.FunctionComponent<AdminTransferModalProps> = ({
  data,
  onClose,
  actionButtons,
}) => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = React.useState<
    "acknowledge" | "reject" | "request_info"
  >();
  const [reason, setReason] = React.useState("");

  const { mutateAsync: updateStatusMutation, isLoading: isSubmitting } =
    useUpdateAdministrativeApprovalStatus();

  const isApproveModal = confirmationModal === "acknowledge";
  const isRejectModal = confirmationModal === "reject";
  const isRequestInfoModal = confirmationModal === "request_info";
  const { status: statusTag = "default", text: statusText } = getStatusAndText(
    data?.status
  );

  const handleSave = async () => {
    try {
      const status = isApproveModal
        ? AdministrativeApprovalNumericStatus.Approved
        : isRejectModal
        ? AdministrativeApprovalNumericStatus.Declined
        : isRequestInfoModal
        ? AdministrativeApprovalNumericStatus.UnderReview
        : AdministrativeApprovalNumericStatus.Pending;

      await updateStatusMutation({
        id: data.id,
        status,
        reason,
      });
      const message = isApproveModal
        ? t("approval.stock_and_domestic.toast.approve")
        : isRejectModal
        ? t("approval.stock_and_domestic.toast.rejected")
        : isRequestInfoModal
        ? t("approval.stock_and_domestic.toast.req_info")
        : "";

      toastSuccess(message);
    } catch (e: any) {
      toastError(t("approval.stock_and_domestic.toast.error"));
    }
    setConfirmationModal(undefined);
    if (!isApproveModal) {
      onClose();
    }
  };

  const handleCancelConfirmation = () => {
    setReason("");
    setConfirmationModal(undefined);
  };

  const extractionRight = data?.extractionRights?.[0];

  const details = [
    {
      label: t("common.level1wrs"),
      value: data?.level1Resource.name,
      show: data?.level1Resource.name,
    },
    {
      label: t("extraction_point.name"),
      value: data?.extractionPoint?.name,
      show: true,
    },
    {
      label: t("extraction_point.link.point_zone"),
      value: data?.extractionPoint?.level0WRS?.identifier,
      show: true,
    },
    {
      label: t("extraction_right.name"),
      value: extractionRight?.name,
      show: true,
    },
    {
      label: t("extraction_point.link.entitlement_zone"),
      value: extractionRight?.level0Resource?.identifier,
      show: true,
    },
    {
      label: t("common.water_class"),
      value: extractionRight?.waterClass?.name,
      show: true,
    },
    {
      label: t("common.accounting_period"),
      value: data?.accountingPeriod
        ? formatAccountingPeriod(data?.accountingPeriod)
        : "-",
      show: data?.accountingPeriod,
    },
    {
      label: t("common.status"),
      value: (
        <Tag className="text-sx inline-block" status={statusTag}>
          {statusText}
        </Tag>
      ),
      show: true,
    },
    {
      label: t("common.reason_for_rejection"),
      value: data?.rejectionText ?? "-",
      show: data?.status === AdministrativeApprovalNumericStatus.Declined,
    },
    {
      label: t("common.actioned_at"),
      value: getActionTimestamp(data),
      show: true,
    },
    {
      label: t("common.actioned_by"),
      value: getActionByUser(data)?.name ?? "-",
      show: true,
    },
  ];

  const subscribers = [
    {
      title: t("common.customer"),
      body: [
        {
          label: t("subscriber.name"),
          value: data?.buyer?.name,
        },
        {
          label: t("subscriber.account_number"),
          value: data?.buyer?.accountNumber,
        },
        {
          label: t("common.status"),
          value: (
            <Tag
              className="text-sx inline-block"
              status={data?.buyer?.isActive ? "success" : "default"}
            >
              {data?.buyer?.isActive
                ? t("common.active")
                : t("common.inactive")}
            </Tag>
          ),
        },
      ],
    },
  ];

  const confirmModals = [
    {
      open: isApproveModal,
      confirmText: t("common.ledger") as string,
      description: <>{t("approval.permanent_trades.modal.confirm.approval")}</>,
    },
    {
      open: isRejectModal,
      confirmText: t("common.reject") as string,
      description: (
        <>
          {t("approval.permanent_trades.modal.confirm.reject")}
          <div className="mt-4 font-normal">
            <Label>{t("common.reason_for_rejection")}</Label>
            <TextArea
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={(e) => setReason(e.target.value)}
              rows={4}
            />
          </div>
        </>
      ),
    },
    {
      open: isRequestInfoModal,
      confirmText: t("common.request") as string,
      description: (
        <>
          {t("common.request_more_info")}
          <div className="mt-4 font-normal">
            <Label>{t("common.request_info_query")}</Label>
            <TextArea
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={(e) => setReason(e.target.value)}
              rows={4}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal open={data && !confirmationModal}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("approval.admin_transfer.title")}
            </h3>
          </div>

          <div className="p-4">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1 mb-4">
              {details.map((i) =>
                i.show ? (
                  <div
                    className="col-span-2 sm:col-span-1 flex gap-1"
                    key={`${i.label}--details`}
                  >
                    <dt>{i.label}:</dt>
                    <dd className="text-gray-500">{i.value}</dd>
                  </div>
                ) : null
              )}
            </dl>

            {subscribers.map((s) => (
              <div
                className="border rounded-lg p-2 flex flex-col gap-3"
                key={s.title}
              >
                <h3 className="font-semibold">{s.title}</h3>
                <dl className="text-sm grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1">
                  {s.body.map((i) => (
                    <div
                      className="col-span-2 sm:col-span-1 flex gap-1"
                      key={i.label}
                    >
                      <dt>{i.label}:</dt>
                      <dd className="text-gray-500">{i.value}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            ))}
          </div>

          {data?.evidences?.length ? (
            <EvidenceList data={data.evidences} />
          ) : null}

          {actionButtons ? (
            <div className="flex justify-end gap-3 p-4 border-t text-sm">
              {actionButtons}
            </div>
          ) : (
            <ApplicationActionButtons
              status={data?.status}
              onApprove={() => setConfirmationModal("acknowledge")}
              onReject={() => setConfirmationModal("reject")}
              onRequireMoreInfo={() => setConfirmationModal("request_info")}
              onCancel={() => {
                setConfirmationModal(undefined);
                onClose();
              }}
            />
          )}
        </div>
      </Modal>

      {confirmModals.map((i, index) => (
        <ConfirmModal
          key={`confirm-modal-${index}`}
          open={i.open}
          confirmText={i.confirmText}
          onConfirm={handleSave}
          onClose={handleCancelConfirmation}
          isSubmitting={isSubmitting}
        >
          {i.description}
        </ConfirmModal>
      ))}
    </>
  );
};

export default AdminTransferModal;
