import React, { useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { sumBy } from "lodash";

import Card from "@components/layout/Card";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { getAccountingPeriodOptions } from "@utils/getAccountingPeriodOptions";
import classNames from "classnames";
import { useSubscriberIndexContext } from "@context/SubscriberIndexContext";
import { toastWarning } from "@utils/toast";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";

const Level0WRSSummaryTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    zonePriorityBalance,
    otherUsages,
    getTotalUsage,
    rightTotalNominalVolume,
    transferVolume,
    prevYearFDCarriedOver,
    getAllocatedVolumeObjects,
    totalAvailableWater,
    rightNames,
    subscriber,
  } = useSubscriberIndexContext();
  const [selectedAccountingPeriod, setSelectedAccountingPeriod] = useState<{
    periodStart: string;
    periodEnd: string;
  }>();

  const { data: accountingPeriods = [] } = useAllAccountingPeriods({
    params: { level1ResourceId: subscriber?.level1ResourceId },
  });

  const activeRights = rightNames?.filter((name: string) => {
    const right = subscriber?.extractionRights.find(
      (i: any) => i.name === name
    );
    if (!right) {
      return false;
    }
    return !right.inactivedAt;
  });
  const filteredRightNames = activeRights?.filter(
    (n: string) =>
      !/FD$/.test(n) &&
      !/WH$/.test(n) &&
      !/SA$/.test(n) &&
      !/^PT-Q/.test(n) &&
      !n.includes(`Quasi-Q/${subscriber.id}`)
  );

  const filteredOtherUsages = otherUsages?.filter(
    (ou: any) => ou.priority !== "Quasi"
  );
  const handleSelectAccountingPeriod = (value: any) => {
    toastWarning(t("subscriber.select_water_accounting_period_warning"));
    setSelectedAccountingPeriod(value.value);
  };
  const allocatedVolumeObjects = getAllocatedVolumeObjects();
  const totalUsage = getTotalUsage();

  const announceAllocations =
    allocatedVolumeObjects?.remainingBalance +
      allocatedVolumeObjects?.usage +
      -1 * transferVolume || 0;
  const totalOverusedBalance = sumBy(
    zonePriorityBalance?.zoneBalances,
    (i: any) => i.overusedBalance
  );
  const remainingBalance =
    totalAvailableWater - (totalUsage + totalOverusedBalance);
  const today = formatDate(new Date());

  return (
    <div className="h-full pl-2 pt-2 mb-10">
      <div className="flex flex-col gap-4">
        <form className="flex items-center">
          <div className="w-80">
            <label htmlFor="accounting_period" className="block text-gray-600">
              {t("subscriber.select_accounting_period")}
            </label>
            <div className="mt-1 flex rounded-md">
              <Select
                className="w-full"
                options={getAccountingPeriodOptions(accountingPeriods)}
                onChange={handleSelectAccountingPeriod}
                value={getAccountingPeriodOptions(accountingPeriods)?.find(
                  (y) => y.value === selectedAccountingPeriod
                )}
                placeholder={t("accounting_period.select")}
                defaultValue={getAccountingPeriodOptions(accountingPeriods)[0]}
              />
            </div>
          </div>
        </form>
        <div className="flex gap-3">
          <div className="w-2/3">
            <Card className="p-0">
              <div className="flex flex-col">
                <header
                  className="w-full h-auto px-4 py-2 rounded-t-lg"
                  style={{
                    backgroundColor: "#0091B3",
                  }}
                >
                  <div className="w-full flex">
                    <h1 className="flex justify-between items-center w-full font-light text-white text-lg">
                      <span>{t("subscriber.level0wrs_accounts")}</span>
                      <span>{zonePriorityBalance?.zoneIdentifier}</span>
                    </h1>
                  </div>
                </header>
                <div className="grid grid-cols-10">
                  <div
                    className="border-r border-gray-200 flex flex-col gap-3 relative bg-gray-200 col-span-4"
                    style={{
                      backgroundColor: "#E2ECF2",
                    }}
                  >
                    <div
                      className="absolute bg-gray-200 -bottom-4 border rounded-b-lg px-4 h-full w-full grid"
                      style={{
                        backgroundColor: "#E2ECF2",
                      }}
                    >
                      <div className="flex justify-between">
                        <div>
                          <h3 className="text-sm">
                            {t("extraction_right.name")}
                          </h3>
                          <div className="text-xl text-primary-2 font-bold">
                            {filteredRightNames?.length ? (
                              filteredRightNames.join(", ")
                            ) : (
                              <span className="text-red-500">
                                {t("extraction_right.no_extraction_rights")}
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <h3 className="text-sm">
                            {t("common.accounting_period")}
                          </h3>
                          <div className="text-xl text-primary-2 font-bold">
                            {!selectedAccountingPeriod
                              ? new Date(accountingPeriods[0]?.periodStart)
                                  .getFullYear()
                                  .toString()
                              : new Date(selectedAccountingPeriod.periodStart!)
                                  .getFullYear()
                                  .toString()}
                          </div>
                        </div>
                      </div>
                      <div>
                        <h3 className="text-sm">
                          {t("extraction_right.effective_volume")}
                        </h3>
                        <div className="text-4xl text-primary-2 font-bold mt-2">
                          {formatVolume(rightTotalNominalVolume)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-r border-gray-200 p-4 gap-6 col-span-3 grid content-between">
                    <div>
                      <h3 className="text-sm text-gray-500 flex justify-between">
                        {t("subscriber.announced_allocations")}
                        <span className="text-lg text-primary-2 font-bold">
                          {formatVolume(
                            announceAllocations - prevYearFDCarriedOver
                          )}
                        </span>
                      </h3>

                      {prevYearFDCarriedOver > 0 && (
                        <h3 className="mt-2 text-sm text-gray-500 flex justify-between">
                          {t("subscriber.prev_accounting_period_fd_unused")}
                          <span className="text-lg text-primary-2 font-bold">
                            {formatVolume(prevYearFDCarriedOver)}
                          </span>
                        </h3>
                      )}

                      <h3 className="mt-2 text-sm text-gray-500 flex justify-between">
                        {t("subscriber.approved_transfers")}
                        <span
                          className={classNames(
                            "text-lg text-primary-2 font-bold",
                            {
                              "text-green-500": transferVolume > 0,
                              "text-red-500": transferVolume < 0,
                            }
                          )}
                        >
                          {formatVolume(transferVolume)}
                        </span>
                      </h3>
                      <h3 className="mt-2 text-sm text-gray-500 flex justify-between">
                        {t("subscriber.forward_draw_volume")}
                        <span className="text-lg text-primary-2 font-bold">
                          {formatVolume(
                            allocatedVolumeObjects?.forwardDraw || 0
                          )}
                        </span>
                      </h3>
                    </div>
                    <div>
                      <h3 className="text-sm text-gray-900">
                        {t("subscriber.volume_available")}
                      </h3>
                      <div className="text-3xl text-primary-2 font-bold mt-2">
                        {formatVolume(totalAvailableWater) ||
                          `0.000 ${t("common.volume_unit")}`}
                      </div>
                    </div>
                  </div>
                  <div className="border-r border-gray-200 p-4 gap-3 col-span-3 grid content-between">
                    <h3 className="text-sm flex justify-between text-gray-500">
                      {t("subscriber.volume_used")}
                      <span className="text-lg text-primary-2 font-bold">
                        {formatVolume(Math.abs(totalUsage)) ||
                          `0.000 ${t("common.volume_unit")}`}
                      </span>
                    </h3>
                    <div>
                      <h3 className="text-sm">
                        {t("subscriber.volume_remain", { today })}
                      </h3>
                      <div
                        className={classNames(
                          "text-3xl font-bold mt-2",
                          remainingBalance >= 0
                            ? "text-primary-2"
                            : "text-red-500"
                        )}
                      >
                        {formatVolume(remainingBalance) ||
                          `0.000 ${t("common.volume_unit")}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="w-min">
            {filteredOtherUsages?.length > 0 && (
              <Card className="p-0 w-max">
                <div className="flex flex-col">
                  <header
                    className="w-full h-auto p-4 rounded-t-lg"
                    style={{
                      backgroundColor: "#7ECCC2",
                    }}
                  >
                    <h1 className="font-thin text-gray-700 text-xl">
                      {t("subscriber.volume_other")}
                    </h1>
                  </header>
                  <div className="grid grid-cols-1">
                    <div className="p-4 flex flex-col">
                      {otherUsages
                        ?.filter((ou: any) => ou.priority !== "Quasi")
                        .map((otherUsage: any, index: any) => (
                          <div key={index}>
                            <h3 className="text-sm flex justify-between text-gray-500">
                              {otherUsage.priority}
                              <span className="pl-32 text-lg text-primary-2 font-bold">
                                {formatVolume(Math.abs(otherUsage.usage)) ||
                                  `0.000 ${t("common.volume_unit")}`}
                              </span>
                            </h3>
                            {otherUsage.spUsage !== 0 && (
                              <h3 className="text-sm flex justify-between text-gray-500">
                                {t("subscriber.special_announcement")}
                                <span className="pl-32 text-lg text-primary-2 font-bold">
                                  {formatVolume(Math.abs(otherUsage.spUsage)) ||
                                    `0.000 ${t("common.volume_unit")}`}
                                </span>
                              </h3>
                            )}
                          </div>
                        ))}

                      {totalOverusedBalance > 0 ? (
                        <h3 className="text-sm flex justify-between text-gray-500">
                          {t("subscriber.overused_balance")}
                          <span className="pl-32 text-lg text-red-500 font-bold">
                            {formatVolume(totalOverusedBalance)}
                          </span>
                        </h3>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Level0WRSSummaryTab;
