import { capitalize, sumBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import InfoPanel from "@components/form/InfoPanel";
import StepButton from "@components/shared/StepButton";
import WorkflowPanels from "@components/form/WorkflowPanels";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import AmalgamateOrSubdivideDetails from "./AmalgamateOrSubdivideDetails";
import AmalgamateOrSubdivideWorkflowCompleted from "./AmalgamateOrSubdivideWorkflowCompleted";
import AmalgamateOrSubdivideSelectLevel1WRS from "./AmalgamateOrSubdivideSelectLevel1WRS";
import AmalgamateOrSubdivideSelectSubscriber from "./AmalgamateOrSubdivideSelectSubscriber";
import AmalgamateOrSubdivideSelectExtractionRights from "./AmalgamateOrSubdivideSelectExtractionRights";
import AmalgamateOrSubdivideLedger from "./AmalgamateOrSubdivideLedger";
import { useAmalgamateOrSubdivideExtractionRightsContext } from "@context/AmalgamateOrSubdivideExtractionRightContext";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { formatVolume } from "@utils/formatVolume";

const AmalgamateOrSubdivideWorkflow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    currentStep,
    workflowCompleted,
    details,
    networkErrors,
    outputExtractionRights,
    inputExtractionRights,
    context,
    getInfoMessages,
    stepHelpers,
    setCancelIndex,
    cancelInformation,
  } = useAmalgamateOrSubdivideExtractionRightsContext();
  const { evidences } = useEvidenceContext();

  const outputExtractionRight = outputExtractionRights?.length
    ? outputExtractionRights[0]
    : { id: "", name: "" };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={Boolean(details.id)}
          forceSuccess={Boolean(details.id)}
          info={
            <ul>
              <li>
                <strong>{t("level1wrs.create.name")}:</strong>{" "}
                {details.level1wrs?.name}
              </li>
              <li>
                <strong>{t("level1wrs.create.identifier")}:</strong>{" "}
                {details.level1wrs?.identifier}
              </li>
            </ul>
          }
        >
          {t(
            "approval.subdivide_and_amalgamate.create.step_1.select_level1wrs"
          )}
        </StepButton>
      ),
      panel: <AmalgamateOrSubdivideSelectLevel1WRS />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-2">
            <p>
              {t("approval.subdivide_and_amalgamate.create.step_1.info", {
                context,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={Boolean(details.id)}
          forceSuccess={Boolean(details.id)}
          info={
            <ul>
              <li>
                <strong>{t("subscriber.create.subscriber_name")}:</strong>{" "}
                {details.subscriber?.name}
              </li>
              <li>
                <strong>{t("subscriber.identifier")}:</strong>{" "}
                {details.subscriber?.accountNumber}
              </li>
            </ul>
          }
        >
          {t("extraction_right.create.step_1.select_subscriber")}
        </StepButton>
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("approval.subdivide_and_amalgamate.create.step_2.info", {
                context,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
      panel: <AmalgamateOrSubdivideSelectSubscriber />,
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={Boolean(details.id)}
          forceSuccess={Boolean(details.id)}
          info={
            <ul>
              <li>
                <strong>{t("common.extraction_rights")}:</strong>{" "}
                {inputExtractionRights.map((er) => er.name).join(", ")}
              </li>
              <li>
                <strong>{t("common.level0wrs")}:</strong>{" "}
                {details.level0wrs?.identifier}
              </li>
              <li>
                <strong>{t("common.single_water_class")}:</strong>{" "}
                {inputExtractionRights[0]?.waterClass?.name}
              </li>
            </ul>
          }
        >
          {t("approval.subdivide_and_amalgamate.create.step_3.select_right")}
        </StepButton>
      ),
      panel: <AmalgamateOrSubdivideSelectExtractionRights />,
      infoPanel: (
        <InfoPanel
          successes={getInfoMessages("success", 2)}
          errors={getInfoMessages("error", 2)}
        >
          <div className="space-y-2">
            <p>
              {t("approval.subdivide_and_amalgamate.create.step_3.info", {
                context,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={
            <ul>
              <li>
                <strong>{t("common.level0wrs")}:</strong>{" "}
                {details.level0wrs?.identifier}
              </li>
              <li>
                <strong>{t("common.single_water_class")}:</strong>{" "}
                {inputExtractionRights[0]?.waterClass?.name}
              </li>
              <li>
                <strong>
                  {t("approval.subdivide_and_amalgamate.create.step_4.new", {
                    context,
                  })}
                  :
                </strong>{" "}
                {outputExtractionRights.map((er) => er.name).join(", ")}
              </li>
              <li>
                <strong>
                  {capitalize(
                    `${t(
                      "approval.subdivide_and_amalgamate.create.step_4.new",
                      { context }
                    )} ${t("common.volume")}`
                  )}
                  :
                </strong>{" "}
                {formatVolume(sumBy(outputExtractionRights, "volume"))}
              </li>
              <li>
                <strong>
                  {t(
                    "approval.subdivide_and_amalgamate.create.step_4.original",
                    { context }
                  )}
                  :
                </strong>{" "}
                {inputExtractionRights.map((er) => er.name).join(", ")}
              </li>
              <li>
                <strong>
                  {capitalize(
                    `${t(
                      "approval.subdivide_and_amalgamate.create.step_4.original",
                      { context }
                    )} ${t("common.volume")}`
                  )}
                  :
                </strong>{" "}
                {formatVolume(sumBy(inputExtractionRights, "volume"))}
              </li>
            </ul>
          }
        >
          {t("approval.subdivide_and_amalgamate.create.step_4.step_name", {
            context,
          })}
        </StepButton>
      ),
      panel: <AmalgamateOrSubdivideDetails />,
      infoPanel: (
        <InfoPanel
          warnings={[
            ...(context === "amalgamate"
              ? [
                  t(
                    "approval.subdivide_and_amalgamate.create.step_4.warning_amalgamate"
                  ),
                ]
              : [
                  t(
                    "approval.subdivide_and_amalgamate.create.step_4.warning_subdivide"
                  ),
                ]),
          ]}
        >
          {cancelInformation}
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          info={
            evidences?.length ? (
              <ul className="text-sm text-gray-600 mt-1 space-y-1">
                {evidences?.map((file: any) => (
                  <li key={file.name}>
                    <strong>{t("common.file_uploaded.file_name")}:</strong>{" "}
                    {file.name}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-600 mt-1">
                {t("evidence.no_new_supporting")}
              </p>
            )
          }
          isOnChain={workflowCompleted}
        >
          {t("evidence.upload_menu_title")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTable="administrative_approvals"
          referenceTableId={details.id}
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={() => setCancelIndex(0)}
          customDropboxTitle={
            t("approval.subdivide_and_amalgamate.create.step_5.title", {
              context,
            }) as string
          }
          newUploadDescription={
            t("approval.subdivide_and_amalgamate.create.step_5.description", {
              extractionRightNames: inputExtractionRights
                .map((er) => er.name)
                .join(", "),
              context,
            }) as string
          }
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("approval.subdivide_and_amalgamate.create.step_5.info_intro", {
                context,
              })}
            </p>
            <p>
              {t(
                "approval.subdivide_and_amalgamate.create.step_5.info_conclusion"
              )}
            </p>
            {cancelInformation}
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
        >
          {t("approval.subdivide_and_amalgamate.create.step_4.step_name", {
            context,
          })}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <AmalgamateOrSubdivideWorkflowCompleted />
      ) : (
        <AmalgamateOrSubdivideLedger />
      ),
      infoPanel: workflowCompleted ? (
        <InfoPanel
          actions={[
            {
              label: (
                <Trans
                  i18nKey="approval.subdivide_and_amalgamate.create.workflow_completed.action"
                  context={context}
                  values={{
                    extractionRightName: outputExtractionRight.name,
                    subscriberName: details.subscriber.name,
                    level0wrsIdentifier: details.level0wrs.identifier,
                  }}
                />
              ),
              action: () => {
                navigate(
                  `/polestar/subscribers/${
                    details.subscriber.id
                  }?level0ResourceId=${
                    details.level0wrs.id
                  }&extractionRightId=${
                    context === "amalgamate"
                      ? outputExtractionRight.id
                      : "random"
                  }`
                );
              },
            },
          ]}
          successes={[
            t(
              "approval.subdivide_and_amalgamate.create.workflow_completed.success",
              {
                context,
              }
            ),
          ]}
        >
          <div className="space-y-4">
            <p>
              <Trans
                i18nKey="approval.subdivide_and_amalgamate.create.workflow_completed.info"
                context={context}
                values={{
                  extractionRightName: outputExtractionRight.name,
                  subscriberName: details.subscriber.name,
                  level0wrsName: details.level0wrs.name,
                }}
              />
            </p>
          </div>
        </InfoPanel>
      ) : (
        <InfoPanel
          successes={[
            t(
              "approval.subdivide_and_amalgamate.create.step_6.success_message",
              { context }
            ),
          ]}
          errors={networkErrors}
        >
          <div className="space-y-4">{cancelInformation}</div>
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default AmalgamateOrSubdivideWorkflow;
