import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import Layout from "@components/layout/Layout";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import StepInfo from "@components/shared/StepInfo";
import SeasonalTransferBuyer from "@components/form/admin_approvals/create_seasonal_transfer/SeasonalTransferBuyer";
import SeasonalTransferSeller from "@components/form/admin_approvals/create_seasonal_transfer/SeasonalTransferSeller";
import SeasonalTransferPrice from "@components/form/admin_approvals/create_seasonal_transfer/SeasonalTransferPrice";
import SeasonalTransferLedger from "@components/form/admin_approvals/create_seasonal_transfer/SeasonalTransferLedger";
import SeasonalTransferSelectLevel1WRS from "@components/form/admin_approvals/create_seasonal_transfer/SeasonalTransferSelectLevel1WRS";
import SeasonalTransferWorkflowCompleted from "@components/form/admin_approvals/create_seasonal_transfer/SeasonalTransferWorkflowCompleted";
import SeasonalTransferSelectLevel0WRSClassWithVolume from "@components/form/admin_approvals/create_seasonal_transfer/SeasonalTransferSelectLevel0WRSClassWithVolume";
import SeasonalTransferSelectLevel0Resource from "@components/form/admin_approvals/create_seasonal_transfer/SeasonalTransferSelectLevel0Resource";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import StepButton from "@components/shared/StepButton";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import {
  SeasonalTransferProvider,
  useSeasonalTransferContext,
} from "@context/SeasonalTransferContext";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { formatVolume } from "@utils/formatVolume";
import { convertMLToLiter } from "@utils/convertUnits";

const CreateSeasonalTransfer = () => {
  return (
    <EvidenceProvider>
      <SeasonalTransferProvider>
        <Main />
      </SeasonalTransferProvider>
    </EvidenceProvider>
  );
};

const Main = () => {
  const { t } = useTranslation();
  const {
    currentStep,
    navigateForCancel,
    stepHelpers,
    details,
    workflowCompleted,
    getInfoMessages,
    handleChangeDetails,
    info,
    isSameAccount,
  } = useSeasonalTransferContext();
  const { getEvidencesInfo } = useEvidenceContext();
  const [searchParams] = useSearchParams();
  const level1ResourceId = searchParams.get("level1ResourceId") || "";
  const { data: level1Resource } = useGetLevel1Resource(level1ResourceId, {
    enabled: Boolean(level1ResourceId),
    onSuccess: (item: any) => handleChangeDetails("level1wrs", item),
  });

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={info.level1Resource().body} />}
        >
          {t("level1wrs.select")}
        </StepButton>
      ),
      panel: <SeasonalTransferSelectLevel1WRS />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_1.info_intro",
              )}
            </p>
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_1.info_conclusion",
              )}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={info.fromSubscriber().body} />}
        >
          {t("approval.seasonal_water_assignments.create.step_2.title")}
        </StepButton>
      ),
      panel: <SeasonalTransferSeller />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_2.info_intro",
              )}
            </p>
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_2.info_have_balance",
              )}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={info.volumes().body} />}
        >
          {t("approval.seasonal_water_assignments.create.step_3.title")}
        </StepButton>
      ),
      panel: <SeasonalTransferSelectLevel0WRSClassWithVolume />,
      infoPanel: (
        <InfoPanel
          errors={getInfoMessages("error", 2)}
          successes={getInfoMessages("success", 2)}
        >
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_3.info_intro",
              )}
            </p>
            <ul className="space-y-2">
              <li className="pl-2">
                <Trans i18nKey="approval.seasonal_water_assignments.create.step_3.info_li_1"></Trans>
              </li>
              <li className="pl-2">
                <Trans i18nKey="approval.seasonal_water_assignments.create.step_3.info_li_2"></Trans>
              </li>
              <li className="pl-2">
                <Trans i18nKey="approval.seasonal_water_assignments.create.step_3.info_li_3"></Trans>
              </li>
            </ul>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={info.toSubscriber().body} />}
        >
          {t("approval.seasonal_water_assignments.create.step_4.title")}
        </StepButton>
      ),
      panel: <SeasonalTransferBuyer />,
      infoPanel: (
        <InfoPanel
          errors={getInfoMessages("error", 3)}
          successes={getInfoMessages("success", 3)}
          warnings={
            isSameAccount
              ? [
                  t(
                    "approval.seasonal_water_assignments.create.step_4.same_customer",
                  ),
                ]
              : []
          }
        >
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_4.info_intro",
              )}
            </p>
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_4.info_body_1",
              )}
            </p>
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_4.info_body_2",
                {
                  sellerLevel0wrsName: details.from.level0Resource.identifier,
                },
              )}
            </p>
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_4.info_conclusion",
              )}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={info.destination().body} />}
        >
          {t(
            "approval.seasonal_water_assignments.create.select_level0_resource.title",
          )}
        </StepButton>
      ),
      panel: <SeasonalTransferSelectLevel0Resource />,
      infoPanel: (
        <InfoPanel
          successes={
            details.to.extractionRights.length
              ? [
                  t(
                    "approval.seasonal_water_assignments.create.select_level0_resource.info.success",
                    {
                      extractionRightNames: details.to.extractionRights
                        .map((i) => i.name)
                        .join(", "),
                      level0ResourceIdentifier:
                        details.to.level0Resource.identifier,
                      waterClassName: details.to.waterClass.name,
                    },
                  ),
                ]
              : []
          }
        >
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.select_level0_resource.info.note",
              )}
            </p>

            <ul className="pl-4 list-decimal space-y-2">
              <li>
                {t(
                  "approval.seasonal_water_assignments.create.select_level0_resource.info.same_priority",
                  {
                    waterClassName: details.from.waterClass.name,
                  },
                )}
              </li>
            </ul>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={info.details().body} />}
        >
          {t("approval.seasonal_water_assignments.create.step_5.title")}
        </StepButton>
      ),
      panel: <SeasonalTransferPrice />,
      infoPanel: (
        <InfoPanel
          errors={getInfoMessages("error", 5)}
          warnings={[
            t("approval.seasonal_water_assignments.create.step_5.warning_1"),
          ]}
        >
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_5.info_intro",
              )}
            </p>
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_5.info_conclusion",
              )}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={6}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("approval.admin_transfer.create.step_5.upload_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTable="administrative_approvals"
          referenceTableId=""
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={() => navigateForCancel()}
          customDropboxTitle={
            t(
              "approval.seasonal_water_assignments.create.step_6.title",
            ) as string
          }
          newUploadDescription={
            t("approval.seasonal_water_assignments.create.step_6.description", {
              sellerName: details.from.subscriber?.name,
              buyerName: details.to.subscriber.name,
            }) as string
          }
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_6.info_intro",
              )}
            </p>
            <p>{t("approval.admin_transfer.create.step_5.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={7}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          forceSuccess={workflowCompleted}
        >
          {t("permanent_trade.confirm_transfer")}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <SeasonalTransferWorkflowCompleted />
      ) : (
        <SeasonalTransferLedger />
      ),
      infoPanel: workflowCompleted ? (
        <InfoPanel
          successes={[
            t(
              "approval.seasonal_water_assignments.create.workflow_complete.success_1",
              {
                sellerName: `${details.from.subscriber?.name} (${details.from.subscriber.accountNumber})`,
                buyerName: `${details.to.subscriber?.name} (${details.to.subscriber.accountNumber})`,
                volume: formatVolume(
                  convertMLToLiter(+details.transferVolume || 0),
                ),
              },
            ),
          ]}
        >
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_7.info_intro",
              )}
            </p>
          </div>
        </InfoPanel>
      ) : (
        <InfoPanel
          successes={[
            t("approval.seasonal_water_assignments.create.step_7.success_1"),
          ]}
        >
          <div className="space-y-4">
            <p>
              {t(
                "approval.seasonal_water_assignments.create.step_7.info_intro",
              )}
            </p>
          </div>
        </InfoPanel>
      ),
    },
  ];

  return (
    <Layout
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        ...(level1Resource
          ? [
              {
                label: level1Resource?.name,
                href: `/polestar/level1wrs/${level1ResourceId}`,
              },
            ]
          : []),
        {
          label: t("common.administrative_approvals"),
          href: level1Resource
            ? `/polestar/level1wrs/${level1ResourceId}#6`
            : `/polestar/administrative_approvals`,
        },
        {
          label: t("approval.seasonal_water_assignments.create.title"),
        },
      ]}
      accountingPeriodLevel1ResourceId={details.level1wrs?.id}
      title={t("approval.seasonal_water_assignments.create.title")}
    >
      <WorkflowPanels selectedStep={currentStep} steps={steps} />
    </Layout>
  );
};

export default CreateSeasonalTransfer;
