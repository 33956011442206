import classNames from "classnames";
import { useTranslation } from "react-i18next";

import WaterLedgerLogo from "@components/icons/WaterLedgerLogo";
import Modal from "@components/layout/Modal";
import TrialRunTag from "./TrialRunTag";
import DeclarationTag from "@components/shared/DeclarationTag";
import { formatDate } from "@utils/formatDate";
import { READING_CALCULATION } from "@services/declarations";
import { formatVolume } from "@utils/formatVolume";
import { convertLiterToML } from "@utils/convertUnits";
import ENV from "@config/env";

type TrialRunDetailsModalProps = {
  onClose: () => void;
  onClick: (value: "click_over" | "backward") => void;
  data: {
    upload: any;
    ledger: any;
  };
  status: "success" | "warning" | "error";
};

const TrialRunDetailsModal: React.FunctionComponent<
  TrialRunDetailsModalProps
> = ({ onClose, onClick, data, status }) => {
  const { t } = useTranslation();
  const { upload: file, ledger } = data;
  const error = ledger.error;
  const IS_SEQWATER = ENV.CLIENT_ID === "seqwater";

  const details = [
    {
      title: t("bulk_meter_upload.modal.file_details"),
      body: [
        ...(IS_SEQWATER
          ? [
              {
                key: t("extraction_point.group"),
                value: file.group,
                isWarn: error.group,
              },
              {
                key: t("extraction_point.sequence"),
                value: file.seq,
                isWarn: error.seq,
              },
            ]
          : []),
        {
          key: t("bulk_meter_upload.table.account_number"),
          value: file.accountNumber,
          isError: error.accountNumber,
        },
        {
          key: t("bulk_meter_upload.table.extraction_point_name"),
          value: file.extractionPointName,
          isError:
            error.extractionPointName ||
            error.extractionPointInactive ||
            error.extractionPointNotFound,
        },
        {
          key: t("bulk_meter_upload.table.meter_serial_no"),
          value: file.serialNo,
          isError: error.serialNo,
        },
        {
          key: t("bulk_meter_upload.table.meter_read"),
          value: `${file.reading}`,
          isError:
            error.duplicate ||
            (error.requireManualIntervention && !error.serialNo),
        },
        {
          key: t("bulk_meter_upload.table.date"),
          value: file.readAt ? formatDate(file.readAt) : "-",
          isError: error.duplicate,
        },
      ],
    },
    {
      title: t("bulk_meter_upload.modal.ledger_details"),
      body: [
        ...(IS_SEQWATER
          ? [
              {
                key: t("extraction_point.group"),
                value: ledger.group,
                isWarn: error.group,
              },
              {
                key: t("extraction_point.sequence"),
                value: ledger?.seq,
                isWarn: error.seq,
              },
            ]
          : []),
        {
          key: t("bulk_meter_upload.table.account_number"),
          value: ledger.accountNumber,
          isError: error.accountNumber,
        },
        {
          key: t("bulk_meter_upload.table.extraction_point_name"),
          value: ledger.extractionPointName,
          isError:
            error.extractionPointName ||
            error.extractionPointInactive ||
            error.extractionPointNotFound,
        },
        {
          key: t("bulk_meter_upload.table.meter_serial_no"),
          value: ledger.serialNo,
          isError: error.serialNo,
        },
        {
          key: t("bulk_meter_upload.modal.last_meter_read"),
          value: convertLiterToML(ledger.lastReading),
          isError:
            error.duplicate ||
            (error.requireManualIntervention && !error.serialNo),
        },
        {
          key: t("bulk_meter_upload.modal.ledger_date"),
          value: ledger.readAt ? formatDate(new Date(ledger.readAt)) : "-",
          isError: error.duplicate,
        },
        {
          key: t("bulk_meter_upload.table.volume_extracted"),
          value: (
            <>
              {formatVolume(ledger.volumeExtracted)}{" "}
              {ledger?.calculation ? (
                <span className="inline-block ml-1">
                  <DeclarationTag value={ledger.calculation} />
                </span>
              ) : null}
            </>
          ),
          isError: error.requireManualIntervention && !error.serialNo,
        },
      ],
    },
  ];

  return (
    <Modal open={true}>
      <header className="p-6 flex gap-2 border-b items-center">
        <WaterLedgerLogo />
        <h3 className="text-xl">{t("audit_trail.modal_title")}</h3>

        <div className="flex gap-2 ml-auto">
          <TrialRunTag status={status} />
        </div>
      </header>

      <div className="p-6 border-b space-y-4">
        {status === "success" ? (
          <p>{t("bulk_meter_upload.info.success")}</p>
        ) : null}
        <ul className="list-inside list-disc space-y-2">
          {IS_SEQWATER && error.group && (
            <li>{t("bulk_meter_upload.modal.error.group")}</li>
          )}
          {IS_SEQWATER && error.seq && (
            <li>{t("bulk_meter_upload.modal.error.seq")}</li>
          )}
          {error.accountNumber && (
            <li>{t("bulk_meter_upload.modal.error.account_no")}</li>
          )}
          {error.extractionPointName && (
            <li>{t("bulk_meter_upload.modal.error.extraction_point.name")}</li>
          )}
          {error.extractionPointInactive && (
            <li>
              {t("bulk_meter_upload.modal.error.extraction_point.inactive")}
            </li>
          )}
          {error.extractionPointNotFound && (
            <li>
              {t("bulk_meter_upload.modal.error.extraction_point.not_found")}
            </li>
          )}
          {error.serialNo && (
            <li>{t("bulk_meter_upload.modal.error.serial_no")}</li>
          )}
          {error.duplicate && (
            <li>{t("bulk_meter_upload.modal.error.duplicate")}</li>
          )}
          {error.requireManualIntervention && (
            <li>
              {t("bulk_meter_upload.modal.error.require_manual_intervention")}
            </li>
          )}
        </ul>

        {error.requireManualIntervention && !error.serialNo && (
          <p>{t("bulk_meter_upload.modal.tag")}</p>
        )}
      </div>

      <div className="flex p-6 border-b gap-4">
        {details.map((i) => {
          return (
            <div className="w-1/2" key={i.title}>
              <h4 className="font-semibold py-2 px-4 bg-gray-100 mb-4">
                {i.title}
              </h4>
              <ul className="text-gray-500 text-sm space-y-1">
                {i.body.map((j) => {
                  return (
                    <li
                      key={`${i.title}--${j.key}`}
                      className={classNames({
                        "text-red-600": j.isError,
                        "text-yellow-500": j.isWarn,
                      })}
                    >
                      <strong>{j.key}</strong> {j.value}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>

      <footer className="px-6 py-4 flex justify-end gap-2">
        {(Boolean(ledger.calculation) || error.requireManualIntervention) && (
          <>
            {
              <button
                type="button"
                className="btn-outline-primary text-sm rounded"
                onClick={() => onClick(READING_CALCULATION.BACKWARD)}
              >
                {t("bulk_meter_upload.table.back_read")}
              </button>
            }
            {
              <button
                type="button"
                className="btn-outline-primary text-sm rounded"
                onClick={() => onClick(READING_CALCULATION.CLICK_OVER)}
              >
                {t("bulk_meter_upload.table.click_over")}
              </button>
            }
          </>
        )}
        <button
          type="button"
          className="btn-primary text-sm rounded"
          onClick={onClose}
        >
          {t("common.close")}
        </button>
      </footer>
    </Modal>
  );
};

export default TrialRunDetailsModal;
