import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "@components/layout/Layout";
import Label from "@components/form/Label";
import AmalgamateOrSubdivideWorkflow from "@components/form/admin_approvals/create_amalgamation_or_subdivision/AmalgamateOrSubdivideWorkflow";
import ConfirmModal from "@components/shared/ConfirmModal";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import {
  AmalgamateOrSubdivideExtractionRightsProvider,
  useAmalgamateOrSubdivideExtractionRightsContext,
} from "@context/AmalgamateOrSubdivideExtractionRightContext";
import { useGetAdministrativeApproval } from "@hooks/query/useGetAdministrativeApproval";
import { useUpdateAmalgamationOrSubdivisionRequest } from "@hooks/mutation/useUpdateAmalgamationOrSubdivisionRequest";
import { useDeleteExtractionRight } from "@hooks/mutation/useDeleteExtractionRight";
import { toastError, toastSuccess } from "@utils/toast";
import {
  AdministrativeApprovalNumericType,
  ApprovalExtractionRightVersion,
  ExtractionRightApprovalType,
} from "@services/administrativeApprovals";

const CreateAmalgamationOrSubdivision = () => {
  return (
    <EvidenceProvider>
      <AmalgamateOrSubdivideExtractionRightsProvider>
        <Consumer />
      </AmalgamateOrSubdivideExtractionRightsProvider>
    </EvidenceProvider>
  );
};

const Consumer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    handleChangeDetails,
    setInputExtractionRights,
    setOutputExtractionRights,
    details,
    setContext,
    context,
    stepHelpers,
    cancelActions,
    cancelIndex,
    setCancelIndex,
    navigateForCancel,
    outputExtractionRights,
  } = useAmalgamateOrSubdivideExtractionRightsContext();

  const [searchParams] = useSearchParams();
  const action = searchParams.get("action") || "";
  const administrativeApprovalId =
    searchParams.get("administrativeApprovalId") || "";
  const from = searchParams.get("from") || "";

  useEffect(() => {
    if (administrativeApprovalId) {
      stepHelpers.setStep(3);
    }
    if (!action) return;
    if (
      !Object.values(ExtractionRightApprovalType).includes(
        action as ExtractionRightApprovalType
      )
    ) {
      throw new Error("Action could be only of Amalgamate or Subdivide", {
        cause: "invalid URL",
      });
    }
    setContext(action as ExtractionRightApprovalType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutateAsync: abandon, isLoading: isUpdatingAmalgamation } =
    useUpdateAmalgamationOrSubdivisionRequest();

  const { mutateAsync: deleteExtractionRightMutation, isLoading: isDeleting } =
    useDeleteExtractionRight();

  useGetAdministrativeApproval(details.id || administrativeApprovalId, {
    enabled: Boolean(details.id) || Boolean(administrativeApprovalId),
    onSuccess: (data: any) => {
      handleChangeDetails("id", data.id);
      handleChangeDetails("level1wrs", data.level1Resource);
      handleChangeDetails("level0wrs", data.level0Resource);
      setInputExtractionRights(
        data.extractionRights.filter(
          (e: any) => +e.version === ApprovalExtractionRightVersion.Old
        )
      );
      setOutputExtractionRights(
        data.extractionRights.filter(
          (e: any) => +e.version === ApprovalExtractionRightVersion.New
        )
      );
      handleChangeDetails("subscriber", data.buyer);
      const context =
        data.type === AdministrativeApprovalNumericType.AME
          ? ExtractionRightApprovalType.Amalgamate
          : ExtractionRightApprovalType.Subdivide;
      setContext(context);
    },
  });

  const handleCancelConfirm = async () => {
    try {
      if (cancelIndex === 1) {
        await abandon({
          administrativeApprovalId: details.id,
          abandoned: true,
        });

        for (const er of outputExtractionRights) {
          await deleteExtractionRightMutation({
            extractionRightId: er.id,
            note: "",
          });
          toastSuccess(
            t("extraction_right.delete.toast.success", {
              name: er?.name,
            })
          );
        }

        toastSuccess(
          t(
            "approval.subdivide_and_amalgamate.create.step_4.cancel_toast_message",
            {
              context,
            }
          )
        );
      } else {
        toastSuccess(
          t(
            "approval.subdivide_and_amalgamate.create.step_4.draft_toast_message",
            {
              context,
            }
          )
        );
      }
    } catch (error) {
      toastError(
        t("approval.subdivide_and_amalgamate.create.toast.error", { context })
      );
    }
    navigateForCancel();
  };

  return (
    <>
      <Layout
        permissions={["CreateAmalgamation"]}
        breadcrumb={[
          { label: t("dashboard.dashboard"), href: "/polestar" },
          ...(from === "level1wrs"
            ? [
                {
                  label: details.level1wrs?.name,
                  href: `/polestar/level1wrs/${details.level1wrs?.id}`,
                },
                {
                  label: t("common.extraction_rights"),
                  href: `/polestar/level1wrs/${details.level1wrs?.id}#4`,
                },
              ]
            : from === "subscriber"
            ? [
                {
                  label: t("common.subscribers"),
                  href: "/polestar/subscribers",
                },
                {
                  label: details.subscriber?.name,
                  href: `/polestar/subscribers/${details.subscriber?.id}`,
                },
                {
                  label: details.level0wrs?.identifier,
                  href: `/polestar/subscribers/${details.subscriber?.id}?level0ResourceId=${details.level0wrs?.id}`,
                },
                {
                  label: t("common.extraction_rights"),
                  href: `/polestar/subscribers/${details.subscriber?.id}?level0ResourceId=${details.level0wrs?.id}&extractionRightId=random`,
                },
              ]
            : [
                {
                  label: t("common.administrative_approvals"),
                  href: `/polestar/administrative_approvals`,
                },
              ]),
          {
            label: t("approval.subdivide_and_amalgamate.create.title", {
              context,
            }),
          },
        ]}
        title={t("approval.subdivide_and_amalgamate.create.title", {
          context,
        })}
        accountingPeriodLevel1ResourceId={details.level1wrs?.id}
      >
        <AmalgamateOrSubdivideWorkflow />
      </Layout>
      <ConfirmModal
        onClose={() => {
          setCancelIndex(-1);
        }}
        onConfirm={() => handleCancelConfirm()}
        open={cancelIndex !== -1}
        confirmText={t("common.continue")}
        isSubmitting={isUpdatingAmalgamation || isDeleting}
      >
        {t(
          "approval.subdivide_and_amalgamate.create.step_4.cancel_modal_title"
        )}
        <div className="text-base font-normal">
          {cancelActions.map((item, index) => (
            <Label
              key={index}
              className="flex items-center gap-2 cursor-pointer"
            >
              <input
                type="radio"
                value={item.label}
                checked={cancelIndex === index}
                onChange={() => setCancelIndex(index)}
              />
              {item.label}
            </Label>
          ))}
        </div>
        <div className="mt-6">
          {cancelActions.map((ca, index) => {
            if (!ca.warning) {
              return null;
            }

            return (
              <div
                key={index}
                className="flex items-start gap-2 text-sm text-primary-4 font-normal"
              >
                <InformationCircleIcon className="text-yellow-600 w-4 h-4 shrink-0" />
                <span>{ca.warning as string}</span>
              </div>
            );
          })}
        </div>
      </ConfirmModal>
    </>
  );
};

export default CreateAmalgamationOrSubdivision;
