import { useState } from "react";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import { useAllSpecialAnnouncements } from "@hooks/query/useAllSpecialAnnouncements";
import { formatDateTime } from "@utils/formatDateTime";
import { useNavigate } from "react-router-dom";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import Tag from "@components/shared/Tag";
import Select from "react-select";
import SpecialAnnouncementModal from "@components/modal/SpecialAnnouncementModal";
import _ from "lodash";

type AmalgamateAndSubdivisionTableProps = {
  level1Resource?: any;
};

const SpecialAnnouncementTable: React.FunctionComponent<
  AmalgamateAndSubdivisionTableProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<any>();
  const [filter, setFilter] = useState<{
    customerName?: string;
    level0Identifier?: string;
    hasEnded?: boolean;
  }>({});

  const { data = [], isLoading } = useAllSpecialAnnouncements();

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const options = [
    { label: t("common.active"), value: false },
    { label: t("common.ended"), value: true },
  ];

  return (
    <>
      <header className="flex justify-between items-end gap-3">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-3 2xl:w-2/1 relative z-20 justify-between items-end">
          <div>
            <Label htmlFor="customer-name">
              {t("subscriber.filter_subscriber_name")}
            </Label>
            <SearchInput
              id="customer-name"
              value={filter.customerName}
              onChange={(e) =>
                handleFilterChange("customerName", e.target.value)
              }
            />
          </div>
          <div>
            <Label htmlFor="level0wrs-identifier">
              {t("common.level0wrs")}
            </Label>
            <SelectLevel0Resource
              value={filter.level0Identifier}
              onChange={(e) => handleFilterChange("level0Identifier", e?.label)}
              isClearable
            />
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <Select
              options={options}
              value={options.find((i: any) => i.value === filter?.hasEnded)}
              onChange={(e) => handleFilterChange("hasEnded", e?.value)}
              isClearable
            />
          </div>
        </form>
        <button
          className="btn-secondary text-sm rounded"
          onClick={() => {
            navigate("/polestar/notifications/create?specialAnnouncement=new");
          }}
        >
          {t("approval.special_announcement.create")}
        </button>
      </header>
      <Table
        fields={[
          {
            title: t("common.level0wrs_s"),
            name: "level0Resources",
          },
          {
            title: t("common.subscribers"),
            name: "subscribers",
          },
          {
            title: t("common.status"),
            name: "statusToShow",
          },
          {
            title: t("notification.started_at"),
            name: "startAt",
          },
          {
            title: t("notification.end_at"),
            name: "endAt",
          },
          {
            title: t("notification.actual_end_at"),
            name: "actualAt",
          },
          {
            title: t("notification.end_reason"),
            name: "reason",
          },
          {
            title: t("common.action"),
            name: "action",
          },
        ]}
        data={data
          ?.map((item: any) => {
            const subscriberNames = item?.subscribers.map(
              (subscriber: any) => subscriber.name
            );
            const level0ResourceIdentifiers: any = item?.level0Resources.map(
              (level0Resource: any) => level0Resource.identifier
            );

            return {
              id: item.id,
              startAt: formatDateTime(new Date(item.startAt)),
              endAt: item.endAt ? formatDateTime(new Date(item.endAt)) : "-",
              hasEnded: item.hasEnded,
              subscribers:
                subscriberNames.length > 2
                  ? `${subscriberNames.slice(0, 2).join(", ")} ${t(
                      "common.and_more",
                      { count: subscriberNames.length - 2 }
                    )}`
                  : subscriberNames.join(", "),
              level0Resources:
                level0ResourceIdentifiers.length > 2
                  ? `${level0ResourceIdentifiers.slice(0, 2).join(", ")} ${t(
                      "common.and_more",
                      { count: level0ResourceIdentifiers.length - 2 }
                    )}`
                  : level0ResourceIdentifiers.join(", "),
              statusToShow: (
                <Tag status={item.hasEnded ? "info" : "success"}>
                  {item.hasEnded ? t("common.ended") : t("common.active")}
                </Tag>
              ),
              subscriberNames,
              level0ResourceIdentifiers,
              actualAt: item.allocationTriggeredAt
                ? formatDateTime(new Date(item.allocationTriggeredAt))
                : _.lt(new Date(item.endAt), new Date()) && item.endAt
                ? formatDateTime(new Date(item.endAt))
                : "-",
              reason: item.allocationTriggeredAt
                ? t("notification.end_reason_1")
                : _.lt(new Date(item.endAt), new Date()) && item.endAt
                ? t("notification.end_reason_2")
                : "-",
              action: (
                <div className="flex gap-1 items-center w-full">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedAnnouncement(item);
                    }}
                    className="btn-default text-sm"
                  >
                    {t("common.view")}
                  </button>
                </div>
              ),
            };
          })
          ?.filter(
            (row: { subscriberNames: string }) =>
              !filter?.customerName?.length ||
              row.subscriberNames
                .toString()
                .toLowerCase()
                .includes(filter?.customerName.toString().toLowerCase())
          )
          ?.filter(
            (row: { level0ResourceIdentifiers: string }) =>
              !filter?.level0Identifier?.length ||
              row.level0ResourceIdentifiers
                .toString()
                .toLowerCase()
                .includes(filter?.level0Identifier.toString().toLowerCase())
          )
          ?.filter(
            (row: { hasEnded: any }) =>
              filter.hasEnded === undefined || row.hasEnded === filter.hasEnded
          )}
        stickyHeader
        loading={isLoading}
        noRowsText={t("common.no_data")}
      />
      <SpecialAnnouncementModal
        data={selectedAnnouncement}
        onClose={() => setSelectedAnnouncement(undefined)}
      />
    </>
  );
};

export default SpecialAnnouncementTable;
