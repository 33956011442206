type WeatherSnowyIconProps = {
  className?: string;
};

const WeatherSnowyIcon = ({ className }: WeatherSnowyIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 27 25"
      fill="none"
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M7.618 19.5c-.374 0-.69-.121-.949-.363a1.166 1.166 0 0 1-.388-.887c0-.35.13-.646.388-.887.259-.242.575-.363.949-.363s.69.121.95.363c.258.241.387.537.387.887s-.13.646-.387.887a1.339 1.339 0 0 1-.95.363Zm3.208 3c-.374 0-.69-.121-.948-.363a1.166 1.166 0 0 1-.388-.887c0-.35.129-.646.388-.887.258-.242.574-.363.948-.363.375 0 .69.121.949.363.259.241.388.537.388.887s-.13.646-.388.887a1.335 1.335 0 0 1-.949.363Zm3.209-3c-.375 0-.69-.121-.949-.363a1.166 1.166 0 0 1-.388-.887c0-.35.13-.646.388-.887.258-.242.574-.363.949-.363.374 0 .69.121.948.363.26.241.388.537.388.887s-.129.646-.388.887a1.335 1.335 0 0 1-.948.363Zm6.416 0c-.374 0-.69-.121-.948-.363a1.166 1.166 0 0 1-.388-.887c0-.35.129-.646.388-.887.258-.242.574-.363.948-.363.375 0 .691.121.95.363.258.241.387.537.387.887s-.129.646-.387.887a1.339 1.339 0 0 1-.95.363Zm-3.208 3c-.374 0-.69-.121-.949-.363a1.166 1.166 0 0 1-.388-.887c0-.35.13-.646.388-.887.258-.242.575-.363.949-.363s.69.121.95.363c.258.241.387.537.387.887s-.13.646-.387.887a1.339 1.339 0 0 1-.95.363Zm-8.556-7c-1.621 0-3.007-.538-4.156-1.613-1.15-1.075-1.725-2.37-1.725-3.887 0-1.383.49-2.592 1.47-3.625S6.468 4.733 7.912 4.55a6.087 6.087 0 0 1 2.34-2.238A6.653 6.653 0 0 1 13.5 1.5c1.604 0 2.999.48 4.184 1.438s1.903 2.154 2.152 3.587c1.23.1 2.264.575 3.102 1.425.838.85 1.256 1.867 1.256 3.05 0 1.25-.467 2.313-1.403 3.188-.936.875-2.072 1.312-3.41 1.312H8.688Z"
      />
    </svg>
  );
};

export default WeatherSnowyIcon;
