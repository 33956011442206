import { getImgSrc } from "@utils/getImgSrc";

import waterledgerLogo from "@images/waterledger.svg";
import packageJson from "../../../package.json";

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: React.FunctionComponent<AuthLayoutProps> = ({ children }) => {
  const isPolestar = window.location.pathname.includes("polestar");
  const polestarLogoPath = getImgSrc("/polestar-logo.svg");
  const zenithLogoPath = getImgSrc("/zenith-logo.svg");
  const polestarBackgroundPath = getImgSrc("/polestar-background.jpg");
  const zenithBackgroundPath = getImgSrc("/zenith-background.jpg");

  const logoPath = isPolestar ? polestarLogoPath : zenithLogoPath;
  const bgPath = isPolestar
    ? `url("${polestarBackgroundPath}")`
    : `url("${zenithBackgroundPath}")`;

  return (
    <div
      className="flex flex-col w-full min-h-screen antialiased bg-blue-900 justify-between"
      style={{
        background: bgPath,
        backgroundColor: "#004A88",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <header className="bg-white w-full flex justify-center h-24 py-4">
        <img src={logoPath} alt="Waterledger Wallet" />
      </header>
      <section className="m-auto w-full sm:w-1/2 xl:w-1/3 px-3 lg:px-6">
        {children}
      </section>
      <footer>
        <div className="space-y-3 mx-auto py-12 px-4 sm:px-6 lg:px-8 text-sm text-white text-center">
          <p className="inline-flex items-center">
            Powered by{" "}
            <img
              src={waterledgerLogo}
              alt="Water Ledger Logo"
              className="h-6 ml-2"
            />
          </p>
          <p className="text-xs">Version: {packageJson.version}</p>
        </div>
      </footer>
    </div>
  );
};

export default AuthLayout;
