import React from "react";
import { useQuery } from "@tanstack/react-query";

import TransactionHistoryTable from "@components/form/transactionHistory/TransactionHistoryTable";
import { getEventTransactions } from "@services/zenith/eventTransaction";

type TransactionHistoryProps = {
  zonePriorityBalance: any;
};

const TransactionHistory: React.FunctionComponent<TransactionHistoryProps> = ({
  zonePriorityBalance,
}: TransactionHistoryProps) => {
  const { data: eventTransactions } = useQuery(
    ["eventTransactions"],
    getEventTransactions,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (!zonePriorityBalance.zoneBalances) return <p>Loading...</p>;
  const rightNames = zonePriorityBalance.zoneBalances.reduce(
    (accumulator: any, value: any) => {
      value.rightNames.forEach((rn: string) => {
        if (accumulator) accumulator.push(rn);
      });
      return accumulator;
    },
    []
  );

  return (
    <TransactionHistoryTable
      eventTransactions={eventTransactions?.filter(
        (th: any) => !!th.transactionAmount && rightNames.includes(th.reference)
      )}
      stickyHeader
    />
  );
};

export default TransactionHistory;
