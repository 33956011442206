import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useMeter } from "@hooks/query/useMeter";
import { convertLiterToML } from "@utils/convertUnits";
import { type ConfirmData } from "@components/shared/ConfirmationDetail";

type Details = {
  type: "reading" | "usage";
  subscriberId: string;
  level0ResourceId: string;
  declarationId: string;
  currentReading: string;
  volume: string;
  currentVolume: string;
  reading: string;
  readAt: Date;
  serialNo: string;
  note: string;
};

type ContextValue = {
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<Details>>;
  info: Record<string, ConfirmData>;
};

const AdjustDeclarationContext = React.createContext<ContextValue | undefined>(
  undefined
);

const AdjustDeclarationProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [details, setDetails] = React.useState<Details>({
    type: "reading",
    subscriberId: "",
    level0ResourceId: "",
    declarationId: "",
    volume: "",
    currentVolume: "",
    reading: "",
    currentReading: "",
    readAt: new Date(),
    serialNo: "",
    note: "",
  });

  const meterId = searchParams.get("meterId") ?? "";
  useMeter(meterId, {
    onSuccess: (res: any) => {
      if (Boolean(details.reading)) {
        return;
      }

      const currentReading = res.lastDeclaration?.reading
        ? convertLiterToML(res.lastDeclaration.reading)
        : "0";
      const currentVolume = res.lastDeclaration?.volume
        ? convertLiterToML(res.lastDeclaration.volume)
        : "0";
      setDetails((prev) => ({
        ...prev,
        subscriberId: res.subscriberId,
        level0ResourceId: res.extractionPoint?.level0ResourceId,
        declarationId: res.lastDeclaration?.id,
        serialNo: res.serialNo,
        volume: currentVolume,
        currentVolume,
        reading: currentReading,
        currentReading,
        readAt: new Date(res.lastDeclaration.readAt),
      }));
    },
  });

  const info = {
    details: {
      title: t("declaration.reading_details"),
      body: [
        {
          key: t("meter.serial_no"),
          value: details.serialNo,
        },
        ...(details.type === "reading"
          ? [
              {
                key: t("declaration.reading"),
                value: details.reading,
              },
            ]
          : []),
        ...(details.type === "usage"
          ? [
              {
                key: t("declaration.volume"),
                value: details.volume + t("common.volume_unit"),
              },
            ]
          : []),
        {
          key: t("common.note"),
          value: details.note || "-",
        },
      ],
    },
  };

  const value: ContextValue = {
    details,
    setDetails,
    info,
  };

  return (
    <AdjustDeclarationContext.Provider value={value}>
      {children}
    </AdjustDeclarationContext.Provider>
  );
};

const useAdjustDeclarationContext = () => {
  const context = React.useContext(AdjustDeclarationContext);
  if (context === undefined) {
    throw new Error(
      "useAdjustDeclarationContext must be used within a AdjustDeclarationProvider"
    );
  }
  return context;
};

export { AdjustDeclarationProvider, useAdjustDeclarationContext };
