import { useState } from "react";
import orderBy from "lodash/orderBy";

import SearchInput from "@components/form/SearchInput";
import Label from "@components/form/Label";
import OfftakeAndMeter from "@components/shared/OfftakeAndMeter";
import Loading from "@components/shared/Loading";
import { useAllExtractionRights } from "@hooks/query/zenith/useAllExtractionRights";

const OfftakesAndMetersTab: React.FunctionComponent = () => {
  const [searchValue, setSearchValue] = useState("");
  const { data: extractionRights, isLoading } = useAllExtractionRights();

  if (isLoading) {
    return (
      <div className="mt-8">
        <Loading />
      </div>
    );
  }

  const extractionPoints = extractionRights[0].extractionPoints;
  const filteredExtractionPoints = extractionPoints.filter(({ name }: any) =>
    name.includes(searchValue)
  );
  const orderExtractionPoints = orderBy(
    filteredExtractionPoints,
    ["isActive", "name"],
    ["desc", "asc"]
  );

  return (
    <div>
      <form className="sm:w-2/3 lg:w-1/3">
        <Label htmlFor="search">Search offtake ID</Label>
        <SearchInput
          id="search"
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </form>

      <div className="flex gap-6 flex-col mt-8">
        {orderExtractionPoints.map((extractionPoint: any) => (
          <OfftakeAndMeter
            key={extractionPoint.id}
            extractionPoint={extractionPoint}
          />
        ))}
      </div>
    </div>
  );
};

export default OfftakesAndMetersTab;
