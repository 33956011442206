import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import { useCreateDistributionLossContext } from "@context/CreateDistributionLossContext";
import { useStepContext } from "@context/shared/StepContext";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { toastError, toastSuccess } from "@utils/toast";
import { createDistributionLoss } from "@services/distributionLosses";
import ConfirmModal from "@components/shared/ConfirmModal";
import { convertMLToLiter } from "@utils/convertUnits";
import { DBTables } from "@utils/constants/dbTables";

const LedgerDistributionLoss: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const {
    distributionLossData,
    handleCancel,
    getDetails,
    setIsComplete,
    isValidData,
    setWorkflowInstance,
  } = useCreateDistributionLossContext();
  const { stepHelpers } = useStepContext();
  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();
  const [isLoading, setIsLoading] = React.useState(false);

  const { mutateAsync: saveData } = useMutation({
    mutationFn: createDistributionLoss,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const [res, workflowInstance] = await saveData({
        volume: convertMLToLiter(+distributionLossData.volume),
        readAt: distributionLossData.readAt,
        meterId: distributionLossData.meterSerialNo,
        extractionPointName: distributionLossData.extractionPointName,
      });

      await uploadEvidences({
        description: t("distribution_losses.evidence_description"),
        references: [
          {
            referenceId: res?.id,
            referenceTable: DBTables.Declarations,
          },
          {
            referenceId: res?.meter?.subscriber?.id,
            referenceTable: DBTables.Subscribers,
          },
          {
            referenceId: res?.meter?.subscriber?.level1ResourceId,
            referenceTable: DBTables.WRSHierarchies,
          },
          {
            referenceId: workflowInstance.id,
            referenceTable: DBTables.WorkflowInstances,
          },
        ],
      });

      setWorkflowInstance(workflowInstance);
      setIsComplete(true);
      toastSuccess(t("distribution_losses.modal.success"));
    } catch (error: any) {
      toastError(t("distribution_losses.modal.error"));
    }

    setShowConfirmModal(false);
    setIsLoading(false);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col grow p-6 space-y-6"
      >
        <Heading light>{t("distribution_losses.ledger")}</Heading>

        <ConfirmationDetail
          data={[getDetails(), getEvidencesInfo()]}
          onEdit={stepHelpers.setStep}
        />

        <div className="grow" />

        <footer className="-mx-6 flex gap-4 p-6 pb-0 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>
          <button type="submit" className="btn-primary" disabled={!isValidData}>
            {t("common.ledger")}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="btn-outline-primary"
          >
            {t("common.cancel")}
          </button>
        </footer>
      </form>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={handleConfirm}
        isSubmitting={isLoading}
      >
        {t("distribution_losses.save_confirm", {
          subscriberName: distributionLossData.subscriber?.name,
        })}
      </ConfirmModal>
    </>
  );
};

export default LedgerDistributionLoss;
