import Heading from "@components/layout/Heading";
import SelectDeclarationTable from "@components/table/SelectDeclarationTable";
import {
  BalanceAdjustmentType,
  useBalanceAdjustmentContext,
} from "@context/BalanceAdjustmentContext";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

type CreateBalanceAdjustmentSelectDeclarationProps = {
  onSave: () => void;
  onGoBack: () => void;
};

const CreateBalanceAdjustmentSelectDeclaration: FunctionComponent<
  CreateBalanceAdjustmentSelectDeclarationProps
> = ({ onSave, onGoBack }) => {
  const { t } = useTranslation();
  const { balanceAdjustmentData, setBalanceAdjustmentData, navigateForCancel } =
    useBalanceAdjustmentContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSave();
  };

  return (
    <form className="pb-4 flex flex-col h-full" onSubmit={handleSubmit}>
      <div className="p-6 grow flex flex-col">
        <div className="space-y-4">
          <Heading light>{t("balance_adjustment.select_declaration")}</Heading>
        </div>
        <SelectDeclarationTable
          onSelect={(items: any[]) => {
            setBalanceAdjustmentData({
              ...balanceAdjustmentData,
              declarations: items.map((item) => ({
                ...item,
                startReading: item.reading,
                startReadAt: new Date(item.readAt),
                endReading: item.reading,
                endReadAt: new Date(),
                adjustment: 0,
                isInitial: item.isInitial,
              })),
            });
          }}
          definedByWalletId={balanceAdjustmentData.subscriber?.walletId!}
          level0ResourceId={
            balanceAdjustmentData.extractionRight?.level0ResourceId!
          }
          selected={balanceAdjustmentData.declarations}
          multipleSelection={
            balanceAdjustmentData.type ===
            BalanceAdjustmentType.WATER_HARVESTING
          }
        />
      </div>

      <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onGoBack}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="submit"
          className="btn-primary"
          disabled={!balanceAdjustmentData?.declarations?.length}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default CreateBalanceAdjustmentSelectDeclaration;
