import { Fragment } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  CheckIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { ClockIcon } from "@heroicons/react/24/outline";
import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";

import { formatDate, formatDateInput } from "@utils/formatDate";

import { useAppContext } from "@context/AppContext";
import { getImgSrc } from "@utils/getImgSrc";
import { useAllNotification } from "@hooks/query/useAllNotifications";
import AppNavigation from "./AppNavigation";
import LanguageSwitcher from "@components/shared/LanguageSwitcher";

const userNavigation = [
  { name: translate("zenith.topnav.profile"), href: "/zenith/profile/me" },
  {
    name: translate("zenith.topnav.subscriber"),
    href: "/zenith/profile/subscriber",
  },
  { name: translate("zenith.topnav.logout"), href: "/zenith/logout" },
];

const transformNotification = (item: any) => {
  return {
    id: item.id,
    name: translate(item.type?.titleTextTemplate, {
      senderName: item.relatedMessage?.sender?.isAdmin
        ? translate("common.administrators")
        : item.relatedMessage?.sender?.name,
    }),
    description: item.relatedMessage?.subject,
    date: new Date(item.createdAt),
    href: `/zenith/notifications/${item.id}`,
    icon: UserCircleIcon,
  };
};

const AppBar: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { t } = useTranslation();

  const user = {
    name: loggedInInfo?.userDetails?.name,
    email: loggedInInfo?.userDetails?.email,
    walletId: loggedInInfo?.userDetails?.walletId,
  };

  const { data: { data: unReadNotifications = [] } = {} } = useAllNotification({
    params: {
      recipientWalletId: user.walletId,
      onlyNew: 1,
      limit: -1,
    },
    enabled: Boolean(user.walletId),
  });
  const { data: { data: readNotifications = [] } = {} } = useAllNotification({
    params: {
      recipientWalletId: user.walletId,
      read: 1,
      limit: -1,
    },
    enabled: Boolean(user.walletId),
  });
  const unReadNotificationsLength = unReadNotifications.length;

  const notifications = [
    ...(unReadNotificationsLength
      ? [
          {
            groupName: t("common.new"),
            items: unReadNotifications.slice(0, 3).map(transformNotification),
          },
        ]
      : []),
    ...(readNotifications?.length
      ? [
          {
            groupName: t("notification.before_that"),
            items: readNotifications.slice(0, 2).map(transformNotification),
          },
        ]
      : []),
  ];

  return (
    <Menu as="header">
      {({ open, close }) => (
        <>
          {/* Desktop menu */}
          <div className="fixed top-0 inset-x-0 px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8 bg-white shadow z-30">
            <div className="relative flex h-16 justify-between">
              <div className="flex px-2 lg:px-0 z-10">
                <Link to="/zenith" className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 md:h-10 w-auto"
                    src={getImgSrc("/zenith-logo.svg")}
                    alt="Zenith logo"
                  />
                </Link>
              </div>

              {/* Search Input */}
              {/* <div className="flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0">
                <div className="w-full sm:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    {t("common.search")}
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder={t("common.search") as string}
                      type="search"
                    />
                  </div>
                </div>
              </div> */}

              {/* Mobile toggle menu */}
              <div className="grid place-content-center lg:hidden relative">
                <Menu.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset">
                  <span className="sr-only">
                    {t("zenith.topnav.open_menu")}
                  </span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Menu.Button>
              </div>

              {/* Right menu */}
              <div className="hidden lg:relative lg:z-10 lg:ml-auto lg:flex lg:gap-4 lg:items-center">
                <div className="text-sm flex items-center gap-3 mr-4 text-gray-400">
                  <label
                    htmlFor="water-year"
                    className="text-primary-1 font-semibold"
                  >
                    {t("zenith.topnav.ledger_date")}
                  </label>
                  <input
                    type="date"
                    className="border px-3 py-1 rounded"
                    id="water-year"
                    defaultValue={formatDateInput(new Date())}
                  />
                </div>

                {/* Select Language */}
                <LanguageSwitcher />

                {/* Notifications */}
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="relative flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-1 focus:ring-offset-2">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                      {unReadNotificationsLength ? (
                        <span className="grid place-content-center w-6 h-6 absolute -top-2 -right-2 rounded-full bg-red-500 text-white text-xs">
                          {unReadNotificationsLength > 99
                            ? "99+"
                            : unReadNotificationsLength}
                        </span>
                      ) : null}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="w-screen max-w-md flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                        <header className="flex justify-between p-4 border-b">
                          <div>
                            <h3 className="text-base font-semibold leading-7 text-gray-700">
                              {t("zenith.navigation.notifications")}
                            </h3>
                            <p className="text-sm leading-6 text-gray-500">
                              {t("notification.unread_messages", {
                                count: unReadNotificationsLength,
                              })}
                            </p>
                          </div>

                          <CheckIcon className="w-6 h-6 text-primary-1" />
                        </header>

                        {notifications.map(({ groupName, items }) => {
                          return (
                            <div
                              className="space-y-6 py-4"
                              key={`notifications-${groupName}`}
                            >
                              <div className="px-4">
                                <h4 className="uppercase text-xs tracking-wider font-semibold text-gray-400 mb-2">
                                  {groupName}
                                </h4>
                                <div>
                                  {items.map(
                                    (item: {
                                      id: string;
                                      name: string;
                                      description: string;
                                      date: Date;
                                      href: string;
                                      icon: any;
                                    }) => (
                                      <Menu.Item
                                        as={Link}
                                        to={`notifications?id=${item.id}`}
                                        className="group relative flex items gap-x-3 rounded-lg p-2 hover:bg-gray-100"
                                        key={item.id}
                                      >
                                        <item.icon
                                          className="w-8 h-8 text-gray-300 shrink-0"
                                          aria-hidden="true"
                                        />
                                        <div className="text-gray-500">
                                          <div>
                                            <span className="text-gray-700 font-semibold">
                                              {item.name}
                                            </span>{" "}
                                            <p className="line-clamp-2">
                                              {item.description}
                                            </p>
                                          </div>
                                          <p className="text-sm flex items-center gap-2 tracking-wider mt-1 text-gray-400">
                                            <ClockIcon className="w-4 h-4" />
                                            {formatDate(item.date)}
                                          </p>
                                        </div>
                                      </Menu.Item>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <Menu.Item>
                          <Link
                            to="notifications"
                            className="block p-3 text-primary-1 font-semibold bg-gray-50 hover:bg-gray-100 text-center"
                          >
                            {t("common.view_all")}
                          </Link>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {/* Profile dropdown */}
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="relative flex-shrink-0 rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-1 focus:ring-offset-2">
                      <div className="w-8 h-8 grid place-content-center bg-gray-800 text-white rounded-full">
                        <span className="sr-only">Open user menu</span>
                        {loggedInInfo?.userDetails?.name[0]}
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        <div className="p-3">
                          <h3 className="font-semibold leading-7 mb-1">
                            {loggedInInfo.userDetails?.name}
                          </h3>
                          <p className="text-gray-500 break-words text-sm">
                            {loggedInInfo.userDetails?.email}
                          </p>
                        </div>
                      </Menu.Item>
                      <div className="p-1">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "group flex w-full items-center rounded-md px-2 py-2 text-sm"
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <Menu.Items
            as="nav"
            className="p-6 fixed left-0 right-0 top-16 bottom-0 z-30 bg-white overflow-y-auto lg:hidden"
            aria-label="Global"
          >
            <AppNavigation isMobile onClose={close} />

            <div className="border-t pt-4 -mx-6 px-4 mt-4">
              <div className="flex items-center">
                <div className="flex-shrink-0 w-8 h-8 grid place-content-center bg-gray-800 text-white rounded-full">
                  <span className="sr-only">Open user menu</span>
                  {loggedInInfo?.userDetails?.name[0]}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user.name}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                {userNavigation.map((item) => (
                  <Menu.Item
                    key={item.name}
                    as={Link}
                    to={item.href}
                    className="block rounded-md p-2 text-gray-500 hover:bg-gray-50 hover:text-gray-900 text-sm"
                  >
                    {item.name}
                  </Menu.Item>
                ))}
              </div>
            </div>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default AppBar;
