import { useTranslation } from "react-i18next";

import Tag from "@components/shared/Tag";
import { READING_CALCULATION } from "@services/declarations";

type DeclarationTagProps = {
  value: string;
};

const DeclarationTag: React.FunctionComponent<DeclarationTagProps> = ({
  value,
}) => {
  const { t } = useTranslation();
  const stringMapping: Record<string, string> = {
    [READING_CALCULATION.CLICK_OVER]: t("declaration.click_over"),
    [READING_CALCULATION.BACKWARD]: t("declaration.backward"),
    [READING_CALCULATION.WATER_HARVESTING]: t("declaration.water_harvesting"),
    [READING_CALCULATION.METER_READING_ADJUSTMENT]: t(
      "declaration.meter_reading_adjustment"
    ),
    [READING_CALCULATION.DISTRIBUTION_LOSS]: t("declaration.distribution_loss"),
  };

  return (
    <Tag status="warning" className="">
      {stringMapping[value] ?? ""}
    </Tag>
  );
};

export default DeclarationTag;
