type DamLevelIconProps = {
  className?: string;
};

const DamLevelIcon = ({ className }: DamLevelIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M22.637 15.2a.8.8 0 0 1-.8.8 1.6 1.6 0 0 0-1.072.544 3.425 3.425 0 0 1-1.6.96 3.024 3.024 0 0 1-2.816-.592l-.624-.56a1.233 1.233 0 0 0-1.742-.018l-.018.018c-.224.192-.432.4-.672.592a3.056 3.056 0 0 1-3.936-.112l-.544-.528-.16-.096.48 1.952.784 3.2a.8.8 0 0 1-.784 1.04h-6.4a.8.8 0 0 1-.8-.8V2.4a.8.8 0 0 1 .8-.8h1.76a.8.8 0 0 1 .784.624l1.456 5.872v.224a3.008 3.008 0 0 1 3.2.384c.256.208.496.448.752.656a1.2 1.2 0 0 0 1.6 0c.256-.208.496-.448.752-.656a3.04 3.04 0 0 1 3.92 0c.24.208.464.432.704.624a1.2 1.2 0 0 0 1.6 0l.752-.656A2.848 2.848 0 0 1 21.821 8a.8.8 0 0 1 0 1.6 1.6 1.6 0 0 0-1.072.544 3.425 3.425 0 0 1-1.6.96 3.024 3.024 0 0 1-2.816-.592l-.528-.544a1.233 1.233 0 0 0-1.742-.018l-.018.018c-.224.192-.432.4-.672.592a3.056 3.056 0 0 1-3.936-.112l-.624-.544a1.2 1.2 0 0 0-1.6 0l-.096.08L8.221 14.4a3.04 3.04 0 0 1 1.712.688c.256.208.496.448.752.656a1.2 1.2 0 0 0 1.6 0c.256-.208.496-.448.752-.656a3.04 3.04 0 0 1 3.92 0c.24.208.464.432.704.624a1.2 1.2 0 0 0 1.6 0l.752-.656a2.848 2.848 0 0 1 1.808-.656.801.801 0 0 1 .816.8Z"
      />
    </svg>
  );
};

export default DamLevelIcon;
