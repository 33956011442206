import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";

import { UseStepHelpers, useStep } from "@hooks/useStep";
import { useExtractionPoint } from "@hooks/query/useExtractionPoint";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useLevel0Resource } from "@hooks/query/useLevel0Resource";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import { useGenerateExtractionPointSequence } from "@hooks/query/useGenerateExtractionPointSequence";
import { useGenerateExtractionPointName } from "@hooks/query/useGenerateExtractionPointName";
import { convertLiterToML } from "@utils/convertUnits";
import { getLatitudeLongitude } from "@utils/getLatitudeLongitude";
import ENV from "@config/env";
import { type ConfirmData } from "@components/shared/ConfirmationDetail";

type LocationCoordinates = typeof initialLocationCoordinates;
const initialLocationCoordinates = {
  lat: "",
  lng: "",
};

type HandleChangeDetails = (
  key: keyof Details,
  value: any,
  subKey?: string
) => void;

type UpdatingPoint = {
  id: string;
  name: string;
  sequence?: number;
  group: string;
};

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<Details>>;
  locationCoordinates: LocationCoordinates;
  setLocationCoordinates: React.Dispatch<
    React.SetStateAction<LocationCoordinates>
  >;
  handleChangeDetails: HandleChangeDetails;
  navigateForCancel: () => void;
  updatingPoint: UpdatingPoint;
  setUpdatingPoint: React.Dispatch<React.SetStateAction<UpdatingPoint>>;
  locationFiles: any;
  setLocationFiles: React.Dispatch<any>;
  saveLocationDetails: () => void;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<boolean>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  networkErrors: string[];
  setNetworkErrors: React.Dispatch<React.SetStateAction<string[]>>;
  checkHasEntitlementInSameZone: () => boolean;
  info: Record<
    "subscriber" | "extractionPoint" | "location" | "meter",
    ConfirmData
  >;
};

const ExtractionPointContext = React.createContext<ContextValue | undefined>(
  undefined
);

type Details = {
  id: string;
  subscriber: {
    id: string;
    extractionRights: Array<any>;
    name: string;
    accountNumber: string;
    walletId: string;
  };
  level1wrs: { id: string; name: string };
  level0wrs: { id: string; identifier: string };
  waterClass: { id: string };
  name: string;
  description: string;
  maxFlow?: string;
  volLimit?: string;
  source: string;
  activatedEvidenceIds?: Array<string>;
  meter: {
    id: string;
    serialNo: string;
    extractionPointName: string;
  };
  address: { location: string };
  linkToExtractionPoints: Array<any>;
  group: string;
  sequence: string;
  geography: string;
};

const initialDetails: Details = {
  id: "",
  subscriber: {
    id: "",
    extractionRights: [],
    name: "",
    accountNumber: "",
    walletId: "",
  },
  level1wrs: { id: "", name: "" },
  level0wrs: { id: "", identifier: "" },
  waterClass: { id: "" },
  name: "",
  description: "",
  maxFlow: "",
  volLimit: "",
  source: "",
  activatedEvidenceIds: undefined,
  meter: {
    id: "",
    serialNo: "",
    extractionPointName: "",
  },
  address: { location: "" },
  linkToExtractionPoints: [],
  group: "",
  sequence: "",
  geography: "",
};

const ExtractionPointProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { extractionPointId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") || "";
  const level1ResourceId = searchParams.get("level1ResourceId") || "";
  const level0ResourceId = searchParams.get("level0ResourceId") || "";
  const [currentStep, stepHelpers] = useStep(6);
  const [details, setDetails] = React.useState({ ...initialDetails });
  const [locationCoordinates, setLocationCoordinates] =
    React.useState<LocationCoordinates>({
      ...initialLocationCoordinates,
    });
  const [workflowCompleted, setWorkflowCompleted] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const [updatingPoint, setUpdatingPoint] = React.useState<UpdatingPoint>({
    id: "",
    name: "",
    group: "",
  });
  const [locationFiles, setLocationFiles] = React.useState<any[]>([]);
  const [networkErrors, setNetworkErrors] = React.useState<string[]>([]);

  useGenerateExtractionPointName({
    params: {
      level1ResourceId: details.level1wrs.id,
    },
    refetchOnWindowFocus: false,
    onSuccess: (value: number) => {
      if (details.name === "") {
        setDetails((prevState) => ({
          ...prevState,
          name: value.toString(),
        }));
      }
    },
  });

  useGenerateExtractionPointSequence({
    params: {
      level1ResourceId: details.level1wrs.id,
      group: details.group,
    },
    refetchOnWindowFocus: false,
    onSuccess: (value: number) => {
      if (details.sequence === "") {
        setDetails((prevState) => ({
          ...prevState,
          sequence: value.toFixed(3),
        }));
      }
    },
  });

  useSubscriber(subscriberId, {
    refetchOnWindowFocus: false,
    onSuccess: (data: any) => {
      if (details.subscriber.id === "") {
        setDetails((prevState) => ({
          ...prevState,
          subscriber: {
            id: data.id,
            name: data.name,
            accountNumber: data.accountNumber,
            extractionRights: data.extractionRights,
            walletId: data.walletId,
          },
          level1wrs: {
            id: data.level1ResourceId,
            name: data.level1WRS?.name,
          },
        }));
      }
    },
  });

  useGetLevel1Resource(level1ResourceId, {
    refetchOnWindowFocus: false,
    onSuccess: (data: any) => {
      if (details.level1wrs.id === "") {
        setDetails((prevState) => ({
          ...prevState,
          level1wrs: { id: data.id, name: data.name },
        }));
      }
    },
  });

  useLevel0Resource(level0ResourceId, {
    refetchOnWindowFocus: false,
    onSuccess: (data: any) => {
      if (details.level0wrs.id === "") {
        setDetails((prevState) => ({
          ...prevState,
          level0wrs: {
            id: data.id,
            identifier: data.identifier,
          },
          source: data.source,
        }));
      }
    },
  });

  useExtractionPoint(extractionPointId, {
    refetchOnWindowFocus: false,
    onSuccess: (data: any) => {
      setUpdatingPoint({
        id: data.id,
        name: data.name,
        group: data.group ?? "",
        sequence: data.sequence ? +data.sequence : undefined,
      });

      if (details.address.location === "") {
        const { isValid, lat, lng } = getLatitudeLongitude(
          data.address?.location
        );

        if (isValid) {
          setLocationCoordinates({
            lat: lat ? lat.toString() : "",
            lng: lng ? lng.toString() : "",
          });
        }
      }

      if (details.id === "") {
        setDetails(
          (prevState): Details => ({
            ...prevState,
            id: data.id,
            name: data.name,
            description: data.description,
            maxFlow: data.maxFlow ? convertLiterToML(+data.maxFlow) : "",
            volLimit: data.volLimit ? convertLiterToML(+data.volLimit) : "",
            group: data.group,
            sequence: data.sequence,
            subscriber: {
              ...prevState.subscriber,
              id: data.subscriber.id,
              name: data.subscriber.name,
              accountNumber: data.subscriber.accountNumber,
            },
            meter: {
              id: data.meter?.id ?? "",
              serialNo: data.meter?.serialNo ?? "",
              extractionPointName: data.meter?.extractionPoint?.name ?? "",
            },
            level0wrs: {
              id: data.level0ResourceId,
              identifier: data.level0WRS?.identifier,
            },
            level1wrs: {
              id: data.level0WRS?.parentId ?? "",
              name: data.level0WRS?.parent?.name ?? "",
            },
            source: data.level0WRS?.source,
          })
        );
      }
    },
  });

  const handleChangeDetails = (
    key: keyof Details,
    value: any,
    subKey?: string
  ) => {
    setDetails((prevState) => {
      const updatedDetails: any = { ...prevState };
      if (subKey) {
        const value: any = prevState[key];
        updatedDetails[key] = { ...value, [subKey]: value };
      } else {
        updatedDetails[key] = value;
      }
      return updatedDetails;
    });
  };

  const navigateForCancel = () => {
    if (!!searchParams.get("newWindow")) {
      window.close();
      return;
    }

    const returnString = subscriberId
      ? `/polestar/subscribers/${subscriberId}?level0ResourceId=${level0ResourceId}&extractionPointId=random`
      : `/polestar/level1wrs/${level1ResourceId}#5`;
    navigate(returnString);
  };

  const saveLocationDetails = () => {
    setDetails((prevState) => {
      const { lat, lng } = locationCoordinates;
      return {
        ...prevState,
        geography:
          lat && lng
            ? `${lat},${lng}`
            : locationFiles?.length > 0
            ? locationFiles[0].name
            : "",
      };
    });
  };

  const checkHasEntitlementInSameZone = (): boolean => {
    return details.subscriber.extractionRights
      ?.map((er: any) => er.level0ResourceId)
      .includes(details.level0wrs.id);
  };

  const info = {
    subscriber: {
      title: t("subscriber.create.subscriber_details"),
      body: [
        {
          key: t("common.name"),
          value: `${details.subscriber?.name} (${details.subscriber?.accountNumber})`,
        },
      ],
      disableEdit: Boolean(subscriberId),
    },
    extractionPoint: {
      title: t("extraction_point.create.details"),
      body: [
        { key: t("extraction_point.name"), value: details.name },
        {
          key: t("extraction_point.create.description"),
          value: details.description,
        },
        ...(ENV.CLIENT_ID === "seqwater"
          ? [
              {
                key: t("extraction_point.group"),
                value: details.group,
              },
              {
                key: t("extraction_point.sequence"),
                value: details.sequence,
              },
            ]
          : [
              {
                key: t("extraction_point.maximum_flow_rate"),
                value: `${details.maxFlow || 0} ${t("common.volume_unit")}`,
              },
              {
                key: t("extraction_point.maximum_volume"),
                value: `${details.volLimit || 0} ${t("common.volume_unit")}`,
              },
            ]),
        {
          key: t("extraction_point.create.source"),
          value: startCase(details.source),
        },
      ],
    },
    location: {
      title: t("extraction_point.create.step_3.location"),
      body: [
        {
          key: t("common.latitude"),
          value: locationCoordinates.lat,
        },
        {
          key: t("common.longitude"),
          value: locationCoordinates.lng,
        },
      ],
    },
    meter: {
      title: t("extraction_point.create.related_meter"),
      body: [
        {
          key: t("meter.serial_no"),
          value: details.meter.serialNo,
        },
      ],
    },
  };

  return (
    <ExtractionPointContext.Provider
      value={{
        currentStep,
        stepHelpers,
        details,
        setDetails,
        locationCoordinates,
        setLocationCoordinates,
        handleChangeDetails,
        navigateForCancel,
        updatingPoint,
        setUpdatingPoint,
        locationFiles,
        setLocationFiles,
        saveLocationDetails,
        workflowCompleted,
        setWorkflowCompleted,
        workflowInstance,
        setWorkflowInstance,
        networkErrors,
        setNetworkErrors,
        checkHasEntitlementInSameZone,
        info,
      }}
    >
      {children}
    </ExtractionPointContext.Provider>
  );
};

const useExtractionPointContext = () => {
  const context = React.useContext(ExtractionPointContext);
  if (context === undefined) {
    throw new Error(
      "useExtractionPointContext must be used within a CreateExtractionPointProvider"
    );
  }
  return context;
};

export { ExtractionPointProvider, useExtractionPointContext };
