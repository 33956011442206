import _ from "lodash";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { formatDateTime } from "../../../utils/formatDateTime";
import EditIcon from "../../icons/EditIcon";
import Heading from "../../layout/Heading";
import {
  NotificationType,
  SpecialAnnouncementType,
  useNotificationContext,
} from "@context/NotificationContext";
import { toast } from "react-toastify";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useCreateMessage } from "@hooks/mutation/useCreateMessage";
import { useCreateSpecialAnnouncement } from "@hooks/mutation/useCreateSpecialAnnouncement";
import { useEndSpecialAnnouncement } from "@hooks/mutation/useEndSpecialAnnouncements";

const CreateNotificationStep3: React.FunctionComponent = () => {
  const { details, stepHelpers, navigateForCancel, context } =
    useNotificationContext();
  const { t } = useTranslation();
  const { mutateAsync: createMessageMutation, isLoading } = useCreateMessage();
  const {
    mutateAsync: createSpecialAnnouncementMutation,
    isLoading: isCreatingSpecialAnnouncement,
  } = useCreateSpecialAnnouncement();

  const {
    mutateAsync: endSpecialAnnouncementMutation,
    isLoading: isEndingSpecialAnnouncement,
  } = useEndSpecialAnnouncement();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isEndingSA = context === SpecialAnnouncementType.End;
  const canUploadEvidenceMessage = [
    NotificationType.Bulletin,
    NotificationType.SpecialAnnouncement,
  ].includes(details.message.type);

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleOnConfirm = async () => {
    const { subscriberIds, message, notificationId } = details;
    const { fromDate, toDate, evidence, ...baseMessage } = message;
    try {
      let updatedMessage: any = { ...baseMessage };

      switch (updatedMessage.type) {
        case NotificationType.Shutdown:
          updatedMessage = { ...updatedMessage, fromDate, toDate };
          break;
        case NotificationType.Bulletin:
          updatedMessage = { ...updatedMessage, evidence };
          break;
        case NotificationType.SpecialAnnouncement:
          updatedMessage = { ...updatedMessage, evidence };
          break;
      }

      await createMessageMutation({
        subscriberIds,
        message: { ...updatedMessage, type: _.startCase(updatedMessage.type) },
        notificationId,
      });

      if (details.message.type === NotificationType.SpecialAnnouncement) {
        if (context === SpecialAnnouncementType.Start) {
          await createSpecialAnnouncementMutation({
            startAt: details.message.specialAnnouncementDate,
            level0ResourceIds: details.level0wrsIds,
            subscriberIds: details.subscriberIds,
          });
        } else {
          await endSpecialAnnouncementMutation({
            endAt: details.message.specialAnnouncementDate,
            specialAnnouncementIds: details.specialAnnouncementIds,
          });
        }
      }

      toast.success(t("notification.save_succeed"), {
        className: "toast toast-success",
      });

      navigateForCancel();
    } catch (e: any) {
      toast.error(
        <>
          <p>{t("notification.save_failed")}</p>
          <p>{e.message}</p>
        </>,
        {
          className: "toast toast-error",
        }
      );
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <form className="flex flex-col grow" onSubmit={handleOnSubmit}>
        <div className="p-6 grow">
          <Heading className="text-xl leading-6" light={true}>
            {t("notification.confirm_values")}
          </Heading>
          <div className="mt-6 flex flex-col gap-2">
            <div className="text-base font-bold text-gray-900 mt-2">
              {t("notification.message")}
            </div>
            <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
              <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("notification.type")}:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {_.startCase(context)}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-500">
                    {details.message?.type === NotificationType.Shutdown && (
                      <dl className="grid grid-cols-1 gap-x-4">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-bold text-gray-500">
                            {t("notification.shutdown_dates")}:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-500 truncate">
                            {details.message?.fromDate &&
                              formatDateTime(details.message?.fromDate)}{" "}
                            -{" "}
                            {details.message?.toDate &&
                              formatDateTime(details.message?.toDate)}
                          </dd>
                        </div>
                      </dl>
                    )}
                    <dl className="grid grid-cols-1 gap-x-4">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("notification.subject")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500 truncate">
                          {details.message?.subject}
                        </dd>
                      </div>
                    </dl>
                    <dl className="grid grid-cols-1 gap-x-4">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-bold text-gray-500">
                          {t("notification.body")}:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-500 truncate">
                          {details.message?.body}
                        </dd>
                      </div>
                    </dl>
                    {canUploadEvidenceMessage && details.message?.evidence && (
                      <dl className="grid grid-cols-1 gap-x-4">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-bold text-gray-500">
                            {t("notification.evidence")}:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-500 truncate">
                            {details.message?.evidence?.name}
                          </dd>
                        </div>
                      </dl>
                    )}
                  </dd>
                </div>
              </dl>
              <button
                type="button"
                className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
                onClick={() => stepHelpers.setStep(0)}
              >
                <EditIcon /> {t("common.edit")}
              </button>
            </dl>
            {isEndingSA ? (
              <>
                <div className="text-base font-bold text-gray-900">
                  {t("common.special_announcements")}
                </div>
                <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dd className="mt-1 text-sm text-gray-500">
                      {t("notification.selected_special_announcements", {
                        count: details.specialAnnouncementIds.length,
                      })}
                    </dd>
                  </div>
                  <button
                    type="button"
                    className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
                    onClick={() => stepHelpers.setStep(1)}
                  >
                    <EditIcon /> {t("common.edit")}
                  </button>
                </dl>
              </>
            ) : (
              <>
                <div className="text-base font-bold text-gray-900">
                  {t("common.level0wrs_s")}
                </div>
                <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dd className="mt-1 text-sm text-gray-500">
                      {t("notification.selected_level0wrs", {
                        count: details.level0wrsIds.length,
                      })}
                    </dd>
                  </div>
                  <button
                    type="button"
                    className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
                    onClick={() => stepHelpers.setStep(1)}
                  >
                    <EditIcon /> {t("common.edit")}
                  </button>
                </dl>
                <div className="text-base font-bold text-gray-900">
                  {t("common.subscribers")}
                </div>
                <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dd className="mt-1 text-sm text-gray-500">
                      {t("notification.selected_subscribers", {
                        count: details.subscriberIds.length,
                      })}
                    </dd>
                  </div>
                  <button
                    type="button"
                    className="sm:col-span-2 text-left flex gap-1 items-center font-bold text-sm text-primary-3"
                    onClick={() => stepHelpers.setStep(2)}
                  >
                    <EditIcon /> {t("common.edit")}
                  </button>
                </dl>
              </>
            )}
          </div>
        </div>
        <div className="border-t w-full border-gray-200 mt-5 p-6 flex gap-1">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>

          <button
            type="submit"
            className="btn-primary text-sm font-semibold"
            disabled={isLoading}
          >
            {t("common.save")}
          </button>
          <button
            type="button"
            className="btn-outline-primary text-sm font-semibold"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </div>
      </form>
      <ConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleOnConfirm}
        isSubmitting={
          isLoading ||
          isCreatingSpecialAnnouncement ||
          isEndingSpecialAnnouncement
        }
      >
        {t("notification.modal_confirmation")}
      </ConfirmModal>
    </>
  );
};

export default CreateNotificationStep3;
