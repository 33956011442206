import { useTranslation } from "react-i18next";
import { useAppContext } from "@context/AppContext";
import TabPanels from "@components/form/TabPanels";
import TransactionHistoryTab from "./TransactionHistoryTab";
import SubscriberNotes from "../../SubscriberNotes";
import NotificationHistoryTab from "./NotificationHistoryTab";
import EvidenceTable from "@components/table/EvidenceTable";

type SubscriberAuditTrailAndEvidenceTabsProps = {
  subscriber: any;
};

const SubscriberAuditTrailAndEvidenceTabs: React.FunctionComponent<
  SubscriberAuditTrailAndEvidenceTabsProps
> = ({ subscriber }: SubscriberAuditTrailAndEvidenceTabsProps) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();

  return (
    <TabPanels
      className="bg-white"
      tabs={[
        ...(checkPermissions(["ViewEventTransactions"])
          ? [
              {
                title: t("subscriber.audit_trail"),
                panel: (
                  <TransactionHistoryTab walletId={subscriber?.walletId} />
                ),
              },
            ]
          : []),
        ...(checkPermissions(["ViewEvidences"])
          ? [
              {
                title: t("evidence.documents"),
                panel: <EvidenceTable subscriber={subscriber} />,
              },
            ]
          : []),
        ...(checkPermissions(["ViewSubscribers"])
          ? [
              {
                title: t("subscriber.notes"),
                panel: <SubscriberNotes subscriber={subscriber} />,
              },
            ]
          : []),
        ...(checkPermissions(["ViewNotifications"]) ? [] : []),
        {
          title: t("notification.title"),
          panel: <NotificationHistoryTab walletId={subscriber?.walletId} />,
        },
      ]}
    />
  );
};

export default SubscriberAuditTrailAndEvidenceTabs;
