import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import MapView from "./MapView";

type Props = {
  extractionPoint: Record<string, any>;
};

function parseLatitudeLongitude(latLon: string) {
  const invalid = {
    isValid: false,
    lat: 0,
    lng: 0,
  };

  if (!latLon) return invalid;

  const lat = parseFloat(latLon.split(",")[0]);
  const lng = parseFloat(latLon.split(",")[1]);
  if (isNaN(lat) || isNaN(lng)) {
    return invalid;
  }

  const isLatitude = isFinite(lat) && Math.abs(lat) <= 90;
  const isLongitude = isFinite(lng) && Math.abs(lng) <= 180;
  return {
    isValid: isLatitude && isLongitude,
    lat,
    lng,
  };
}

const OfftakeAndMeter: React.FunctionComponent<Props> = ({
  extractionPoint,
}) => {
  const { t } = useTranslation();
  const { isValid, lat, lng } = parseLatitudeLongitude(
    extractionPoint.address?.location
  );

  const tableColumns = [
    {
      title: t("meter.serial_no"),
      name: "meterId",
    },
    {
      title: t("declaration.read_at"),
      name: "readAt",
    },
    {
      title: t("declaration.reading"),
      name: "reading",
    },
    {
      title: `${t("declaration.volume")} (${t("common.volume_unit")})`,
      name: "volume",
    },
    {
      title: t("meter.source"),
      name: "source",
    },
  ];

  const tableData = extractionPoint?.declarations?.map((declaration: any) => ({
    ...declaration,
    meterId: declaration.meter?.serialNo,
    readAt: formatDate(new Date(declaration.readAt)),
    reading: formatVolume(declaration.reading, ""),
    volume: formatVolume(declaration.volume < 0 ? 0 : declaration.volume, ""),
    source: extractionPoint.source,
  }));

  return (
    <div key={extractionPoint.id}>
      <article className="grid grid-cols-3 gap-6 place-content-between">
        <div className="p-4 col-span-2 border border-gray-300 rounded">
          <header className="flex flex-col gap-2 items-start 2xl:flex-row 2xl:items-center mb-4">
            <ul className="flex flex-row gap-6 items-center justify-start text-gray-600 text-xs">
              <li>
                {t("extraction_point.name")} #{extractionPoint.name}
              </li>
              <li>
                {t("extraction_point.is_active")}:{" "}
                <Tag status={extractionPoint.isActive ? "success" : "warning"}>
                  {extractionPoint.isActive
                    ? t("common.active")
                    : t("common.inactive")}
                </Tag>
              </li>
              <li>
                {t("extraction_point.source")}:{" "}
                {extractionPoint.source.toLowerCase()}
              </li>
              <li>
                {t("extraction_point.lat")}: {lat !== 0 ? lat.toFixed(5) : "-"}
              </li>
              <li>
                {t("extraction_point.long")}: {lng !== 0 ? lng.toFixed(5) : "-"}
              </li>
            </ul>

            <Link to="." className="btn-secondary ml-auto text-sm rounded-sm">
              {t("extraction_point.add_meter_read")}
            </Link>
          </header>

          <Table
            containerClassName="rounded-none md:rounded-none text-xs"
            fields={tableColumns}
            data={tableData}
            noRowsText="No declarations available"
            pageSize={5}
          ></Table>
        </div>

        <div className="col-span-1 h-80">
          {isValid ? (
            <MapView
              markers={[
                {
                  lat,
                  lng,
                },
              ]}
              zoomLevel={14}
            />
          ) : (
            <div className="font-semibold text-primary-3 text-center text-sm pt-10">
              Invalid map data
            </div>
          )}
        </div>
      </article>
    </div>
  );
};

export default OfftakeAndMeter;
