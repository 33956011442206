import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import SelectSingleSubscriberTable from "@components/table/SelectSingleSubscriberTable";
import { useExtractionPointContext } from "@context/ExtractionPointContext";

const ExtractionPointSelectSubscriber = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") || "";
  const { stepHelpers, details, setDetails, navigateForCancel } =
    useExtractionPointContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    stepHelpers.goToNextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col grow">
      <div className="flex flex-col grow gap-6 p-6">
        <Heading light>
          {t("extraction_right.create.step_1.select_subscriber")}
        </Heading>
        {subscriberId && !details?.subscriber?.name ? null : (
          <SelectSingleSubscriberTable
            onSelect={(selected: any) => {
              setDetails((prevState) => ({
                ...prevState,
                subscriber: {
                  id: selected.id,
                  name: selected.name,
                  accountNumber: selected.accountNumber,
                  walletId: selected.walletId,
                  extractionRights: selected.extractionRights,
                },
                level1wrs: {
                  id: selected.level1ResourceId,
                  name: selected.level1WRS?.name,
                },
                level0wrs: { id: "", identifier: "" },
                waterClass: { id: "" },
                linkToExtractionPoints: [],
              }));
            }}
            selected={details.subscriber}
            params={{
              level1ResourceId: details.level1wrs.id,
            }}
            noRowText={
              t("approval.admin_transfer.create.step_2.no_row_text", {
                level1wrsName: details.level1wrs.name,
              }) as string
            }
            predefinedFilter={{
              subscriberAccountIdName: subscriberId && details.subscriber.name,
            }}
          />
        )}
      </div>

      <footer className="flex gap-4 p-6  border-t border-gray-200">
        <button
          type="submit"
          className="btn-primary"
          disabled={details.subscriber.id === ""}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default ExtractionPointSelectSubscriber;
