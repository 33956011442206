import classNames from "classnames";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";

import Card from "@components/layout/Card";
import Tag from "@components/shared/Tag";
import { useSubscriberIndexContext } from "@context/SubscriberIndexContext";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import AddLevel0Resource from "./AddLevel0Resource";
import { Link } from "react-router-dom";

const SubscriberLevel0WRSBalanceWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    subscriber,
    setCurrentMenu,
    setCurrentSubMenu,
    setZonePriorityBalance,
    getTotalUsage,
    getAllocatedVolumeObjects,
    refetchSubscriber,
  } = useSubscriberIndexContext();

  const getRemainingBalance = (zonePriorityBalance: any) => {
    const allocatedVolumeObjects =
      getAllocatedVolumeObjects(zonePriorityBalance);
    const totalAvailableWater =
      allocatedVolumeObjects?.remainingBalance +
      allocatedVolumeObjects?.usage +
      allocatedVolumeObjects?.forwardDraw;
    const totalOverusedBalance = sumBy(
      zonePriorityBalance?.zoneBalances,
      (i: any) => i.overusedBalance
    );
    const totalUsage = getTotalUsage(zonePriorityBalance);

    return totalAvailableWater - (totalUsage + totalOverusedBalance);
  };

  const today = formatDate(new Date());

  return (
    <Card
      header={
        <div className="flex justify-between">
          <p className="flex gap-3">{t("subscriber.level0wrs_volumes")}</p>

          {subscriber?.id ? (
            <AddLevel0Resource
              subscriberId={subscriber.id}
              level1ResourceId={subscriber?.level1ResourceId}
              excludeIds={
                subscriber?.zonePriorityBalances?.length
                  ? subscriber.zonePriorityBalances.map((i: any) => i.zoneId)
                  : []
              }
              onSuccess={refetchSubscriber}
            />
          ) : null}
        </div>
      }
    >
      {subscriber?.zonePriorityBalances?.length <= 0 ? (
        <div className="justify-center flex">
          <div className="font-light text-primary-2 text-center text-xl h-40">
            {t("level0wrs.no_data")}
          </div>
        </div>
      ) : (
        <div className="flex flex-row gap-4 overflow-scroll scrollbar-hide">
          {subscriber.zonePriorityBalances.map(
            (zonePriorityBalance: any, index: number) => {
              const filteredZonePriorityBalance =
                zonePriorityBalance?.zoneBalances.filter(
                  (zb: any) => zb.priority !== "Quasi"
                );
              const remainingBalance =
                getRemainingBalance(zonePriorityBalance) || 0;

              return (
                <div
                  key={zonePriorityBalance?.zoneId}
                  className={classNames(
                    "flex flex-col items-start p-4 rounded-lg border shrink-0",
                    {
                      "w-48": !filteredZonePriorityBalance?.length,
                    }
                  )}
                >
                  <header className="flex-1 mb-2">
                    <div className="text-xs text-gray-500">
                      {t("common.level0wrs")}
                    </div>
                    <div className="text-xl">
                      {zonePriorityBalance?.zoneIdentifier}
                    </div>
                  </header>
                  {filteredZonePriorityBalance?.length ? (
                    <>
                      <div className="pb-12 flex flex-row gap-4">
                        <div>
                          <h6 className="mb-2 text-xs text-gray-500">
                            {t("common.status")}
                          </h6>
                          <Tag status="success">{t("common.active")}</Tag>
                        </div>
                        <div>
                          <h6 className="mb-2 text-xs text-gray-500">
                            {t("subscriber.volume_remain", { today })}
                          </h6>
                          <span
                            className={classNames(
                              remainingBalance < 0
                                ? "text-red-500"
                                : "text-primary-3"
                            )}
                          >
                            {formatVolume(remainingBalance)}
                          </span>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn-primary px-3 py-1.5 text-xs rounded"
                        onClick={() => {
                          setCurrentSubMenu(index);
                          setCurrentMenu(1);
                          setZonePriorityBalance(zonePriorityBalance);
                        }}
                      >
                        {t("common.view")}
                      </button>
                    </>
                  ) : (
                    <Link
                      className="btn-primary px-3 py-1.5 text-xs rounded"
                      to={`/polestar/level1wrs/${subscriber?.level1ResourceId}/extraction_rights/create?subscriberId=${subscriber?.id}&level0ResourceId=${zonePriorityBalance?.zoneId}`}
                    >
                      {t("extraction_right.create.title")}
                    </Link>
                  )}
                </div>
              );
            }
          )}
        </div>
      )}
    </Card>
  );
};

export default SubscriberLevel0WRSBalanceWidget;
