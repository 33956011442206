import { useEffect, useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import TextInput from "@components/form/TextInput";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import {
  AdministrativeApprovalStatus,
  AdministrativeApprovalType,
} from "@services/administrativeApprovals";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import { formatVolume } from "@utils/formatVolume";
import { formatDatetimeInput } from "@utils/formatDate";
import { formatDateTime } from "@utils/formatDateTime";
import { getType } from "@utils/administrativeApproval";

type Filter = {
  subscriberName: string;
  fromLodgedAt: string;
  toLodgedAt: string;
  type: number;
};

const typeOptions = [1, 2, 4, 7].map((value) => ({
  label: getType(value),
  value,
}));

const PendingAdminApprovalConfirmationChecklist: React.FunctionComponent =
  () => {
    const { loggedInInfo } = useAppContext();
    const {
      currentAccountingPeriodCheck,
      selectedCanceledAdministrativeApprovalIds,
      setSelectedCanceledAdministrativeApprovalIds,
      selectedCarriedOverAdministrativeApprovalIds,
      setSelectedCarriedOverAdministrativeApprovalIds,
    } = useAccountingPeriodChecksContext();
    const { t } = useTranslation();
    const [filter, setFilter] = useState<Partial<Filter>>({
      fromLodgedAt: "",
      toLodgedAt: "",
      subscriberName: "",
      type: 0,
    });

    const { data: approvals = [], isLoading } = useAllAdministrativeApprovals({
      params: {
        types: [
          AdministrativeApprovalType.PT,
          AdministrativeApprovalType.SWT,
          AdministrativeApprovalType.FD,
          AdministrativeApprovalType.SD,
        ],
        level1ResourceId: currentAccountingPeriodCheck?.level1ResourceId,
        accountingPeriodId: currentAccountingPeriodCheck?.accountingPeriod?.id,
        statuses: [
          AdministrativeApprovalStatus.Pending,
          AdministrativeApprovalStatus.InDraft,
          AdministrativeApprovalStatus.UnderReview,
        ],
      },
      options: {
        onSuccess: (data: any[]) => {
          if (data.length === 0 || filter.fromLodgedAt || filter.toLodgedAt) {
            return;
          }

          const allTimestamps = data.map((d) => d.createdAt);
          const sortedTimestamps = sortBy(allTimestamps);
          const firstDate = sortedTimestamps[0];
          const lastDate = sortedTimestamps[sortedTimestamps.length - 1];

          setFilter((prevState) => ({
            ...prevState,
            fromLodgedAt: firstDate,
            toLodgedAt: lastDate,
          }));
        },
      },
    });

    useEffect(() => {
      const cancelApprovalIds = approvals
        .filter(
          (i: any) =>
            !selectedCarriedOverAdministrativeApprovalIds.includes(i.id)
        )
        .map((i: any) => i.id);

      setSelectedCanceledAdministrativeApprovalIds(cancelApprovalIds);
    }, [
      approvals,
      selectedCarriedOverAdministrativeApprovalIds,
      setSelectedCanceledAdministrativeApprovalIds,
    ]);

    const tableFields = [
      {
        title: t("common.ledger_timestamp"),
        name: "createdAt",
      },
      {
        title: t("common.type"),
        name: "typeTag",
      },
      {
        title: t("approval.from_subscriber"),
        name: "sellerName",
      },
      {
        title: t("approval.to_subscriber"),
        name: "buyerName",
      },
      {
        title: t("common.volume"),
        name: "volume",
      },
      {
        title: t("approval.permanent_trades.price"),
        name: "price",
      },
      {
        title: "",
        name: "view",
      },
    ];

    const tableData = approvals
      .map((app: any) => ({
        ...app,
        level1Resource: app.level1Resource.name,
        createdAt: formatDateTime(new Date(app.createdAt)),
        createdAtRaw: app.createdAt,
        sellerName: app?.seller?.name,
        buyerName: app?.buyer?.name,
        volume: formatVolume(app.volume),
        volumeRaw: app.volume,
        price: `${t("common.currency")}${app.price || 0}`,
        typeTag: <Tag status="info">{getType(app.type)}</Tag>,
        view: (
          <Link
            to={`/polestar/administrative_approvals`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-default flex items-center gap-3 w-min"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            {t("common.view")}
          </Link>
        ),
      }))
      ?.filter(
        (i: any) =>
          !filter.subscriberName ||
          i.buyerName?.includes(filter.subscriberName) ||
          i.sellerName?.includes(filter.subscriberName)
      )
      ?.filter((i: any) => !filter.type || i.type === filter.type)
      ?.filter(
        (i: any) =>
          !filter.fromLodgedAt ||
          new Date(filter.fromLodgedAt).getTime() <=
            new Date(i.createdAtRaw).getTime()
      )
      ?.filter(
        (i: any) =>
          !filter.toLodgedAt ||
          new Date(filter.toLodgedAt).getTime() >=
            new Date(i.createdAtRaw).getTime()
      )
      ?.sort((a: any, b: any) => a.type - b.type);

    const handleFilterChange = (field: string, value: any) => {
      setFilter({
        ...filter,
        [field]: value,
      });
    };

    return (
      <>
        <div className="flex flex-col grow gap-4 p-4">
          <header className="flex justify-between items-center">
            <Heading1 light>
              {t(
                "accounting_period_checks.step_labels.pending_admin_approval_confirmation"
              )}
              <strong className="inline-block ml-2">{approvals.length}</strong>
            </Heading1>
          </header>

          <form className="flex items-end justify-start gap-3 flex-wrap">
            <div>
              <Label htmlFor="from-date">
                {t("subscriber.filter_name_id")}
              </Label>
              <div className="flex items-center gap-1">
                <SearchInput
                  id="subscriber"
                  placeholder={t("common.search") as string}
                  value={filter.subscriberName}
                  onChange={(e) => {
                    handleFilterChange("subscriberName", e?.target?.value);
                  }}
                />
              </div>
            </div>
            <div className="w-48">
              <Label>{t("common.type")}</Label>
              <Select
                options={typeOptions}
                value={typeOptions.find((i: any) => i.value === filter.type)}
                onChange={(selected: any) => {
                  setFilter((prev) => ({
                    ...prev,
                    type: selected?.value ?? 0,
                  }));
                }}
                isClearable
              />
            </div>
            <div>
              <Label htmlFor="from_lodged_at">
                {t("common.ledger_timestamp")}
              </Label>
              <div className="flex items-center gap-1">
                <TextInput
                  type="datetime-local"
                  id="from_lodged_at"
                  placeholder={t("common.date_range.from") as string}
                  value={
                    filter.fromLodgedAt &&
                    formatDatetimeInput(new Date(filter.fromLodgedAt))
                  }
                  onChange={(e) => {
                    handleFilterChange(
                      "fromLodgedAt",
                      new Date(e.target.value)
                    );
                  }}
                />
                <ArrowRightCalendarIcon className="w-10 h-10" />
                <TextInput
                  type="datetime-local"
                  placeholder={t("common.date_range.to") as string}
                  value={
                    filter.toLodgedAt &&
                    formatDatetimeInput(new Date(filter.toLodgedAt))
                  }
                  onChange={(e) => {
                    handleFilterChange("toLodgedAt", new Date(e.target.value));
                  }}
                />
              </div>
            </div>
          </form>

          <Table
            fields={tableFields}
            selectionKey="id"
            selectedKeys={selectedCarriedOverAdministrativeApprovalIds}
            data={tableData}
            loading={isLoading}
            stickyHeader
            onSelectionChange={(ids) => {
              setSelectedCarriedOverAdministrativeApprovalIds(ids);
            }}
          />
        </div>
        <AccountingPeriodChecklistFooter
          stepData={{
            pendingAdminApprovalsCheckedAt: new Date(),
            pendingAdminApprovalsCheckedByUserId: loggedInInfo?.userDetails?.id,
            canceledAdministrativeApprovalIds:
              selectedCanceledAdministrativeApprovalIds,
            carriedOverAdministrativeApprovalIds:
              selectedCarriedOverAdministrativeApprovalIds,
          }}
        />
      </>
    );
  };

export default PendingAdminApprovalConfirmationChecklist;
