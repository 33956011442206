import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { formatVolume } from "@utils/formatVolume";
import { ExtractionRight } from "@types";
import { formatDate } from "@utils/formatDate";
import { startCase } from "lodash";

type Props = {
  data: ExtractionRight;
  isActive: boolean;
  className?: string;
};

const WaterEntitlementItem: React.FunctionComponent<Props> = ({
  data,
  isActive,
  className,
}) => {
  const { t } = useTranslation();
  const { name, purpose, volume, level0Resource, waterClass, startAt, endAt } =
    data;

  const dates = [
    {
      text: t("extraction_right.start_at"),
      value: startAt ? formatDate(new Date(startAt)) : "-",
    },
    {
      text: t("extraction_right.end_at"),
      value: endAt ? formatDate(new Date(endAt)) : "-",
    },
  ];

  const details = [
    {
      text: t("extraction_right.allocation_type"),
      value: "WA",
    },
    {
      text: t("extraction_right.is_active"),
      value: isActive ? "A" : "I",
    },
    {
      text: t("extraction_right.source"),
      value: startCase(level0Resource?.source),
    },
    {
      text: t("common.water_class"),
      value: waterClass.name,
    },
  ];

  return (
    <div className={`lg:w-3/5 ${className}`}>
      <h2 className="flex justify-between items-center font-roboto font-light text-white text-lg px-4 py-2 rounded-t-lg bg-primary-4">
        <span>
          {t("extraction_right.name")} {name}
        </span>
        <span className="text-base">
          {t("extraction_right.purpose")}: {purpose}
        </span>
      </h2>

      <div className="grid grid-cols-5">
        <div className="bg-[#E2ECF2] col-span-3 flex flex-col p-5 rounded-b-lg">
          <ul className="flex justify-between">
            {dates.map(({ text, value }) => (
              <li key={text}>
                <h3 className="text-sm">{text}</h3>
                <div className="text-lg text-primary-2 font-bold">{value}</div>
              </li>
            ))}
          </ul>
          <div className="mt-4">
            <h3 className="text-sm">{t("extraction_right.volume")}</h3>
            <div className="text-4xl text-primary-2 font-bold mt-2">
              {formatVolume(volume)}
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <div className="p-5 border-b border-r border-gray-300">
            <table className="w-full text-sm">
              <tbody>
                {details.map(({ text, value }, index) => (
                  <tr key={text}>
                    <th
                      className={classNames(
                        "text-gray-400 text-left font-normal",
                        {
                          "pt-2": index > 0,
                        }
                      )}
                    >
                      {text}
                    </th>
                    <td className="text-right">{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterEntitlementItem;
