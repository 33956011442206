import api from "./api";

export const getWaterLevels = async (params: {
  lat: number;
  lng: number;
  n: number;
}): Promise<any> => {
  const { data } = await api.get("/waterLevels", { params });

  return data.waterLevels;
};
