import React from "react";
import { useTranslation } from "react-i18next";

import TransactionHistoryTable from "@components/form/transactionHistory/TransactionHistoryTable";
import TransactionIcon from "@components/icons/TransactionIcon";
import Heading from "@components/layout/Heading";
import { useSubscriberIndexContext } from "@context/SubscriberIndexContext";

const TransactionHistoryTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { eventTransactionTableData } = useSubscriberIndexContext();

  return (
    <>
      <Heading className="flex gap-3">
        <TransactionIcon /> {t("audit_trail.widget_title")}
      </Heading>
      <TransactionHistoryTable
        eventTransactions={eventTransactionTableData}
        stickyHeader
      />
    </>
  );
};

export default TransactionHistoryTab;
