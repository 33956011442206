import React from "react";
import Select from "react-select";

import ErrorMessage from "@components/form/ErrorMessage";
import { useGetBillingGroups } from "@hooks/query/useGetBillingGroups";

type SelectProps = React.ComponentProps<Select>;

type SelectBillingGroupProps = Exclude<SelectProps, "value"> & {
  level1ResourceId: string;
  value?: string;
  errorMessage?: string;
  isClearable?: boolean;
};

const SelectBillingGroup: React.FunctionComponent<SelectBillingGroupProps> = ({
  level1ResourceId,
  value,
  errorMessage,
  ...restProps
}) => {
  const { data: options = [], isLoading } = useGetBillingGroups({
    params: { level1ResourceId },
    options: {
      select: (data: any) => {
        return data
          .map((bg: any) => ({
            label: bg.name,
            value: bg.id,
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label));
      },
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col w-full">
      <Select
        options={options}
        value={options.find((i: any) => i.value === value)}
        {...restProps}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default SelectBillingGroup;
