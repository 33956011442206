import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import SelectBillingGroup from "@components/form/SelectBillingGroup";
import SelectItemNo from "@components/form/SelectItemNo";
import TextInput from "@components/form/TextInput";
import RadioInput from "@components/form/RadioInput";
import Legend from "@components/form/Legend";
import { usePermanentTransferContext } from "@context/PermanentTransferContext";
import { formatDateInput } from "@utils/formatDate";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";

type EnterDetailsProps = {
  onNext: () => void;
  onPrev: () => void;
  onCancel: () => void;
};

const EnterDetails: React.FunctionComponent<EnterDetailsProps> = ({
  onNext,
  onPrev,
  onCancel,
}) => {
  const { t } = useTranslation();
  const {
    level1Resource,
    selectedExtractionRights,
    usageIn,
    setUsageIn,
    lodgedAt,
    setLodgedAt,
    changeBillingGroup,
    changeItemNo,
  } = usePermanentTransferContext();

  const { data: currentAccountingPeriod = {}, isLoading } =
    useAllAccountingPeriods({
      params: {
        level1ResourceId: level1Resource?.id,
        isActive: true,
      },
      options: {
        select: (data: any) => data?.[0],
        onSuccess: (data: any) => {
          setLodgedAt(formatDateInput(new Date(data?.periodStart)));
        },
        refetchOnWindowFocus: false,
      },
    });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  return (
    <form
      className="flex flex-col p-6 grow justify-between"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col grow gap-6">
        <Heading light>
          {t("approval.permanent_trades.enter_details.title")}
        </Heading>

        <fieldset className="space-y-6">
          <div>
            <Label>
              {t("approval.seasonal_water_assignments.create.step_5.usage_in")}
            </Label>
            <div className="space-y-3">
              {["Irrigation", "Non-irrigation"].map((value) => {
                return (
                  <RadioInput
                    key={value}
                    htmlFor="zeroValueTradeReason"
                    label={value}
                    checked={usageIn === value}
                    onChange={() => {
                      setUsageIn(value);
                    }}
                  />
                );
              })}
            </div>
          </div>
          {isLoading ? null : (
            <div className="max-w-xs">
              <Label htmlFor="lodged_at">
                {t("approval.permanent_trades.lodged_at")}
              </Label>
              <TextInput
                type="date"
                id="lodged_at"
                value={lodgedAt}
                onChange={(e) => setLodgedAt(e.target.value)}
                min={formatDateInput(
                  new Date(currentAccountingPeriod.periodStart)
                )}
                max={formatDateInput(
                  new Date(currentAccountingPeriod.periodEnd)
                )}
              />
            </div>
          )}
        </fieldset>

        <fieldset className="space-y-6">
          <Legend>{t("extraction_right.create.step_2.billing_details")}</Legend>
          {selectedExtractionRights.map((i) => (
            <div key={i.id} className="space-y-2">
              <h4>
                {t("extraction_right.name")}: {i.name}
              </h4>
              <div className="flex gap-4">
                <div className="flex-1">
                  <Label htmlFor={`billingGroup--${i.id}`}>
                    {t("extraction_right.create.step_2.billing_group")}
                  </Label>
                  <SelectBillingGroup
                    level1ResourceId={level1Resource.id}
                    value={i.billingGroup.id}
                    onChange={(e: any) => {
                      if (e) {
                        changeBillingGroup(i.id, {
                          id: e.value,
                          name: e.label,
                        });
                      }
                    }}
                    inputId={`billingGroup--${i.id}`}
                    openMenuOnFocus
                  />
                </div>
                <div className="flex-1">
                  <Label htmlFor={`itemNo--${i.id}`}>
                    {t("extraction_right.create.step_2.item_number")}
                  </Label>
                  <SelectItemNo
                    inputId={`itemNo--${i.id}`}
                    billingGroupId={i.billingGroup.id}
                    value={i.itemNo}
                    onChange={(e: any) => {
                      if (e) {
                        changeItemNo(i.id, e.value);
                      }
                    }}
                    openMenuOnFocus
                    isDisabled={!Boolean(i.billingGroup.id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </fieldset>
      </div>

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button type="button" className="btn-outline-primary" onClick={onPrev}>
          {t("common.prev_step")}
        </button>

        <button type="submit" className="btn-primary">
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default EnterDetails;
