import { useTranslation } from "react-i18next";

import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";

type Props = {
  zonePriorityBalance: Record<string, any>;
  extractionRights: Record<string, any>[];
};

export default function ZoneAccountSummary({
  zonePriorityBalance,
  extractionRights,
}: Props) {
  const { t } = useTranslation();
  const otherUsages = zonePriorityBalance.zoneBalances.reduce(
    (
      // keep this comment: not required now,
      // if the spUsage has two classes
      // it should add up without dividing into priorities
      accumulator: Array<{ priority: string; usage: number; spUsage: number }>,
      value: any
    ) => {
      accumulator.push({
        priority: value.priority,
        usage: +value.usage + +value.uaUsage,
        spUsage: +value.spUsage,
      });
      return accumulator;
    },
    []
  );

  const totalUsage = zonePriorityBalance.zoneBalances.reduce(
    (accumulator: any, value: any) => {
      if (value.priority === "Water Harvesting") return accumulator;

      return accumulator + value.usage + value.uaUsage;
    },
    0
  );

  const rightNames = zonePriorityBalance.zoneBalances.reduce(
    (accumulator: any, value: any) => {
      value.rightNames.forEach((rn: string) => {
        if (accumulator) accumulator.push(rn);
      });
      return accumulator;
    },
    []
  );

  const rightTotalNominalVolume = extractionRights
    .filter((er: any) => er.type.identifier !== "FD")
    .reduce((accumulator: any, value: any) => {
      if (rightNames.includes(value.name)) {
        return accumulator + +value.volume;
      }
      return accumulator;
    }, 0);

  const prevYearFDCarriedOver = extractionRights.reduce(
    (accumulator: any, value: any) => {
      if (rightNames.includes(value.name)) {
        return accumulator + +value.forwardDrawWaterVolumeOfLastYear;
      }
      return accumulator;
    },
    0
  );

  const allocatedVolumeObjects = zonePriorityBalance.zoneBalances.reduce(
    (
      accumulator: {
        announcedAllocation: number;
        remainingBalance: number;
        seasonalTransfer: number;
        forwardDraw: number;
      },
      value: any
    ) => {
      const rightAllocation = +value.rightAllocation;
      const balance = +value.balance;
      const approvedSWT = +value.approvedSWT;

      if (value.priority === "Forward Draw") {
        accumulator.forwardDraw += rightAllocation;
      } else {
        accumulator.announcedAllocation += rightAllocation;
        accumulator.remainingBalance += balance;
        accumulator.seasonalTransfer += approvedSWT;
      }
      return accumulator;
    },
    {
      announcedAllocation: 0,
      remainingBalance: 0,
      seasonalTransfer: 0,
      forwardDraw: 0,
    }
  );

  const totalAvailableWater =
    allocatedVolumeObjects.announcedAllocation +
    allocatedVolumeObjects.seasonalTransfer +
    allocatedVolumeObjects.forwardDraw +
    +prevYearFDCarriedOver;

  const summary = [
    {
      id: "available-volume",
      description: t("subscriber.volume_available"),
      balance: totalAvailableWater,
      details: [
        {
          text: t("subscriber.announced_allocations"),
          value: allocatedVolumeObjects.announcedAllocation,
        },
        ...(prevYearFDCarriedOver > 0
          ? [
              {
                text: t("subscriber.prev_accounting_period_fd_unused"),
                value: prevYearFDCarriedOver,
              },
            ]
          : []),
        {
          text: t("subscriber.announced_transactions"),
          value: allocatedVolumeObjects.seasonalTransfer,
        },
        {
          text: t("subscriber.forward_draw_volume"),
          value: allocatedVolumeObjects.forwardDraw,
        },
      ],
    },
    {
      id: "remaining-volume",
      description: t("zenith.zone_accounts.volume_remain", {
        date: formatDate(new Date()),
      }),
      balance: totalAvailableWater - Math.abs(totalUsage),
      details: [
        {
          text: t("subscriber.volume_used"),
          value: Math.abs(totalUsage),
        },
      ],
    },
  ];

  return (
    <>
      <div className="bg-white">
        <h2 className="font-roboto font-light text-2xl bg-gray-100 px-4 py-3 border border-gray-300 rounded-t-lg relative overflow-hidden">
          {t("zenith.zone_accounts.account_summary")}
        </h2>

        {summary.map((item) => {
          return (
            <div
              key={item.id}
              className="p-4 flex flex-col gap-4 border border-t-0 border-gray-300"
            >
              <div>
                {item.details.map(({ text, value }) => {
                  return (
                    <dl
                      key={text}
                      className="flex justify-between items-center"
                    >
                      <dt className="text-sm text-gray-400">{text}</dt>
                      <dd className="text-lg font-black text-primary-2 tracking-wide">
                        {formatVolume(value)}
                      </dd>
                    </dl>
                  );
                })}
              </div>
              <div className="bg-gradient-to-r from-[#cce2f1] to-[#cce2f1] p-4 text-primary-2 rounded">
                <h5 className="mb-2">{item.description}</h5>
                <p className="font-bold text-3xl">
                  {formatVolume(item.balance)}
                </p>
              </div>
            </div>
          );
        })}

        <div className="mt-6 pt-6 px-4 pb-8 bg-primary-1 text-white rounded-b-lg">
          <h6>{t("extraction_right.volume")}</h6>
          <p className="text-5xl font-bold tracking-wide mt-2">
            {formatVolume(rightTotalNominalVolume)}
          </p>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="font-roboto font-light text-2xl bg-gray-100 px-4 py-3 border border-gray-300 rounded-t-lg relative overflow-hidden">
          {t("subscriber.volume_other")}
        </h2>
        <div className="p-4 border border-t-0 border-gray-300 bg-white">
          {otherUsages?.map((otherUsage: any, index: any) => (
            <div key={index}>
              <h3 className="text-sm flex justify-between text-gray-500">
                {otherUsage.priority}
                <span className="pl-32 text-lg text-primary-2 font-bold">
                  {formatVolume(Math.abs(otherUsage.usage))}
                </span>
              </h3>
              {otherUsage.spUsage !== 0 && (
                <h3 className="text-sm flex justify-between text-gray-500">
                  Special announcement
                  <span className="pl-32 text-lg text-primary-2 font-bold">
                    {formatVolume(Math.abs(otherUsage.spUsage))}
                  </span>
                </h3>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
