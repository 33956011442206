import api from "../api";

export const getAllSubscriberAdministrativeApprovals = async (
  params: Record<string, any> = {}
) => {
  const { data } = await api.get("/administrativeApprovals", { params });
  return data;
};

export const getAdministrativeApproval = async (id: string) => {
  const { data } = await api.get(`/administrativeApprovals/${id}`);

  return data.administrativeApproval;
};

export type AdministrativeTransferPayload = {
  subscriberId: string;
  extractionPointId: string;
  extractionRightId: string;
};

export const createAdministrativeTransfer = async (
  payload: AdministrativeTransferPayload
) => {
  const { data } = await api.post(
    "/administrativeApprovals/administrativeTransfer",
    payload
  );

  return [data.administrativeApproval, data.workflowInstance];
};

export const responseInfoRequest = async (payload: {
  id: string;
  subject: string;
  body: string;
}) => {
  const { data } = await api.post(
    "/administrativeApprovals/responseInfoRequest",
    payload
  );

  return [data.administrativeApproval, data.workflowInstance];
};
