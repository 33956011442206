import { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import TextInput from "@components/form/TextInput";
import SearchInput from "@components/form/SearchInput";
import Tag from "@components/shared/Tag";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import ArrowRightCircleIcon from "@components/icons/ArrowRightCircleIcon";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { formatDate, formatDatetimeInput } from "@utils/formatDate";
import { formatDateTime } from "@utils/formatDateTime";
import { getStatusAndText } from "@utils/administrativeApproval";
import { AdministrativeApprovalType } from "@services/administrativeApprovals";

const ForwardDrawsChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { currentAccountingPeriodCheck } = useAccountingPeriodChecksContext();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<{
    fromDate: string;
    toDate: string;
    subscriberIdName?: string;
    selectedLevel0Resource?: { name: string; id: string };
  }>({
    fromDate: "",
    toDate: "",
  });

  const { data: forwardDraws = [], isLoading } = useAllAdministrativeApprovals({
    params: {
      type: AdministrativeApprovalType.FD,
      level1ResourceId: currentAccountingPeriodCheck?.level1ResourceId,
      accountingPeriodId: currentAccountingPeriodCheck?.accountingPeriod?.id,
    },
    options: {
      onSuccess: (data: any) => {
        if (data.length === 0) return;
        if (filter.fromDate || filter.toDate) return;
        const allTimestamps = data.map((d: any) => d.createdAt);
        const sortedTimestamps = sortBy(allTimestamps);

        const firstDate = sortedTimestamps[0];
        const lastDate = sortedTimestamps[sortedTimestamps.length - 1];

        setFilter((prevState) => ({
          ...prevState,
          fromDate: firstDate,
          toDate: lastDate,
        }));
      },
    },
  });

  const tableFields = [
    { title: t("distribution_losses.timestamp"), name: "timestamp" },
    { title: t("subscriber.name"), name: "subscriberName" },
    { title: t("subscriber.account_number"), name: "subscriberAccountNumber" },
    { title: t("common.level0wrs"), name: "level0Resource" },
    {
      title: t("common.accounting_period"),
      name: "accountingPeriod",
    },
    {
      title: t("extraction_point.link.entitlement_id"),
      name: "extractionRightName",
    },
    {
      title: t("approval.forward_draws.created_at"),
      name: "lodgedAt",
    },
    {
      title: t("approval.forward_draws.date_approved_rejected"),
      name: "approvedAt",
    },
    {
      title: t("common.status"),
      name: "status",
    },
  ];

  const getZoneOptions = (level0Resources: any) => {
    const zones = level0Resources?.map((zone: any) => ({
      name: zone?.identifier,
      id: zone?.id,
    }));

    const uniqueZones = zones?.filter(
      (value: any, index: any, self: any) =>
        self.map((x: any) => x.name).indexOf(value.name) === index
    );

    return uniqueZones?.map((zone: any) => ({
      label: zone.name,
      value: zone.id,
    }));
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };
  return (
    <>
      <div className="flex flex-col grow gap-4 p-4">
        <header className="flex justify-between items-center">
          <Heading1 light>
            {t("accounting_period_checks.forward_draws")}
            <strong className="inline-block ml-2">{forwardDraws.length}</strong>
          </Heading1>
        </header>

        <form className="flex items-end justify-start gap-3 flex-wrap">
          <div>
            <Label htmlFor="from-date">{t("subscriber.filter_name_id")}</Label>
            <SearchInput
              id="subscriber"
              placeholder={t("common.search") as string}
              value={filter.subscriberIdName}
              onChange={(e) => {
                handleFilterChange("subscriberIdName", e?.target?.value);
              }}
            />
          </div>
          <div>
            <Label htmlFor="from-date">
              {t("accounting_period_checks.select_zone")}
            </Label>
            <Select
              className="w-72"
              options={getZoneOptions(
                forwardDraws?.map(
                  (d: any) => d.extractionRights[0]?.level0Resource
                )
              )}
              value={getZoneOptions(
                forwardDraws?.map(
                  (d: any) => d.extractionRights[0]?.level0Resource
                )
              )?.find((s: any) => s.value === filter.selectedLevel0Resource)}
              onChange={(e) => {
                handleFilterChange("selectedLevel0Resource", {
                  name: e?.label,
                  id: e?.value,
                });
              }}
              isClearable
            />
          </div>

          <div>
            <Label htmlFor="from-date">{t("common.ledger_timestamp")}</Label>
            <div className="flex items-center gap-1">
              <TextInput
                type="datetime-local"
                id="from_lodged_at"
                placeholder={t("common.date_range.from") as string}
                value={
                  filter.fromDate &&
                  formatDatetimeInput(new Date(filter.fromDate))
                }
                onChange={(e) => {
                  handleFilterChange("fromDate", new Date(e.target.value));
                }}
              />
              <ArrowRightCircleIcon className="w-10 h-10" />
              <TextInput
                type="datetime-local"
                placeholder={t("common.date_range.to") as string}
                value={
                  filter.toDate && formatDatetimeInput(new Date(filter.toDate))
                }
                onChange={(e) => {
                  handleFilterChange("toDate", new Date(e.target.value));
                }}
              />
            </div>
          </div>

          <Link
            className="btn-secondary flex items-center gap-1"
            to="/polestar/administrative_approvals/approve_forward_draw"
            target="_blank"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            {t("approval.forward_draws.approve_action_text")}
          </Link>
        </form>

        <Table
          fields={tableFields}
          data={forwardDraws
            ?.map((record: any) => {
              const { status, text } = getStatusAndText(
                record?.status as number
              );
              return {
                timestamp: formatDateTime(new Date(record.createdAt)),
                createdAtRaw: record.createdAt,
                subscriberName: record?.buyer?.name,
                subscriberAccountNumber: record?.buyer?.accountNumber,
                accountingPeriod: formatAccountingPeriod(
                  record?.accountingPeriod
                ),
                level0Resource:
                  record?.extractionRights[0]?.level0Resource?.identifier,
                lodgedAt: record?.lodgedAt
                  ? formatDate(new Date(record?.lodgedAt))
                  : record?.approvedAt
                  ? formatDate(new Date(record?.approvedAt))
                  : record?.rejectedAt
                  ? formatDate(new Date(record?.rejectedAt))
                  : "",
                approvedAt: record?.approvedAt
                  ? formatDate(new Date(record?.approvedAt))
                  : record?.rejectedAt
                  ? formatDate(new Date(record?.rejectedAt))
                  : "",
                extractionRightName: record?.extractionRights[0].name,
                status: record?.status && (
                  <Tag status={status ?? "default"}>{text}</Tag>
                ),
              };
            })
            ?.filter(
              (row: any) =>
                !filter.subscriberIdName ||
                !filter.subscriberIdName.length ||
                row?.subscriberName
                  ?.toString()
                  .toLowerCase()
                  .includes(filter.subscriberIdName.toString().toLowerCase()) ||
                row?.subscriberAccountNumber
                  ?.toString()
                  .includes(filter.subscriberIdName.toString())
            )
            ?.filter(
              (row: any) =>
                !filter.selectedLevel0Resource ||
                !filter.selectedLevel0Resource?.name?.length ||
                row?.level0Resource
                  ?.toString()
                  .toLowerCase()
                  .includes(
                    filter.selectedLevel0Resource?.name
                      ?.toString()
                      .toLowerCase()
                  )
            )
            ?.filter(
              (pt: any) =>
                !filter.fromDate ||
                new Date(filter.fromDate).getTime() <=
                  new Date(pt.createdAtRaw).getTime()
            )
            ?.filter(
              (pt: any) =>
                !filter.toDate ||
                new Date(filter.toDate).getTime() >=
                  new Date(pt.createdAtRaw).getTime()
            )}
          pageSize={100}
          loading={isLoading}
          stickyHeader
        />
      </div>
      <AccountingPeriodChecklistFooter
        stepData={{
          forwardDrawsCheckedAt: new Date(),
          forwardDrawsCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />
    </>
  );
};

export default ForwardDrawsChecklist;
