import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { useNavigate, Outlet, ScrollRestoration } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MainTopNav from "./TopNav";
import Breadcrumb, { BreadcrumObject } from "./Breadcrumb";
import Loading from "@components/shared/Loading";
import NotificationListSlideOver from "@components/form/notification/NotificationListSlideOver";
import { useAppContext } from "@context/AppContext";
import encryptedStorage from "@services/encryptedStorage";
import { useRefreshToken } from "@hooks/query/useRefreshToken";
import waterledgerLogo from "@images/waterledger-dark.svg";
import packageJson from "../../../package.json";
import { useAllNotification } from "@hooks/query/useAllNotifications";

type LayoutProps = {
  allowAnonymous?: boolean;
  children?: ReactNode;
  breadcrumb?: BreadcrumObject[];
  title?: React.ReactNode;
  permissions?: string[];
  status?: ReactNode;
  moreInfo?: ReactNode;
  accountingPeriodLevel1ResourceId?: string;
};

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  allowAnonymous = false,
  breadcrumb,
  title,
  permissions,
  status,
  moreInfo,
  accountingPeriodLevel1ResourceId = undefined,
}) => {
  const {
    setLoggedInInfo,
    setNotifications,
    checkPermissions,
    loggedInInfo,
    notifications,
  } = useAppContext();
  const { t } = useTranslation();
  const [isNotificationSlideOverOpen, setIsNotificationSlideOverOpen] =
    useState(false);
  const navigate = useNavigate();
  const { isLoading, data } = useRefreshToken({
    onSuccess: (data: any) => {
      encryptedStorage.setItem("token", data.token);
      encryptedStorage.setItem("isLoggedIn", "true");
      encryptedStorage.setItem("userDetails", JSON.stringify(data.user));

      setLoggedInInfo({
        userDetails: data.user,
        token: data.token,
        isLoggedIn: true,
      });
    },
    onError: (error: any) => {
      const { status } = error.response;
      const isUnAuthenticateError = status > 400 && status < 500;
      if (isUnAuthenticateError && !allowAnonymous) {
        navigate("login");
      }
    },
  });

  useAllNotification({
    params: {
      recipientWalletId: loggedInInfo.userDetails?.walletId,
      limit: -1,
    },
    queryKey: ["notifications", { id: loggedInInfo.userDetails?.id }],
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    enabled: Boolean(loggedInInfo.userDetails?.walletId),
    onSuccess: ({ data }: any = []) => setNotifications(data),
  });

  useEffect(() => {
    let userDetails;
    let token;
    let isLoggedIn;

    userDetails = encryptedStorage.getItem("userDetails");
    token = encryptedStorage.getItem("token");
    isLoggedIn = encryptedStorage.getItem("isLoggedIn");

    if (isLoggedIn) {
      setLoggedInInfo({
        userDetails,
        token,
        isLoggedIn,
      });
    } else if (!allowAnonymous) {
      navigate("login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading && !data) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="w-full fixed z-30">
        <MainTopNav
          onShowNotification={() => setIsNotificationSlideOverOpen(true)}
        />
      </div>

      <section className="flex flex-col grow mt-16 p-4 sm:p-6 lg:px-8">
        {!checkPermissions(permissions || []) ? (
          <div className="w-full h-96 mt-40 sm:mt-60 flex items-center justify-center text-center">
            <h1 className="text-xl text-gray-500">
              {t("common.access_denied")}
            </h1>
          </div>
        ) : (
          <div className="flex flex-col grow">
            {breadcrumb && (
              <Breadcrumb
                accountingPeriodLevel1ResourceId={
                  accountingPeriodLevel1ResourceId
                }
                items={breadcrumb}
              />
            )}
            {title && (
              <div className="flex items-center gap-3">
                <h1 className="text-2xl text-primary-3 font-bold my-4">
                  {title}
                </h1>
                {status}
                {moreInfo}
              </div>
            )}
            {children || <Outlet />}
          </div>
        )}
      </section>
      <footer className="flex flex-col-reverse md:flex-row items-center justify-between gap-3 py-6 px-4 sm:px-6 lg:px-8 border-t text-xs">
        <p className="text-gray-500 text-xs">
          <span className="inline-block mr-3">
            Version: {packageJson.version}
          </span>
          &copy; {new Date().getFullYear()} {t("common.all_rights_reserved")}
        </p>
        <p className="flex items-center gap-2">
          <span>{t("common.powered_by")}</span>
          <img src={waterledgerLogo} alt="Water Ledger logo" className="h-5" />
        </p>
      </footer>
      <ScrollRestoration />
      <NotificationListSlideOver
        open={isNotificationSlideOverOpen}
        notifications={notifications}
        onClose={() => setIsNotificationSlideOverOpen(false)}
        onViewMessageModalClose={() => setIsNotificationSlideOverOpen(true)}
      />
    </div>
  );
};

export default Layout;
