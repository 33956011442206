import { useState } from "react";
import { useTranslation } from "react-i18next";
import { sumBy } from "lodash";
import Select from "react-select";

import Heading1 from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import Modal from "@components/layout/Modal";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import { useAppContext } from "@context/AppContext";
import { useAllLevel0Resources } from "@hooks/query/useAllLevel0Resources";
import { formatDateTime } from "@utils/formatDateTime";
import { formatVolume } from "@utils/formatVolume";

const ZoneVolumesChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<{
    selectedLevel0Resource?: { name: string; id: string };
  }>({});
  const [data, setData] = useState<any[]>([]);
  const [changeZoneSelected, setChangeZoneSelected] = useState<string>();
  const [selectedLevel0Resource, setSelectedLevel0Resource] = useState<any>();

  const { isLoading } = useAllLevel0Resources({
    onSuccess: (response: any) => {
      setData(
        response?.map((record: any) => ({
          ...record,
          timestamp: formatDateTime(new Date(record.createdAt)),
          level0ResourceName: record?.identifier,
          level0ResourceNameExtended: record?.name,
          subscriberName: record?.extractionPoint?.subscriber?.name,
          subscriberAccountNumber:
            record?.extractionPoint?.subscriber?.accountNumber,
          volume: formatVolume(record?.yield),
          reading: formatVolume(record?.reading, ""),
          action: (
            <div className="flex gap-1 items-center w-full">
              <button
                type="button"
                onClick={() => setSelectedLevel0Resource(record)}
                className="btn-default text-sm"
              >
                {t("common.view")}
              </button>
            </div>
          ),
        }))
      );
    },
  });

  const tableFields = [
    { title: t("common.timestamp"), name: "timestamp" },
    {
      title: t("accounting_period_checks.level0_resource_identifier"),
      name: "level0ResourceName",
    },
    {
      title: t("accounting_period_checks.level0_resource_name"),
      name: "level0ResourceNameExtended",
    },
    {
      title: `${t("common.volume")} (${t("common.volume_unit")})`,
      name: "volume",
    },
    {
      title: t("approval.application"),
      name: "action",
    },
  ];

  const getZoneOptions = (level0Resources: any) => {
    const zones = level0Resources?.map((zone: any) => ({
      name: zone?.identifier,
      id: zone?.id,
    }));

    const uniqueZones = zones?.filter(
      (value: any, index: any, self: any) =>
        self.map((x: any) => x.name).indexOf(value.name) === index
    );

    return uniqueZones?.map((zone: any) => ({
      label: zone.name,
      value: zone.id,
    }));
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex flex-col p-4 grow">
        <div className="flex flex-row">
          <div className="flex flex-row gap-10">
            <Heading1 light>
              {t("accounting_period_checks.zone_volumes")}
            </Heading1>
            <Heading1>{data.length}</Heading1>
          </div>
        </div>
        <div className="flex flex-col grow gap-4">
          <form className="flex items-end justify-between w-full gap-4">
            <div className="mt-4 w-auto">
              <Label htmlFor="from-date">
                {t("accounting_period_checks.select_zone")}
              </Label>
              <div className="flex items-center gap-1">
                <Select
                  className="w-72"
                  options={getZoneOptions(data)}
                  value={getZoneOptions(data)?.find(
                    (s: any) => s.value === filter.selectedLevel0Resource
                  )}
                  onChange={(e) => {
                    handleFilterChange("selectedLevel0Resource", {
                      name: e?.label,
                      id: e?.value,
                    });
                  }}
                  isClearable
                />
              </div>
            </div>
            <button
              className="btn-secondary flex items-center gap-1"
              disabled={changeZoneSelected === undefined}
              onClick={(e) => {
                e.preventDefault();
                const selectedZone = data.find(
                  (d) => d.id === changeZoneSelected
                );
                window.open(
                  `/polestar/level1wrs/${selectedZone.parentId}/level0wrs/${selectedZone.id}/edit?startingStep=2`,
                  "_blank"
                );
              }}
            >
              <ExternalLinkIcon className="w-4 h-4" />
              {t("accounting_period_checks.update_zone_volume")}
            </button>
          </form>
          <Table
            selectionKey="id"
            multiple={false}
            onSelectionChange={(keys) => {
              if (keys.length) setChangeZoneSelected(keys[0]);
            }}
            selectedKeys={[changeZoneSelected]}
            fields={tableFields}
            data={data?.filter(
              (row: any) =>
                !filter.selectedLevel0Resource ||
                !filter.selectedLevel0Resource?.name?.length ||
                row?.level0ResourceName
                  ?.toString()
                  .toLowerCase()
                  .includes(
                    filter.selectedLevel0Resource?.name
                      ?.toString()
                      .toLowerCase()
                  )
            )}
            pageSize={100}
            loading={isLoading}
            stickyHeader
          />
        </div>
      </div>
      <AccountingPeriodChecklistFooter
        stepData={{
          zoneVolumesCheckedAt: new Date(),
          zoneVolumesCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />
      <Modal open={selectedLevel0Resource}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("accounting_period_checks.zone_volume", {
                zone: selectedLevel0Resource?.identifier,
              })}{" "}
            </h3>
          </div>
          <div className="p-4">
            <Table
              fields={[
                {
                  title: t("accounting_period_checks.water_class"),
                  name: "name",
                },
                {
                  title: t("common.volume"),
                  name: "volume",
                },
              ]}
              data={selectedLevel0Resource?.waterClasses?.map((wc: any) => ({
                ...wc,
                volume: formatVolume(wc?.volume),
              }))}
              totals={[
                { value: t("common.total"), justifyRight: true },
                {
                  value: formatVolume(
                    sumBy(
                      selectedLevel0Resource?.waterClasses?.map(
                        (wc: any) => wc.volume
                      )
                    )
                  ),
                },
              ]}
            />
          </div>
          <div className="mt-5 border-t px-4 py-2 flex flex-row-reverse gap-4">
            <button
              type="button"
              className="btn-primary text-base font-medium sm:col-start-1 sm:text-sm"
              onClick={() => setSelectedLevel0Resource(undefined)}
            >
              {t("common.close")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ZoneVolumesChecklist;
