import { t } from "i18next";

export const formatVolume = (
  quantity: number,
  unit: string = t("common.volume_unit"),
  decimals: number = 3
) => {
  if (isNaN(quantity)) return "";

  return `${(quantity / 1000000).toFixed(decimals)} ${unit}`.trim();
};

export const formatVolumeWithoutDivision = (
  quantity: number,
  unit: string = t("common.volume_unit"),
  decimals: number = 3
) => {
  if (isNaN(quantity)) return "";

  return `${quantity.toFixed(decimals)} ${unit}`.trim();
};

export const formatReportVolume = (quantity: number, unit = "") => {
  return formatVolume(quantity, unit, 2);
};
