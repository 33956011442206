import { useQuery } from "@tanstack/react-query";
import {
  getAllDeclarations,
  GetAllDeclarationsParams,
} from "@services/declarations";

type UseAllDeclarationsParams = {
  params: Partial<GetAllDeclarationsParams>;
  enabled: boolean;
  [key: string]: any;
};

export function useAllDeclarations({
  params,
  ...options
}: Partial<UseAllDeclarationsParams> = {}) {
  return useQuery<any>({
    queryKey: ["declarations", params],
    queryFn: () => getAllDeclarations(params),
    ...options,
  });
}
