import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toastError } from "@utils/toast";
import { useCreateEvidence } from "@hooks/mutation/useCreateEvidence";
import type { ConfirmData } from "@components/shared/ConfirmationDetail";
import { useArchiveUnselectedEvidence } from "@hooks/mutation/useArchiveUnselectedEvidence";

type ContextValue = {
  evidences: File[];
  setEvidences: React.Dispatch<File[]>;
  getRootProps: any;
  getInputProps: any;
  handleDeleteFile: (index: number) => void;
  getFileNames: () => string;
  getEvidencesInfo: () => ConfirmData;
  uploadEvidences: (data: any) => Promise<any>;
  isSubmitting: boolean;
  reset: () => void;
  isArchivingUnselectedEvidence: boolean;
  archiveUnselectedEvidences: (data: any) => Promise<any>;
};

type Props = {
  children: React.ReactNode;
  accept?: Record<string, string[]>;
  multiple?: boolean;
};

const Context = React.createContext<ContextValue | undefined>(undefined);

const defaultAccept = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
};

function EvidenceProvider({
  children,
  accept = defaultAccept,
  multiple = true,
}: Props) {
  const { t } = useTranslation();
  const [evidences, setEvidences] = React.useState<File[]>([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: File[]) => {
      const maxSize = 1024 * 1024 * 1024;
      const tooLargeFiles = files.filter((file) => file.size > maxSize);
      if (tooLargeFiles.length) {
        toastError(
          t("common.file_uploaded.file_too_large", { maxSize: "1GB" })
        );
        return;
      }

      const duplicateFiles = files.filter((file) =>
        evidences.some((evidence) => evidence.name === file.name)
      );
      if (duplicateFiles.length) {
        toastError(t("common.file_uploaded.duplicate_file"));
        return;
      }

      setEvidences((prevState) =>
        multiple ? [...prevState, ...files] : files
      );
    },
    onDropRejected: () => {
      toastError(t("common.file_uploaded.file_type_rejected"));
    },
    accept: accept,
    multiple,
  });
  const { mutateAsync: createEvidenceMutation, isLoading } =
    useCreateEvidence();

  const {
    mutateAsync: archiveUnselectedEvidenceMutation,
    isLoading: isArchivingUnselectedEvidence,
  } = useArchiveUnselectedEvidence();

  const handleDeleteFile = (fileIndex: number) => {
    setEvidences((prevState: any) => {
      return prevState.filter((_: any, index: number) => index !== fileIndex);
    });
  };

  const getFileNames = () => {
    return evidences.length
      ? evidences.map((f) => f.name).join(", ")
      : t("common.file_uploaded.no_files");
  };

  const reset = () => {
    setEvidences([]);
  };

  const getEvidencesInfo = () => {
    return {
      title: t("evidence.supporting"),
      body: [
        {
          key: t("common.file_uploaded.file_name", { count: evidences.length }),
          value: getFileNames(),
        },
      ],
    };
  };

  const uploadEvidences = async (data: {
    title?: string;
    description: string;
    references: {
      referenceId: string;
      referenceTable: string;
    }[];
    isPublic?: boolean;
    isEncrypted?: boolean;
  }) => {
    if (!evidences.length) return;

    const promises = evidences.map((evidence) =>
      createEvidenceMutation({
        attachment: evidence,
        title: data.title || evidence.name,
        description: data.description,
        references: data.references,
        isPublic: data.isPublic ?? false,
        isEncrypted: data.isEncrypted ?? true,
      })
    );

    return Promise.all(promises);
  };

  const archiveUnselectedEvidences = async (data: {
    referenceTable: string;
    referenceId: string;
    evidenceIds: any[];
  }) => {
    await archiveUnselectedEvidenceMutation(data);
  };

  const value = {
    evidences,
    setEvidences,
    getRootProps,
    getInputProps,
    handleDeleteFile,
    getFileNames,
    getEvidencesInfo,
    uploadEvidences,
    isSubmitting: isLoading,
    reset,
    isArchivingUnselectedEvidence,
    archiveUnselectedEvidences,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function useEvidenceContext() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      "useEvidenceContext must be used within a EvidenceProvider"
    );
  }
  return context;
}

export { EvidenceProvider, useEvidenceContext };
