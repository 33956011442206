import { ReactNode, useCallback, useEffect, useState } from "react";

import WeatherSnowyIcon from "@components/icons/WeatherSnowyIcon";
import Card from "@components/layout/Card";
import Loading from "@components/shared/Loading";
import ENV from "@config/env";
import axios from "axios";
import { useAppContext } from "@context/AppContext";
import { useAllSubscriberExtractionPoints } from "@hooks/query/zenith/useAllExtractionPoints";
import Select from "react-select";
import { useGetSubscriber } from "@hooks/query/zenith/useGetSubscriber";
import { getLatitudeLongitude } from "@utils/getLatitudeLongitude";
import { t } from "i18next";
import Clear from "@images/weather/clear.png";
import Rain from "@images/weather/rain.png";
import Snow from "@images/weather/snow.png";
import Clouds from "@images/weather/clouds.png";
import Haze from "@images/weather/haze.png";
import Smoke from "@images/weather/smoke.png";
import Mist from "@images/weather/mist.png";
import Drizzle from "@images/weather/drizzle.png";
import NoData from "@images/weather/no_data.png";
import WeatherPrefix from "@images/weather/weather_prefix.png";
import { useTranslation } from "react-i18next";

type AddressOptionsType = {
  label: ReactNode;
  value?: string;
  latLon?: string;
  town?: string;
};

const axiosInstance = axios.create({
  baseURL: "https://api.openweathermap.org/data/2.5/",
});

const weatherImages = [
  {
    type: t("widgets.weather.types.clear"),
    img: Clear,
  },
  {
    type: t("widgets.weather.types.rain"),
    img: Rain,
  },
  {
    type: t("widgets.weather.types.snow"),
    img: Snow,
  },
  {
    type: t("widgets.weather.types.clouds"),
    img: Clouds,
  },
  {
    type: t("widgets.weather.types.haze"),
    img: Haze,
  },
  {
    type: t("widgets.weather.types.smoke"),
    img: Smoke,
  },
  {
    type: t("widgets.weather.types.mist"),
    img: Mist,
  },
  {
    type: t("widgets.weather.types.drizzle"),
    img: Drizzle,
  },
];
const noWeatherImage = [
  {
    type: t("widgets.weather.types.no_data"),
    img: NoData,
  },
];

const WeatherWidget = () => {
  const { loggedInInfo } = useAppContext();
  const { t } = useTranslation();
  const definedByWalletId = loggedInInfo?.userDetails?.walletId;
  const subscriberId = loggedInInfo.userDetails?.subscriberId;
  const [loading, setLoading] = useState(false);
  const [fetchedWeatherData, setFetchedWeatherData] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<AddressOptionsType>({
    label: null,
  });
  const [showWeatherImage, setShowWeatherImage] =
    useState<{ type: string; img: string }[]>(noWeatherImage);

  const { data: subscriberAddress } = useGetSubscriber({
    id: subscriberId,
    options: {
      enabled: Boolean(subscriberId),
      refetchOnWindowFocus: false,
      select: (data: any): AddressOptionsType => {
        return {
          label: (
            <div className="flex gap-4 justify-between text-ellipsis overflow-hidden">
              <div>{data.name}</div>
              <span>{t("widgets.weather.subscriber")}</span>
            </div>
          ),
          value: data.address?.town ? data.address?.id : data.id,
          latLon: data.address?.location,
          town: data.address?.town,
        };
      },
      onSuccess: (data: any) => {
        setSelectedLocation(data);
      },
    },
  });

  const { data: extractionPointAddresses = [] } =
    useAllSubscriberExtractionPoints({
      params: { definedByWalletId },
      options: {
        enabled: Boolean(definedByWalletId),
        refetchOnWindowFocus: false,
        select: (data: any) => {
          return data.map((point: any): AddressOptionsType => {
            return {
              label: (
                <div className="flex gap-4 justify-between text-ellipsis overflow-hidden">
                  <div>{point?.name}</div>
                  <span>{t("widgets.weather.extraction_point")}</span>
                </div>
              ),
              value: point?.address?.id || point?.id,
              latLon: point?.address?.location,
            };
          });
        },
      },
    });

  const handleLocationChange = useCallback(async () => {
    setLoading(true);
    setFetchedWeatherData(null);
    const { latLon } = selectedLocation;
    const { isValid, lat, lng } = getLatitudeLongitude(latLon || "");
    const apiKey = ENV.OPEN_WEATHER_MAP_API_KEY;

    if (!isValid && selectedLocation.town === undefined) {
      setNotFoundImage();
      setLoading(false);
      return;
    }

    const URL = isValid
      ? `weather?lat=${lat}&lon=${lng}&units=metric&appid=${apiKey}`
      : `weather?q=${selectedLocation.town}&units=metric&appid=${apiKey}`;
    try {
      const { data } = await axiosInstance.get(URL);
      if (data.cod === 404 || data.cod === 400) {
        setNotFoundImage();
      } else {
        setShowWeatherImage(() => {
          return weatherImages.filter(
            (weather) => weather.type === data.weather[0].main
          );
        });
      }
      setFetchedWeatherData(data);
    } catch {
      setNotFoundImage();
    }

    setLoading(false);
  }, [selectedLocation]);

  useEffect(() => {
    handleLocationChange();
  }, [handleLocationChange, selectedLocation]);

  const setNotFoundImage = () => {
    setShowWeatherImage(noWeatherImage);
  };

  const addressOptions = [subscriberAddress, ...extractionPointAddresses];

  return (
    <Card
      header={
        <p className="flex gap-3">
          <WeatherSnowyIcon className="w-6 h-6 text-primary-2" />
          {t("widgets.weather.title")}
        </p>
      }
    >
      <div className="grid place-items-center">
        <div className="bg-white w-auto p-4 rounded-md">
          {fetchedWeatherData && (
            <p className="text-xl font-semibold">
              {`${fetchedWeatherData?.name}, ${fetchedWeatherData?.sys?.country}`}
            </p>
          )}
          {loading ? (
            <div className="grid place-items-center">
              <Loading className="w-80 h-80" />
            </div>
          ) : (
            <div className="text-center flex flex-col gap-6 mt-10">
              <img
                src={showWeatherImage[0]?.img}
                alt="..."
                className="h-40 mx-auto"
              />
              <h3 className="text-2xl font-bold text-zinc-800">
                {showWeatherImage[0]?.type}
              </h3>

              {fetchedWeatherData && (
                <>
                  <div className="flex justify-center">
                    <img src={WeatherPrefix} alt="..." className="h-9 mt-1" />
                    <h2 className="text-4xl font-extrabold">
                      {fetchedWeatherData?.main?.temp}&#176;C
                    </h2>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <Select
          className="w-full mt-4 px-10"
          options={addressOptions}
          value={
            addressOptions.find(
              (i: any) => i?.value === selectedLocation.value
            ) || null
          }
          onChange={(e: any) => {
            setSelectedLocation(e);
          }}
        />
      </div>
    </Card>
  );
};

export default WeatherWidget;
