import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { useSubscriberLevel0ResourceBalances } from "@hooks/query/useSubscriberLevel0ResourceBalances";
import { formatVolume } from "@utils/formatVolume";

type SelectLevel0ResouceTableProps = {
  subscriberId: string;
  value: any;
  onSelect: (value: any) => void;
  filter: {
    level0ResourceId: string;
    waterClassName: string;
  };
  filterByWaterClassName: string;
};

const SelectLevel0ResouceTable: React.FunctionComponent<
  SelectLevel0ResouceTableProps
> = ({ subscriberId, value, onSelect, filter }) => {
  const { t } = useTranslation();
  const { data = [], isLoading } = useSubscriberLevel0ResourceBalances(
    subscriberId,
    {
      select: (res: any[]) => {
        return res
          .map(({ waterClasses, level0Resource }) => {
            return waterClasses
              .filter(
                (i: any) =>
                  i.waterClass.name === filter.waterClassName &&
                  level0Resource.id !== filter.level0ResourceId,
              )
              .map(({ waterClass, balance, extractionRights }: any) => ({
                level0Resource,
                waterClass,
                balance,
                formatBalance: formatVolume(balance),
                radio: (
                  <input
                    type="radio"
                    checked={
                      value.waterClass.id === waterClass.id &&
                      value.level0Resource.id === level0Resource.id &&
                      value.extractionRights.length
                    }
                    onChange={() => {
                      onSelect({
                        level0Resource,
                        waterClass,
                        extractionRights,
                      });
                    }}
                  />
                ),
                action: (
                  <div className="flex">
                    <a
                      href={`/polestar/subscribers/${subscriberId}`}
                      title={t("common.view") as string}
                      className="btn-default flex items-center gap-3 w-min"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ExternalLinkIcon className="w-4 h-4" />
                      {t("common.view")}
                    </a>
                  </div>
                ),
              }));
          })
          .flat();
      },
    },
  );

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t(
        "approval.seasonal_water_assignments.create.select_level0_resource.table.level0_resource",
      ),
      name: "level0Resource.identifier",
    },
    {
      title: t("common.water_class"),
      name: "waterClass.name",
    },
    {
      title: t(
        "approval.seasonal_water_assignments.create.select_level0_resource.table.balance",
      ),
      name: "formatBalance",
    },
    {
      title: "",
      name: "action",
    },
  ];

  return (
    <Table fields={tableFields} data={data} stickyHeader loading={isLoading} />
  );
};

export default SelectLevel0ResouceTable;
