import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { getWaterLevels } from "@services/waterLevels";

export const useGetWaterLevels = ({
  lat,
  lng,
  n,
  options,
}: {
  lat: number;
  lng: number;
  n: number;
  options?: UseQueryOptions;
}) => {
  return useQuery<any>({
    queryKey: ["waterLevels", { lat, lng, n }],
    queryFn: () => getWaterLevels({ lat, lng, n }),
    ...options,
  });
};
