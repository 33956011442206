import AlertIcon from "@components/icons/AlertIcon";
import CheckCircleIcon from "@components/icons/CheckCircleIcon";
import Heading from "@components/layout/Heading";
import Heading1 from "@components/layout/Heading";
import Modal from "@components/layout/Modal";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import {
  requestResetAccountingPeriod,
  saveCurrentAccountingPeriodCheck,
} from "@services/accountingPeriodChecks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { formatDate } from "@utils/formatDate";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import { toastError, toastSuccess } from "@utils/toast";
import { useResetAccountingPeriod } from "@hooks/mutation/useResetAccountingPeriod";

const FinalizeChecklist: React.FunctionComponent = () => {
  const {
    currentAccountingPeriodCheck,
    setWorkflowCompleted,
    setWorkflowInstance,
    selectedCanceledAdministrativeApprovalIds,
  } = useAccountingPeriodChecksContext();
  const { t } = useTranslation();
  const { checkPermissions, loggedInInfo } = useAppContext();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { mutateAsync: requestReset } = useMutation(
    () => requestResetAccountingPeriod(currentAccountingPeriodCheck),
    {
      onSuccess: () => {
        setIsModalOpen(true);
        queryClient.invalidateQueries({
          queryKey: ["accountingPeriodChecks", "current"],
        });
      },
    }
  );

  const { mutateAsync: saveAccountingPeriodCheckForLater } = useMutation<
    any,
    any
  >(() => saveCurrentAccountingPeriodCheck(currentAccountingPeriodCheck), {
    onSuccess: () => {
      toastSuccess(t("accounting_period_checks.save_success"));
    },
    onError: () => {
      toastError(t("accounting_period_checks.save_error"));
    },
  });

  const { mutateAsync: resetAcciountingPeriod, isLoading } =
    useResetAccountingPeriod();

  const handleRequestClick = async () => {
    await requestReset();
  };
  const handleResetClick = async () => {
    setIsConfirmationModalOpen(true);
  };

  const handleOnConfirmReset = async () => {
    await saveAccountingPeriodCheckForLater();

    try {
      const [, workflowInstance] = await resetAcciountingPeriod({
        level1ResourceId: currentAccountingPeriodCheck?.level1ResourceId,
        canceledAdministrativeApprovalIds:
          selectedCanceledAdministrativeApprovalIds,
      });
      setWorkflowInstance(workflowInstance);
      setWorkflowCompleted(true);
      toastSuccess(t("accounting_period_checks.save_reset_success"));
      setIsConfirmationModalOpen(false);
    } catch (error) {
      toastError(t("accounting_period_checks.save_reset_error"));
    }
  };

  return (
    <>
      <div className="p-4 grow">
        <Heading1 light>
          {t("accounting_period_checks.reset_accounting_period")}
        </Heading1>
        <div className="flex justify-between mt-4">
          <div className="w-2/3 flex">
            <div className="w-1/2 flex flex-col">
              <Heading1 light className="text-sm">
                {t("accounting_period_checks.checks")}
              </Heading1>
              <ul className="flex flex-col gap-3">
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("common.level1wrs")}
                  </h3>

                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.permanentTradesCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.permanentTradesCheckedAt
                      )
                    )}
                  </div>
                </li>

                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.permanent_trades")}
                  </h3>

                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.permanentTradesCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.permanentTradesCheckedAt
                      )
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.seasonal_assignments")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.seasonalAssignmentsCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.seasonalAssignmentsCheckedAt
                      )
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.meter_adjustments")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.meterAdjustmentsCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.meterAdjustmentsCheckedAt
                      )
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.meter_reads")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.meterReadsCheckedBy?.name ||
                      loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(currentAccountingPeriodCheck.meterReadsCheckedAt)
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.water_harvestings")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.waterHarvestingsCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.waterHarvestingsCheckedAt
                      )
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.distribution_losses")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.distributionLossesCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.distributionLossesCheckedAt
                      )
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.forward_draws")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.forwardDrawsCheckedBy?.name ||
                      loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.forwardDrawsCheckedAt
                      )
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.billing_reports")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.billingReportsCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.billingReportsCheckedAt
                      )
                    )}
                  </div>
                </li>
              </ul>
            </div>
            <div className="w-1/2 flex flex-col">
              <Heading1 light className="text-sm">
                {t("accounting_period_checks.confirms")}
              </Heading1>
              <ul className="flex flex-col gap-3">
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.zone_volumes")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.zoneVolumesCheckedBy?.name ||
                      loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.zoneVolumesCheckedAt
                      )
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.water_charges")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.waterChargesCheckedBy?.name ||
                      loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.waterChargesCheckedAt
                      )
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period_checks.pending_admin_approvals")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.waterChargesCheckedBy?.name ||
                      loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period_checks.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.waterChargesCheckedAt
                      )
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-1/3">
            {!currentAccountingPeriodCheck?.accountingPeriod?.isActive ? (
              <button type="button" className="btn-primary" disabled>
                {t("accounting_period_checks.reset_accounting_period")}
              </button>
            ) : checkPermissions(["ResetAccountingPeriod"]) ? (
              <button
                type="button"
                className="btn-primary"
                onClick={handleResetClick}
              >
                {t("accounting_period_checks.reset_accounting_period")}
              </button>
            ) : (
              <button
                type="button"
                className="btn-primary"
                onClick={handleRequestClick}
                disabled={currentAccountingPeriodCheck?.status !== 0}
              >
                {t("accounting_period_checks.request_reset")}
              </button>
            )}
          </div>
        </div>
      </div>
      <AccountingPeriodChecklistFooter isLastStep={true} />
      <Modal open={isModalOpen}>
        <div className="py-8 px-4 flex items-center gap-4">
          <div className="mx-auto flex h-12 w-12">
            <AlertIcon className="w-12 h-12" />
          </div>
          <Heading className="text-xl font-medium leading-6 text-primary-2">
            {t("accounting_period_checks.request_reset_modal_confirmation")}
          </Heading>
        </div>
        <div className="mt-5 border-t px-4 py-2 flex flex-row-reverse gap-4">
          <button
            type="button"
            className="btn-primary text-base font-medium sm:col-start-1 sm:text-sm"
            onClick={() => setIsModalOpen(false)}
          >
            {t("common.close")}
          </button>
        </div>
      </Modal>
      <ConfirmModal
        open={isConfirmationModalOpen}
        onConfirm={handleOnConfirmReset}
        isSubmitting={isLoading}
        onClose={() => setIsConfirmationModalOpen(false)}
      >
        <Heading className="text-xl font-medium leading-6 text-primary-2">
          {t("accounting_period_checks.request_reset_confirmation_modal")}
        </Heading>
      </ConfirmModal>
    </>
  );
};

export default FinalizeChecklist;
