import Select from "react-select";

import ErrorMessage from "./ErrorMessage";
import { useAllWaterClasses } from "@hooks/query/useAllWaterClasses";
import ENV from "@config/env";

type SelectProps = React.ComponentProps<Select>;

type SelectWaterClassProps = Exclude<SelectProps, "value"> & {
  level0ResourceId?: string;
  value: string;
  errorMessage?: string;
};

const SelectWaterClass: React.FunctionComponent<SelectWaterClassProps> = ({
  level0ResourceId,
  errorMessage,
  value,
  ...restProps
}) => {
  const { isLoading, data: options = [] } = useAllWaterClasses({
    params: {
      level0ResourceId,
    },
    select: (data: any[]) => {
      return data
        .filter((i) => !ENV.EXCLUDE_WATER_CLASS_NAMES.includes(i.name))
        .map((i) => ({
          label: i.name,
          value: i.id,
          volume: i.volume,
        }));
    },
    refetchOnWindowFocus: true,
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Select
        options={options}
        value={options.find((i: any) => i.label === value) || null}
        openMenuOnFocus
        {...restProps}
      />

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectWaterClass;
