import React, { FunctionComponent } from "react";

const ForwordDrawRequestIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <g clipPath="url(#clip0_1839_4099)">
        <path
          fill="#0C3C9D"
          d="M24.82-.06a1.926 1.926 0 00-.241.06 1.923 1.923 0 00-1.502 1.923v2.044c-10.121.917-18.192 8.989-19.112 19.11H1.923a1.925 1.925 0 100 3.846h2.044c.918 10.121 8.989 18.192 19.11 19.112v2.042a1.923 1.923 0 103.846 0v-2.044c10.121-.918 18.192-8.989 19.112-19.11h2.042a1.923 1.923 0 100-3.846h-2.044c-.918-10.121-8.987-18.193-19.11-19.112V1.923A1.923 1.923 0 0024.82-.06zm-1.743 9.795v1.803a1.923 1.923 0 103.846 0V9.735a15.358 15.358 0 0113.342 13.342h-1.803a1.925 1.925 0 100 3.846h1.803a15.358 15.358 0 01-13.342 13.342v-1.804a1.923 1.923 0 10-3.846 0v1.804A15.357 15.357 0 019.735 26.923h1.803a1.923 1.923 0 100-3.846H9.735A15.357 15.357 0 0123.077 9.734z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1839_4099">
          <path fill="#fff" d="M0 0H50V50H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ForwordDrawRequestIcon;
