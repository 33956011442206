import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import ExtractionRightSelectSubscriber from "./ExtractionRightSelectSubscriber";
import ExtractionRightLinkExtractionPoints from "./ExtractionRightLinkExtractionPoints";
import ExtractionRightWorkflowCompleted from "./ExtractionRightWorkflowCompleted";
import ExtractionRightLedger from "./ExtractionRightLedger";
import ExtractionRightDetails from "@components/form/extraction_right/ExtractionRightDetails";
import WorkflowPanels from "@components/form/WorkflowPanels";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import InfoPanel from "@components/form/InfoPanel";
import WaterClassInfoTable from "@components/table/WaterClassInfoTable";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import WaterClassVolumeRemainingInfo from "@components/shared/WaterClassVolumeRemainingInfo";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { useCreateOrUpdateExtractionRightContext } from "@context/CreateOrUpdateExtractionRightContext";
import { formatVolume } from "@utils/formatVolume";

const CreateExtractionRightWorkflow = () => {
  const { t } = useTranslation();
  const {
    currentStep,
    stepHelpers,
    getInfoMessages,
    navigateForCancel,
    workflowCompleted,
    details,
    networkErrors,
    fromSubscriber,
    fromLevel0wrs,
    totalWaterClassesVolume,
    waterClasses,
    checkHasOutOfLevel0WRSRequest,
    checkHasInactivePointToLink,
    amalgamateOrSubdivideDetails,
    translationContext: context,
    remainingVolumeForSubdivide,
    info,
  } = useCreateOrUpdateExtractionRightContext();
  const { getEvidencesInfo } = useEvidenceContext();

  useEffect(() => {
    stepHelpers.setStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={Boolean(details.id)}
          forceSuccess={Boolean(details.id)}
          info={<StepInfo data={info.level1Resource.body} />}
        >
          {t(
            "approval.subdivide_and_amalgamate.create.step_1.select_level1wrs"
          )}
        </StepButton>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          info={<StepInfo data={info.subscriber.body} />}
        >
          {t("extraction_right.create.step_1.select_subscriber")}
        </StepButton>
      ),
      panel: <ExtractionRightSelectSubscriber />,
      infoPanel: (
        <InfoPanel
          warnings={[
            ...(fromSubscriber && !amalgamateOrSubdivideDetails
              ? [t("information.direct_from_subscriber_selected")]
              : []),
          ]}
          actions={[
            {
              label: t("subscriber.add_subscriber"),
              action: () => {
                window.open(
                  `/polestar/subscribers/create?newWindow=true`,
                  "_blank"
                );
              },
            },
          ]}
        >
          <div className="space-y-4">
            <p>{t("extraction_right.create.step_1.info_intro")}</p>
            <p>{t("information.subscriber_not_found_create")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          info={<StepInfo data={info.extractionRight.body} />}
          isOnChain={workflowCompleted}
        >
          {t("extraction_right.create.step_2.enter_details")}
        </StepButton>
      ),
      panel: <ExtractionRightDetails />,
      infoPanel: (
        <InfoPanel
          warnings={[
            ...(fromLevel0wrs && !amalgamateOrSubdivideDetails
              ? [t("information.direct_from_level0wrs_selected")]
              : []),
            ...(amalgamateOrSubdivideDetails
              ? [t("extraction_right.create.step_2.warning", { context })]
              : []),
            ...(context === "subdivide"
              ? [
                  t("extraction_right.create.step_2.warning_2_subdivide", {
                    context,
                    volume: formatVolume(remainingVolumeForSubdivide),
                  }),
                ]
              : []),
            ...(!details.level0wrs.hasClasses
              ? [t("extraction_right.create.step_2.warning_3")]
              : []),
          ]}
          successes={getInfoMessages("success", 1)}
          errors={getInfoMessages("error", 1)}
          actions={[
            ...(details.level1wrs?.id
              ? [
                  {
                    label: (
                      <Trans
                        i18nKey="level0wrs.add_level1wrs_level0wrs"
                        values={{
                          schemeName: details.level1wrs?.name,
                        }}
                      />
                    ),
                    action: () => {
                      window.open(
                        `/polestar/level1wrs/${details.level1wrs?.id}/level0wrs/create`,
                        "_blank"
                      );
                    },
                  },
                ]
              : []),
            ...(details?.level0wrs?.identifier
              ? [
                  {
                    label: (
                      <Trans
                        i18nKey="water_class.add_level0wrs_water_class"
                        values={{
                          level0wrsIdentifier: details?.level0wrs?.identifier,
                        }}
                      />
                    ),
                    action: () => {
                      window.open(
                        `/polestar/level1wrs/${details.level1wrs?.id}/water_classes/create?level0ResourceId=${details?.level0wrs?.id}&newWindow=true`
                      );
                    },
                  },
                ]
              : []),
          ]}
        >
          <div className="space-y-4">
            <ul className="space-y-4">
              <li>{t("extraction_right.create.step_2.info_intro")}</li>
              <li>{t("extraction_right.create.step_2.info_body_1")}</li>
              <li>{t("extraction_right.create.step_2.info_body_2")}</li>
              <li>{t("extraction_right.create.step_2.info_conclusion")}</li>
              {waterClasses.length ? (
                <>
                  <li>
                    {t("water_class.info.total_zone_volume", {
                      level0ResourceName: details.level0wrs?.identifier,
                      volume: formatVolume(
                        details.level0wrs?.yield - totalWaterClassesVolume
                      ),
                    })}
                  </li>
                  <li>
                    <WaterClassInfoTable
                      data={waterClasses}
                      totalVolume={totalWaterClassesVolume}
                      level0wrsName={details.level0wrs?.identifier}
                    />
                  </li>
                </>
              ) : null}
              {details?.waterClass?.id ? (
                <li>
                  <WaterClassVolumeRemainingInfo
                    waterClass={details.waterClass}
                    level0wrsName={details.level0wrs?.identifier}
                  />
                </li>
              ) : null}
            </ul>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={workflowCompleted}
        >
          {t("evidence.upload_menu_title")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTable={details.id}
          referenceTableId="extraction_rights"
          onNext={() => {
            stepHelpers.goToNextStep();
          }}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={() => navigateForCancel()}
          customDropboxTitle={
            t("extraction_right.create.step_3.title") as string
          }
          newUploadDescription={
            t("extraction_right.create.step_3.description", {
              extractionRightName: details.name,
            }) as string
          }
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>{t("extraction_right.create.step_3.info_intro")}</p>
            <p>{t("extraction_right.create.step_3.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          info={<StepInfo data={info.extractionPoints.body} />}
          isOnChain={workflowCompleted}
        >
          {t("extraction_right.create.step_4.link_extraction_points")}
        </StepButton>
      ),
      panel: <ExtractionRightLinkExtractionPoints />,
      infoPanel: (
        <InfoPanel
          errors={[
            ...(checkHasOutOfLevel0WRSRequest()
              ? [t("extraction_right.create.step_4.error_1")]
              : []),
            ...(checkHasInactivePointToLink()
              ? [t("extraction_right.create.step_4.error_2")]
              : []),
          ]}
          warnings={[
            ...(amalgamateOrSubdivideDetails
              ? [
                  t(
                    "extraction_right.create.step_4.warning_amalgamate_or_subdivide_1"
                  ),
                  t(
                    "extraction_right.create.step_4.warning_amalgamate_or_subdivide_2"
                  ),
                ]
              : []),
          ]}
          actions={[
            {
              label: t("extraction_right.create.step_4.action_1"),
              action: () => {
                window.open(
                  `/polestar/level1wrs/${details?.level1wrs?.id}/extraction_points/create?subscriberId=${details?.subscriber?.id}&level0ResourceId=${details?.level0wrs?.id}&newWindow=true`,
                  "_blank"
                );
              },
            },
          ]}
        >
          <div className="space-y-4">
            <p>{t("extraction_right.create.step_4.info_intro")}</p>
            <p>{t("extraction_right.create.step_4.info_body_1")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep}
          isOnChain={workflowCompleted}
          forceSuccess={workflowCompleted}
        >
          {t("extraction_right.create.step_5.ledger_entitlement")}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <ExtractionRightWorkflowCompleted />
      ) : (
        <ExtractionRightLedger />
      ),
      infoPanel: workflowCompleted ? (
        <InfoPanel
          successes={[
            t("extraction_right.create.workflow_complete.success_1", {
              extractionRightNumber: details.name,
              subscriberName: details.subscriber?.name,
            }),
          ]}
          actions={[
            {
              label: (
                <Trans
                  i18nKey="extraction_right.view_name"
                  values={{
                    extractionRightName: details.name,
                  }}
                />
              ),
              action: () =>
                window.open(
                  `/polestar/subscribers/${details.subscriber?.id}?level0ResourceId=${details.level0wrs?.id}&extractionRightId=${details.id}`,
                  "_blank"
                ),
            },
          ]}
        >
          <div className="space-y-4">
            <p>
              {fromSubscriber ? (
                <Trans
                  i18nKey="extraction_right.update.workflow_completed.info_from"
                  context="customer_dashboard"
                  values={{
                    extractionPointName: details.name,
                  }}
                />
              ) : (
                <Trans
                  i18nKey="extraction_right.update.workflow_completed.info_from"
                  context="scheme_dashboard"
                  values={{
                    extractionPointName: details.name,
                  }}
                />
              )}
            </p>
          </div>
        </InfoPanel>
      ) : (
        <InfoPanel
          successes={
            networkErrors.length > 0
              ? []
              : [t("extraction_right.create.step_5.success_1")]
          }
          errors={networkErrors}
        >
          <div className="space-y-4">
            <p>{t("information.edit_info_intro")}</p>
            <p className="pl-2">
              <Trans i18nKey="information.edit_info_subscriber" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="extraction_right.create.step_5.info_details" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="information.edit_info_evidence" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="extraction_right.create.step_5.info_extraction_point" />
            </p>
            <p>{t("information.edit_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default CreateExtractionRightWorkflow;
