import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import Tag from "@components/shared/Tag";
import ConfirmModal from "@components/shared/ConfirmModal";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { useResponseApprovalInfoRequestContext } from "@context/ResponseApprovalInfoRequestContext";
import { formatVolume } from "@utils/formatVolume";
import { toastError, toastSuccess } from "@utils/toast";
import { isValidationError } from "@utils/formError";
import { getType } from "@utils/administrativeApproval";

type ConfirmApprovalInfoRequestProps = {
  onPrevious: () => void;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  onEdit: (index: number) => void;
};

const ConfirmApprovalInfoRequest: React.FunctionComponent<
  ConfirmApprovalInfoRequestProps
> = ({ onPrevious, onSubmit, onCancel, onEdit }) => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { approval, message } = useResponseApprovalInfoRequestContext();
  const { getEvidencesInfo } = useEvidenceContext();

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await onSubmit();
      toastSuccess(t("zenith.response_approval_info_request.toast.success"));
    } catch (error: any) {
      const { data } = error?.response;
      const errorMessages = isValidationError(error)
        ? data?.errors?.map((i: any) => i.message)
        : [data?.message];

      toastError(
        <>
          <p>{t("zenith.response_approval_info_request.toast.error")}</p>
          {errorMessages?.length ? (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          ) : null}
        </>
      );
    }

    setIsLoading(false);
    setShowConfirmModal(false);
  };

  const confirmData = [
    {
      title: t("zenith.response_approval_info_request.details"),
      body: [
        {
          key: t("zenith.response_approval_info_request.from_subscriber"),
          value: approval?.seller?.name,
        },
        {
          key: t("zenith.response_approval_info_request.to_subscriber"),
          value: approval?.buyer?.name,
        },
        {
          key: t("common.level0wrs"),
          value: approval?.extractionRights?.[0].level0Resource?.identifier,
        },
        {
          key: t("common.extraction_rights"),
          value: approval?.extractionRights
            ?.map((i: any) => i.name)
            ?.join(", "),
        },
        {
          key: t("common.volume"),
          value: formatVolume(approval?.volume ? +approval.volume : 0),
        },
        {
          key: t("common.type"),
          value: <Tag status="success">{getType(approval?.type)}</Tag>,
        },
      ].filter((i) => Boolean(i.value)),
    },
    {
      title: t("notification.message"),
      body: [
        { key: t("notification.subject"), value: message.subject },
        {
          key: t("notification.body"),
          value: (
            <span className="block border-l-4 mt-1 py-1 pl-4 whitespace-pre-wrap">
              {message.body}
            </span>
          ),
        },
      ],
    },
    getEvidencesInfo(),
  ];

  return (
    <>
      <form
        className="flex flex-col grow"
        onSubmit={(e) => {
          e.preventDefault();
          setShowConfirmModal(true);
        }}
      >
        <Heading light className="mb-8">
          {t("zenith.response_approval_info_request.confirm_title")}
        </Heading>

        <div className="mb-10 grid grid-cols-1 xl:grid-cols-2 gap-4">
          <ConfirmationDetail onEdit={onEdit} data={confirmData} />
        </div>

        <div className="grow" />

        <footer className="py-4 flex gap-2">
          <button
            type="button"
            className="btn-outline-primary text-sm font-semibold"
            onClick={onPrevious}
          >
            {t("common.prev_step")}
          </button>
          <button type="submit" className="btn-primary text-sm font-semibold">
            {t("common.ledger")}
          </button>
          <button
            type="button"
            className="btn-default text-sm font-semibold"
            onClick={onCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </form>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={isLoading}
      >
        {t("zenith.response_approval_info_request.modal.confirmation")}
      </ConfirmModal>
    </>
  );
};

export default ConfirmApprovalInfoRequest;
