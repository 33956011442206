import { useTranslation } from "react-i18next";
import NotificationIcon from "../../../icons/NotificationIcon";
import Card from "../../../layout/Card";
import NotificationListCompact from "@components/form/notification/NotificationListCompact";
import { useSubscriberIndexContext } from "@context/SubscriberIndexContext";

const SubscriberNotificationWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { subscriberNotifications } = useSubscriberIndexContext();

  return (
    <Card
      className="h-full"
      header={
        <p className="flex gap-3">
          <NotificationIcon />
          {t("notification.subscriber_widget_title")}
        </p>
      }
    >
      {subscriberNotifications && subscriberNotifications?.length > 0 ? (
        <NotificationListCompact notifications={subscriberNotifications} />
      ) : (
        <div className="font-light text-primary-2 text-center text-xl">
          {t("notification.no_data")}
        </div>
      )}
    </Card>
  );
};

export default SubscriberNotificationWidget;
