import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import {
  BalanceAdjustmentType,
  useBalanceAdjustmentContext,
} from "@context/BalanceAdjustmentContext";
import { formatVolume } from "@utils/formatVolume";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { useMutation } from "@tanstack/react-query";
import { createBalanceAdjustment } from "@services/balanceAdjustment";
import { toastError, toastSuccess } from "@utils/toast";
import { useCreateEvidence } from "@hooks/mutation/useCreateEvidence";
import { sumBy } from "lodash";
import { DBTables } from "@utils/constants/dbTables";

type CreateBalanceAdjustmentLedgerProps = {
  onEditStep: (step: number) => void;
  onGoBack: () => void;
  onSave: () => void;
};

const CreateBalanceAdjustmentLedger: FunctionComponent<
  CreateBalanceAdjustmentLedgerProps
> = ({ onEditStep, onGoBack, onSave }) => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { balanceAdjustmentData, setWorkflowInstance, navigateForCancel } =
    useBalanceAdjustmentContext();

  const {
    mutateAsync: createBalanceAdjustmentMutate,
    isLoading: isCreateBalanceAdjustmentLoading,
  } = useMutation((data: any) => createBalanceAdjustment(data));
  const {
    mutateAsync: createEvidenceMutation,
    isLoading: isCreateEvidenceLoading,
  } = useCreateEvidence();

  const getConfirmData = () => {
    return [
      {
        title: t("balance_adjustment.select_type"),
        body: [
          {
            key: t("balance_adjustment.create.type"),
            value:
              balanceAdjustmentData.type ===
              BalanceAdjustmentType.WATER_HARVESTING
                ? t("balance_adjustment.water_harvesting.title")
                : balanceAdjustmentData.type ===
                    BalanceAdjustmentType.METER_READING
                  ? t("balance_adjustment.meter_reading.title")
                  : "",
          },
        ],
      },
      {
        title: t("balance_adjustment.select_level1wrs"),
        body: [
          {
            key: t("common.level1wrs"),
            value: balanceAdjustmentData.level1Resource?.name,
          },
        ],
      },
      {
        title: t("balance_adjustment.select_subscriber"),
        body: [
          {
            key: t("common.subscriber"),
            value: balanceAdjustmentData.subscriber?.name,
          },
        ],
      },
      {
        title: t("balance_adjustment.select_extraction_right"),
        body: [
          {
            key: t("common.level0wrs"),
            value:
              balanceAdjustmentData.extractionRight?.level0Resource?.identifier,
          },
        ],
      },
      {
        title: t("balance_adjustment.select_declaration"),
        body: [
          {
            key: t("common.meter"),
            value: balanceAdjustmentData.declarations
              ?.map((d) => d.meter?.serialNo)
              .join(", "),
          },
        ],
      },
      {
        title: t("balance_adjustment.enter_adjustment"),
        body: [
          {
            key: t("balance_adjustment.create.adjustment"),
            value:
              (sumBy(
                balanceAdjustmentData.declarations,
                (d: any) => d.adjustment,
              ) > 0
                ? "+"
                : "") +
              formatVolume(
                sumBy(balanceAdjustmentData.declarations, "adjustment"),
              ),
          },
        ],
      },
      {
        title: t("evidence.supporting"),
        body: [
          {
            key: t("evidence.file.name"),
            value: balanceAdjustmentData.file?.name || "-",
          },
        ],
      },
    ];
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    try {
      const [balanceAdjustment, workflowInstance] =
        await createBalanceAdjustmentMutate({
          type: balanceAdjustmentData.type,
          level0ResourceId:
            balanceAdjustmentData.extractionRight?.level0ResourceId,
          subscriberId: balanceAdjustmentData.subscriber?.id,
          declarations: balanceAdjustmentData.declarations?.map((d) => ({
            id: d.id,
            adjustment: d.adjustment,
            beforeAdjustmentReading: d.reading,
            startReading: d.startReading,
            startReadAt: d.startReadAt,
            endReading: d.endReading,
            endReadAt: d.endReadAt,
          })),
          notes: balanceAdjustmentData.notes,
        });

      if (balanceAdjustmentData.file) {
        await uploadFile(
          balanceAdjustment,
          balanceAdjustmentData.file,
          workflowInstance,
        );
      }

      toastSuccess(t("balance_adjustment.create.success"));

      setWorkflowInstance(workflowInstance);
      setShowConfirmModal(false);
      onSave();
    } catch (error: any) {
      toastError(
        <>
          <p>{t("balance_adjustment.create.save_failed")}</p>
          <p>{error.message}</p>
        </>,
      );

      setShowConfirmModal(false);
    }
  };

  const uploadFile = async (
    balanceAdjustment: any,
    file: File,
    workflowInstance: any,
  ) => {
    await createEvidenceMutation({
      title: t("balance_adjustment.create.evidence_title"),
      references: [
        {
          referenceId: balanceAdjustment?.id,
          referenceTable: DBTables.BalanceAdjustments,
        },
        {
          referenceId: balanceAdjustmentData.subscriber?.id,
          referenceTable: DBTables.Subscribers,
        },
        {
          referenceId: balanceAdjustmentData.subscriber?.level1ResourceId,
          referenceTable: DBTables.WRSHierarchies,
        },
        {
          referenceId: workflowInstance.id,
          referenceTable: DBTables.WorkflowInstances,
        },
      ],
      description: t("balance_adjustment.create.evidence_saved"),
      isPublic: false,
      isEncrypted: true,
      attachment: file,
    });
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <div className="p-6 flex flex-col h-full">
        <div>
          <Heading className="text-xl leading-6" light>
            {t("balance_adjustment.ledger")}
          </Heading>
        </div>
        <div className="flex flex-col grow gap-10 mt-6">
          <ConfirmationDetail data={getConfirmData()} onEdit={onEditStep} />
        </div>
      </div>

      <footer className="border-t border-gray-200 py-4 px-6 flex gap-3">
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={onGoBack}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary text-sm font-semibold">
          <LockClosedIcon className="w-5 h-5 mr-2" />
          {t("common.ledger")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={
          isCreateBalanceAdjustmentLoading || isCreateEvidenceLoading
        }
      >
        {t("balance_adjustment.modal_confirmation")}
      </ConfirmModal>
    </form>
  );
};

export default CreateBalanceAdjustmentLedger;
