import Select from "react-select";
import classNames from "classnames";

import { useAllSubscribers } from "@hooks/query/useAllSubscribers";
import ErrorMessage from "@components/form/ErrorMessage";

type SelectProps = React.ComponentProps<Select>;

type Props = SelectProps & {
  level1ResourceId?: string;
  errorMessage?: string;
};

const SelectSubscriber: React.FunctionComponent<Props> = ({
  value,
  className,
  level1ResourceId,
  errorMessage,
  ...props
}) => {
  const { data: options = [] } = useAllSubscribers({
    params: {
      level1ResourceId,
    },
    options: {
      select: (data: any) => {
        return data.map((item: any) => ({
          label: item.name,
          value: item.id,
          walletId: item.walletId,
          level1ResourceId: item.level1ResourceId,
        }));
      },
    },
  });

  return (
    <>
      <Select
        options={options}
        value={
          value
            ? options.find((i: any) => i.value === value || i.label === value)
            : undefined
        }
        className={classNames("text-sm", className)}
        isClearable
        {...props}
      />
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectSubscriber;
